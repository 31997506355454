import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useSessionState} from "../../../../hooks/useSessionState";
import {Table} from "../../../../ui/table/Table";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {LeaderboardUtils} from "../../../../../../shared/utils/LeaderboardUtils";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {GameIDUtils} from "../../../../../utils/GameIDUtils";
import {useEventState} from "../../../../hooks/useEventState";
import {useWindowState} from "../../../../hooks/useWindowState";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";

/******************************************************************
 * SessionLeaderboard
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionLeaderboard() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict, state} = useServices()
    const {eventData} = useEventState()
    const {isWindowFocused} = useWindowState()
    const {
        sessionID,
        sessionState,
        sessionData,
        sessionLeaderboard,
        sessionMode,
        sessionAvailableStints,
        sessionNumSectors,
        sessionClassificationMode,
        sessionLeaderboardGrouping,
        sessionStartOrderFromSession,
        sessionStartOrderReversed,
        sessionShowOnlyOwnStints,
        sessionTiming
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [initialized, setInitialized] = useState(false)
    const [leaderboardData, setLeaderboardData] = useState<ISessionLeaderboardEntry[]>(null)
    const [startOrderLeaderboard, setStartOrderLeaderboard] = useState<ISessionLeaderboardEntry[]>(null)
    const [columnsDefinition, setColumnsDefinition] = useState<ITableColumnDefinition<ISessionLeaderboardEntry>[]>(null)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setInitialized(false)
        state.sessionLeaderboard.setValue(null)
        requestLeaderboardData()
        requestSessionLogData()
    }, [sessionID])

    useEffect(() => {
        if (!isWindowFocused) return
        requestLeaderboardData()
        requestSessionLogData()
    }, [isWindowFocused])

    useEffect(() => {
        if (!startOrderLeaderboard
            || sessionMode != "race"
            || LeaderboardUtils.isAnyDriverDrivingOrFinished(sessionLeaderboard)) {
            return setLeaderboardData(sessionLeaderboard)
        }
        setLeaderboardData(
            LeaderboardUtils.mergeLeaderboardEntries(
                startOrderLeaderboard,
                sessionLeaderboard
            )
        )
    }, [sessionLeaderboard, startOrderLeaderboard, sessionMode])

    useEffect(() => {
        switch (sessionState) {
            case "finished":
                return
        }
        requestStartOrderLeaderboardData()
    }, [sessionStartOrderFromSession, sessionStartOrderReversed, sessionState, sessionClassificationMode])

    useEffect(() => {
        if (!leaderboardData) return
        updateTableDefinition()
    }, [leaderboardData, sessionLeaderboardGrouping])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function requestLeaderboardData() {
        if (!sessionID) {
            setInitialized(true)
            setLeaderboardData(null)
            return
        }
        const leaderboardEntries = await api.leaderboard.getSessionLeaderboard(sessionID)
        state.sessionLeaderboard.setValue(leaderboardEntries)
        setTimeout(() => setInitialized(true), 200)
    }

    async function requestSessionLogData() {
        state.sessionLogData.setValue(null)
        const sessionLogData = await api.log.getSessionLogData(sessionID)
        state.sessionLogData.setValue(sessionLogData)
    }

    async function requestStartOrderLeaderboardData() {
        if (!sessionStartOrderFromSession) {
            setStartOrderLeaderboard(null)
            return
        }
        const startOrderLeaderboardEntries = await api.leaderboard.getSessionLeaderboard(
            sessionStartOrderFromSession, true, sessionStartOrderReversed)
        setStartOrderLeaderboard(startOrderLeaderboardEntries)
    }

    function updateTableDefinition() {
        switch (sessionMode) {
            case "lap":
                setColumnsDefinition(lapColumnsDefinition())
                break
            case "race":
                setColumnsDefinition(raceColumnsDefinition())
                break
            case "gymkhana":
                setColumnsDefinition(gymkhanaColumnsDefinition())
                break
        }
    }

    function lapColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const sectorsSize = sessionNumSectors > 1 ? sessionNumSectors + "fr" : null
        const driverSize = sessionClassificationMode === "drivers" ? "4fr" : null
        const carSize = sessionClassificationMode === "solo" ? "4fr" : null
        const teamSize = sessionClassificationMode === "teams" ? "4fr" : null
        const stintsSize = sessionLeaderboardGrouping !== "stint" ? "80px" : null
        const stintSize = sessionLeaderboardGrouping === "stint" ? "80px" : null
        const timerSize = LeaderboardUtils.showTimer(sessionData) ? "120px" : null
        const pointsSize = LeaderboardUtils.showPoints(sessionData) ? "90px" : null
        return [
            {type: "leaderboard-driver-state", size: "15px"},
            {columnName: dict("table.head.position"), type: "leaderboard-position", size: "45px"},
            {columnName: dict("table.head.driver"), type: "leaderboard-driver", size: driverSize},
            {columnName: dict("table.head.team"), type: "leaderboard-team", size: teamSize},
            {columnName: dict("table.head.car"), type: "leaderboard-car", size: carSize},
            {columnName: dict("table.head.bestLap"), type: "leaderboard-best-lap-time", size: "120px"},
            {columnName: dict("table.head.sectorTimes"), type: "leaderboard-lap-sector-times", size: sectorsSize},
            {columnName: dict("table.head.timer"), type: "leaderboard-timer", size: timerSize},
            {columnName: dict("table.head.laps"), type: "leaderboard-laps", size: "100px"},
            {columnName: dict("table.head.stints"), type: "leaderboard-stints", size: stintsSize},
            {columnName: dict("table.head.stint"), type: "leaderboard-stint", size: stintSize},
            {columnName: dict("table.head.points"), type: "leaderboard-points", size: pointsSize},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    function raceColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const driverSize = sessionClassificationMode === "drivers" ? "4fr" : null
        const teamSize = sessionClassificationMode === "teams" ? "4fr" : null
        const carSize = sessionClassificationMode === "solo" ? "4fr" : null
        const stintsSize = sessionAvailableStints > 0 || sessionTiming == "async" ? "80px" : null
        const timerSize = LeaderboardUtils.showTimer(sessionData) ? "120px" : null
        const pointsSize = LeaderboardUtils.showPoints(sessionData) ? "90px" : null
        const jokerLapSize = LeaderboardUtils.showJokerLap(sessionData) ? "90px" : null
        return [
            {type: "leaderboard-driver-state", size: "15px"},
            {columnName: dict("table.head.position"), type: "leaderboard-position", size: "45px"},
            {columnName: dict("table.head.car"), type: "leaderboard-car", size: carSize},
            {columnName: dict("table.head.driver"), type: "leaderboard-driver", size: driverSize},
            {columnName: dict("table.head.team"), type: "leaderboard-team", size: teamSize},
            {columnName: dict("table.head.laps"), type: "leaderboard-laps", size: "100px"},
            {columnName: dict("table.head.time"), type: "leaderboard-driven-time", size: "120px"},
            {columnName: dict("table.head.avgLap"), type: "leaderboard-avg-lap-time", size: "120px"},
            {columnName: dict("table.head.bestLap"), type: "leaderboard-best-lap-time", size: "120px"},
            {columnName: dict("table.head.timer"), type: "leaderboard-timer", size: timerSize},
            {columnName: dict("table.head.jokerLap"), type: "leaderboard-joker-lap", size: jokerLapSize},
            {columnName: dict("table.head.stints"), type: "leaderboard-stints", size: stintsSize},
            {columnName: dict("table.head.points"), type: "leaderboard-points", size: pointsSize},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    function gymkhanaColumnsDefinition(): ITableColumnDefinition<ISessionLeaderboardEntry>[] {
        const driverSize = sessionClassificationMode == "drivers" ? "4fr" : null
        const teamSize = sessionClassificationMode === "teams" ? "4fr" : null
        const carSize = sessionClassificationMode === "solo" ? "4fr" : null
        const stintsSize = sessionLeaderboardGrouping !== "stint" ? "1fr" : null
        const stintSize = sessionLeaderboardGrouping === "stint" ? "1fr" : null
        const pointsSize = LeaderboardUtils.showPoints(sessionData) ? "90px" : null
        return [
            {type: "leaderboard-driver-state", size: "15px"},
            {columnName: dict("table.head.position"), type: "leaderboard-position", size: "45px"},
            {columnName: dict("table.head.driver"), type: "leaderboard-driver", size: driverSize},
            {columnName: dict("table.head.team"), type: "leaderboard-team", size: teamSize},
            {columnName: dict("table.head.car"), type: "leaderboard-car", size: carSize},
            {columnName: dict("table.head.score"), type: "leaderboard-score", size: "1.5fr"},
            {columnName: dict("table.head.time"), type: "leaderboard-driven-time", size: "1.5fr"},
            {columnName: dict("table.head.targethits"), type: "leaderboard-targethits", size: "1.3fr"},
            {columnName: dict("table.head.stints"), type: "leaderboard-stints", size: stintsSize},
            {columnName: dict("table.head.stint"), type: "leaderboard-stint", size: stintSize},
            {columnName: dict("table.head.points"), type: "leaderboard-points", size: pointsSize},
            {type: "leaderboard-entry-menu", size: "40px"}
        ]
    }

    function showLeaderboardEntry(entry: ISessionLeaderboardEntry) {
        state.showLeaderboardEntry.setValue(entry)
    }

    function showTable() {
        return leaderboardData?.length > 0 && columnsDefinition
    }

    function emptyDataInfoText() {
        if (sessionShowOnlyOwnStints) {
            return dict("session.leaderboard.onlyOwnStints.empty.info")
                .replace("{GAME_ID}", GameIDUtils.gameID(eventData, sessionData))
        }
        return dict("session.leaderboard.empty")
            .replace("{GAME_ID}", GameIDUtils.gameID(eventData, sessionData))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            className="session-leaderboard"
            isPropertyAvailable={initialized}>
            {showTable()
                ? <div style={initialized ? null : {width: "100%", height: 1, overflow: "hidden"}}>
                    <ContentLayout gap="large">
                        <Table<ISessionLeaderboardEntry>
                            style="leaderboard"
                            rowsData={leaderboardData}
                            visibleRows={25}
                            onRowClick={showLeaderboardEntry}
                            columnsDefinition={columnsDefinition}/>
                        {sessionShowOnlyOwnStints &&
                            <ContentLayout justifyItems="center">
                                <InfoText
                                    align="center"
                                    size="small"
                                    width="small"
                                    text={dict("session.leaderboard.onlyOwnStints.info")}/>
                            </ContentLayout>}
                    </ContentLayout>
                </div>
                : <EmptyDataInfo text={emptyDataInfoText()}/>}
        </ComponentInitializer>
    )

}
