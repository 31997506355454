import * as React from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {useResultState} from "../../../hooks/useResultState";
import {ResultHeader} from "./header/ResultHeader";
import {ResultScoreboard} from "./scoreboard/ResultScoreboard";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {useServices} from "../../../hooks/useServices";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * ResultPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ResultPage() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {resultID, resultData} = useResultState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showActionBar() {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={resultID}>
            <ContentLayout
                className="result-page"
                alignItems="stretch"
                rowTemplate="min-content 1fr">
                <ResultHeader/>
                <ResultScoreboard/>
            </ContentLayout>
            {showActionBar() && <ActionBar location="main">
                <IconButton
                    type="settings"
                    size="small"
                    scale={0.9}
                    onClick={() => state.showResultSetup.setValue(resultData)}/>
            </ActionBar>}
        </ComponentInitializer>
    );

}
