import {useEffect, useState} from "react";
import {useSessionState} from "../../hooks/useSessionState";
import {ISessionLeaderboardEntry} from "../../../../shared/types/ISessionLeaderboardEntry";
import {useServices} from "../../hooks/useServices";
import {SessionUtils} from "../../../../shared/utils/SessionUtils";
import {LeaderboardUtils} from "../../../../shared/utils/LeaderboardUtils";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";

/******************************************************************
 * useRaceAnnouncer
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useRaceAnnouncer(props: {
    visibleSettings: StateValue<boolean>[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker, language} = useServices()
    const {
        sessionData,
        sessionLeaderboard,
        sessionState,
        sessionAnnounceLeaderboardEveryLap
    } = useSessionState();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [previousLeaderboard, setPreviousLeaderboard] = useState<ISessionLeaderboardEntry[]>()
    const [currentLeaderboard, setCurrentLeaderboard] = useState<ISessionLeaderboardEntry[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setPreviousLeaderboard(currentLeaderboard?.map(entry => entry))
        setCurrentLeaderboard(sessionLeaderboard?.map(entry => entry))
    }, [sessionLeaderboard]);

    useEffect(() => {
        if (sessionState !== "running") return
        if (!previousLeaderboard) return
        announceStarted()
        announceFinished()
        announceCurrentLeaderboard()
        announceFinalLeaderboard()
        announceFinalBestLapTime()
    }, [currentLeaderboard])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function isSettingVisible(annouceSettingState: StateValue<boolean>) {
        return props.visibleSettings?.some(setting => setting === annouceSettingState)
    }

    function announceCurrentLeaderboard() {
        const currEntry = currentLeaderboard?.[0]
        const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, currEntry?.latestStint?._id)
        const currDrivenLaps = currEntry?.overall.drivenLaps
        const prevDrivenLaps = prevEntry?.overall.drivenLaps
        if (currDrivenLaps
            && prevDrivenLaps
            && currDrivenLaps > prevDrivenLaps
            && currDrivenLaps % sessionAnnounceLeaderboardEveryLap === 0) {
            if (isSettingVisible(speaker.race.currentLeaderboard)) {
                speaker.race.announceCurrentLeaderboard(currDrivenLaps, currentLeaderboardNames())
            }
        }
    }

    function announceStarted() {
        const current = SessionUtils.hasSessionStarted(currentLeaderboard)
        const previous = SessionUtils.hasSessionStarted(previousLeaderboard)
        if (current && !previous) {
            if (isSettingVisible(speaker.race.started)) {
                speaker.race.announceStarted()
            }
        }
    }

    function announceFinished() {
        const isSessionFullfilled = SessionUtils.hasSessionFullfilled(currentLeaderboard, sessionData)
        const isLeaderFinished = isEntryFinished(currentLeaderboard?.[0])
        const wasLeaderFinished = isEntryFinished(previousLeaderboard?.[0])
        const isLeaderboardFinished = areEntriesFinished(currentLeaderboard)
        const wasLeaderboardFinished = areEntriesFinished(previousLeaderboard)
        const announceFinished = isSessionFullfilled && isLeaderboardFinished && !wasLeaderboardFinished
        const announceWinner = isSessionFullfilled && isLeaderFinished && !wasLeaderFinished
        if (announceFinished && isSettingVisible(speaker.race.finished)) {
            speaker.race.announceFinished()
        }
        if (announceWinner && isSettingVisible(speaker.race.wonBy)) {
            speaker.race.announceWonBy(nameToAnnounce(currentLeaderboard[0]))
        }
        if (announceWinner && isSettingVisible(speaker.race.drivenTime)) {
            speaker.race.announceDrivenTime(currentLeaderboard[0].overall.drivenTimeSinceOverallFirstStartSignal)
        }
    }

    function announceFinalLeaderboard() {
        const isSessionFullfilled = SessionUtils.hasSessionFullfilled(currentLeaderboard, sessionData)
        const isLeaderboardFinished = areEntriesFinished(currentLeaderboard)
        const wasLeaderboardFinished = areEntriesFinished(previousLeaderboard)
        if (isSessionFullfilled && isLeaderboardFinished && !wasLeaderboardFinished) {
            if (isSettingVisible(speaker.race.finalLeaderboard)) {
                speaker.race.announceFinalLeaderboard(currentLeaderboardNames())
            }
        }
    }

    function announceFinalBestLapTime() {
        if (!isReadyToAnnounceFinalResults()) return
        const entry = LeaderboardUtils.getBestLapTimeEntry(currentLeaderboard)
        if (!entry) return
        if (isSettingVisible(speaker.race.bestLapTime)) {
            speaker.race.announceBestLapTime(nameToAnnounce(entry), entry.overall.bestLapTime)
        }
    }

    function isReadyToAnnounceFinalResults() {
        const isSessionFullfilled = SessionUtils.hasSessionFullfilled(currentLeaderboard, sessionData)
        const isLeaderboardFinished = areEntriesFinished(currentLeaderboard)
        const wasLeaderboardFinished = areEntriesFinished(previousLeaderboard)
        if (isSessionFullfilled && isLeaderboardFinished && !wasLeaderboardFinished) {
            return true
        }
        return false
    }

    function isEntryFinished(entry: ISessionLeaderboardEntry): boolean {
        if (!entry) return false
        if (entry.team && entry.team?.leaderboardEntries) {
            return entry.state == "finished"
                && entry.team.leaderboardEntries.every(entry => entry.state === "finished")
        }
        return entry.state === "finished"
    }

    function areEntriesFinished(entries: ISessionLeaderboardEntry[]): boolean {
        return entries?.every(entry => isEntryFinished(entry))
    }

    function currentLeaderboardNames(): string {
        let names = ""
        currentLeaderboard.forEach(entry => {
            const position = entry.position
            const name = nameToAnnounce(entry)
            names += `${name} ${position}..  `
        })
        return names
    }

    function nameToAnnounce(entry: ISessionLeaderboardEntry): string {
        if (entry.team) {
            return entry.team.name
        }
        return SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
    }

}
