import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {UserHomeHeader} from "./header/UserHomeHeader";
import {UserOnBoardingInfo} from "./onboarding/UserOnBoardingInfo";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {TrainingsInfobox} from "../../../../ui/infobox/training/TrainingsInfobox";
import {GarageInfobox} from "../../../../ui/infobox/garage/GarageInfobox";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {TracksInfobox} from "../../../../ui/infobox/tracks/TracksInfobox";
import {JoinedEventsInfobox} from "../../../../ui/infobox/events/JoinedEventsInfobox";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {useServices} from "../../../../hooks/useServices";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {UserGroupsInfobox} from "../../../../ui/infobox/groups/UserGroupsInfobox";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {UserNewsletterInfo} from "./newsletter/UserNewsletterInfo";

/******************************************************************
 * UserHomePage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserHomePage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {authUserOnboardingCompleted, authUserNewsletter} = useAuthUser()
    const {isRouteOwnerAuthUser, routeOwnerData} = useRouteOwner()
    const [isMobileMode, isMobileModeAvailable] = useMobileStyle(rootRef, 600)
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="user-home-page"
            gap={isMobile ? "large" : "medium"}
            alignContent="start">
            <UserHomeHeader/>
            <ComponentInitializer isPropertyAvailable={isMobileModeAvailable}>
                <ContentLayout>
                    {isRouteOwnerAuthUser && !authUserOnboardingCompleted &&
                        <UserOnBoardingInfo/>}
                    {isMobileMode
                        ? <ContentLayout>
                            {isRouteOwnerAuthUser && !authUserNewsletter &&
                                <UserNewsletterInfo/>}
                            {isRouteOwnerAuthUser &&
                                <TrainingsInfobox/>}
                            <JoinedEventsInfobox/>
                            <UserGroupsInfobox/>
                            <GarageInfobox/>
                            <TracksInfobox user={routeOwnerData}/>
                        </ContentLayout>
                        : <ContentLayout columns={2}>
                            <ContentLayout>
                                {isRouteOwnerAuthUser && !authUserNewsletter &&
                                    <UserNewsletterInfo/>}
                                {isRouteOwnerAuthUser &&
                                    <TrainingsInfobox/>}
                                <UserGroupsInfobox/>
                                <TracksInfobox user={routeOwnerData}/>
                            </ContentLayout>
                            <ContentLayout>
                                <JoinedEventsInfobox/>
                                <GarageInfobox/>
                            </ContentLayout>
                        </ContentLayout>}
                </ContentLayout>
            </ComponentInitializer>
            {isRouteOwnerAuthUser &&
                <ActionBar location="main">
                    <ActionBarSpacer/>
                    <IconButton
                        type="settings"
                        size="small"
                        onClick={() => state.showAuthUserSettings.setValue("default")}/>
                    <Divider style={"action-bar"}/>
                    <IconButton
                        type="academy"
                        size="small"
                        onClick={() => state.showAcademy.setValue(true)}/>
                    <ActionBarSpacer/>
                </ActionBar>}
        </ContentLayout>
    );

}
