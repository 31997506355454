/******************************************************************
 * SharedDictionaryKeys
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SharedDictionaryKeys {

    static readonly ADMIN_TITLE_DICT_KEY = "admin.title";
    static readonly HOME_DICT_KEY = "home.title.welcome";
    static readonly APP_TITLE_DICT_KEY = "app.title";
    static readonly GROUPS_DICT_KEY = "groups.title";
    static readonly EVENTS_DICT_KEY = "events.title";
    static readonly PLANS_DICT_KEY = "plans.title";
    static readonly PAGE_NOT_FOUND_TITLE_DICT_KEY = "page.not.found.title";
    static readonly DEFAULT_META_DESCRIPTION_DICT_KEY = "meta.description.default";

}
