import * as React from "react";

/******************************************************************
 * JokerIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function JokerIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="joker-icon">
            <svg width={16 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.84366 0.0545165C7.47071 0.113079 7.14708 0.371988 7.01762 0.714117C6.92515 0.957614 6.93748 1.35214 7.04228 1.55557C7.14091 1.74667 7.31352 1.92544 7.49537 2.03023L7.65565 2.11962L7.52311 2.51106C6.78029 4.70562 5.92959 7.21765 5.90493 7.2793L5.87411 7.35635L5.6676 7.25156C4.68128 6.7584 3.62099 6.46558 2.6131 6.41318L2.24323 6.39161L2.22473 6.24983C2.18158 5.92927 1.9535 5.61488 1.64527 5.45461C1.50041 5.37755 1.44801 5.3683 1.1891 5.3683C0.936355 5.3683 0.871628 5.38063 0.726763 5.44844C0.316824 5.64262 0.070245 6.07722 0.122643 6.51798C0.224357 7.38717 1.27848 7.76629 1.91034 7.16217L2.03672 7.04196L2.27097 7.29162C3.22646 8.30876 3.76277 9.79132 3.94462 11.9242C3.95695 12.0968 3.95695 12.103 3.87682 12.1215C3.46688 12.2078 3.20489 12.4852 3.17098 12.8705C3.14016 13.2219 3.39907 13.6133 3.72579 13.7119C3.9138 13.7705 12.0879 13.7705 12.2759 13.7119C12.4701 13.6534 12.7013 13.4284 12.7783 13.2188C12.9664 12.7287 12.6735 12.2386 12.1249 12.1215C12.0448 12.103 12.0448 12.0968 12.0571 11.9242C12.2451 9.76975 12.7691 8.30876 13.7123 7.30704L13.965 7.04196L14.0914 7.16217C14.5106 7.56594 15.1517 7.5567 15.5739 7.14676C16.1226 6.61661 15.913 5.68578 15.1886 5.41146C14.9421 5.31899 14.5876 5.33748 14.3564 5.45769C14.0513 5.61488 13.8201 5.92927 13.777 6.24983L13.7585 6.39161L13.3362 6.4101C12.5133 6.45017 11.6163 6.66901 10.7965 7.0358C10.6238 7.11285 10.4019 7.21149 10.3033 7.25464C10.2047 7.29779 10.1214 7.32861 10.1153 7.32245C10.106 7.3132 9.60362 5.83989 8.47861 2.51106L8.34607 2.11962L8.50635 2.03023C8.6882 1.92544 8.8608 1.74667 8.95944 1.55557C9.06423 1.35214 9.07656 0.957614 8.98718 0.717199C8.81765 0.267191 8.32141 -0.0194574 7.84366 0.0545165Z" fill="white"/>
            </svg>
        </div>
    );

}
