import * as React from "react";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {SharedConfig} from "../../../../../../../shared/config/SharedConfig";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Modal} from "../../../../modals/Modal";
import {useServices} from "../../../../../hooks/useServices";
import {useState} from "react";
import {useEventState} from "../../../../../hooks/useEventState";
import {EventDriverListType} from "../lists/EventDriversList";

/******************************************************************
 * ConfirmationMailModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ConfirmationMailModal(props: {
    type: EventDriverListType
    message: string
    defaultMessage: string
    onMessageChange: (message: string) => void
    onRequestClose: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {
        eventID,
    } = useEventState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [savingMessage, setSavingMessage] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function saveMessage() {
        setSavingMessage(true)
        switch (props.type) {
            case "pending":
                await api.event.update(eventID, {drivers: {entryFeeMessage: props.message}})
                break
            case "registered":
                await api.event.update(eventID, {drivers: {registeredMessage: props.message}})
                break
        }
        setSavingMessage(false)
        props.onRequestClose()
    }

    function modalMessageInfo() {
        switch (props.type) {
            case "pending":
                return dict("event.drivers.list.message.info.entryFee")
            case "registered":
                return dict("event.drivers.list.message.info.registered")
        }
        return ""
    }

    function isMessageValid() {
        switch (props.type) {
            case "pending":
                return props.message !== props.defaultMessage && props.message?.length > 10
            case "registered":
                return props.message !== props.defaultMessage && props.message?.length > 10
        }
        return props.message?.length > 10
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            closeAction={props.onRequestClose}>
            <Headline
                text={dict("event.drivers.list.message.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    size="small"
                    text={modalMessageInfo()}/>
                <Input
                    type="textarea"
                    label={dict("event.drivers.list.message.input.label")}
                    defaultValue={props.defaultMessage}
                    customLinkLabel={(SharedConfig.MAX_MESSAGE_LENGTH - (props.message?.length ?? 0)).toString()}
                    onCustomLinkClick={() => null}
                    onChange={props.onMessageChange}
                    maxTextareaLength={SharedConfig.MAX_MESSAGE_LENGTH}/>
                <ContentLayout columns={2}>
                    <LabelButton
                        label={dict("generic.abort")}
                        style="secondary"
                        onClick={props.onRequestClose}/>
                    <LabelButton
                        label={dict("event.drivers.list.message.confirm")}
                        style="primary"
                        progressing={savingMessage}
                        disabled={!isMessageValid()}
                        onClick={saveMessage}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
