import * as React from "react";

/******************************************************************
 * SortableIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SortableIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="sortable-icon">
            <svg width={16 * (props.scale ?? 1)} height={15 * (props.scale ?? 1)} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.70541 6.24078C4.09468 6.63005 4.72571 6.6304 5.11541 6.24155L7.29369 4.07019C7.68404 3.68069 8.31602 3.68069 8.70636 4.07019L10.8846 6.24155C11.2743 6.6304 11.9054 6.63005 12.2946 6.24078C12.6842 5.85121 12.6842 5.21958 12.2946 4.83001L8.70713 1.2425C8.31661 0.851977 7.68344 0.851977 7.29292 1.2425L3.70541 4.83001C3.31584 5.21958 3.31584 5.85121 3.70541 6.24078Z"
                    fill="white"/>
                <path
                    d="M12.2947 9.65864C11.9054 9.26936 11.2743 9.26902 10.8847 9.65787L8.70637 11.8292C8.31602 12.2187 7.68405 12.2187 7.2937 11.8292L5.11542 9.65787C4.72572 9.26902 4.09469 9.26936 3.70542 9.65863C3.31585 10.0482 3.31585 10.6798 3.70542 11.0694L7.29293 14.6569C7.68345 15.0474 8.31662 15.0474 8.70714 14.6569L12.2947 11.0694C12.6842 10.6798 12.6842 10.0482 12.2947 9.65864Z"
                    fill="white"/>
            </svg>
        </div>
    );

}
