import * as React from "react";
import {useEffect, useState} from "react";
import {IUserData} from "../../../../../shared/models/IUserData";
import {UserInfo} from "../../info/user/UserInfo";
import {IconType} from "../../icons/IconType";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Table} from "../../table/Table";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {IconButton} from "../../buttons/icon/IconButton";

/******************************************************************
 * DriverList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverList(props: {
    data: IUserData[]
    primaryIconType: IconType
    secondaryIconType?: IconType
    tertiaryIconType?: IconType
    hideFilter?: boolean
    emptyDataInfo: string
    userClickable?: boolean
    isBusy?: boolean
    visibleRows?: number
    size?: "small" | "normal"
    onPrimaryIconClick?: (userID: MongoObjectIDType) => void
    onSecondaryIconClick?: (userID: MongoObjectIDType) => void
    onTertiaryIconClick?: (userID: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    const [drivers, setDrivers] = useState<IUserData[]>();

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const data = props.data ?? []
        const users = [...data]
        users.sort((a, b) => {
            if (a.nick < b.nick) {
                return -1
            }
            if (a.nick === b.nick) {
                return 0
            }
            return 1
        })
        return setDrivers(users)
    }, [props.data])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function numButtons() {
        let num = 0
        if (props.onPrimaryIconClick) {
            num++
        }
        if (props.onSecondaryIconClick) {
            num++
        }
        if (props.onTertiaryIconClick) {
            num++
        }
        return num
    }

    function iconColumnSize() {
        switch (numButtons()) {
            case 1:
                return props.size == "small" ? "40px" : "50px"
            case 2:
                return props.size == "small" ? "70px" : "80px"
            case 3:
                return props.size == "small" ? "100px" : "110px"
        }
        return null
    }

    function iconsColumnTemplate() {
        switch (numButtons()) {
            case 1:
                return props.size == "small" ? "30px" : "40px"
            case 2:
                return props.size == "small" ? "30px 30px" : "35px 35px"
            case 3:
                return props.size == "small" ? "30px 30px 30px" : "35px 35px 35px"
        }
        return null
    }

    function columnDefinition(): ITableColumnDefinition<IUserData>[] {
        return [{
            type: "empty",
            size: "10px"
        }, {
            type: "custom",
            size: "2fr",
            customCell: (key, user) => {
                return <UserInfo
                    key={key}
                    user={user}
                    clickToShowProfile={user?._id && props.userClickable}
                    style={props.size ?? "normal"}/>
            }
        }, {
            type: "custom",
            size: iconColumnSize(),
            customCell: (key, user) => {
                return <ContentLayout
                    key={key}
                    columnTemplate={iconsColumnTemplate()}
                    gap="none">
                    {props.onSecondaryIconClick &&
                        <IconButton
                            scale={props.size == "small" ? 0.8 : 1}
                            disabled={props.isBusy}
                            type={props.secondaryIconType}
                            onClick={() => props.onSecondaryIconClick?.(user?._id)}/>}
                    {props.onPrimaryIconClick &&
                        <IconButton
                            scale={props.size == "small" ? 0.8 : 1}
                            disabled={props.isBusy}
                            type={props.primaryIconType}
                            onClick={() => onIconButtonClicked(user)}/>}
                    {props.onTertiaryIconClick &&
                        <IconButton
                            scale={props.size == "small" ? 0.8 : 1}
                            disabled={props.isBusy}
                            type={props.tertiaryIconType}
                            onClick={() => props.onTertiaryIconClick?.(user?._id)}/>}
                </ContentLayout>
            }
        }]
    }

    function onIconButtonClicked(user: IUserData) {
        props.onPrimaryIconClick?.(user?._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="driver-list">
            <Table<IUserData>
                rowsData={drivers}
                showFilter={props.hideFilter ? false : drivers?.length > 7}
                showHeader={false}
                visibleRows={props.visibleRows ?? 7}
                style={props.size == "small" ? "standard-compact" : "standard"}
                columnsDefinition={columnDefinition()}
                noDataInfo={props.emptyDataInfo}/>
        </ContentLayout>
    );

}
