import * as React from "react";

/******************************************************************
 * TranslateIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TranslateIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="translate-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.725 13.5582L8.60834 11.4665L8.63334 11.4415C10.0833 9.82484 11.1167 7.9665 11.725 5.99984H14.1667V4.33317H8.33334V2.6665H6.66668V4.33317H0.833344V5.9915H10.1417C9.58334 7.59984 8.70001 9.12484 7.50001 10.4582C6.72501 9.59984 6.08334 8.65817 5.57501 7.6665H3.90834C4.51668 9.02484 5.35001 10.3082 6.39168 11.4665L2.15001 15.6498L3.33334 16.8332L7.50001 12.6665L10.0917 15.2582L10.725 13.5582ZM15.4167 9.33317H13.75L10 19.3332H11.6667L12.6 16.8332H16.5583L17.5 19.3332H19.1667L15.4167 9.33317ZM13.2333 15.1665L14.5833 11.5582L15.9333 15.1665H13.2333Z"
                    fill="white"/>
            </svg>
        </div>
    );

}
