import * as React from "react";
import {useRef} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useTrackState} from "../../../../hooks/useTrackState";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../ui/form/elements/input/Input";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * TrackGeneralSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackGeneralSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {trackID, trackName, trackLayout} = useTrackState()
    const [isMobileMode] = useMobileStyle(rootRef, 600)
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateName(name: string): Promise<Response> {
        await api.track.update(trackID, {name: name})
        return null
    }

    async function updateLayout(layout: string): Promise<Response> {
        await api.track.update(trackID, {layout: layout})
        return null
    }

    function editable(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-general-settings"
            ref={rootRef}>
            <ContentLayout columns={isMobileMode ? 1 : 2}>
                <Input
                    type="text"
                    label={dict("track.name.label")}
                    readonly={!editable()}
                    defaultValue={trackName}
                    action={updateName}/>
                {(editable() || trackLayout) &&
                    <Input
                        type="text"
                        label={dict("track.layout.label")}
                        readonly={!editable()}
                        helpTopic="track.layout"
                        defaultValue={trackLayout}
                        action={updateLayout}/>}
            </ContentLayout>
        </ContentLayout>
    );

}
