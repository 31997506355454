import * as React from "react";
import {useEffect} from "react";
import {useServices} from "../../components/hooks/useServices";
import {FrontendConfig} from "../FrontendConfig";
import {useSocketState} from "../../components/hooks/useSocketState";
import {useAuthUser} from "../../components/hooks/useAuthUser";

/******************************************************************
 * UpdateChecker
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UpdateChecker() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, api} = useServices()
    const {authUserID, authUserExists} = useAuthUser()
    const {isSocketConnected} = useSocketState()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        checkForUpdates()
        const interval = setInterval(() => {
            checkForUpdates()
        }, FrontendConfig.APP_UPDATE_CHECK_INTERVAL)
        return () => clearInterval(interval)
    }, [isSocketConnected, authUserID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function checkForUpdates() {
        if (state.showUpdateApp.getValue() == true) return
        if (!isSocketConnected) return
        if (await showUpdateApp()) return
        if (await showUpdatedLegalVersion()) return
        if (await showUnseenReleaseNotes()) return
    }

    async function showUpdateApp(): Promise<boolean> {
        const version = await api.info.getServerVersion()
        const isNewAppVersionAvailable = version !== FrontendConfig.SERVER_VERSION
        if (isNewAppVersionAvailable) {
            state.showUpdateApp.setValue(true)
            return true
        }
        return false
    }

    async function showUpdatedLegalVersion(): Promise<boolean> {
        if (!authUserExists) return false
        const isNewLegalVersionAvailable = await api.user.isNewLegalVersionAvailableForUser()
        if (isNewLegalVersionAvailable) {
            if (state.showAcceptLegal.getValue() == true) return false
            state.showAcceptLegal.setValue(true)
            return true
        }
        return false
    }

    async function showUnseenReleaseNotes(): Promise<boolean> {
        if (!authUserExists) return false
        const hasBeenSeen = await api.user.hasLatestReleaseNotesBeenSeen()
        if (!hasBeenSeen) {
            const latestVersionReleaseNoteArticleID = await api.user.getServerVersionReleaseNoteArticleIDAndSetItToSeen()
            if (!latestVersionReleaseNoteArticleID) return false
            state.showArticle.setValue(latestVersionReleaseNoteArticleID)
            return true
        }
        return false
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <></>

}
