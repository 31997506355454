import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {AdminSimulatorGeneral} from "./general/AdminSimulatorGeneral";
import {AdminSimulatorDrivers} from "./drivers/AdminSimulatorDrivers";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {useSimulatorStore} from "./hooks/useSimulatorStore";
import {useServices} from "../../../../hooks/useServices";
import {CapiRouteUtils} from "../../../../../../shared/utils/CapiRouteUtils";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useSimulatorAPI} from "./hooks/useSimulatorAPI";
import {ICapiPingData} from "../../../../../../shared/types/ICapiPingData";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";

/******************************************************************
 * AdminSimulator
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AdminSimulator() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict} = useServices()
    const {gameID} = useSimulatorStore()
    const {ping} = useSimulatorAPI()
    const [isMobileMode] = useMobileStyle(ref, 800)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [sessionData, setSessionData] = useState<ISessionData>()
    const [pingData, setPingData] = useState<ICapiPingData>()
    const [simulatorEnabled, setSimulatorEnabled] = useState<boolean>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadSessionData()
    }, [gameID, simulatorEnabled])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadSessionData() {
        if (!gameID) {
            setSessionData(null)
            return
        }
        const enabled = await api.settings.getValue("simulator.enabled")
        setSimulatorEnabled(enabled)
        if (!enabled) return
        const capiRoute = CapiRouteUtils.parse(gameID)
        const sessionRoute = FrontendRoute.EVENT_SESSION(capiRoute.ownerType, capiRoute.ownerPath, capiRoute.eventPath, capiRoute.sessionPath)
        const sessionData = await api.session.getSessionByRoute(sessionRoute)
        const pingData = await ping(gameID)
        setPingData(pingData)
        setSessionData(sessionData)

    }

    async function toggleSimulator(enable: boolean) {
        await api.settings.setValue("simulator.enabled", enable)
        setSimulatorEnabled(await api.settings.getValue("simulator.enabled"))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-simulator" ref={ref}>
            <ContentLayout columns={2} justifyContent="space-between" columnTemplate="auto min-content">
                <Headline
                    text={dict("admin.simulator.title")}
                    style="modal"/>
                <LabelButton
                    label={dict("admin.simulator." + (simulatorEnabled ? "disable" : "enable"))}
                    style={simulatorEnabled ? "secondary-small" : "primary-small"}
                    onClick={() => toggleSimulator(!simulatorEnabled)}/>
            </ContentLayout>
            {simulatorEnabled && <>
                <AdminSimulatorGeneral
                    session={sessionData}
                    isMobileMode={isMobileMode}/>
                <AdminSimulatorDrivers
                    session={sessionData}
                    isMobileMode={isMobileMode}
                    ping={pingData}/>
            </>}
        </ContentLayout>
    );

}
