/******************************************************************
 * YouTubeUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class YouTubeUtils {

    static getVideoIDFromURL(url: string): string {
        if (!url) return ""
        const match = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?$/)
        if (!match) return ""
        return match[1]
    }

    static getVideoURLFromID(id: string): string {
        if (!id) return ""
        return `https://www.youtube.com/watch?v=${id}`
    }

    static gotoYoutubeURL(urlOrID: string) {
        if (urlOrID.indexOf("http") === 0) {
            window.open(urlOrID, "_blank")
            return
        }
        const youTubeURL = YouTubeUtils.getVideoURLFromID(urlOrID)
        window.open(youTubeURL, "_blank")
    }

}
