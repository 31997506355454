import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * ZipCodeValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ZipCodeValidation {

    static get REGEXP_CH() {
        return /^[1-9]\d{3}$/g;
    }

    static get REGEXP_DE() {
        return /^\d{5}$/g;
    }

    static get REGEXP_AT() {
        return /^\d{4}$/g;
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => {
                    return ZipCodeValidation.isValid(value)
                },
                message: ErrorCode.ZIP_CODE_INVALID
            },
        }
    };

    static isValid(zip: string): boolean {
        return [
            ZipCodeValidation.REGEXP_DE,
            ZipCodeValidation.REGEXP_CH,
            ZipCodeValidation.REGEXP_AT
        ].some(regex => regex.test(zip));
    }

}
