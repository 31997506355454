import * as React from "react";
import {FormMessageType} from "./FormMessageType";

/******************************************************************
 * FormMessage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FormMessage(props: {
    message: string,
    type: FormMessageType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {!!props.message && <div className="form-message" data-type={props.type}>
            {props.message}
        </div>}
    </>
}
