import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";

/******************************************************************
 * GroupDeleteSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupDeleteSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {groupID, groupName} = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function requestDelete() {
        state.showConfirm.setValue({
            type: "deleteGroup",
            payload: {
                groupName: groupName,
                groupID: groupID
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="group-delete-settings"
            alignSelf="start"
            justifyItems="center">
            <Spacer height="large"/>
            <InfoText
                text={dict("group.delete.info")}
                align="center"/>
            <LabelButton
                label={dict("group.delete.button.label")}
                style="primary"
                onClick={requestDelete}/>
        </ContentLayout>
    );

}
