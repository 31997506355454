import * as React from "react";

/******************************************************************
 * LeaveIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LeaveIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="leave-icon">
            <svg width={15 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.12716 2.95551V2.68192C8.12716 2.30461 7.97727 1.94276 7.71047 1.67596C7.44367 1.40916 7.08182 1.25928 6.70451 1.25928H2.17393C1.79662 1.25928 1.43476 1.40916 1.16796 1.67596C0.901167 1.94276 0.751282 2.30461 0.751282 2.68192V11.2178C0.751282 11.5951 0.901167 11.957 1.16796 12.2238C1.43476 12.4906 1.79662 12.6404 2.17393 12.6404H6.70451C7.08182 12.6404 7.44367 12.4906 7.71047 12.2238C7.97727 11.957 8.12716 11.5951 8.12716 11.2178V10.9442" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.4034 4.10474L14.2487 6.95003L11.4034 9.79532" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.83725 6.94995H14.2487" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );

}
