import * as React from "react";
import {Headline} from "../../../../../../../ui/text/headings/Headline";
import {EditableField} from "../../../../../../../ui/form/editable/EditableField";

/******************************************************************
 * SimulatorDriverProperty
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SimulatorDriverProperty(props: {
    label: string
    value: string
    onChange?: (value: string) => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function action(value) {
        props.onChange?.(value)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="simulator-driver-property">
            <Headline
                text={props.label}
                style="h5"/>
            <EditableField
                text={props.value}
                action={action}
                disabled={!props.onChange}
                useEnterToSave={true}
                ui="chromeless"/>
        </div>
    );

}
