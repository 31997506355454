import * as React from "react";
import {useEffect, useState} from "react";
import {MultiSelectOption, MultiSelectOptionType, MultiSelectPresetOptionType} from "../options/MultiSelectOption";
import {ContentLayout} from "../../../../layout/content/ContentLayout";
import {Input} from "../../input/Input";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {useServices} from "../../../../../hooks/useServices";
import {EmptyDataInfo} from "../../../../info/empty/EmptyDataInfo";
import {Badge} from "../../../../info/badge/Badge";
import {LabelButton} from "../../../../buttons/label/LabelButton";
import {SelectInput, SelectInputOptionType} from "../SelectInput";

/******************************************************************
 * MultiSelectOptionsList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MultiSelectOptionsList(props: {
    options: MultiSelectOptionType[]
    presetOptions?: MultiSelectPresetOptionType[]
    scrollable?: boolean,
    defaultValues?: string[]
    minSelected?: number,
    showHeader?: boolean,
    filterPlaceholder?: string,
    emptyDataInfo?: string,
    keepFilterOnOptionsChange?: boolean,
    onChange: (selectedValues: string[]) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [options, setOptions] = useState<MultiSelectOptionType[]>(props.options)
    const [filterString, setFilterString] = useState<string>(null)
    const [useCustomOptions, setUseCustomOptions] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateFilter(filterString)
    }, [props.options])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateFilter(filter: string) {
        setFilterString(filter)
        let availableOptions = selectedPresetOptions()
        if (isCustomPresetSelected()) {
            availableOptions = props.options
        }
        if (!filter || filter == "") {
            setOptions(availableOptions)
            return;
        }
        const filterSplits = filter.split(" ")
        const filteredOptions = availableOptions.filter(option => {
            return filterSplits.filter(filterSplit => {
                return option.text.toLowerCase().includes(filterSplit.toLowerCase())
            }).length == filterSplits.length
        })
        setOptions(filteredOptions);
    }

    async function onChange(value: string) {
        const selectedOptionsValue: string[] = []
        if (firstIsAny() && value == "any") {
            props.onChange([props.options[0].value])
            return
        }
        props.options.forEach(option => {
            if (option.value == value) {
                if (!isSelected(option)) {
                    selectedOptionsValue.push(option.value)
                }
            } else {
                if (isSelected(option)) {
                    selectedOptionsValue.push(option.value)
                }
            }
        })
        if (value != "any" && selectedOptionsValue[0] == "any") {
            selectedOptionsValue.shift()
        }
        const isValid = selectedOptionsValue.length >= (props.minSelected ?? 1);
        if (!isValid) {
            await PromisedDelay.wait(1)
            return;
        }
        props.onChange(selectedOptionsValue)
    }

    function isSelected(option: MultiSelectOptionType) {
        return props.defaultValues?.filter(value => value == option.value).length > 0;
    }

    function firstIsAny(): boolean {
        return props.options[0]?.value == "any"
    }

    function badgeText(): string {
        if (!props.options) {
            return "–"
        }
        let availableOptions = selectedPresetOptions()?.length
        if (isCustomPresetSelected()) {
            availableOptions = props.options?.length
        }
        if (!availableOptions) {
            availableOptions = 0
        }
        if (filterString) {
            return options.length + " / " + availableOptions
        }
        return availableOptions.toString() ?? "–"
    }

    function selectAllFiltered() {
        let selectedOptionsValue: string[] = []
        options.forEach(option => {
            selectedOptionsValue.push(option.value)
        })
        if (firstIsAny() && selectedOptionsValue[0] == "any") {
            selectedOptionsValue = [props.options[0].value]
        }
        props.onChange(selectedOptionsValue)
    }

    function showSelectAllFiltered(): boolean {
        return options.length > 1 && options.length < props.options.length && filterString?.length > 0
    }

    function presetOptions(): SelectInputOptionType[] {
        const presetOptions = props.presetOptions?.concat()
        if (presetOptions && presetOptions.length > 0) {
            presetOptions.push({
                label: customPresetLabelAndValues(),
                optionValues: [customPresetLabelAndValues()]
            })
        }
        return presetOptions?.map(presetOption => {
            return {
                value: presetOption.label,
                text: presetOption.label
            }
        })
    }

    function updateFilterByPreset(presetLabel: string) {
        if (presetLabel == customPresetLabelAndValues()) {
            setUseCustomOptions(true)
            props.onChange(options.map(option => option.value))
            return;
        }
        setUseCustomOptions(false)
        const presetOption = props.presetOptions?.find(presetOption => presetOption.label == presetLabel)
        if (!presetOption || !presetOption.optionValues || presetOption.optionValues.length == 0) return;
        props.onChange(presetOption.optionValues)
    }

    function selectedPresetValue(): string {
        const presetOption = selectedPresetOption()
        if (!presetOption || useCustomOptions) return customPresetLabelAndValues()
        return presetOption.label
    }

    function customPresetLabelAndValues(): string {
        return dict("multiselect.predefined.selection.custom")
    }

    function selectedPresetOptions(): MultiSelectOptionType[] {
        const presetOption = selectedPresetOption()
        if (!presetOption) {
            return [props.options[0]]
        }
        if (isCustomPresetSelected()) {
            return options
        }
        return props.options.filter(option => presetOption.optionValues?.includes(option.value))
    }

    function selectedPresetOption(): MultiSelectPresetOptionType {
        return props.presetOptions?.find(presetOption => {
            return presetOption.optionValues?.length == props.defaultValues?.length
                && presetOption.optionValues?.filter(value => props.defaultValues?.includes(value)).length == presetOption.optionValues.length
        })
    }

    function isCustomPresetSelected(): boolean {
        return selectedPresetValue() == customPresetLabelAndValues() || useCustomOptions
    }

    function showList(): boolean {
        if (isCustomPresetSelected() || filterString?.length > 0) {
            return true
        }
        if (props.presetOptions?.concat().length > 0) {
            return options?.length > 1
        }
        return true
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="multiselect-options-list">
            {props.options?.length > 0 && props.showHeader && <>
                <ContentLayout
                    framed={true}
                    className="multiselect-options-list-header"
                    gap="small">
                    {props.presetOptions?.concat().length > 0 &&
                        <SelectInput
                            defaultValue={selectedPresetValue()}
                            options={presetOptions()}
                            onChange={updateFilterByPreset}/>}
                    {(options?.length > 1 || filterString?.length > 0) &&
                        <ContentLayout
                            justifyItems="center"
                            gap="small">
                            <div className="multiselect-options-list-header-filter">
                                <Input
                                    type="text"
                                    icon="filter"
                                    size="small"
                                    placeholder={props.filterPlaceholder ?? dict("multiselect.filter.placeholder")}
                                    onChange={updateFilter}/>
                                <Badge label={badgeText()}/>
                            </div>
                            {showSelectAllFiltered() &&
                                <LabelButton
                                    label={dict("multiselect.select.all.label")}
                                    style="secondary-small"
                                    onClick={selectAllFiltered}/>}
                        </ContentLayout>}
                </ContentLayout>
            </>}
            {showList() &&
                <ContentLayout
                    className="multiselect-options-list-content"
                    scrollable={props.scrollable}>
                    {options?.length > 0
                        ? <div
                            className="multiselect-field-options">
                            {options.map(option => {
                                return <MultiSelectOption
                                    key={option.value}
                                    selected={isSelected(option)}
                                    onChange={onChange}
                                    data={option}/>
                            })}</div>
                        : <EmptyDataInfo
                            text={props.emptyDataInfo ?? dict("multiselect.empty.info")}/>}
                </ContentLayout>}
        </ContentLayout>

    );

}
