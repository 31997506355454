import * as React from "react";
import {useEffect, useState} from "react";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useServices} from "../../../../../hooks/useServices";
import {PathUtils} from "../../../../../../../shared/utils/PathUtils";
import {FrontendConfig} from "../../../../../../core/FrontendConfig";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {PasswordValidationIndicator} from "../../../../../ui/form/validation/PasswordValidationIndicator";
import {AvatarImage} from "../../../../../ui/image/avatar/AvatarImage";
import {VerifyConfirmModal} from "../../../verify/VerifyConfirmModal";

/******************************************************************
 * UserAccountSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserAccountSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {authUserData, authUserMail, authUserNick, authUserPath} = useAuthUser();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [previewPath, setPreviewPath] = useState<string>()
    const [password, setPassword] = useState<string>()
    const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false)
    const [newNick, setNewNick] = useState<string>()
    const [newPassword, setNewPassword] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!showVerifyModal) {
            setNewNick(null)
            setNewPassword(null)
        }
    }, [showVerifyModal])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function verifyAction(code: string): Promise<Response> {
        let response: Response = null
        if (newNick) {
            response = await api.user.update({nick: newNick, verificationCode: code})
        }
        if (newPassword) {
            response = await api.user.update({password: newPassword, verificationCode: code})
        }
        if (response?.status == 200) {
            setShowVerifyModal(false)
        }
        return response
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout className="user-account-settings">
            <ContentLayout justifyItems="center">
                <AvatarImage
                    user={authUserData}
                    mode="settings"
                    editable={true}/>
            </ContentLayout>
            <Input
                label={dict("user.yourpath")}
                type="text"
                readonly={true}
                defaultValue={FrontendConfig.BASE_URL + FrontendRoute.USER_BASE + "/" + (previewPath ?? authUserPath)}/>
            <Input
                label={dict("user.mail")}
                type="email"
                defaultValue={authUserMail}
                readonly={true}/>
            <Input
                key={newNick}
                label={dict("user.nick")}
                type="text"
                readonly={true}
                defaultValue={newNick ?? authUserNick}
                // DISABLED NICK CHANGE
                // pattern={NickValidation.REGEXP.source}
                // showInvalid={true}
                // preventActionOnBlur={true}
                onChange={nickValue => setPreviewPath(PathUtils.getURLFriendlyPath(nickValue))}
                // inlineHelp={dict("user.account.nick.changeinfo")}
                // action={(value) => {
                //     setNewNick(value)
                //     setShowVerifyModal(true)
                //     return null
                // }}
            />
            <ContentLayout gap="small">
                <Input
                    label={dict("user.password.change")}
                    type="password"
                    preventActionOnBlur={true}
                    placeholder=""
                    onChange={setPassword}
                    inlineHelp={dict("user.account.password.changeinfo")}
                    action={(value) => {
                        setNewPassword(value)
                        setShowVerifyModal(true)
                        return null
                    }}/>
                <PasswordValidationIndicator password={password ?? ""}/>
            </ContentLayout>
        </ContentLayout>
        {showVerifyModal &&
            <VerifyConfirmModal
                action={verifyAction}
                requestClose={() => setShowVerifyModal(false)}/>}
    </>

}
