import * as React from "react";
import {EventSidebarImage} from "../../../ui/image/event/EventSidebarImage";
import {EventSidebarInfo} from "./info/EventSidebarInfo";
import {EventSidebarMenu} from "./menu/EventSidebarMenu";
import {useEventState} from "../../../hooks/useEventState";

/******************************************************************
 * EventSidebar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSidebar() {

    /* ----------------------------------------------------------------
    * HOOKS
    * --------------------------------------------------------------*/

    const {eventImage, eventID} = useEventState();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section key={eventID} className="event-sidebar" >
            <EventSidebarImage
                enableMediaView={true}
                editable={!eventImage}/>
            <EventSidebarInfo/>
            <EventSidebarMenu/>
        </section>
    );

}
