import * as React from "react";
import {useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {ISubscriptionChangePreviewData} from "../../../../../../../shared/types/ISubscriptionChangePreviewData";
import {PaymentInfo} from "../../../../../ui/info/payment/PaymentInfo";
import {Divider} from "../../../../../ui/utils/divider/Divider";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {DriverLicenseType} from "../../../../../../../shared/types/DriverLicenseType";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {DriverLicenseUtils} from "../../../../../../../shared/utils/DriverLicenseUtils";
import {VerifySubscriptionChangeModal} from "../../../verify/VerifySubscriptionChangeModal";

/******************************************************************
 * SubscriptionChangePreviewSummary
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SubscriptionChangePreviewSummary(props: {
    requestedLicense: DriverLicenseType
    data: ISubscriptionChangePreviewData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {authUserSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showRequestVerificationCode, setShowRequestVerificationCode] = useState(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function infoText(): string {
        const realDriverLicense = authUserSubscription?.driverLicense
        const isUpgrade = DriverLicenseUtils.isUpgrade(props.requestedLicense, realDriverLicense)
        if (isUpgrade) {
            return dict("plan.change.preview.upgrade.info")
        }
        return dict("plan.change.preview.downgrade.info")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout className={"subscription-change-preview-summary"}>
            <Divider style="modal"/>
            {props.data?.immediatePayment && <>
                <PaymentInfo data={props.data?.immediatePayment}/>
                <Divider style="modal"/>
            </>}
            {props.data?.nextPayment && <>
                <PaymentInfo data={props.data?.nextPayment}/>
                <Divider style="modal"/>
            </>}
            {props.data?.nextPayment && <>
                <PaymentInfo data={props.data?.recurringPayment}/>
                <Divider style="modal"/>
            </>}
            <InfoText
                size="small"
                text={infoText()}
                align="center"/>
            <LabelButton
                label={props.requestedLicense == "free"
                    ? dict("plan.change.preview.accept.free.button.label")
                    : dict("plan.change.preview.accept.paid.button.label")}
                style="primary"
                progressing={showRequestVerificationCode}
                onClick={() => setShowRequestVerificationCode(true)}/>
        </ContentLayout>
        {showRequestVerificationCode &&
            <VerifySubscriptionChangeModal
                requestedLicense={props.requestedLicense}
                requestClose={() => setShowRequestVerificationCode(false)}/>}
    </>

}
