import * as React from "react";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {UserContextMenu} from "../../../context/user/UserContextMenu";

/******************************************************************
 * TableUserMenuCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableUserMenuCell(props: {
    user: IUserData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserID, authUserIsAdmin} = useAuthUser()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-usermenu-cell">
            {authUserID && authUserIsAdmin && <UserContextMenu user={props.user}/>}
        </div>
    );

}
