import * as React from "react";
import {useEffect, useState} from "react";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {IEventData} from "../../../../../shared/models/IEventData";
import {EventFinderMap} from "./map/EventFinderMap";
import {EventFinderTeaserList} from "./list/EventFinderTeaserList";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {Modal} from "../Modal";
import {EventUtils} from "../../../../../shared/utils/EventUtils";

/******************************************************************
 * EventsFinderModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventsFinderModal(props: {
    onMapTouchStart?: () => void
    onMapTouchEnd?: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [upcomingEvents, setUpcomingEvents] = useState<IEventData[]>([]);
    const [localEvents, setLocalEvents] = useState<IEventData[]>([]);
    const [liveEvents, setLiveEvents] = useState<IEventData[]>([]);
    const [permanentEvents, setPermanentEvents] = useState<IEventData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadEvents();
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadEvents() {
        setLoading(true)
        const allEvents = await api.event.getFinderEvents()
        setLiveEvents(EventUtils.sortDateEvents(allEvents
            ?.filter(event => EventUtils.isLiveEvent(event))))
        setUpcomingEvents(EventUtils.sortDateEvents(allEvents
            ?.filter(event => EventUtils.isUpcomingEvent(event))))
        setLocalEvents(EventUtils.sortDateEvents(allEvents
            ?.filter(event => EventUtils.isLocalEvent(event)))?.reverse())
        setPermanentEvents(allEvents?.filter(event => EventUtils.isPermanentEvent(event)))
        setLoading(false)
    }

    function hasLiveEvents() {
        return liveEvents?.length > 0
    }

    function hasPermanentEvents() {
        return permanentEvents?.length > 0
    }

    function hasUpcomingEvents() {
        return upcomingEvents?.length > 0
    }

    function hasLocalEvents() {
        return localEvents?.length > 0
    }

    function initialContentID() {
        if (hasLiveEvents()) {
            return 0
        }
        if (hasPermanentEvents()) {
            return 1
        }
        if (hasUpcomingEvents()) {
            return 2
        }
        if (hasLocalEvents()) {
            return 3
        }
        return 0
    }

    function hasEvents() {
        return hasLiveEvents() || hasUpcomingEvents() || hasLocalEvents() || hasPermanentEvents()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="extra-large"
            className="events-finder"
            closeAction={() => state.showEventsFinder.setValue(false)}>
            <ContentLayout
                gap="none"
                fullHeight={false}
                rowTemplate={"min-content 1fr"}>
                <ContentLayout columnTemplate={"auto min-content"} justifyContent={"space-between"}>
                    <Headline text={dict("events.finder.title")} style={"modal"}/>
                    <LabelButton
                        label={dict("events.help.button.label")}
                        style={"secondary-small"}
                        onClick={() => state.showAcademy.setValue(dict("academy.id.events"))}/>
                </ContentLayout>
                <ComponentInitializer isPropertyAvailable={!loading} fullHeight={false}>
                    {!loading &&
                        <ContentLayout
                            fullHeight={false}
                            rowTemplate={"min-content 1fr"}>
                            <InfoText text={dict("events.finder.info")} size="small"/>
                            <ContentLayout
                                alignContent={hasEvents() ? null : "center"}
                                justifyItems={hasEvents() ? null : "center"}>
                                {hasEvents() &&
                                    <TabbedContent
                                        showContentByID={initialContentID()}>
                                        <EventFinderTeaserList
                                            tabbedContent={{
                                                name: dict("events.finder.live.label"),
                                                disabled: !hasLiveEvents()
                                            }}
                                            isLoading={loading}
                                            areLiveEvents={true}
                                            events={liveEvents}/>
                                        <EventFinderTeaserList
                                            tabbedContent={{
                                                name: dict("events.finder.permanent.label"),
                                                disabled: !hasPermanentEvents()
                                            }}
                                            isLoading={loading}
                                            areLiveEvents={false}
                                            events={permanentEvents}/>
                                        <EventFinderTeaserList
                                            tabbedContent={{
                                                name: dict("events.finder.upcoming.label"),
                                                disabled: !hasUpcomingEvents()
                                            }}
                                            isLoading={loading}
                                            areLiveEvents={false}
                                            events={upcomingEvents}/>
                                        <EventFinderMap
                                            onTouchStart={props.onMapTouchStart}
                                            onTouchEnd={props.onMapTouchEnd}
                                            fullHeight={true}
                                            projection="globe"
                                            initialZoomLevel={4}
                                            tabbedContent={{
                                                name: dict("events.finder.map.label"),
                                                disabled: !hasLocalEvents()
                                            }}
                                            events={localEvents}/>
                                    </TabbedContent>}
                                {!hasEvents() &&
                                    <InfoText
                                        text={dict("events.finder.no.events")}
                                        size="small"
                                        style="warning"
                                        align="center"/>}
                            </ContentLayout>
                        </ContentLayout>}
                </ComponentInitializer>
            </ContentLayout>
        </Modal>
    );

}
