import * as React from "react";
import {useMemo, useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useGroupState} from "../../../../hooks/useGroupState";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {MemberInfo} from "../../../../ui/info/group/MemberInfo";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";
import {Input} from "../../../../ui/form/elements/input/Input";
import {IGroupMemberData} from "../../../../../../shared/models/submodels/IGroupMemberData";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {ArrayUtils} from "../../../../../utils/ArrayUtils";

/******************************************************************
 * GroupMembersPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupMembersPage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {groupMembers, isGroupAdmin} = useGroupState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const [isTiny] = useMobileStyle(ref, 300)
    const [isSmall] = useMobileStyle(ref, 600)
    const [isMedium] = useMobileStyle(ref, 1000)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [filteredMembers, setFilteredMembers] = useState<IGroupMemberData[]>(ArrayUtils.shuffledArray(groupMembers) || [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function subTitle(): string {
        if (!groupMembers || groupMembers?.length == 0) {
            return dict("group.members.subtitle.noMembers")
        }
        if (groupMembers?.length == 1) {
            return dict("group.members.subtitle.oneMember")
        }
        return dict("group.members.subtitle.manyMembers")
            .replaceAll("{NUM_MEMBERS}", groupMembers?.length.toString())
    }

    function filterMemberList(search: string) {
        if (!search) {
            setFilteredMembers(groupMembers || [])
            return
        }
        setFilteredMembers(groupMembers?.filter((member) => {
            const groupRole = dict("group.member.role." + (member.role ?? "member")).toLowerCase()
            const userRole = dict("user.role." + (member.user?.role ?? "member")).toLowerCase()
            const driverLicense = DriverLicenseUtils.validLicenseType(member.user?.subscription)
            const dictLicense = dict("plan.license.simple." + (driverLicense ?? "free")).toLowerCase()
            const nick = member.user?.nick?.toLowerCase()
            return groupRole?.includes(search.toLowerCase())
                || userRole?.includes(search.toLowerCase())
                || dictLicense?.includes(search.toLowerCase())
                || nick?.includes(search.toLowerCase())
        }) || [])
    }

    /* ----------------------------------------------------------------
 	 * MEMO
 	 * --------------------------------------------------------------*/

    const filteredMembersMemo = useMemo(() => {
        return filteredMembers?.map((member) => {
            return <MemberInfo
                key={member._id}
                member={member.user}
                role={member.role}/>
        });
    }, [filteredMembers]);

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!!ref.current}>
            <ContentLayout
                ref={ref}
                className="group-members-page"
                alignContent="start">
                <BasicPageTitle
                    title={dict("group.members.title")}
                    subTitle={subTitle()}/>
                {groupMembers?.length > 6 &&
                    <div
                        style={{
                            maxWidth: 400,
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }}>
                        <Input
                            type="text"
                            icon="search"
                            size="small"
                            placeholder={dict("group.members.search.placeholder")}
                            onChange={filterMemberList}/>
                    </div>}
                <ContentLayout
                    alignContent="start"
                    useFrameMargin={!isTiny}
                    columns={isTiny ? 2 : (isSmall ? 3 : (isMedium ? 4 : 5))}
                    gap="medium-to-large">
                    {filteredMembersMemo}
                </ContentLayout>
            </ContentLayout>
            {(isRouteOwnerAuthUser || isGroupAdmin) &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("group.member.add.label")}
                        icon="plus"
                        onClick={() => state.showSelectDrivers.setValue(true)}
                        style={"action-main"}/>
                </ActionBar>}
        </ComponentInitializer>
    )
}
