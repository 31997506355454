import {FrontendSpeaker} from "../../../core/services/FrontendSpeaker";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendServices} from "../../../core/FrontendServices";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";
import {FrontendConfig} from "../../../core/FrontendConfig";

/******************************************************************
 * SessionAnnouncements
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionAnnouncements {

    /******************************************************************
     * Properties
     *****************************************************************/

    public newBestLapTime = new StateValue<boolean>()
    public newBestScore = new StateValue<boolean>()
    public lapsRemaining = new StateValue<boolean>()
    public timeRemaining = new StateValue<boolean>()
    public startTimeCountdown = new StateValue<boolean>()
    public positionGained = new StateValue<boolean>()
    public positionLost = new StateValue<boolean>()
    public jokerLap = new StateValue<boolean>()
    public falseStart = new StateValue<boolean>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        this.initAnnouncements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public announceNewBestLapTime(name: string, lapTime: number) {
        if (!this.newBestLapTime.getValue()) return
        let formattedTime = SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: lapTime
        })
        let text = this.dict("speaker.text.session.newBestLapTime")
        text = text.replace("{TIME}", formattedTime)
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceNewBestScore(name: string, score: number) {
        if (!this.newBestScore.getValue()) return
        let text = this.dict("speaker.text.session.newBestScore")
        text = text.replace("{SCORE}", SpeakerUtils.getSpeakableScore(score, this._services.state.language.getValue()))
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceLapsRemaining(lapsRemaining: number) {
        if (!this.lapsRemaining.getValue()) return
        let text = this.dict("speaker.text.session.lapsRemaining")
            .replace("{LAPS}", lapsRemaining.toString())
        if (lapsRemaining === 1) {
            text = this.dict("speaker.text.session.lastLap")
        }
        if (lapsRemaining === 0) {
            return
        }
        this.speaker.speak(text)
    }

    public announceTimeRemaining(remainingSeconds: number) {
        if (!this.timeRemaining.getValue()) return
        let text = this.dict("speaker.text.session.timeRemaining")
            .replace("{TIME}", SpeakerUtils.getSpeakableTime({
                services: this._services,
                time: remainingSeconds,
                removeMillisecondsSeconds: true,
                removeSecondsIfNull: true,
            }))
        if (remainingSeconds <= FrontendConfig.ANNOUNCE_TIME_REMAINING_MIN_SECONDS) {
            text = this.dict("speaker.text.session.lastMinute")
        }
        if (remainingSeconds == 0) {
            text = this.dict("speaker.text.session.timeOver")
        }
        this.speaker.speak(text)
    }

    public announceStartTimeCountdown(remainingSeconds: number) {
        if (!this.startTimeCountdown.getValue()) return
        const data = SpeakerUtils.getStartTimeCountdownSetup(remainingSeconds)
        if (!data) return
        if (!data.dictKey) {
            this.speaker.speak(remainingSeconds + "!")
            return
        }
        let text = this.dict(data.dictKey)
            .replace("{TIME}", SpeakerUtils.getSpeakableTime({
                services: this._services,
                time: remainingSeconds,
                speakSecondsLabel: data.speakSeconds,
                removeMillisecondsSeconds: true,
                removeSecondsIfNull: true
            }))
        this.speaker.speak(text)
    }

    public announcePositionGained(name: string, newPosition: number) {
        if (!this.positionGained.getValue()) return
        let text = this.dict("speaker.text.session.positionGained")
        text = text.replace("{NAME}", name)
        text = text.replace("{POS}", newPosition.toString())
        this.speaker.speak(text, true)
    }

    public announcePositionLost(name: string, newPosition: number) {
        if (!this.positionLost.getValue()) return
        let text = this.dict("speaker.text.session.positionLost")
        text = text.replace("{NAME}", name)
        text = text.replace("{POS}", newPosition.toString())
        this.speaker.speak(text)
    }

    public announceJokerLap(name: string, currentJokerLap: number, availableJokerLaps: number) {
        if (!this.jokerLap.getValue()) return
        if (currentJokerLap === 0) return
        if (availableJokerLaps === 0) return
        if (availableJokerLaps == 1) {
            this.speaker.speak(this.dict("speaker.text.session.singleJokerLap")
                .replace("{NAME}", name))
            return
        }
        this.speaker.speak(this.dict("speaker.text.session.multipleJokerLap")
            .replace("{NAME}", name)
            .replace("{CURRENT}", currentJokerLap.toString())
            .replace("{AVAILABLE}", availableJokerLaps.toString())
        )
    }

    public announceFalseStart(name: string) {
        if (!this.falseStart.getValue()) return
        this.speaker.speak(this.dict("speaker.text.session.falseStart")
            .replace("{NAME}", name))
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAnnouncements() {
        SpeakerUtils.initAnnoucementSetting(this.newBestLapTime, "session.newBestLap")
        SpeakerUtils.initAnnoucementSetting(this.lapsRemaining, "session.lapsRemaining")
        SpeakerUtils.initAnnoucementSetting(this.timeRemaining, "session.timeRemaining")
        SpeakerUtils.initAnnoucementSetting(this.newBestScore, "session.newBestScore")
        SpeakerUtils.initAnnoucementSetting(this.startTimeCountdown, "session.startTimeCountdown")
        SpeakerUtils.initAnnoucementSetting(this.positionGained, "session.positionGained")
        SpeakerUtils.initAnnoucementSetting(this.positionLost, "session.positionLost")
        SpeakerUtils.initAnnoucementSetting(this.jokerLap, "session.jokerLap")
        SpeakerUtils.initAnnoucementSetting(this.falseStart, "session.falseStart")
    }

    private get speaker(): FrontendSpeaker {
        return this._services.speaker
    }

    private dict(key: string): string {
        return this._services.dict.get(key)
    }


}
