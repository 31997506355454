import * as React from "react";
import {useEffect, useState} from "react";
import {LabelText} from "../../../../../../ui/text/label/LabelText";
import {LabelButton} from "../../../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../../../hooks/useServices";
import {useEventState} from "../../../../../../hooks/useEventState";
import {DriverList} from "../../../../../../ui/list/driver/DriverList";
import {MongoObjectIDType} from "../../../../../../../../shared/types/MongoObjectIDType";
import {useRouteStates} from "../../../../../../hooks/useRouteStates";
import {IUserData} from "../../../../../../../../shared/models/IUserData";
import {ContentLayout} from "../../../../../../ui/layout/content/ContentLayout";
import {IconButton} from "../../../../../../ui/buttons/icon/IconButton";
import {useGroupState} from "../../../../../../hooks/useGroupState";

/******************************************************************
 * RegisteredDrivers
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RegisteredDrivers(props: {
    disabled?: boolean
    size?: "small" | "normal"
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {routeType} = useRouteStates()
    const {isGroupAdmin} = useGroupState()
    const {eventID, eventRegisteredDrivers, eventIsFinished} = useEventState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [registeredDrivers, setRegisteredDrivers] = useState<IUserData[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        switch (routeType) {
            case "event":
                setRegisteredDrivers(eventRegisteredDrivers)
        }
    }, [eventRegisteredDrivers])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function selectDriver() {
        state.showSelectDrivers.setValue(true);
    }

    async function removeUser(userID: MongoObjectIDType) {
        setIsBusy(true);
        switch (routeType) {
            case "event":
                await api.event.removeDriver(eventID, userID);
                break;
        }
        setIsBusy(false);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="registered-drivers"
            gap="small"
            disabled={props.disabled}>
            <ContentLayout
                alignItems="end"
                justifyContent="space-between"
                columnTemplate={isGroupAdmin ? "1fr min-content" : null}>
                <LabelText
                    label={dict(`drivers.registered`) + ` (${registeredDrivers?.length})`}/>
                {isGroupAdmin && !eventIsFinished && <>
                    {(props.size ?? "normal") == "normal"
                        ? <LabelButton
                            label={dict("drivers.add")}
                            disabled={isBusy}
                            style="primary-small"
                            onClick={selectDriver}/>
                        : <IconButton
                            type="add"
                            onClick={selectDriver}/>}
                </>}
            </ContentLayout>
            <DriverList
                data={registeredDrivers}
                primaryIconType="cancel"
                isBusy={isBusy}
                visibleRows={25}
                size={props.size ?? "normal"}
                userClickable={true}
                onPrimaryIconClick={isGroupAdmin && !eventIsFinished ? removeUser : null}
                emptyDataInfo={dict("drivers.add.nodata")}/>
        </ContentLayout>
    );

}
