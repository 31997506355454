import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {LabelValueText} from "../../../../../ui/text/labelvalue/LabelValueText";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {DriftUtils} from "../../../../../../../shared/utils/DriftUtils";

/******************************************************************
 * StintTrackInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintTrackInfo(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="stint-track-info"
            framed={true}
            gap="small">
            <LabelValueText
                size="small"
                label={dict("drift.gameMode")}
                value={dict("drift.gameMode." + props.stint.enterData.game_mode)}/>
            <LabelValueText
                size="small"
                label={dict("drift.trackBundle")}
                value={dict("drift.trackBundle." + DriftUtils.mapTrackBundle(props.stint.enterData.track_bundle))}/>
            <LabelValueText
                size="small"
                label={dict("drift.trackCondition")}
                value={dict("drift.trackCondition." + DriftUtils.mapTrackCondition(props.stint.enterData.track_condition))}/>
        </ContentLayout>
    );
}
