import * as React from "react";

/******************************************************************
 * WarningIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function WarningIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="warning-icon">
            <svg width={16 * (props.scale ?? 1)} height={16 * (props.scale ?? 1)} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.14159 12.7925V11.098C9.14159 11.0147 9.11335 10.9449 9.05687 10.8884C9.00038 10.8319 8.93349 10.8036 8.8562 10.8036H7.14385C7.06656 10.8036 6.99967 10.8319 6.94319 10.8884C6.8867 10.9449 6.85846 11.0147 6.85846 11.098V12.7925C6.85846 12.8757 6.8867 12.9456 6.94319 13.002C6.99967 13.0585 7.06656 13.0868 7.14385 13.0868H8.8562C8.93349 13.0868 9.00038 13.0585 9.05687 13.002C9.11335 12.9456 9.14159 12.8757 9.14159 12.7925ZM9.12375 9.45695L9.28429 5.36337C9.28429 5.29203 9.25456 5.23554 9.1951 5.19392C9.11781 5.12852 9.04646 5.09582 8.98106 5.09582H7.01899C6.95359 5.09582 6.88224 5.12852 6.80495 5.19392C6.74549 5.23554 6.71576 5.29797 6.71576 5.38121L6.86738 9.45695C6.86738 9.51641 6.89711 9.56546 6.95656 9.60411C7.01602 9.64276 7.08737 9.66208 7.17061 9.66208H8.82053C8.90376 9.66208 8.97363 9.64276 9.03011 9.60411C9.08659 9.56546 9.11781 9.51641 9.12375 9.45695ZM8.9989 1.12708L15.8483 13.6843C16.0564 14.0589 16.0504 14.4334 15.8304 14.808C15.7293 14.9804 15.5911 15.1172 15.4157 15.2183C15.2403 15.3193 15.0515 15.3699 14.8494 15.3699H1.15061C0.948456 15.3699 0.759682 15.3193 0.584284 15.2183C0.408887 15.1172 0.270651 14.9804 0.169575 14.808C-0.0504142 14.4334 -0.0563598 14.0589 0.151738 13.6843L7.0011 1.12708C7.10218 0.942763 7.2419 0.797095 7.42027 0.690073C7.59864 0.583051 7.79187 0.529541 7.99997 0.529541C8.20807 0.529541 8.4013 0.583051 8.57967 0.690073C8.75804 0.797095 8.89782 0.942763 8.9989 1.12708Z" fill="white"/>
            </svg>
        </div>
    );

}
