import * as React from "react";
import {CSSProperties, ReactElement, useEffect, useRef, useState} from "react";
import {LabelButton} from "../buttons/label/LabelButton";
import {FormMessage} from "./elements/message/FormMessage";
import {IKeyValuePair} from "../../../../shared/types/IKeyValuePair";
import {FormUtils} from "../../../utils/FormUtils";
import {FormMessageType} from "./elements/message/FormMessageType";
import {useServices} from "../../hooks/useServices";

/******************************************************************
 * Form
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Form(props: {
    children: ReactElement | ReactElement[]
    forceProcessing?: boolean,
    className?: string,
    actionLabel: string,
    successMessage?: string,
    buttonSize?: "small" | "normal"
    errorMessage?: string,
    onSuccess?: (formData: IKeyValuePair | any) => Promise<void>,
    onError?: (formData: IKeyValuePair | any) => Promise<void>,
    action: (formData: IKeyValuePair | any) => Promise<Response>
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {error} = useServices();

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const formRef = useRef<HTMLFormElement>();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();
    const [messageType, setMessageType] = useState<FormMessageType>("error");

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setIsValid(formRef.current.checkValidity());
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function checkValidity() {
        setIsValid(formRef.current.checkValidity());
    }

    function style(): CSSProperties {
        if (isSending || props.forceProcessing) {
            return {
                pointerEvents: "none",
                opacity: 0.5
            }
        }
        return null;
    }

    async function action() {
        if (isSending) return;
        if (!formRef.current.checkValidity()) return;
        setIsSending(true);
        setMessage(null);
        const formData = FormUtils.getKeyValuePairs(formRef.current);
        const response = await props.action(formData as any);
        if (response && response.status != 200) {
            if (props.errorMessage) {
                setMessage(props.errorMessage);
            } else {
                const errorJSON = await response.json();
                setMessage(error.createMessage(errorJSON));
            }
            setMessageType("error");
            if (props.onError) {
                await props.onError(formData);
            }
        } else {
            setMessage(props.successMessage);
            setMessageType("success");
            formRef.current.reset();
            if (props.onSuccess) {
                await props.onSuccess(formData);
            }
        }
        setIsSending(false);
    }

    function onSendClicked(e: MouseEvent) {
        e.preventDefault();
        action();
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <form
            className={props.className ?? "form"}
            ref={formRef}
            style={style()}
            onChange={checkValidity}>
            {props.children}
            <FormMessage message={message} type={messageType}/>
            <LabelButton
                label={props.actionLabel}
                style={props.buttonSize == "small" ? "primary-small" : "primary"}
                type="submit"
                progressing={isSending || props.forceProcessing}
                onClick={onSendClicked}
                disabled={!isValid}/>
        </form>
    );

}
