import * as React from "react";
import {ReactElement} from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {useServices} from "../../../hooks/useServices";
import {InfoText} from "../../text/infotext/InfoText";
import {PlanAmount} from "../../text/amount/PlanAmount";
import {PaddlePriceIntervalType} from "../../../../../shared/types/PaddlePriceIntervalType";
import {LabelButton, LabelButtonStyleType} from "../../buttons/label/LabelButton";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {PlanHeadline} from "../../text/headings/PlanHeadline";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanFeature} from "./feature/PlanFeature";
import {Divider} from "../../utils/divider/Divider";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {isPaddlePriceOfferType, PaddlePriceOfferType} from "../../../../../shared/types/PaddlePriceOfferType";
import {PlanPriceOffer} from "./offer/PlanPriceOffer";
import {Spacer} from "../../utils/spacer/Spacer";

/******************************************************************
 * DriverLicensePlan
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverLicensePlan(props: {
    type: DriverLicenseType
    priceID?: string
    currency?: string
    offer?: PaddlePriceOfferType
    amount?: number
    interval?: PaddlePriceIntervalType
    showSignupButton?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {authUserSubscription, authUserHasUnpaidSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function CheckoutButton(): ReactElement {
        return <LabelButton
            label={buttonLabel()}
            disabled={buttonDisabled()}
            style={buttonStyle()}
            onClick={buttonAction}/>
    }

    function previewSubscriptionChange() {
        state.showSubscriptionChangePreview.setValue({
            requestedLicense: props.type,
        })
    }

    function checkoutSubscription() {
        state.showSubscriptionCheckout.setValue({
            type: "subscribe",
            requestedLicenseType: props.type
        })
    }

    function buttonDisabled(): boolean {
        if (DriverLicenseUtils.validLicenseType(authUserSubscription) == props.type) {
            return false
        }
        return isCanceled()
            || authUserHasUnpaidSubscription
    }

    function buttonAction() {
        if (props.showSignupButton) {
            return state.showLogin.setValue("signup")
        }
        const isSameLicense: boolean = DriverLicenseUtils.validLicenseType(authUserSubscription) == props.type
        if (isSameLicense) {
            return state.showSubscriptionInfo.setValue(true)
        }
        if (authUserSubscription.subscriptionID) {
            return previewSubscriptionChange()
        }
        checkoutSubscription()
    }

    function buttonLabel(): string {
        if (props.showSignupButton) {
            return dict("plan.account.signup.label")
        }
        if (isActivated()) {
            if (isCanceled()) {
                return dict("plan.activated.canceled.label")
            }
            if (authUserHasUnpaidSubscription) {
                return dict("plan.activated.unpaid.label")
            }
            return dict("plan.activated.label")
        }
        if (isCanceled()) {
            return dict("plan.canceled.label")
                .replace("{DATE}", subscribeAgainDate())
        }
        return dict(props.type == "free"
            ? "plan.free.label"
            : "plan.order.label")
    }

    function buttonStyle(): LabelButtonStyleType {
        if (props.showSignupButton) {
            return "primary"
        }
        let style: LabelButtonStyleType = "primary"
        switch (authUserSubscription.driverLicense) {
            case "free":
                switch (props.type) {
                    case "free":
                        style = "secondary"
                        break
                }
                break
            case "basic":
                switch (props.type) {
                    case "free":
                        style = "secondary"
                        break
                }
            case "premium":
                switch (props.type) {
                    case "free":
                    case "basic":
                        style = "secondary"
                        break
                }
                break
        }
        return style
    }

    function isActivated(): boolean {
        return authUserSubscription.driverLicense == props.type
    }

    function isCanceled(): boolean {
        return !!authUserSubscription.cancelAt
    }

    function subscribeAgainDate(): string {
        const cancelAt: string = authUserSubscription.cancelAt
        const date: Date = new Date(cancelAt)
        date.setDate(date.getDate() + 1)
        return TimeUtils.formatDate(date.toISOString(), false, true)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="plan"
            framed={true}>
            {props.offer && isPaddlePriceOfferType(props.offer) &&
                <PlanPriceOffer offer={props.offer}/>}
            <ContentLayout gap="none">
                <Spacer height="small"/>
                <PlanHeadline
                    title={dict("plan.type." + props.type + ".title")}
                    size="large"/>
            </ContentLayout>
            <InfoText
                text={dict("plan.type." + props.type + ".description")}
                minHeight={80}
                size="small"/>
            <PlanAmount
                size="large"
                amount={props.amount}
                currency={props.currency}
                interval={props.interval}/>
            <CheckoutButton/>
            {authUserHasUnpaidSubscription &&
                <InfoText
                    text={dict("plan.unpaid.info")}
                    style="warning"
                    size="small"/>}
            <Divider style="modal"/>
            <ContentLayout>
                {PlanFeatureDefinitions.getFeatures().map(feature => (
                    <PlanFeature
                        key={feature}
                        feature={feature}
                        access={PlanFeatureDefinitions.getAccessType(feature, props.type)}/>

                ))}
            </ContentLayout>
            <ContentLayout
                columnTemplate="auto auto"
                justifyContent="space-between">
                <PlanHeadline
                    title={dict("plan.type." + props.type + ".title")}
                    size="small"/>
                <PlanAmount
                    size="small"
                    amount={props.amount}
                    currency={props.currency}
                    interval={props.interval}/>
            </ContentLayout>
            <CheckoutButton/>
        </ContentLayout>
    );

}
