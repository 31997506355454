import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {useState} from "react";
import {DriverLicenseType} from "../../../../../../shared/types/DriverLicenseType";
import {Modal} from "../../Modal";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {AuthUserPlanInfo} from "../../../../ui/info/plan/AuthUserPlanInfo";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";

/******************************************************************
 * SubscriptionChangedModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SubscriptionChangedModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [oldLicense] = useState<DriverLicenseType>(state.showSubscriptionChanged.getValue().oldLicense)
    const [newLicense] = useState<DriverLicenseType>(state.showSubscriptionChanged.getValue().newLicense)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function changeType(): "downgrade" | "upgrade" | "downgradeToFree" {
        if (oldLicense == "premium" && newLicense == "basic") {
            return "downgrade"
        }
        if (oldLicense != "free" && newLicense == "free") {
            return "downgradeToFree"
        }
        return "upgrade"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-changed-modal"
            width="medium"
            closeAction={() => state.showSubscriptionChanged.setValue(null)}>
            <ContentLayout>
                <Spacer height="small"/>
                <Headline
                    text={dict("plan.change." + changeType() + ".title")}
                    align="center"
                    style="h1"/>
                <InfoText
                    text={dict("plan.change." + changeType() + ".text")}
                    style="abstract"
                    align="center"/>
                <Spacer height="small"/>
                <AuthUserPlanInfo
                    type={"summary"}
                    onRequestClose={() => state.showSubscriptionChanged.setValue(null)}/>
            </ContentLayout>
        </Modal>
    );

}
