import * as React from "react";
import {ReactElement, RefObject} from "react";

/******************************************************************
 * ContentLayout
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const ContentLayout = React.forwardRef((props: {
    children?: ReactElement | ReactElement[] | null
    className?: string
    size?: "content" | "content-wide" | "max"
    disabled?: boolean
    hide?: boolean
    framed?: boolean
    frameColor?: "default" | "black" | "yellow"
    columns?: number
    columnTemplate?: string
    rowTemplate?: string
    fullHeight?: boolean
    minHeight?: number
    scrollable?: boolean
    alignItems?: "start" | "center" | "end" | "stretch"
    alignContent?: "start" | "center" | "end" | "stretch"
    justifyContent?: "start" | "stretch" | "space-between" | "center" | "end"
    justifyItems?: "start" | "center" | "end" | "stretch"
    alignSelf?: "start" | "stretch" | "center" | "end"
    marginBottom?: "for-footer-ui"
    gap?: "none" | "tiny" | "small" | "medium" | "medium-to-large" | "large"
    useFrameMargin?: boolean
    useFrameOverflowHidden?: boolean
}, ref: RefObject<HTMLDivElement>) => {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function style() {
        const {rowTemplate, columnTemplate, minHeight} = props;
        if (!rowTemplate && !columnTemplate && !minHeight) {
            return undefined;
        }
        return {
            ...(minHeight ? {minHeight: minHeight + "px"} : {}),
            ...(rowTemplate ? {gridTemplateRows: rowTemplate} : {}),
            ...(columnTemplate ? {gridTemplateColumns: columnTemplate} : {}),
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={ref}
            className={"content-layout" + (props.className ? " " + props.className : "")}
            style={style()}
            data-hide={props.hide}
            data-full-height={props.fullHeight}
            data-use-gap={props.gap !== "none"}
            data-framed={props.framed}
            data-frame-color={props.frameColor ?? "default"}
            data-use-frame-margin={props.useFrameMargin}
            data-use-frame-overflow-hidden={props.useFrameOverflowHidden != false}
            data-gap={props.gap ?? "medium"}
            data-align-self={props.alignSelf}
            data-justify-content={props.justifyContent ?? "start"}
            data-justify-items={props.justifyItems ?? "stretch"}
            data-align-items={props.alignItems ?? "start"}
            data-align-content={props.alignContent ?? "stretch"}
            data-size={props.size ?? "max"}
            data-columns={props.columns ?? 1}
            data-disabled={props.disabled}
            data-margin-bottom={props.marginBottom}
            data-scrollable={props.scrollable}>
            {props.children}
        </div>
    );
});


