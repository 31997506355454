import * as React from "react";
import {ICarData} from "../../../../../../../shared/models/ICarData";
import {Picture} from "../../../../../ui/image/Picture";
import {useServices} from "../../../../../hooks/useServices";
import {StateInfo} from "../../../../../ui/info/state/StateInfo";
import {LabelText} from "../../../../../ui/text/label/LabelText";

/******************************************************************
 * GarageCar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GarageCar(props: {
    car: ICarData
    inactive?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="garage-car"
            data-inactive={props.inactive}
            onClick={() => state.showCar.setValue(props.car)}>
            <Picture
                defaultAspectRatio={1}
                forceDefaultAspectRatio={true}
                fit="cover"
                file={props.car.images?.[0]}/>
            <div className="garage-car-meta">
                <StateInfo state={props.car.state}/>
            </div>
            <div className="garage-car-name">
                <LabelText
                    label={props.car.name}/>
            </div>
        </div>
    );

}
