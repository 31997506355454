import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * Nick
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class NickValidation {

    static get REGEXP() {
        return /^[a-zA-Z0-9\s\-_.:!?äöüÄÖÜ]{2,20}$/;
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => NickValidation.isValid(value),
                message: ErrorCode.USER_NICK_INVALID
            },
        }
    };

    static isValid(value: string): boolean {
        return NickValidation.REGEXP.test(value);
    }
}
