import * as React from "react";
import {useEffect, useRef} from "react";
import {useServices} from "../../../hooks/useServices";
import {ColorUtils} from "../../utils/color/ColorUtils";

/******************************************************************
 * MapboxGeocoder
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

declare const MapboxGeocoder: any;

export function MapboxGeocoderControl(props: {
    map: any
    mapboxgl: any
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const scriptElementRef = useRef<HTMLScriptElement>(null)
    const linkElementRef = useRef<HTMLLinkElement>(null)
    const geocoderRef = useRef<any>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, language} = useServices()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadScript()
        appendStyle()
        return () => {
            scriptElementRef.current.onload = null
            scriptElementRef.current?.remove()
            linkElementRef.current?.remove()
        }
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function loadScript() {
        scriptElementRef.current = document.createElement('script');
        scriptElementRef.current.src = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.min.js';
        scriptElementRef.current.onload = () => initdMapboxGeocoder()
        document.head.appendChild(scriptElementRef.current);
    }

    function appendStyle() {
        linkElementRef.current = document.createElement('link');
        linkElementRef.current.href = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v5.0.0/mapbox-gl-geocoder.css';
        linkElementRef.current.rel = 'stylesheet';
        document.head.appendChild(linkElementRef.current);
    }

    function initdMapboxGeocoder() {
        geocoderRef.current = new MapboxGeocoder({
            accessToken: props.mapboxgl.accessToken,
            mapboxgl: props.mapboxgl,
            language: language,
            marker: {
                color: ColorUtils.getHexStringFromCSSVariable("--red-100"),
            },
            placeholder: dict("map.search.placeholder"),
            zoom: 15,
        });
        props.map.addControl(geocoderRef.current, "top-left");
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <></>

}
