/******************************************************************
 * SizeUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

type UnitType =
    | 'Bytes'
    | 'KB'
    | 'MB'
    | 'GB'
    | 'TB'

export class SizeUtils {

    static readonly sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    static bytesToSize(bytes: number, unit?: UnitType): string {
        if (bytes === 0) return '0 Byte';

        if (unit) {
            const i = this.sizes.indexOf(unit);
            if (i !== -1) {
                return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + this.sizes[i];
            } else {
                throw new Error(`Unknown unit: ${unit}`);
            }
        } else {
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + this.sizes[i];
        }
    }
}
