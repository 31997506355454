import * as React from "react";

/******************************************************************
 * GalleryIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GalleryIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="gallery-icon">
            <svg width={20 * (props.scale ?? 1)} height={18 * (props.scale ?? 1)} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5895 3.32471H5.54688C4.21875 3.32471 3.125 4.39932 3.125 5.72041V15.304C3.125 16.6251 4.21875 17.6997 5.54688 17.6997H17.5781C18.9062 17.6997 20 16.6771 20 15.356V5.72041C20 4.39932 18.9188 3.32471 17.5895 3.32471ZM14.5758 5.7208C14.9307 5.72296 15.277 5.83018 15.5711 6.02893C15.8651 6.22768 16.0937 6.50907 16.2281 6.83759C16.3624 7.16611 16.3964 7.52705 16.3258 7.87489C16.2553 8.22272 16.0833 8.54187 15.8316 8.79207C15.5798 9.04228 15.2597 9.21233 14.9114 9.28078C14.5631 9.34923 14.2024 9.31302 13.8747 9.1767C13.547 9.04038 13.267 8.81007 13.0701 8.51482C12.8731 8.21957 12.768 7.8726 12.768 7.51768C12.7698 7.03976 12.9612 6.5821 13.3002 6.24515C13.6391 5.90819 14.0979 5.71948 14.5758 5.72041V5.7208ZM5.53086 16.5021C4.8668 16.5021 4.36445 15.9649 4.36445 15.3044V12.772L7.89805 9.61611C8.24349 9.31225 8.6913 9.15066 9.15118 9.16392C9.61107 9.17719 10.0488 9.36432 10.3762 9.6876L12.823 12.1138L8.40898 16.5017L5.53086 16.5021ZM18.75 15.304C18.7499 15.4614 18.7189 15.6173 18.6585 15.7627C18.5982 15.9081 18.5098 16.0402 18.3984 16.1515C18.287 16.2627 18.1548 16.3509 18.0093 16.411C17.8637 16.471 17.7078 16.5019 17.5504 16.5017H10.1172L14.6906 11.9575C15.0152 11.6837 15.4258 11.533 15.8504 11.5319C16.275 11.5307 16.6865 11.6792 17.0125 11.9513L18.75 13.4259V15.304Z" fill="white"/>
                <path d="M15 0.199707H2.5C1.83696 0.199707 1.20107 0.463099 0.732233 0.93194C0.263392 1.40078 0 2.03667 0 2.69971L0 12.6997C0.000457233 13.254 0.184781 13.7924 0.524089 14.2307C0.863397 14.669 1.33852 14.9823 1.875 15.1216V4.88721C1.875 4.14129 2.17132 3.42592 2.69876 2.89847C3.22621 2.37102 3.94158 2.07471 4.6875 2.07471H17.4219C17.2826 1.53822 16.9693 1.0631 16.531 0.723796C16.0927 0.384488 15.5543 0.200164 15 0.199707Z" fill="white"/>
            </svg>
        </div>
    );

}
