import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {
    SessionStartTimeDelayValidation
} from "../../../../../../../shared/validation/SessionStartTimeDelayValidation";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {SelectInput} from "../../../../../ui/form/elements/select/SelectInput";
import {FollowSessionGameIDRuleType} from "../../../../../../../shared/types/FollowSessionGameIDRuleType";

/******************************************************************
 * EventSessionSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSessionSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {
        eventID,
        eventResetSessionStartTimeDelay,
        eventFollowSessionStartTimeDelay,
        eventFollowSessionGameIDRule,
        eventFollowSessionFinishOld,
        eventIsFinished
    } = useEventState();
    const [isMobileMode] = useMobileStyle(ref, 500)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function placeholder() {
        return SessionStartTimeDelayValidation.MIN_MINUTES
            + "..." + SessionStartTimeDelayValidation.MAX_MINUTES
    }

    async function updateFollowSessionStartTimeDelay(value: string) {
        return api.event.update(eventID, {followSessionStartTimeDelay: parseInt(value)});
    }

    async function updateResetSessionStartTimeDelay(value: string) {
        return api.event.update(eventID, {resetSessionStartTimeDelay: parseInt(value)});
    }

    async function updateFollowSessionGameIDRule(rule: FollowSessionGameIDRuleType) {
        return api.event.update(eventID, {followSessionGameIDRule: rule});
    }

    async function updateFollowSessionFinishOld(value: "true" | "false") {
        return api.event.update(eventID, {followSessionFinishOld: value === "true"});
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="event-session-settings">
            <ContentLayout framed={true}>
                <ContentLayout
                    columnTemplate="auto min-content"
                    justifyContent="space-between">
                    <Headline
                        text={dict("event.settings.followsession.title")}
                        style="h5-underlined"/>
                    <LabelButton
                        label={dict("event.settings.followsession.label")}
                        style="secondary-small"
                        onClick={() => state.showHelp.setValue("event.settings.followsession")}/>
                </ContentLayout>
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <Input
                        type="number"
                        readonly={eventIsFinished}
                        minNumber={SessionStartTimeDelayValidation.MIN_MINUTES}
                        maxNumber={SessionStartTimeDelayValidation.MAX_MINUTES}
                        stepNumber={1}
                        label={dict("event.settings.session.followSessionStartTimeDelay.label")}
                        helpTopic={"event.settings.session.followSessionStartTimeDelay"}
                        placeholder={placeholder()}
                        defaultValue={eventFollowSessionStartTimeDelay?.toString()}
                        action={updateFollowSessionStartTimeDelay}/>
                    <SelectInput
                        label={dict("event.settings.session.followSessionGameIDRule.label")}
                        defaultValue={eventFollowSessionGameIDRule}
                        readonly={eventIsFinished}
                        helpTopic="event.settings.session.followSessionGameIDRule"
                        onChange={updateFollowSessionGameIDRule}
                        options={[
                            {
                                value: "increase" as FollowSessionGameIDRuleType,
                                text: dict("event.settings.session.followSessionGameIDRule.increase")
                            },
                            {
                                value: "keep" as FollowSessionGameIDRuleType,
                                text: dict("event.settings.session.followSessionGameIDRule.keep")
                            }
                        ]}/>
                    <SelectInput
                        label={dict("event.settings.session.followSessionFinishOld.label")}
                        defaultValue={eventFollowSessionFinishOld ? "true" : "false"}
                        readonly={eventIsFinished}
                        helpTopic="event.settings.session.followSessionFinishOld"
                        onChange={updateFollowSessionFinishOld}
                        options={[
                            {
                                value: "true" as FollowSessionGameIDRuleType,
                                text: dict("event.settings.session.followSessionFinishOld.true")
                            },
                            {
                                value: "false" as FollowSessionGameIDRuleType,
                                text: dict("event.settings.session.followSessionFinishOld.false")
                            }
                        ]}/>
                </ContentLayout>
            </ContentLayout>
            <ContentLayout framed={true}>
                <ContentLayout
                    columnTemplate="auto min-content"
                    justifyContent="space-between">
                    <Headline
                        text={dict("event.settings.resetsession.title")}
                        style="h5-underlined"/>
                    <LabelButton
                        label={dict("event.settings.resetsession.label")}
                        style="secondary-small"
                        onClick={() => state.showHelp.setValue("event.settings.resetsession")}/>
                </ContentLayout>
                <ContentLayout columns={isMobileMode ? 1 : 2}>
                    <Input
                        type="number"
                        readonly={eventIsFinished}
                        minNumber={SessionStartTimeDelayValidation.MIN_MINUTES}
                        maxNumber={SessionStartTimeDelayValidation.MAX_MINUTES}
                        stepNumber={1}
                        label={dict("event.settings.session.resetSessionStartTimeDelay.label")}
                        helpTopic={"event.settings.session.resetSessionStartTimeDelay"}
                        placeholder={placeholder()}
                        defaultValue={eventResetSessionStartTimeDelay?.toString()}
                        action={updateResetSessionStartTimeDelay}/>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
    );

}
