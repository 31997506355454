import * as React from "react";
import {useEffect, useState} from "react";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Map, MapProjection, MapStyle} from "../../../../ui/map/Map";
import {IMapMarkerData} from "../../../../ui/map/marker/IMapMarkerData";
import {IEventData} from "../../../../../../shared/models/IEventData";
import {MarkerFactory} from "../../../../ui/map/marker/MarkerFactory";

/******************************************************************
 * EventFinderMap
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventFinderMap(props: {
    tabbedContent?: ITabbedContentChildProps
    initialZoomLevel?: number
    projection?: MapProjection
    style?: MapStyle
    events: IEventData[]
    fullHeight?: boolean
    onTouchStart?: () => void
    onTouchEnd?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [eventMarkers, setEventMarkers] = useState<IMapMarkerData[]>([])

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const events = props.events?.filter(event => {
            const location = event.mapLocation ?? event.owner?.location
            return location?.latitude && location?.longitude
        })
        setEventMarkers(events?.map(event => MarkerFactory.createEventMarker(event)))
    }, [props.events])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            fullHeight={props.fullHeight ? true : null}
            gap={"none"}
            className="group-finder-map">
            <Map
                projection={props.projection}
                style={props.style}
                maxZoomLevel={12}
                initialZoomLevel={props.initialZoomLevel}
                height={props.fullHeight ? "full" : null}
                onTouchStart={props.onTouchStart}
                onTouchEnd={props.onTouchEnd}
                markers={eventMarkers}/>
        </ContentLayout>
    );

}
