import * as React from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Icon} from "../../icons/Icon";
import {Headline} from "../../text/headings/Headline";

/******************************************************************
 * PasswordValidationIndicatorElement
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ValidationIndicatorElement(props: {
    label: string
    valid: boolean
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            columnTemplate="20px auto"
            gap="tiny"
            alignContent="center"
            className="password-validation-indicator-element">
            <ContentLayout justifyItems="center">
                <Icon
                    type={props.valid ? "check" : "close"}
                    scale={0.8}
                    iconColor={props.valid ? "green" : "red"}/>
            </ContentLayout>
            <Headline
                text={props.label}
                style="h6"/>
        </ContentLayout>
    );

}
