import * as React from "react";
import {ContentAlignType} from "../../../../../utils/types/ContentAlignType";
import {Icon} from "../../../icons/Icon";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * TableValueCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type TableValueCellType = "number" | "text";
export type TableValueCellStyle = "normal" | "bold" | "light";

export function TableValueCell(props: {
    value: string;
    type: TableValueCellType
    style: TableValueCellStyle
    warning?: boolean
    align?: ContentAlignType
    onClick?: (value: string) => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function align() {
        if (props.align) {
            return props.align;
        }
        if (props.type === "number") {
            return "right";
        }
        return "left";
    }

    function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (props.onClick) {
            e.stopPropagation();
            props.onClick(props.value);
        }
    }

    function value() {
        if (props.type === "number" && props.value) {
            return CharUtils.replaceZeroWithO(props.value);
        }
        return props.value ?? "–";
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-value-cell"
            data-type={props.type}
            data-align={align()}
            data-style={props.style}
            data-warning={props.warning}
            data-clickable={!!props.onClick}
            onClick={onClick}>
            {value()}
            {!!props.onClick && <Icon type="link" scale={0.7}/>}
        </div>
    );

}
