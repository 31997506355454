import * as React from "react";
import {PinComponent} from "../../../../ui/utils/observer/PinComponent";
import {DriverLicenseInfo} from "../../../../ui/info/license/DriverLicenseInfo";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {IUserSubscription} from "../../../../../../shared/models/submodels/IUserSubscription";
import {DriverLicenseBadge} from "../../../../ui/info/license/DriverLicenseBadge";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {UserRoleType} from "../../../../../../shared/types/UserRoleType";
import {UserUtils} from "../../../../../../shared/utils/UserUtils";
import {useServices} from "../../../../hooks/useServices";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * UserSidebarInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserSidebarInfo(props: {
    nick: string,
    name: string,
    role: UserRoleType,
    isRouteOwner?: boolean
    useSubscription?: boolean
    subscription?: IUserSubscription
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices()
    const {authUserExists} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    function showAuthUserSubscriptionSettings() {
        state.showAuthUserSettings.setValue("subscription")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <PinComponent className="user-sidebar-info">
            <ContentLayout
                justifyContent="space-between"
                alignItems="center"
                gap="small"
                columnTemplate={props.useSubscription || UserUtils.doesRoleSkipSubscription(props.role)
                    ? "1fr min-content"
                    : null}>
                <ContentLayout gap="none">
                    <h2>{props.nick}</h2>
                </ContentLayout>
                {(props.useSubscription || UserUtils.doesRoleSkipSubscription(props.role)) &&
                    <DriverLicenseBadge
                        size="extra-large"
                        role={props.role}
                        licenseType={DriverLicenseUtils.validLicenseType(props.subscription)}
                        onClick={props.isRouteOwner && authUserExists
                            ? showAuthUserSubscriptionSettings
                            : null}/>}
            </ContentLayout>
            {(props.useSubscription || UserUtils.doesRoleSkipSubscription(props.role)) &&
                <DriverLicenseInfo
                    subscription={props.subscription}
                    role={props.role}
                    isRouteOwner={props.isRouteOwner}
                    style={"sidebar"}
                    onHeadlineClick={props.isRouteOwner && authUserExists
                        ? showAuthUserSubscriptionSettings
                        : null}/>}
        </PinComponent>
    );

}
