import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {TimeUtils} from "../../shared/utils/TimeUtils";
import {LanguageType} from "../../shared/types/LanguageType";
import {FrontendServices} from "../core/FrontendServices";
import {IUserData} from "../../shared/models/IUserData";
import {LanguageUtils} from "../../shared/utils/LanguageUtils";

/******************************************************************
 * SpeakerUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SpeakerUtils {

    static get isSpeechAPIAvailable(): boolean {
        return 'speechSynthesis' in window
    }

    static async availableVoices(lang?: string): Promise<SpeechSynthesisVoice[]> {
        if (speechSynthesis.getVoices().length == 0) {
            await PromisedDelay.waitUntilValid(() => speechSynthesis.getVoices().length > 0)
        }
        const voices = speechSynthesis.getVoices();
        let uniqueVoices = {};
        voices.forEach(voice => {
            if (lang && voice.lang.toLowerCase().includes(lang.toLowerCase())) {
                uniqueVoices[voice.name] = voice;
            }
            else if (!lang) {
                uniqueVoices[voice.name] = voice;
            }
        });
        return Object.values(uniqueVoices);
    }

    static initAnnoucementSetting(setting: StateValue<boolean>, key: string) {
        const fullKey = "speaker." + key
        setting.setValue(localStorage.getItem(fullKey) == "enabled")
        setting.onChangeSignal.add(() => {
            if (setting.getValue()) {
                localStorage.setItem(fullKey, "enabled")
            } else {
                localStorage.removeItem(fullKey)
            }
        })
    }

    static getUserAnnouncementLocalStorageKey(userID: MongoObjectIDType): string {
        if (!userID) return null
        return "speaker.announcements.user." + userID
    }

    static areUserAnnouncementsEnabled(userID: MongoObjectIDType): boolean {
        if (!userID) return false
        const key = SpeakerUtils.getUserAnnouncementLocalStorageKey(userID)
        return localStorage.getItem(key) === "enabled"
    }

    static getTeamAnnouncementLocalStorageKey(teamName: string): string {
        if (!teamName) return null
        return "speaker.announcements.team." + teamName
    }

    static areTeamAnnouncementsEnabled(teamName: string): boolean {
        if (!teamName) return false
        const key = SpeakerUtils.getTeamAnnouncementLocalStorageKey(teamName)
        return localStorage.getItem(key) === "enabled"
    }

    static getSpeakableTime(args: {
        services: FrontendServices
        time: number,
        speakSecondsLabel?: boolean,
        speakOnlyTenthSeconds?: boolean,
        removeMillisecondsSeconds?: boolean,
        removeSecondsIfNull?: boolean
    }): string {
        let formattedTime = TimeUtils.formatDrivenTime(
            args.time,
            args.removeMillisecondsSeconds
                ? 0
                : args.speakOnlyTenthSeconds ? 1 : 3,
            args.services.state.language.getValue()
        )
        if (args.removeSecondsIfNull) {
            const hasSeconds = args.time % 60 != 0
            if (!hasSeconds) {
                if (formattedTime.includes("{MIN}")) {
                    formattedTime = formattedTime.split("{MIN}")[0] + "{MIN}"
                }
                if (formattedTime.includes("{MINS}")) {
                    formattedTime = formattedTime.split("{MINS}")[0] + "{MINS}"
                }
            }
        }
        return formattedTime
            .replace("{HOUR}", " " + args.services.dict.get("speaker.text.hour"))
            .replace("{HOURS}", " " + args.services.dict.get("speaker.text.hours"))
            .replace("{MIN}", " " + args.services.dict.get("speaker.text.minute"))
            .replace("{MINS}", " " + args.services.dict.get("speaker.text.minutes"))
            .replace("{SEC}", args.speakSecondsLabel ? " " + args.services.dict.get("speaker.text.second") : "")
            .replace("{SECS}", args.speakSecondsLabel ? " " + args.services.dict.get("speaker.text.seconds") : "")
    }

    static getSpeakableScore(score: number, lang: LanguageType): string {
        if (!score) return null
        const formatter = new Intl.NumberFormat(LanguageUtils.getLangLocale(lang), {
            useGrouping: true
        });
        return formatter.format(score);
    }

    static getStartTimeCountdownSetup(remainingSeconds: number): {
        intervalInSeconds: number
        dictKey: "speaker.text.session.startTimeCountdown" | "speaker.text.session.startTimeCountdownSimple" | null
        speakSeconds: boolean
        activeUntilSeconds: number
    } {
        if (!remainingSeconds) return null
        if (remainingSeconds > (5 * 60)) {
            return {
                intervalInSeconds: 5 * 60,
                activeUntilSeconds: 5 * 60,
                speakSeconds: false,
                dictKey: "speaker.text.session.startTimeCountdown",
            }
        }
        if (remainingSeconds > 60) {
            return {
                intervalInSeconds: 60,
                activeUntilSeconds: 60,
                speakSeconds: false,
                dictKey: "speaker.text.session.startTimeCountdown",
            }
        }
        if (remainingSeconds > 10) {
            return {
                intervalInSeconds: 10,
                activeUntilSeconds: 10,
                speakSeconds: true,
                dictKey: "speaker.text.session.startTimeCountdownSimple"
            }
        }
        if (remainingSeconds > 0) {
            return {
                intervalInSeconds: 5,
                activeUntilSeconds: 5,
                speakSeconds: true,
                dictKey: null
            }
        }
        return null
    }

    static nickToSpeak(user: IUserData, language: LanguageType): string {
        return LanguageUtils.getValueFromMultilangString(user?.speakerNick, language) ?? user?.nick ?? ""
    }

    static getVoice(
        currentVoiceName: string,
        voices: SpeechSynthesisVoice[],
        language: LanguageType
    ): SpeechSynthesisVoice {
        let availableVoice = voices?.find(voice => {
            return voice.name == currentVoiceName
        })
        if (!availableVoice) {
            switch (language) {
                case "de":
                    voices?.forEach(voice => {
                        switch (voice.name) {
                            case "Anna":
                            case "Google Deutsch":
                                availableVoice = voice
                                break
                        }
                    })
                    break
                case "en":
                    voices?.forEach(voice => {
                        switch (voice.name) {
                            case "Alex":
                            case "Daniel":
                            case "Google US English":
                            case "Google UK English Male":
                                availableVoice = voice
                                break
                        }
                    })
            }
        }
        availableVoice = availableVoice || voices?.[0]
        return availableVoice
    }

}
