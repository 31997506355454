import {IUserData} from "../../shared/models/IUserData";
import {isValidLanguage, LanguageType} from "../../shared/types/LanguageType";
import {SharedConfig} from "../../shared/config/SharedConfig";

/******************************************************************
 * LanguageUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class LanguageFrontendUtils {

    static getLanguageFromHTML(authUser: IUserData): LanguageType {
        const html = document?.querySelector("html");
        const autoLanguage = html?.getAttribute("lang");
        const localStoredLanguage = localStorage.getItem("language");
        const userLanguage = authUser?.language;
        if (isValidLanguage(userLanguage)) {
            return userLanguage as LanguageType;
        }
        if (isValidLanguage(localStoredLanguage)) {
            return localStoredLanguage as LanguageType;
        }
        if (isValidLanguage(autoLanguage)) {
            return autoLanguage as LanguageType;
        }
        return SharedConfig.DEFAULT_LANGUAGE;
    }

}
