import * as React from "react"
import {useEffect, useState} from "react"
import * as moment from "moment"
import {useServices} from "../../../hooks/useServices"
import {Divider} from "../../utils/divider/Divider"

/******************************************************************
 * EventDateInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventDateInfo(props: {
    date: string,
    dateUntil?: string,
    style?: "default" | "marker"
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [day, setDay] = useState<string>(null)
    const [month, setMonth] = useState<string>(null)
    const [year, setYear] = useState<string>(null)
    const [untilDay, setUntilDay] = useState<string>(null)
    const [untilMonth, setUntilMonth] = useState<string>(null)
    const [untilYear, setUntilYear] = useState<string>(null)
    const [mode, setMode] = useState<"same-month" | "same-year" | "different-year">()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateDateStates()
    }, [props.date, props.dateUntil])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateDateStates() {
        const date = props.date ? moment(props.date) : null
        const untilDate = props.dateUntil ? (props.date == props.dateUntil ? null : moment(props.dateUntil)) : null
        const isSameDay = date?.date() == untilDate?.date() && !!date
        const isSameYear = date?.year() == untilDate?.year() && !!date
        const isSameMonth = date?.month() == untilDate?.month() && !!date
        if (isSameYear && isSameMonth) {
            setDay(isSameDay ? date.date().toString() : (date.date() + "-" + untilDate.date()))
            setMonth(props.style == "marker" ? (date.month() + 1).toString() : dict("date.month." + date.month()))
            setYear(date.year().toString())
            setUntilDay(null)
            setUntilMonth(null)
            setUntilYear(null)
            setMode("same-month")
            return;
        } else if (isSameYear) {
            setDay(date.date().toString())
            setMonth(props.style == "marker" ? (date.month() + 1).toString() : dict("date.month." + date.month()))
            setYear(null)
            setUntilDay(untilDate.date().toString())
            setUntilMonth(props.style == "marker" ? (untilDate.month() + 1).toString() : dict("date.month." + untilDate.month()))
            setUntilYear(untilDate.year().toString())
            return;
        }
        setMode("different-year")
        setDay(date ? date.date().toString() : null)
        setMonth(date ? (props.style == "marker" ? (date.month() + 1).toString() : dict("date.month." + date.month())) : null)
        setYear(date ? date.year().toString() : null)
        setUntilDay(untilDate ? untilDate.date().toString() : null)
        setUntilMonth(untilDate ? (props.style == "marker" ? (untilDate.month() + 1).toString() : dict("date.month." + untilDate.month())) : null)
        setUntilYear(untilDate ? untilDate.year().toString() : null)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="event-date-info"
            data-style={props.style ?? "default"}
            data-mode={mode}>
            <div className="event-date-info-day">{day}</div>
            <div className="event-date-info-month">{month}</div>
            <div className="event-date-info-year">{year}</div>
            {props.dateUntil && props.dateUntil != props.date && mode != "same-month" && <>
                {props.style == "marker"
                    ? " – "
                    : <Divider style="date-seperator"/>
                }</>}
            {untilDay && <div className="event-date-info-until-day">{untilDay}</div>}
            {untilMonth && <div className="event-date-info-until-month">{untilMonth}</div>}
            {untilYear && <div className="event-date-info-until-year">{untilYear}</div>}
        </div>
    )

}
