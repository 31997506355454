import * as React from "react";
import {IMailBodyBlockData} from "../../../../../../../shared/models/IMailData";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Icon} from "../../../../../ui/icons/Icon";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {MailUtils} from "../../../../../../../shared/utils/MailUtils";

/******************************************************************
 * MailUnsupportedBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailUnsupportedBlock(props: {
    bodyBlock: IMailBodyBlockData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            framed={true}
            columnTemplate="min-content auto"
            className="mail-unsupported-block"
            alignItems="center">
            <Icon type="warning" iconColor="yellow"/>
            <InfoText text={dict("mail.body.block.type.unsupported")
                    .replace("{BLOCKTYPE}", MailUtils.getBodyBlockType(props.bodyBlock))}/>
        </ContentLayout>
    );

}
