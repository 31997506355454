import * as React from "react";
import {EventStateType} from "../../../../../../shared/types/EventStateType";
import {SessionStateType} from "../../../../../../shared/types/SessionStateType";
import {StateInfo} from "../../../info/state/StateInfo";
import {SessionDriverStateType} from "../../../../../../shared/types/SessionDriverStateType";
import {ContentAlignType} from "../../../../../utils/types/ContentAlignType";

/******************************************************************
 * TableStateCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableStateCell(props: {
    state: EventStateType | SessionStateType | SessionDriverStateType
    compact?: boolean
    align?: ContentAlignType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-state-cell" data-align={props.align}>
            <StateInfo state={props.state} compact={props.compact}/>
        </div>
    );

}
