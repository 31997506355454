import * as React from "react";
import {useEffect, useState} from "react";
import {IMailBodyBlockData, IMailData, MailBodyBlockType} from "../../../../../../../shared/models/IMailData";
import {useServices} from "../../../../../hooks/useServices";
import {ActionBar} from "../../../../../ui/bar/ActionBar";
import {MailAddBodyBlockContextMenu} from "../../../../../ui/context/mail/MailAddBodyBlockContextMenu";
import {Divider} from "../../../../../ui/utils/divider/Divider";
import {IconButton} from "../../../../../ui/buttons/icon/IconButton";
import {MailUtils} from "../../../../../../../shared/utils/MailUtils";
import {InlineDeleteAction} from "../../../../../ui/form/inlineaction/delete/InlineDeleteAction";

/******************************************************************
 * MailBodyBlockActionBar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailBodyBlockActionBar(props: {
    mailData: IMailData
    block: IMailBodyBlockData
    onChange: () => void
    onToggleAddMenu?: (isOpen: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showAdd, setShowAdd] = useState<boolean>(false)
    const [showEdits, setShowEdits] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setShowAdd(!MailUtils.isUnknownBlockType(props.block))
        setShowEdits(props.mailData?.bodyBlocks?.length > 0)
    }, [props.mailData, props.block])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function addBlock(blockType: MailBodyBlockType) {
        let block: IMailBodyBlockData = {}
        switch (blockType) {
            case "image":
                block.image = {
                    file: null,
                    caption: null
                }
                break
            case "markdown":
                block.markdown = {
                    markdown: null
                }
                break
            case "verificationCode":
                block.verificationCode = {
                    code: true
                }
                break
            default:
                return
        }
        const index = props.mailData.bodyBlocks.indexOf(props.block)
        props.mailData.bodyBlocks.splice(index + 1, 0, block)
        await api.mail.updateMail(props.mailData._id, props.mailData)
        props.onChange()
    }

    async function deleteBlock() {
        props.mailData.bodyBlocks.splice(props.mailData.bodyBlocks.indexOf(props.block), 1)
        await api.mail.updateMail(props.mailData._id, props.mailData)
        props.onChange()
    }

    async function moveBlockUp() {
        const index = props.mailData.bodyBlocks.indexOf(props.block)
        const block = props.mailData.bodyBlocks.splice(index, 1)[0]
        props.mailData.bodyBlocks.splice(index - 1, 0, block)
        await api.mail.updateMail(props.mailData._id, props.mailData)
        props.onChange()
    }

    async function moveBlockDown() {
        const index = props.mailData.bodyBlocks.indexOf(props.block)
        const block = props.mailData.bodyBlocks.splice(index, 1)[0]
        props.mailData.bodyBlocks.splice(index + 1, 0, block)
        await api.mail.updateMail(props.mailData._id, props.mailData)
        props.onChange()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ActionBar location="article-block">
            {showAdd &&
                <MailAddBodyBlockContextMenu
                    onToggle={props.onToggleAddMenu}
                    onAddBlock={addBlock}/>}
            {showEdits && <>
                <Divider style="action-bar"/>
                <IconButton
                    type="up"
                    size="small"
                    disabled={props.mailData.bodyBlocks?.[0]?._id === props.block?._id}
                    onClick={moveBlockUp}/>
                <IconButton
                    type="down"
                    size="small"
                    disabled={props.mailData.bodyBlocks?.[props.mailData.bodyBlocks.length - 1]?._id === props.block?._id}
                    onClick={moveBlockDown}/>
                <Divider style="action-bar"/>
                <InlineDeleteAction
                    isBusy={false}
                    size="small"
                    onSave={deleteBlock}/>
            </>}
        </ActionBar>
    );

}
