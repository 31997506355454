import * as React from "react"
import {useEffect, useState} from "react"
import {useServices} from "../../../hooks/useServices"
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {useRouteStates} from "../../../hooks/useRouteStates"
import {FrontendRouteUtils} from "../../../../../shared/utils/FrontendRouteUtils"
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData"
import {useTrackState} from "../../../hooks/useTrackState"
import {TrackFilterContextMenu} from "../../context/track/TrackFilterContextMenu"
import {useGroupState} from "../../../hooks/useGroupState"
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {TrackUtils} from "../../../../utils/TrackUtils";

/******************************************************************
 * TrackFilterButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackFilterButton(props: {
    data: ITrackFilterData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, state, dict} = useServices()
    const {trackPath, trackOwnerType, trackOwner, trackType} = useTrackState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {routePath} = useRouteStates()
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isCurrentFilter, setIsCurrentFilter] = useState<boolean>()
    const [hover, setHover] = useState<boolean>(hasTouch)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!routePath) return
        const trackRoute = FrontendRouteUtils.parseTrackRoute(routePath)
        setIsCurrentFilter(trackRoute?.filterPath == props.data.path)
    }, [routePath])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoToFilter(e: React.MouseEvent) {
        switch (e.detail) {
            case 1:
                router.showRoute(FrontendRoute.TRACK_FILTER(trackOwnerType, trackOwner.path, trackPath, props.data.path))
                break
            case 2:
                state.showTrackFilter.setValue(props.data)
                break
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="track-filter-button"
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(hasTouch)}>
            <div
                className="label-button"
                data-style="sidebar"
                data-selected={isCurrentFilter}
                onClick={gotoToFilter}>
                {TrackUtils.trackFilterName(props.data, trackType, dict)}
            </div>
            {(isRouteOwnerAuthUser || isGroupAdmin) && hover && <TrackFilterContextMenu
                onActionSelected={() => setHover(false)}
                data={props.data}/>}
        </div>
    )

}
