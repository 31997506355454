import * as React from "react";
import {useState} from "react";
import {useServices} from "../../../../hooks/useServices";
import {IMailData} from "../../../../../../shared/models/IMailData";
import {MailRecipientsFilters, MailRecipientsFilterType} from "../../../../../../shared/types/MailRecipientsFilterType";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {ScrollBox} from "../../../../ui/utils/scrollbox/ScrollBox";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {Input} from "../../../../ui/form/elements/input/Input";
import {MailBodyBlock} from "./blocks/MailBodyBlock";
import {MailEmptyBodyBlock} from "./blocks/MailEmptyBodyBlock";
import {MailCustomRecipientsListModal} from "./recipients/MailCustomRecipientsListModal";
import {MailTemplateType, MailTemplateTypes} from "../../../../../../shared/types/MailTemplateType";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {Languages, LanguageType} from "../../../../../../shared/types/LanguageType";
import {LabelText} from "../../../../ui/text/label/LabelText";

/******************************************************************
 * MailEditor
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailEditor(props: {
    mailData: IMailData,
    showFrame?: boolean
    recipients: IUserData[]
    onMailDataChange: (mailData: IMailData) => void
    requestMailData: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showRecipientsList, setShowRecipientsList] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateSubject(subject: string): Promise<Response> {
        const updatedMail = await api.mail.updateMail(props.mailData._id, {
            subject: subject
        })
        props.onMailDataChange(updatedMail)
        return null
    }

    async function updateRecipientFilters(filter: MailRecipientsFilterType): Promise<Response> {
        const updatedMail = await api.mail.updateMail(props.mailData._id, {
            recipientsFilter: filter
        })
        props.onMailDataChange(updatedMail)
        return null
    }

    async function updateMailLanguage(mailLanguage: LanguageType | "all"): Promise<Response> {
        const updatedMail = await api.mail.updateMail(props.mailData._id, {
            language: mailLanguage
        })
        props.onMailDataChange(updatedMail)
        return null
    }

    async function updateTemplateType(templateType: MailTemplateType): Promise<Response> {
        const updatedMail = await api.mail.updateMail(props.mailData._id, {
            templateType: templateType
        })
        props.onMailDataChange(updatedMail)
        return null
    }

    async function removeRecipient(user: IUserData) {
        const updatedMail = await api.mail.updateMail(props.mailData._id, {
            customRecipients: props.mailData.customRecipients.filter(recipient => recipient._id !== user._id)
        })
        props.onMailDataChange(updatedMail)
    }

    function recipientFiltersOptions(): SelectInputOptionType[] {
        return MailRecipientsFilters.map((filter: MailRecipientsFilterType) => {
            return {value: filter, text: dict("mail.recipients.option." + filter)}
        })
    }

    function mailLanguageOptions(): SelectInputOptionType[] {
        let options: SelectInputOptionType[] = []
        options.push({value: "all", text: dict("language.all")})
        Languages.forEach((language: LanguageType) => {
            options.push({value: language, text: dict("language." + language)})
        })
        return options
    }

    function useCustomRecipients(): boolean {
        return props.mailData?.recipientsFilter === "custom"
    }

    function templateTypeOptions(): SelectInputOptionType[] {
        return MailTemplateTypes.map((template: MailTemplateType) => {
            return {value: template, text: dict("mail.template.type." + template)}
        })
    }

    function languageOptions(): SelectInputOptionType[] {
        return SharedConfig.LANGUAGES.map((language: LanguageType) => {
            return {value: language, text: dict("language." + language)}
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout className="mail-editor" framed={props.showFrame}>
            {props.mailData.state === "template" &&
                <ContentLayout gap="small" columnTemplate="min-content 1fr">
                    <SelectInput
                        size="small"
                        defaultValue={props.mailData?.language}
                        options={languageOptions()}
                        onChange={updateMailLanguage}/>
                    <SelectInput
                        size="small"
                        defaultValue={props.mailData?.templateType}
                        options={templateTypeOptions()}
                        onChange={updateTemplateType}/>
                </ContentLayout>}
            {props.mailData.state !== "template" &&
                <ContentLayout gap="small">
                    <Headline
                        text={dict(`mail.recipients.label`)}
                        style="h5-underlined"/>
                    <ContentLayout
                        gap={useCustomRecipients() ? "tiny" : "small"}
                        alignItems="center"
                        columnTemplate={useCustomRecipients()
                            ? "auto min-content min-content 1fr"
                            : "auto auto max-content"}>
                        <SelectInput
                            options={recipientFiltersOptions()}
                            size="small"
                            defaultValue={props.mailData?.recipientsFilter}
                            onChange={updateRecipientFilters}/>
                        {!useCustomRecipients() && <SelectInput
                            options={mailLanguageOptions()}
                            size="small"
                            defaultValue={props.mailData?.language ?? "all"}
                            onChange={updateMailLanguage}/>}
                        {!useCustomRecipients() && props.recipients &&
                            <LabelText
                                label={dict("mail.recipients.count")
                                    .replaceAll(
                                        "{NUM_RECIPIENTS}",
                                        props.recipients.length.toString())}/>}
                        {useCustomRecipients() && <>
                            <IconButton
                                type="add"
                                size="small"
                                onClick={() => setShowRecipientsList(true)}/>
                            <Divider style="action-bar"/>
                            <ScrollBox
                                scrollX={true}
                                scrollY={false}
                                useFlexX={true}
                                gapFlexX="small"
                                useFadeOut={true}>
                                {props.mailData?.customRecipients?.length > 0
                                    ? <>{props.mailData.customRecipients.map(recipient =>
                                        <UserInfo
                                            key={recipient._id}
                                            user={recipient}
                                            onClick={removeRecipient}
                                            showRemoveIconOnHover={true}
                                            style="small-framed"/>)}</>
                                    : <InfoText
                                        size="small"
                                        text={dict("mail.recipients.custom.emtpy.info")}/>
                                }
                            </ScrollBox>
                        </>}
                    </ContentLayout>
                </ContentLayout>}
            <ContentLayout gap="small">
                <Headline
                    text={dict(`mail.subject.label`)}
                    style="h5-underlined"/>
                <Input
                    type="text"
                    size="small"
                    defaultValue={props.mailData?.subject}
                    action={updateSubject}/>
            </ContentLayout>
            <ContentLayout gap="small">
                <Headline
                    text={dict(`mail.body.label`)}
                    style="h5-underlined"/>
                {props.mailData?.bodyBlocks?.length > 0
                    ? <>{props.mailData.bodyBlocks.map(block =>
                        <MailBodyBlock
                            key={block._id}
                            mailData={props.mailData}
                            bodyBlock={block}
                            onChange={props.requestMailData}/>
                    )}</>
                    : <MailEmptyBodyBlock
                        mailData={props.mailData}
                        onChange={props.requestMailData}/>}
            </ContentLayout>
        </ContentLayout>
        {showRecipientsList &&
            <MailCustomRecipientsListModal
                mailData={props.mailData}
                onRecipientAdded={props.onMailDataChange}
                onRequestClose={() => setShowRecipientsList(false)}/>}
    </>

}
