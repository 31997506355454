import * as React from "react";
import {OwnerEventsList} from "../../../../ui/list/events/OwnerEventsList";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";

/******************************************************************
 * UserTrainingPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserTrainingPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="user-training-page">
            <OwnerEventsList/>
            {isRouteOwnerAuthUser && <ActionBar location="main">
                <LabelButton
                    label={dict("user.training.create")}
                    icon="plus"
                    onClick={() => state.showCreateEvent.setValue(true)}
                    style={"action-main"}/>
                <Divider style="action-bar"/>
                <IconButton
                    type="academy"
                    size="small"
                    onClick={() => state.showAcademy.setValue(dict("academy.id.training"))}/>
                <ActionBarSpacer/>
            </ActionBar>}
        </div>
    );
}
