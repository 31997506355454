
import * as React from "react";
/******************************************************************
 * ReplaceUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ReplaceUtils {

    static replaceWithComponent(
        str: string,
        replacements: {
            [key: string]: React.ReactNode
        }
    )  {
        const splitRegex = new RegExp(/\{(\w*)}/g);
        const parts = str.split(splitRegex);
        return parts.map(part => {
            if (replacements.hasOwnProperty(part)) {
                return replacements[part];
            }
            return part;
        });
    };

}
