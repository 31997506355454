import {FrontendSpeaker} from "../../../core/services/FrontendSpeaker";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendServices} from "../../../core/FrontendServices";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";

/******************************************************************
 * RaceAnnouncements
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class RaceAnnouncements {

    /******************************************************************
     * Properties
     *****************************************************************/

    public started = new StateValue<boolean>()
    public finished = new StateValue<boolean>()
    public bestLapTime = new StateValue<boolean>()
    public drivenTime = new StateValue<boolean>()
    public wonBy = new StateValue<boolean>()
    public currentLeaderboard = new StateValue<boolean>()
    public finalLeaderboard = new StateValue<boolean>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        this.initAnnouncements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public announceStarted() {
        if (!this.started.getValue()) return
        let text = this.dict("speaker.text.race.started")
        this.speaker.speak(text, true)
    }

    public announceFinished() {
        if (!this.finished.getValue()) return
        let text = this.dict("speaker.text.race.finished")
        this.speaker.speak(text, true)
    }

    public announceBestLapTime(name: string, lapTime: number) {
        if (!this.bestLapTime.getValue()) return
        let formattedTime = SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: lapTime
        })
        let text = this.dict("speaker.text.race.bestLapTime")
        text = text.replace("{TIME}", formattedTime)
        text = text.replace("{NAME}", name)
        this.speaker.speak(text)
    }

    public announceDrivenTime(drivenTime: number) {
        if (!this.drivenTime.getValue()) return
        let formattedTime = SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: drivenTime, speakSecondsLabel: true
        })
        let text = this.dict("speaker.text.race.drivenTime")
        text = text.replace("{TIME}", formattedTime)
        this.speaker.speak(text, true)
    }

    public announceWonBy(name: string) {
        if (!this.wonBy.getValue()) return
        let text = this.dict("speaker.text.race.wonBy")
        text = text.replace("{NAME}", name)
        this.speaker.speak(text, true)
    }

    public announceCurrentLeaderboard(laps: number, names: string) {
        if (!this.currentLeaderboard.getValue()) return
        let text = this.dict("speaker.text.race.currentLeaderboard")
        text = text.replace("{LAPS}", laps.toString())
        text = text.replace("{NAMES}", names)
        this.speaker.speak(text, true)
    }

    public announceFinalLeaderboard(names: string) {
        if (!this.finalLeaderboard.getValue()) return
        let text = this.dict("speaker.text.race.finalLeaderboard")
        text = text.replace("{NAMES}", names)
        this.speaker.speak(text, true)
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAnnouncements() {
        SpeakerUtils.initAnnoucementSetting(this.started, "race.started")
        SpeakerUtils.initAnnoucementSetting(this.bestLapTime, "race.bestLap")
        SpeakerUtils.initAnnoucementSetting(this.drivenTime, "race.drivenTime")
        SpeakerUtils.initAnnoucementSetting(this.finished, "race.finished")
        SpeakerUtils.initAnnoucementSetting(this.wonBy, "race.wonBy")
        SpeakerUtils.initAnnoucementSetting(this.currentLeaderboard, "race.currentLeaderboard")
        SpeakerUtils.initAnnoucementSetting(this.finalLeaderboard, "race.finalLeaderboard")
    }

    private get speaker(): FrontendSpeaker {
        return this._services.speaker
    }

    private dict(key: string): string {
        return this._services.dict.get(key)
    }


}
