/******************************************************************
 * LanguageType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const Languages = ["de", "en"] as const

export type LanguageType = typeof Languages[number]

export function isValidLanguage(language: string): boolean {
    return Languages.includes(language as LanguageType)
}
