import * as React from "react";
import {IFileData} from "../../../../../shared/models/IFileData";
import {Picture} from "../../image/Picture";
import {Tooltip} from "../../tooltip/Tooltip";
import {useRef} from "react";

/******************************************************************
 * ImageButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ImageButton(props: {
    size: "small"
    file: IFileData
    tooltip?: string
    alt: string
    onClick?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLButtonElement>(null)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function clicked() {
        props.onClick?.()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <button
            className="image-button"
            data-size={props.size}
            ref={rootRef}
            onClick={clicked}>
            <Picture
                file={props.file}
                alt={props.alt}/>
            {props.tooltip &&
                <Tooltip
                    label={props.tooltip}
                    hoverElement={rootRef.current}/>}
        </button>
    );

}
