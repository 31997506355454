import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {IMailData} from "../../../../../shared/models/IMailData";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {IUserData} from "../../../../../shared/models/IUserData";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {MailModalType} from "./MailModalType";
import {MailPreview} from "./preview/MailPreview";
import {MailEditor} from "./editor/MailEditor";
import {MailModalHeader} from "./header/MailModalHeader";
import {MailStateInfo} from "./info/MailStateInfo";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {MailUtils} from "../../../../../shared/utils/MailUtils";

/******************************************************************
 * MailModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const isCreatingMailRef = React.useRef<boolean>(false)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api} = useServices()
    const {showAdminMailTemplates} = useRouteStates()
    const {authUserData} = useAuthUser()
    const {windowWidth, windowHeight} = useWindowSize()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [mailData, setMailData] = useState<IMailData>(null)
    const [showPreview, setShowPreview] = useState<boolean>(false)
    const [recipients, setRecipients] = useState<IUserData[]>([])
    const [recipientPreviewIndex, setRecipientPreviewIndex] = useState<number>(0)
    const [allVerifiedUsers, setAllVerifiedUsers] = useState<IUserData[]>(null)
    const [useMobileLayout, setUseMobileLayout] = useState<boolean>(false)
    const [editable, setEditable] = useState<boolean>(null)
    const [isTemplate] = useState<boolean>(showAdminMailTemplates)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadVerifiedUsers()
        if (state.showMail.getValue() !== true) {
            loadMail()
        } else {
            if (!isCreatingMailRef.current) {
                isCreatingMailRef.current = true
                createMail()
            }
        }
    }, [])

    useEffect(() => {
        setUseMobileLayout(windowWidth < 900)
    }, [windowWidth])

    useEffect(() => {
        if (!mailData) return
        switch (mailData.state) {
            case "template":
            case "draft":
                setEditable(true)
                break;
            case "sending":
            case "sent":
            case "failed":
                setShowPreview(true)
                setEditable(false)
                break;

        }
        switch (mailData.recipientsFilter) {
            case "verified":
                setRecipients(MailUtils.filterRecipientsForMailLanguage(mailData, allVerifiedUsers))
                break;
            case "newsletter":
                setRecipients(MailUtils.filterRecipientsForMailLanguage(mailData, allVerifiedUsers)
                    ?.filter(user => user.newsletter == "subscribed"))
                break;
            case "custom":
                setRecipients(mailData.customRecipients)
                break;

        }
    }, [mailData, allVerifiedUsers])

    useEffect(() => {
        if (!recipients) return
        if (recipients.length - 1 > recipientPreviewIndex) {
            setRecipientPreviewIndex(0)
        }
    }, [recipients])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function mailModalType(): MailModalType {
        if (state.showMail.getValue() == true) {
            return "create"
        }
        return "edit"
    }

    async function createMail() {
        const mailResult = await api.mail.createMail(isTemplate)
        if (mailResult) {
            setMailData(mailResult)
        }
    }

    async function loadVerifiedUsers() {
        setAllVerifiedUsers(await api.user.getAll())
    }

    async function loadMail() {
        const mailResult = await api.mail.getMailByID(mailData?._id ?? state.showMail.getValue())
        setMailData(mailResult)
    }

    function close() {
        state.showMail.setValue(null)
    }

    function updatePreviewIndex(index: number) {
        setRecipientPreviewIndex(prevState => {
            let newIndex = prevState + index
            if (newIndex < 0) {
                newIndex = recipients.length - 1
            }
            if (newIndex >= recipients.length) {
                newIndex = 0
            }
            return newIndex
        })
    }

    async function sendMailing() {
        state.showConfirm.setValue(null)
        await api.mail.sendMailing(mailData._id)
        await loadMail()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            className="mail-modal"
            type="normal"
            width={showPreview && editable ? "extra-large" : "medium"}>
            <ComponentInitializer
                className="mail"
                isPropertyAvailable={!!mailData && !!allVerifiedUsers}>
                <MailModalHeader
                    mailData={mailData}
                    editable={editable}
                    modalType={mailModalType()}
                    showPreview={showPreview}
                    recipients={recipients}
                    onTogglePreview={setShowPreview}
                    onRequestSendMail={sendMailing}/>
                <ContentLayout
                    columns={useMobileLayout
                        ? 1
                        : (showPreview && editable ? 2 : 1)}
                    rowTemplate={editable
                        ? null
                        : "min-content 1fr"}
                    minHeight={showPreview && useMobileLayout
                        ? windowHeight * 0.7
                        : editable
                            ? null
                            : windowHeight * 0.7}
                    alignItems={showPreview
                        ? "stretch"
                        : "start"}>
                    {!editable &&
                        <MailStateInfo
                            mailData={mailData}
                            recipients={recipients}
                            requestMailData={loadMail}
                            onRequestRetrySendMail={sendMailing}/>}
                    {editable && (!showPreview || !useMobileLayout) &&
                        <MailEditor
                            mailData={mailData}
                            showFrame={showPreview}
                            recipients={recipients}
                            onMailDataChange={setMailData}
                            requestMailData={loadMail}/>}
                    {showPreview &&
                        <MailPreview
                            key={recipients?.[recipientPreviewIndex]?._id}
                            recipientPreviewIndex={recipientPreviewIndex}
                            onChangePreviewIndex={updatePreviewIndex}
                            recipient={recipients?.[recipientPreviewIndex] ?? authUserData}
                            recipients={recipients}
                            mailData={mailData}/>}
                </ContentLayout>
            </ComponentInitializer>
        </Modal>
    );

}
