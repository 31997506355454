import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../layout/content/ContentLayout";
import {useServices} from "../../hooks/useServices";
import {ComponentInitializer} from "../utils/init/ComponentInitializer";
import {DriverLicensePlan} from "./plan/DriverLicensePlan";
import {useMobileStyle} from "../../hooks/useMobileStyle";
import {IPaddleDriverLicensePlanData} from "../../../../shared/models/IPaddleDriverLicensePlanData";
import {useRouteStates} from "../../hooks/useRouteStates";

/******************************************************************
 * DriverLicensePlans
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverLicensePlans(props: {
    showSignupButton?: boolean
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices()
    const [isMobileStyle] = useMobileStyle(rootRef, 570)
    const {showPlans} = useRouteStates()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [licensePlans, setLicensePlans] = useState<IPaddleDriverLicensePlanData[]>([])

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const controller = new AbortController()
        loadPlans(controller)
        return () => controller.abort()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadPlans(abortController: AbortController) {
        setLoading(true)
        const plans = await api.paddle.getDriverLicensePlans(abortController)
        plans?.sort((a, b) => a.amount - b.amount)
        setLicensePlans(plans)
        if (plans) {
            setLoading(false)
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            fullHeight={showPlans}
            isPropertyAvailable={!loading}>
            <ContentLayout
                ref={rootRef}
                className="plans"
                columns={isMobileStyle ? 1 : (licensePlans?.length ?? 1)}>
                {licensePlans?.map(plan => (
                    <DriverLicensePlan
                        key={plan.type}
                        type={plan.type}
                        priceID={plan.priceID}
                        currency={plan.currency}
                        amount={plan.amount}
                        interval={plan.interval}
                        offer={plan.offer}
                        showSignupButton={props.showSignupButton}/>
                ))}
            </ContentLayout>
        </ComponentInitializer>
    );

}
