import * as React from "react";
import {useEffect, useState} from "react";
import {IFileData} from "../../../../../shared/models/IFileData";
import {Picture} from "../Picture";
import {useServices} from "../../../hooks/useServices";
import {ICarData} from "../../../../../shared/models/ICarData";
import {Icon} from "../../icons/Icon";
import {IErrorResponse} from "../../../../../shared/types/IErrorResponse";
import {ErrorCode} from "../../../../../shared/keys/ErrorCode";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {InfoText} from "../../text/infotext/InfoText";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * CarImage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CarImage(props: {
    carName: string,
    userID: string,
    displayCarMaxOut?: boolean,
    aspectRatio?: number,
    className?: string,
    onCarImageAvailable?: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices()
    const {authUserID, authUserValidDriverLicense} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [imageFile, setImageFile] = useState<IFileData>(null);
    const [carData, setCarData] = useState<ICarData>(null);
    const [showCarMaxOutInfo, setShowCarMaxOutInfo] = useState<boolean>(false);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        requestCarData()
    }, [props.carName, props.userID, authUserValidDriverLicense])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function requestCarData() {
        const carData: ICarData | IErrorResponse = await api.car.getCarByNameAndOwner(props.carName, props.userID)
        if ((carData as IErrorResponse)?.error) {
            const errorCarData = carData as IErrorResponse
            const error = errorCarData.error as IErrorResponse
            if (error.code === ErrorCode.PLAN_ACCESS_MAX_CARS_REACHED && props.displayCarMaxOut) {
                setShowCarMaxOutInfo(true)
            } else {
                setShowCarMaxOutInfo(false)
                setCarData(null)
                setImageFile(undefined)
            }
            return
        }
        const successCarData = carData as ICarData
        setShowCarMaxOutInfo(false)
        setCarData(successCarData)
        setImageFile(successCarData?.images?.[0] ?? undefined)
        if (successCarData?.images?.[0]) {
            props.onCarImageAvailable?.()
        }
    }

    function showCarModal(e: React.MouseEvent) {
        e.stopPropagation()
        state.showCar.setValue(carData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        {imageFile !== undefined &&
            <div onClick={showCarModal}
                 className={"car-image" + (props.className ? " " + props.className : "")}>
                <Picture
                    defaultAspectRatio={props.aspectRatio}
                    forceDefaultAspectRatio={!!props.aspectRatio}
                    file={imageFile}
                    alt={props.carName}/>
                <div className="car-image-zoom-icon">
                    <Icon type="zoom" scale={0.8}/>
                </div>
            </div>}
        {showCarMaxOutInfo && props.userID == authUserID &&
            <ContentLayout
                className="car-image-maxout"
                useFrameMargin={true}
                justifyItems="center"
                alignContent="center">
                <InfoText
                    text={dict("plan.car.image.out.of.max")}
                    align="center"
                    size="small"/>
                <LabelButton
                    label={dict("plan.license.upgrade.label")}
                    style={"primary-small"}
                    onClick={() => state.showSubscriptionPlans.setValue(true)}/>
            </ContentLayout>}
    </>);

}
