import * as React from "react";
import {useEffect, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {Headline} from "../../../ui/text/headings/Headline";
import {IEventData} from "../../../../../shared/models/IEventData";
import {EventFinderTeaserList} from "../../modals/events/list/EventFinderTeaserList";
import {EventFinderMap} from "../../modals/events/map/EventFinderMap";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {EventUtils} from "../../../../../shared/utils/EventUtils";
import {HomeActionBar} from "../../../ui/bar/home/HomeActionBar";

/******************************************************************
 * EventsMain
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventsMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [upcomingEvents, setUpcomingEvents] = useState<IEventData[]>([]);
    const [localEvents, setLocalEvents] = useState<IEventData[]>([]);
    const [liveEvents, setLiveEvents] = useState<IEventData[]>([]);
    const [permanentEvents, setPermanentEvents] = useState<IEventData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadEvents();
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadEvents() {
        setLoading(true)
        const allEvents = await api.event.getFinderEvents()
        setLiveEvents(EventUtils.sortDateEvents(allEvents
            ?.filter(event => EventUtils.isLiveEvent(event))))
        setUpcomingEvents(EventUtils.sortDateEvents(allEvents
            ?.filter(event => EventUtils.isUpcomingEvent(event))))
        setLocalEvents(allEvents?.filter(event => EventUtils.isLocalEvent(event)))
        setPermanentEvents(allEvents?.filter(event => EventUtils.isPermanentEvent(event)))
        setLoading(false)
    }

    function hasLiveEvents() {
        return liveEvents?.length > 0
    }

    function hasPermanentEvents() {
        return permanentEvents?.length > 0
    }

    function hasUpcomingEvents() {
        return upcomingEvents?.length > 0
    }

    function hasLocalEvents() {
        return localEvents?.length > 0
    }

    function initialContentID() {
        if (hasLiveEvents()) {
            return 0
        }
        if (hasPermanentEvents()) {
            return 1
        }
        if (hasUpcomingEvents()) {
            return 2
        }
        if (hasLocalEvents()) {
            return 3
        }
        return 0
    }

    function hasEvents() {
        return hasLiveEvents() || hasUpcomingEvents() || hasLocalEvents() || hasPermanentEvents()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="events-main"
            alignContent="start"
            rowTemplate={"min-content 1fr"}
            fullHeight={false}>
            <Headline text={dict("events.title")} style="h1"/>
            <ComponentInitializer
                isPropertyAvailable={!loading}
                fullHeight={true}>
                {!loading &&
                    <ContentLayout
                        alignContent={hasEvents() ? null : "center"}
                        justifyItems={hasEvents() ? null : "center"}
                        fullHeight={false}>
                        {hasEvents() &&
                            <TabbedContent
                                showContentByID={initialContentID()}>
                                <EventFinderTeaserList
                                    tabbedContent={{
                                        name: dict("events.finder.live.label"),
                                        disabled: !hasLiveEvents()
                                    }}
                                    isLoading={loading}
                                    areLiveEvents={true}
                                    events={liveEvents}/>
                                <EventFinderTeaserList
                                    tabbedContent={{
                                        name: dict("events.finder.permanent.label"),
                                        disabled: !hasPermanentEvents()
                                    }}
                                    isLoading={loading}
                                    areLiveEvents={false}
                                    events={permanentEvents}/>
                                <EventFinderTeaserList
                                    tabbedContent={{
                                        name: dict("events.finder.upcoming.label"),
                                        disabled: !hasUpcomingEvents()
                                    }}
                                    isLoading={loading}
                                    areLiveEvents={false}
                                    events={upcomingEvents}/>
                                <EventFinderMap
                                    onTouchEnd={() => state.showMobileMenu.setValue(false)}
                                    fullHeight={true}
                                    projection="globe"
                                    initialZoomLevel={4}
                                    tabbedContent={{
                                        name: dict("events.finder.map.label"),
                                        disabled: !hasLocalEvents()
                                    }}
                                    events={localEvents}/>
                            </TabbedContent>}
                        {!hasEvents() &&
                            <InfoText
                                text={dict("events.finder.no.events")}
                                align="center"/>}
                    </ContentLayout>}
            </ComponentInitializer>
        </ContentLayout>
        <HomeActionBar/>
    </>

}
