import * as React from "react";
import {IKeyShortcut} from "../../../utils/interfaces/IKeyShortcut";
import {Icon} from "../icons/Icon";

/******************************************************************
 * KeyShortcutInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function KeyShortcutInfo(props: {
    keyShortcut: IKeyShortcut
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/



    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="key-shortcut-info"
            data-show-frame={props.keyShortcut.framed}>
            {props.keyShortcut.useShiftKey &&
                <Icon type="shift" scale={0.6}/>}
            <div className="key-shortcut-icon-letter">{props.keyShortcut.key}</div>
        </div>
    );

}
