import * as React from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useTrackState} from "../../../../hooks/useTrackState";
import {SelectInput} from "../../../../ui/form/elements/select/SelectInput";
import {TrackType} from "../../../../../../shared/types/TrackType";
import {DriftTargetCodeType} from "../../../../../../shared/types/DriftTargetCodeType";
import {TrackMinLapTimeInput} from "../../../../ui/form/elements/track/TrackMinLapTimeInput";
import {TrackSectorsInput} from "../../../../ui/form/elements/track/TrackSectorsInput";
import {TrackLapCountModeType} from "../../../../../../shared/types/TrackLapCountModeType";
import {DriftTrackBundleInput} from "../../../../ui/form/elements/drift/DriftTrackBundleInput";
import {DriftTrackBundleType} from "../../../../../../shared/types/DriftTrackBundleType";
import {TrackJokerLapTargetInput} from "../../../../ui/form/elements/track/TrackJokerLapTargetInput";
import {TrackTargetPreProcessorInput} from "../../../../ui/form/elements/track/TrackTargetPreProcessorInput";
import {TargetPreProcessorMode} from "../../../../../../shared/types/TargetPreProcessorMode";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useRef} from "react";

/******************************************************************
 * TrackSetupSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackSetupSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        trackID,
        trackMinLapTime,
        trackType,
        trackNumSectors,
        trackSectorTargets,
        trackLapCountMode,
        trackBundle,
        trackJokerTarget,
        trackTargetPreProcessor
    } = useTrackState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateTrackMinLapTime(minLapTime: string): Promise<Response> {
        return await api.track.update(trackID, {minLapTime: parseInt(minLapTime)})
    }

    async function updateTrackType(trackType: TrackType): Promise<Response> {
        return await api.track.update(trackID, {type: trackType})
    }

    async function updateTrackNumSectors(numSectors: number): Promise<Response> {
        return await api.track.update(trackID, {numSectors: numSectors})
    }

    async function updateTrackSectorTargets(sectorTargets: DriftTargetCodeType[]): Promise<Response> {
        return await api.track.update(trackID, {sectorTargets: sectorTargets})
    }

    async function updateTrackLapCountMode(lapCountMode: TrackLapCountModeType): Promise<Response> {
        return await api.track.update(trackID, {lapCountMode: lapCountMode})
    }

    async function updateTrackBundle(trackBundle: DriftTrackBundleType): Promise<Response> {
        return await api.track.update(trackID, {trackBundle: trackBundle})
    }

    async function updateTrackJokerLapTarget(jokerLapTarget: DriftTargetCodeType | "none"): Promise<Response> {
        return await api.track.update(trackID, {jokerLapTarget: jokerLapTarget})
    }

    async function updateTrackTargetPreProcessor(mode: TargetPreProcessorMode): Promise<Response> {
        return await api.track.update(trackID, {targetPreProcessor: mode})
    }

    function showMinLapTimeSetup(): boolean {
        return trackType === "lap"
    }

    function showSectorSetup(): boolean {
        return trackType === "lap"
    }

    function showTrackBundle(): boolean {
        return trackType === "lap"
    }

    function showJokerLapSetup(): boolean {
        return trackBundle === "rally" || trackBundle === "rally_cross"
    }

    function editable(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="track-setup-settings">
            <SelectInput
                label={dict("track.type.label")}
                defaultValue={trackType}
                readonly={!editable()}
                helpTopic="track.type"
                onChange={updateTrackType}
                options={[
                    {value: "lap" as TrackType, text: dict("track.type.lap")},
                    {value: "gymkhana" as TrackType, text: dict("track.type.gymkhana")},
                ]}/>
            {showMinLapTimeSetup() && <ContentLayout framed={true}>
                <Headline text={dict("track.minLapTime.headline")} style="h5-underlined"/>
                <TrackMinLapTimeInput
                    editable={editable()}
                    lapTime={trackMinLapTime?.toString()}
                    action={updateTrackMinLapTime}/>
            </ContentLayout>}
            {showSectorSetup() && <ContentLayout framed={true}>
                <Headline text={dict("track.sectors.headline")} style="h5-underlined"/>
                <TrackSectorsInput
                    editable={editable()}
                    isMobileMode={isMobileMode}
                    numSectors={trackNumSectors}
                    sectorTargets={trackSectorTargets}
                    lapCountMode={trackLapCountMode}
                    onNumSectorsChanged={updateTrackNumSectors}
                    onSectorTargetsChanged={updateTrackSectorTargets}
                    onLapCountModeChanged={updateTrackLapCountMode}/>
            </ContentLayout>}
            {showTrackBundle() && <ContentLayout framed={true} columns={isMobileMode ? 1 : 2}>
                <DriftTrackBundleInput
                    readonly={!editable()}
                    trackBundle={trackBundle}
                    onChange={updateTrackBundle}/>
                {showJokerLapSetup() && <TrackJokerLapTargetInput
                    enabled={isRouteOwnerAuthUser || isGroupAdmin}
                    jokerLapTarget={trackJokerTarget}
                    onChange={updateTrackJokerLapTarget}/>}
            </ContentLayout>}
            {trackType != "gymkhana" &&
                <ContentLayout framed={true}>
                    <Headline text={dict("track.targetPreProcessor.headline")} style="h5-underlined"/>
                    <TrackTargetPreProcessorInput
                        readonly={!editable()}
                        mode={trackTargetPreProcessor}
                        onModeChanged={updateTrackTargetPreProcessor}/>
                </ContentLayout>}
        </ContentLayout>
    );

}
