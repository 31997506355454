import * as React from "react";
import {useEffect} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {ConvertDataUtils} from "../../../../../../shared/utils/ConvertDataUtils";
import {FrontendConfig} from "../../../../../core/FrontendConfig";

/******************************************************************
 * AdminEnvVars
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AdminEnvVars() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [serverVars, setServerVars] = React.useState<{ key: string, value: string }[]>()
    const [clientVars] = React.useState<{ key: string, value: string }[]>(getClientVars())
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadVars()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadVars() {
        setIsLoadingData(true)
        const vars = await api.admin.getEnvironmentVariables()
        setServerVars(ConvertDataUtils.convertObjectToArray(vars))
        setIsLoadingData(false)
    }

    function columnsDefinition(): ITableColumnDefinition<{ key: string, value: string }>[] {
        return [
            {
                type: "text",
                columnName: dict("admin.envvars.name"),
                dataKey: "key",
                size: "2fr"
            },
            {
                type: "text",
                columnName: dict("admin.envvars.value"),
                dataKey: "value",
                size: "2fr"
            }
        ]
    }

    function getClientVars(): { key: string, value: string }[] {
        return [
            {
                key: "PADDLE_ENABLED_PER_DEFAULT",
                value: FrontendConfig.PADDLE_ENABLED_PER_DEFAULT ? "true" : "false"
            }, {
                key: "PADDLE_ENVIRONMENT",
                value: FrontendConfig.PADDLE_ENVIRONMENT
            }, {
                key: "PADDLE_AVAILABLE",
                value: FrontendConfig.PADDLE_AVAILABLE ? "true" : "false"
            }, {
                key: "PADDLE_CLIENT_TOKEN",
                value: FrontendConfig.PADDLE_CLIENT_TOKEN
            }, {
                key: "MAPBOX_ACCESS_TOKEN",
                value: FrontendConfig.MAPBOX_ACCESS_TOKEN
            }, {
                key: "SERVER_ENVIRONMENT",
                value: FrontendConfig.SERVER_ENVIRONMENT
            }
        ];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-envvars">
            <ContentLayout
                framed={true}
                className="admin-environment-vars-server">
                <Headline
                    text={dict("admin.envvars.server.title")}
                    style="h5-underlined"/>
                <Table
                    rowsData={serverVars}
                    showFilter={true}
                    style="admin-compact"
                    sortKey={"key"}
                    isLoadingData={isLoadingData}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
            <ContentLayout
                framed={true}
                className="admin-environment-vars-client">
                <Headline
                    text={dict("admin.envvars.client.title")}
                    style="h5-underlined"/>
                <Table
                    rowsData={clientVars}
                    showFilter={true}
                    style="admin-compact"
                    sortKey={"key"}
                    isLoadingData={isLoadingData}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
        </ContentLayout>
    );

}
