import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Modal} from "../../../Modal";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {DriverList} from "../../../../../ui/list/driver/DriverList";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {UserInfo} from "../../../../../ui/info/user/UserInfo";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {GroupUtils} from "../../../../../../../shared/utils/GroupUtils";

/******************************************************************
 * GroupContactSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupContactSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {groupID, groupData, groupContact, groupMembers} = useGroupState()
    const [isMobile] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showSelectMember, setShowSelectMember] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function selectAsContact(userID: MongoObjectIDType) {
        if (!groupMembers || groupMembers.length == 0) return
        setShowSelectMember(false)
        await api.group.update(groupID, {contact: userID})
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={rootRef}
            className="group-contact-settings"
            alignSelf="start">
            <InfoText
                text={dict("group.settings.contact.info")}/>
            <ContentLayout framed={true}>
                <Headline
                    text={dict("group.settings.contact.title")}
                    style={"h5-underlined"}/>
                <ContentLayout
                    justifyContent="space-between"
                    alignItems="center"
                    columnTemplate={isMobile ? null : "1fr min-content"}>
                    {groupContact &&
                        <UserInfo
                            user={groupContact}
                            clickToShowProfile={true}
                            style="normal"/>}
                    {!groupContact &&
                        <Headline text={dict("group.settings.no.contact.defined")} style="h5"/>}
                    <ContentLayout>
                        <LabelButton
                            label={dict("group.settings.contact.addDriver.label")}
                            style={groupContact ? "secondary" : "primary"}
                            onClick={() => setShowSelectMember(true)}/>
                    </ContentLayout>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
        {showSelectMember &&
            <Modal
                width="medium"
                closeAction={() => setShowSelectMember(false)}>
                <Headline
                    text={dict("group.select.member.title")}
                    style="modal"/>
                <ContentLayout>
                    <InfoText text={dict("select.contact.info")} size="small"/>
                    <DriverList
                        data={groupMembers
                            ?.filter(member => GroupUtils.isAdmin(groupData, member.user))
                            ?.map(member => member.user)}
                        primaryIconType="add"
                        onPrimaryIconClick={selectAsContact}
                        emptyDataInfo={dict("group.members.empty.info")}/>
                </ContentLayout>
            </Modal>}
    </>

}
