import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {ISessionData} from "../../shared/models/ISessionData";
import {IResultData} from "../../shared/models/IResultData";
import {IScoreboardEntry} from "../../shared/types/IScoreboardEntry";

/******************************************************************
 * ResultAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ResultAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async createResult(data: {
        name: string,
        eventID: MongoObjectIDType,
        sessionIDs: MongoObjectIDType[]
    }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.RESULT, data, true);
    }

    public async getResultsContainingSession(sessionID: MongoObjectIDType): Promise<IResultData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.RESULTS_CONTAINING_SESSION, {sessionID});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async update(resultID: MongoObjectIDType, data: IResultData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.RESULT, {resultID: resultID, ...data}, true);
    }

    public async delete(resultID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.RESULT, {resultID: resultID}, true)
    }

    public async getResultByRoute(resultRoute: string): Promise<IResultData> {
        const response = await this._frontend.api.request("GET", APIRoute.RESULT, {resultRoute});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getResultableSessions(eventID: MongoObjectIDType): Promise<ISessionData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.RESULT_SESSIONS, {eventID});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getResultScoreboard(resultID: MongoObjectIDType): Promise<IScoreboardEntry[]> {
        const response = await this._frontend.api.request("GET", APIRoute.RESULT_SCOREBOARD, {resultID});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }
}

