import {ITrackFilterData} from "../models/submodels/ITrackFilterData";
import {
    ANY_DRIFT_TUNING,
    BASIC_DRIFT_TUNINGS,
    CLUB_DRIFT_TUNING, MAX_DRIFT_TUNINGS,
    PERFORMANCE_DRIFT_TUNINGS
} from "../types/DriftTuningType";
import {
    ANY_DRIFT_ENGINE, AUDI_DRIFT_ENGINES,
    BMW_DRIFT_ENGINES,
    D1_DRIFT_ENGINES,
    MERCEDES_DRIFT_ENGINES, NISSAN_DRIFT_ENGINES,
    PORSCHE_DRIFT_ENGINES, SUBARU_DRIFT_ENGINES
} from "../types/DriftEngineType";

/******************************************************************
 * TrackFilterPresets
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TrackFilterPresets {

    /* ----------------------------------------------------------------
 	 * DEFAULT
 	 * --------------------------------------------------------------*/

    static readonly DEFAULT: ITrackFilterData = {
        name: "track.filter.default.name",
        presetType: "default",
        isPreset: true,
        path: "/all",
        carSetup: ["RACE", "DRIFT"],
        trackConditions: ["drift_asphalt", "drift_asphalt_wet", "drift_dirt", "drift_ice"],
        tires: ["normal", "spikes", "gravel_tires"],
        engines: ANY_DRIFT_ENGINE,
        tunings: ANY_DRIFT_TUNING
    }



    /* ----------------------------------------------------------------
 	 * CARS
 	 * --------------------------------------------------------------*/

    static readonly TRACK_FILTER_D1: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/d1",
        name: "track.filter.d1.name",
        engines: D1_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_BMW: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/bmw",
        name: "track.filter.bmw.name",
        engines: BMW_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_MERCEDES: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/mercedes",
        name: "track.filter.mercedes.name",
        engines: MERCEDES_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_PORSCHE: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/porsche",
        name: "track.filter.porsche.name",
        engines: PORSCHE_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_NISSAN: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        name: "track.filter.nissan.name",
        path: "/nissan",
        engines: NISSAN_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_SUBARU: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/subaru",
        name: "track.filter.subaru.name",
        engines: SUBARU_DRIFT_ENGINES
    }

    static readonly TRACK_FILTER_AUDI: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "engine",
        isPreset: true,
        path: "/audi",
        name: "track.filter.audi.name",
        engines: AUDI_DRIFT_ENGINES
    }

    /* ----------------------------------------------------------------
 	 * TUNINGS
 	 * --------------------------------------------------------------*/

    static readonly TRACK_FILTER_CLUBSPORT: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "tuning",
        isPreset: true,
        path: "/clubsport",
        name: "track.filter.clubsport.name",
        tunings: CLUB_DRIFT_TUNING
    }

    static readonly TRACK_FILTER_BASIC: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "tuning",
        isPreset: true,
        path: "/basic",
        name: "track.filter.basic.name",
        tunings: BASIC_DRIFT_TUNINGS
    }

    static readonly TRACK_FILTER_PERFORMANCE: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "tuning",
        isPreset: true,
        path: "/performance",
        name: "track.filter.performance.name",
        tunings: PERFORMANCE_DRIFT_TUNINGS
    }

    static readonly TRACK_FILTER_MAX: ITrackFilterData = {
        ...TrackFilterPresets.DEFAULT,
        presetType: "tuning",
        isPreset: true,
        path: "/max",
        name: "track.filter.max.name",
        tunings: MAX_DRIFT_TUNINGS
    }

    /* ----------------------------------------------------------------
 	 * PRESETS
 	 * --------------------------------------------------------------*/

    static readonly PRESETS: ITrackFilterData[] = [
        TrackFilterPresets.DEFAULT,
        TrackFilterPresets.TRACK_FILTER_D1,
        TrackFilterPresets.TRACK_FILTER_BMW,
        TrackFilterPresets.TRACK_FILTER_MERCEDES,
        TrackFilterPresets.TRACK_FILTER_PORSCHE,
        TrackFilterPresets.TRACK_FILTER_NISSAN,
        TrackFilterPresets.TRACK_FILTER_SUBARU,
        TrackFilterPresets.TRACK_FILTER_AUDI,
        TrackFilterPresets.TRACK_FILTER_CLUBSPORT,
        TrackFilterPresets.TRACK_FILTER_BASIC,
        TrackFilterPresets.TRACK_FILTER_PERFORMANCE,
        TrackFilterPresets.TRACK_FILTER_MAX
    ]


}
