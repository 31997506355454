import {FrontendServices} from "../core/FrontendServices";
import {IAcademyChapterData} from "../../shared/models/submodels/IAcademyChapterData";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IMultilangString} from "../../shared/models/submodels/IMultilangString";

/******************************************************************
 * AcademyAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class AcademyAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getChaptersWithArticles(): Promise<IAcademyChapterData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.ACADEMY_CHAPTERS, null, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async createChapter(title: IMultilangString): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.ACADEMY_CHAPTER, {title}, true)
    }

    public async updateChapter(chapterID: string, data: IAcademyChapterData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.ACADEMY_CHAPTER, {chapterID, data}, true)
    }

    public async deleteChapter(chapterID: string): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.ACADEMY_CHAPTER, {chapterID}, true)
    }

}
