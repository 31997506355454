import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {IArticleData} from "../../shared/models/IArticleData";
import {LanguageType} from "../../shared/types/LanguageType";

/******************************************************************
 * ArticleAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ArticleAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getArticleByID(articleID: MongoObjectIDType, skipBlocks: boolean = false): Promise<IArticleData> {
        const response = await this._frontend.api.request("GET", APIRoute.ARTICLE, {articleID, skipBlocks}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async getArticleByTitle(title: string, language: LanguageType, skipBlocks: boolean = false): Promise<IArticleData> {
        const response = await this._frontend.api.request("GET", APIRoute.ARTICLE, {title, language, skipBlocks}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async createArticle(title: string, language: LanguageType): Promise<IArticleData> {
        const response =  await this._frontend.api.request("POST", APIRoute.ARTICLE, {title, language}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json()
    }

    public async deleteArticle(articleID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.ARTICLE, {articleID}, true)
    }

    public async updateArticle(articleID: MongoObjectIDType, data: IArticleData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.ARTICLE, {articleID, data}, true)
    }

    public async copyContent(sourceArticleID: MongoObjectIDType, targetArticleID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("PATCH", APIRoute.ARTICLE_COPY_CONTENT, {sourceArticleID, targetArticleID}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true

    }

    public async getReleaseNotes(language: LanguageType): Promise<IArticleData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.ARTICLE_RELEASE_NOTES, {language})
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async getArticles(articleIDs?: MongoObjectIDType[]): Promise<IArticleData[]> {
        const response = await this._frontend.api.request("POST", APIRoute.ARTICLES, articleIDs ? {articleIDs} : null, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

}
