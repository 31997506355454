import {IGroupData} from "../../../../../shared/models/IGroupData";
import {IMapMarkerData} from "./IMapMarkerData";
import * as ReactDOM from "react-dom/client";
import * as React from "react";
import {GroupMarker} from "./group/GroupMarker";
import {EventMarker} from "./event/EventMarker";
import {IEventData} from "../../../../../shared/models/IEventData";

/******************************************************************
 * MarkerFactory
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class MarkerFactory {

    static createGroupMarker(groupData: IGroupData): IMapMarkerData {
        const markerElement = document.createElement('div')
        const markerRoot = ReactDOM.createRoot(markerElement)
        markerRoot.render(<GroupMarker
            group={groupData}
            markerElement={markerElement}/>)
        return {
            element: markerElement,
            location: groupData.location
        }
    }

    static createEventMarker(eventData: IEventData): IMapMarkerData {
        const markerElement = document.createElement('div')
        const markerRoot = ReactDOM.createRoot(markerElement);
        const location = eventData.mapLocation ?? eventData.owner?.location
        if (!location) {
            return null
        }
        markerRoot.render(<EventMarker
            event={eventData}
            markerElement={markerElement}/>)
        return {
            element: markerElement,
            location: location
        }
    }

}
