import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {TeamList} from "../../../../../ui/list/team/TeamList";
import {useServices} from "../../../../../hooks/useServices";
import {LabelText} from "../../../../../ui/text/label/LabelText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useEventState} from "../../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * EventTeamSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventTeamSettings() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {eventTeams, eventIsFinished} = useEventState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function editTeam(teamID: MongoObjectIDType) {
        const teamData = eventTeams.filter(team => team._id == teamID)[0]
        state.showTeam.setValue(teamData)
    }

    function addTeam() {
        state.showTeam.setValue(true)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-team-settings" size="content" gap="none">
            <div className="event-team-settings-header">
                <LabelText
                    label={dict("event.teams.label")}/>
                <LabelButton
                  label={dict("event.team.add")}
                  style="primary-small"
                  disabled={eventIsFinished}
                  onClick={addTeam}/>
            </div>
            <TeamList
                data={eventTeams}
                emptyDataInfo={dict("event.team.nodata.info")}
                iconType="edit"
                readonly={eventIsFinished}
                onIconClick={editTeam}/>
        </ContentLayout>
    );

}
