import {IErrorResponse} from "../../../shared/types/IErrorResponse";
import {FrontendServices} from "../FrontendServices";
import {ErrorCode} from "../../../shared/keys/ErrorCode";

/******************************************************************
 * FrontendError
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendError {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public createMessage(error: IErrorResponse): string {
        if (!error) {
            return this._frontend.dict.get(ErrorCode.UNKNOW_ERROR);
        }
        if (Array.isArray(error.error)) {
            let errorMessage = "";
            error.error.forEach((singleError) => {
                errorMessage += this.getErrorMessage(singleError) + ". "
            })
            return errorMessage;
        }
        if (error.error) {
            return this.getErrorMessage(error.error);
        }
        return this.getErrorMessage(error);
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private getErrorMessage(error: IErrorResponse): string {
        if (error.name == "MongoServerError") {
            if (error.keyValue) {
                const key = Object.keys(error.keyValue)[0];
                if (key) {
                    return this._frontend.dict.get(ErrorCode.DUPLICATED_KEY_ERROR(key));
                }
            }
        }
        if (error.name == "ValidatorError") {
            return this._frontend.dict.get(error.message);
        }
        return this._frontend.dict.get(error.code)
    }


}
