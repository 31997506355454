import * as React from "react";
import {useEffect, useState} from "react";
import {ComponentInitializer} from "../utils/init/ComponentInitializer";
import {useServices} from "../../hooks/useServices";
import {ArticleBlockType, IArticleData} from "../../../../shared/models/IArticleData";
import {MongoObjectIDType} from "../../../../shared/types/MongoObjectIDType";
import {ContentLayout} from "../layout/content/ContentLayout";
import {Headline} from "../text/headings/Headline";
import {Input} from "../form/elements/input/Input";
import {InfoText} from "../text/infotext/InfoText";
import {ArticleEmptyBlock} from "./blocks/empty/ArticleEmptyBlock";
import {ArticleBlock} from "./blocks/ArticleBlock";
import {EmptyDataInfo} from "../info/empty/EmptyDataInfo";
import {Spacer} from "../utils/spacer/Spacer";
import {SelectInput, SelectInputOptionType} from "../form/elements/select/SelectInput";
import {LanguageType} from "../../../../shared/types/LanguageType";
import {SharedConfig} from "../../../../shared/config/SharedConfig";
import {useAuthUser} from "../../hooks/useAuthUser";
import {IconButton} from "../buttons/icon/IconButton";
import {ArticleUtils} from "../../../../shared/utils/ArticleUtils";
import {ARTICLE_TYPES, ArticleType} from "../../../../shared/types/ArticleType";

/******************************************************************
 * Article
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type ArticleStyleType = "normal" | "compact" | "home"

export function Article(props: {
    articleID?: MongoObjectIDType
    articleData?: IArticleData
    hideTypeSelect?: boolean
    hideTitle?: boolean
    hideSubTitle?: boolean
    availableBlocksWhenEditing?: ArticleBlockType[]
    style?: ArticleStyleType
    edit?: boolean
    emptyDataInfo?: string
    onRequestEdit?: () => void
    onUpdated?: () => void
    onArticleData?: (articleData: IArticleData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = React.useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices()
    const {authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setInitialLoading] = useState<boolean>(false)
    const [article, setArticle] = useState<IArticleData>(props.articleData)
    const [articleLanguage, setArticleLanguage] = useState<LanguageType>(props.articleData?.language)
    const [articleType, setArticleType] = useState<ArticleType>(props.articleData?.type ?? "default")
    const [style, setStyle] = useState<ArticleStyleType>(props.style ?? "normal")
    const [isHelpArticle, setIsHelpArticle] = useState<boolean>(ArticleUtils.isHelpArticle(props.articleData))


    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.articleData) return
        if (!props.articleID) return
        setInitialLoading(true)
        loadArticle()
    }, [props.articleID])

    useEffect(() => {
        setStyle(props.style ?? "normal")
    }, [props.style])

    useEffect(() => {
        props.onArticleData?.(article)
        if (!article) return
        setArticleLanguage(article.language)
        setArticleType(article.type ?? "default")
        setIsHelpArticle(ArticleUtils.isHelpArticle(article))
        if (article.title == dict("article.default.title")) {
            props.onRequestEdit?.()
        }
    }, [article])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadArticle() {
        const articleID = props.articleID || props.articleData._id
        const article = await api.article.getArticleByID(articleID) as IArticleData
        setArticle(article)
        setInitialLoading(false)
        props.onUpdated?.()
    }

    async function update(partialData: IArticleData, translate?: boolean): Promise<Response> {
        const articleID = props.articleID || props.articleData?._id
        if (!articleID) return null
        if (translate) {
            if (partialData.title) {
                partialData.title = await api.translate.text(partialData.title, null, articleLanguage)
            }
            if (partialData.subTitle) {
                partialData.subTitle = await api.translate.text(partialData.subTitle, null, articleLanguage)
            }
            if (partialData.abstract) {
                partialData.abstract = await api.translate.text(partialData.abstract, null, articleLanguage)
            }
        }
        const response = await api.article.updateArticle(articleID, partialData)
        await loadArticle()
        return response
    }

    function isEmtpy(): boolean {
        const hasBlocks = !!article?.blocks?.length
        return isHeaderEmpty() && !hasBlocks
    }

    function isHeaderEmpty(): boolean {
        const hasTitle = !!article?.title && !props.hideTitle
        const hasSubTitle = !!article?.subTitle && !props.hideSubTitle
        const hasAbstract = !!article?.abstract
        return !hasTitle && !hasSubTitle && !hasAbstract
    }

    function computeGap(type: "overall" | "main") {
        if (props.edit) return "medium"
        if (isEmtpy()) return "none"
        if (style === "compact") {
            return "medium"
        }
        switch (type) {
            case "overall":
                return "large"
            case "main":
                return "medium"
        }
    }

    function languageSelectOptions(): SelectInputOptionType[] {
        return SharedConfig.LANGUAGES.map(language => {
            return {
                value: language,
                text: dict(`language.${language}`)
            }
        })
    }

    function articleTypeOptions(): SelectInputOptionType[] {
        return ARTICLE_TYPES.map(articleType => {
            return {
                value: articleType,
                text: dict(`article.type.${articleType}`)
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="article"
            ref={rootRef}
            data-style={style}>
            <ComponentInitializer
                isPropertyAvailable={!loading}
                fullHeight={true}>
                {!article &&
                    <ContentLayout alignItems="center" alignSelf="stretch">
                        <EmptyDataInfo text={dict("article.no.id.or.data")}/>
                    </ContentLayout>}
                {article &&
                    <ContentLayout
                        justifyItems={"center"}
                        alignContent="start"
                        gap={computeGap("overall")}>
                        {isEmtpy() && !props.edit && props.emptyDataInfo &&
                            <EmptyDataInfo text={props.emptyDataInfo}/>}
                        {(!isHeaderEmpty() || props.edit) &&
                            <ContentLayout
                                framed={props.edit}
                                gap="medium"
                                justifyItems={props.edit ? "stretch" : "center"}
                                className="article-header">
                                {props.edit &&
                                    <ContentLayout
                                        justifyContent="space-between"
                                        columnTemplate="1fr min-content">
                                        <Headline
                                            text={dict("article.header.title")}
                                            style="h5-underlined"/>
                                        <ContentLayout
                                            gap="small"
                                            columnTemplate={isHelpArticle
                                                ? (props.hideTypeSelect ? null : "min-content")
                                                : (props.hideTypeSelect ? "min-content min-content" : "min-content min-content min-content")}>
                                            {!isHelpArticle &&
                                                <IconButton
                                                    type="help"
                                                    size="small"
                                                    scale={0.8}
                                                    onClick={() => state.showHelp.setValue("article.header")}/>}
                                            {!props.hideTypeSelect &&
                                                <SelectInput
                                                    options={articleTypeOptions()}
                                                    size="small"
                                                    defaultValue={articleType}
                                                    onChange={value => update({type: value as ArticleType})}/>}
                                            <SelectInput
                                                options={languageSelectOptions()}
                                                size="small"
                                                defaultValue={articleLanguage}
                                                onChange={value => update({language: value as LanguageType})}/>
                                        </ContentLayout>
                                    </ContentLayout>}
                                {!props.hideTitle && <>
                                    {props.edit
                                        ? <Input
                                            type="text"
                                            size="small"
                                            required={true}
                                            label={dict("article.title.label")}
                                            defaultValue={article?.title}
                                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                            onCustomLinkClick={() => update({title: article?.title}, true)}
                                            action={async value => update({title: value})}/>
                                        : article?.title && article?.type != "homepage" && <>
                                        <Spacer height="normal"/>
                                        <Headline
                                            text={article.title}
                                            align={"center"}
                                            hyphens={true}
                                            style="h1"/>
                                    </>}
                                </>}
                                {!props.hideSubTitle && <>
                                    {props.edit
                                        ? <Input
                                            type="text"
                                            size="small"
                                            label={dict("article.subTitle.label")}
                                            defaultValue={article?.subTitle}
                                            customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                            onCustomLinkClick={() => update({subTitle: article?.subTitle}, true)}
                                            action={async value => update({subTitle: value})}/>
                                        : article?.subTitle && <Headline
                                        text={article.subTitle}
                                        align={"center"}
                                        style="h3"/>}
                                </>}
                                {props.edit
                                    ? <Input
                                        type="textarea"
                                        size="small"
                                        label={dict("article.abstract.label")}
                                        defaultValue={article?.abstract}
                                        customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                        onCustomLinkClick={() => update({abstract: article?.abstract}, true)}
                                        action={async value => update({abstract: value})}/>
                                    : article?.abstract &&
                                    <InfoText
                                        style="abstract"
                                        align={"center"}
                                        text={article.abstract}/>}
                            </ContentLayout>}
                        {(props.edit || article?.blocks?.filter(block => block.hidden != true)?.length > 0) &&
                            <ContentLayout className="article-main" gap={computeGap("main")}>
                                {article?.blocks?.length > 0
                                    ? <>{article.blocks.map(block => {
                                        return <ArticleBlock
                                            key={block._id}
                                            style={style}
                                            onChange={loadArticle}
                                            article={article}
                                            availableBlocks={props.availableBlocksWhenEditing}
                                            block={block}
                                            editing={props.edit}/>
                                    })}</>
                                    : <>{props.edit &&
                                        <ArticleEmptyBlock
                                            onChange={loadArticle}
                                            availableBlocks={props.availableBlocksWhenEditing}
                                            article={article}/>}</>}
                            </ContentLayout>}
                    </ContentLayout>}
            </ComponentInitializer>
        </div>
    );

}
