import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {ICarData} from "../../../../../../shared/models/ICarData";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {GarageCar} from "./car/GarageCar";
import {useSocketSignals} from "../../../../hooks/useSocketSignals";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {BasicPageTitle} from "../../../../ui/text/headings/BasicPageTitle";
import {Headline} from "../../../../ui/text/headings/Headline";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {PlanAccessMaxType} from "../../../../../../shared/types/PlanAccessType";
import {PlanFeatureDefinitions} from "../../../../../../shared/plan/PlanFeatureDefinitions";

/******************************************************************
 * UserGaragePage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserGaragePage() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict, state} = useServices()
    const {routeOwnerID,
        isRouteOwnerAuthUser,
        routeOwnerNick,
        routeOwnerValidDriverLicense,
        routeOwnerUseSubscription} = useRouteOwner()
    const {serverGarageUpdate} = useSocketSignals()
    const {windowWidth} = useWindowSize()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [activeCars, setActiveCars] = useState<ICarData[]>()
    const [inactiveCars, setInactiveCars] = useState<ICarData[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [maxCarsAllowed, setMaxCarsAllowed] = useState<number>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!routeOwnerID) return;
        loadCars()
    }, [routeOwnerID, serverGarageUpdate, routeOwnerValidDriverLicense, routeOwnerUseSubscription])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadCars() {
        setLoading(true)
        const cars: ICarData[] = await api.car.getGarage(routeOwnerID)
        setActiveCars(cars?.filter(car => car._notAllowedToUse !== true))
        setInactiveCars(cars?.filter(car => car._notAllowedToUse === true))
        setMaxCarsAllowed(computeMaxCarsAllowed())
        setLoading(false)
    }

    function computeColumns(inactiveCars?: boolean) {
        if (windowWidth < 600) return inactiveCars ? 3 : 2
        return inactiveCars ? 4 : 3
    }

    function computeMaxCarsAllowed() {
        if (!routeOwnerUseSubscription) return undefined
        return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("garage", routeOwnerValidDriverLicense)?.value
    }

    function title() {
        if (isRouteOwnerAuthUser) {
            return dict("garage.title.owner")
        }
        return dict("garage.title.visitor").replaceAll("{NICK}", routeOwnerNick)
    }

    function numCarsInfo(numCars: number, inactiveCars?: boolean) {
        if (!numCars || numCars === 0) {
            return dict("garage.cars.max.reached")
        }
        if (numCars === 1) {
            return dict(inactiveCars
                ? "garage.cars.inactive.one"
                : "garage.cars.one")
        }
        return dict(inactiveCars
            ? "garage.cars.inactive.many"
            : "garage.cars.many").replaceAll("{CARS}", numCars.toString())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!loading}>
            <ContentLayout
                className="user-garage-page"
                alignContent={activeCars?.length > 0 ? "start" : "stretch"}
                alignItems={activeCars?.length > 0 ? "start" : "center"}>
                {activeCars?.length > 0 &&
                    <BasicPageTitle title={title()} subTitle={numCarsInfo(activeCars?.length)}/>}
                {activeCars?.length > 0
                    ? <ContentLayout>
                        <ContentLayout
                            columns={computeColumns()}
                            gap="medium">
                            {activeCars?.map((carData) => {
                                return <GarageCar
                                    key={carData._id}
                                    car={carData}/>
                            })}
                        </ContentLayout>
                        {!!maxCarsAllowed && activeCars?.length >= maxCarsAllowed && <>
                            <Spacer height="normal"/>
                            <ContentLayout framed={isRouteOwnerAuthUser}>
                                {isRouteOwnerAuthUser && <Spacer height="small"/>}
                                <Headline
                                    text={numCarsInfo(inactiveCars?.length, true)}
                                    align="center"
                                    style={isRouteOwnerAuthUser ? "h3" : "h5"}/>
                                {isRouteOwnerAuthUser &&
                                    <ContentLayout justifyItems={"center"}>
                                        <InfoText
                                            text={dict("subscription.upgrade.info.garage")}
                                            align="center"/>
                                        <LabelButton
                                            label={dict("subscription.upgrade.info.button.showPlans")}
                                            style="primary-small"
                                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                                    </ContentLayout>}
                                {isRouteOwnerAuthUser &&
                                    <ContentLayout
                                        columns={computeColumns(true)}
                                        gap="medium">
                                        {inactiveCars?.map((carData) => {
                                            return <GarageCar
                                                key={carData._id}
                                                inactive={true}
                                                car={carData}/>
                                        })}
                                    </ContentLayout>}
                            </ContentLayout>
                        </>}
                    </ContentLayout>
                    : <ContentLayout gap="small" justifyItems="center">
                        <EmptyDataInfo text={isRouteOwnerAuthUser
                            ? dict("garage.owner.empty")
                            : dict("garage.visitor.empty")}/>
                        <LabelButton
                            label={dict("garage.empty.academy.button.label")}
                            style="secondary"
                            icon="academy"
                            onClick={() => state.showAcademy.setValue(dict("academy.id.garage"))}/>
                    </ContentLayout>}
                {isRouteOwnerAuthUser &&
                    <ActionBar location="main">
                        <LabelButton
                            label={dict("car.create")}
                            icon="plus"
                            onClick={() => state.showCar.setValue(true)}
                            style={"action-main"}/>
                        <Divider style="action-bar"/>
                        <IconButton
                            type="academy"
                            size="small"
                            onClick={() => state.showAcademy.setValue(dict("academy.id.garage"))}/>
                        <ActionBarSpacer/>
                    </ActionBar>}
            </ContentLayout>
        </ComponentInitializer>
    );

}
