import * as React from "react";
import {useEffect} from "react";
import {Modal} from "../Modal";
import {Form} from "../../../ui/form/Form";
import {Headline} from "../../../ui/text/headings/Headline";
import {Input} from "../../../ui/form/elements/input/Input";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../shared/types/PlanAccessType";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * CreateFolderModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CreateFolderModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices();
    const {eventID} = useEventState()
    const {isMobile} = useDeviceInfo()
    const {authUserSubscription, authUserUseSubscription} = useAuthUser()
    const {isRouteOwnerUser} = useRouteOwner()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (isRouteOwnerUser || !authUserUseSubscription) return;
        const validLicenseType = DriverLicenseUtils.validLicenseType(authUserSubscription)
        const isAllowed = PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("events", validLicenseType)?.type == "full";
        if (!isAllowed) {
            state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.eventFolders")})
            state.showCreateFolder.setValue(false)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function createFolder(formData: { name: string }): Promise<Response> {
        const response = await api.event.addFolder(eventID, formData);
        if (response.status == 200) {
            state.showCreateFolder.setValue(false)
        }
        return response;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal width="small" closeAction={() => state.showCreateFolder.setValue(false)}>
            <Headline text={dict("folder.create")} style="modal"/>
            {!!eventID &&
                <Form
                    actionLabel={dict("folder.create")}
                    action={createFolder}>
                    <Input
                        type="text"
                        id="name"
                        focus={!isMobile}
                        required={true}
                        label={dict("folder.create.name")}
                    />
                </Form>}
        </Modal>
    );

}
