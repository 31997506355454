/******************************************************************
 * MailServiceType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const MailServiceTypes = [
    "plesk",
    "ethereal"
] as const

export type MailServiceType = typeof MailServiceTypes[number]

export function isValidMailServiceType(type: string): boolean {
    return MailServiceTypes.includes(type as MailServiceType)
}
