import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IArticleFeatureBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Input} from "../../../form/elements/input/Input";
import {useServices} from "../../../../hooks/useServices";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import ReactMarkdown from "react-markdown";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {UploadableImage} from "../../../image/uploadable/UploadableImage";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {Picture} from "../../../image/Picture";
import {useElementSize} from "../../../../hooks/useElementSize";
import {ArticleTeaser} from "../../../teaser/article/ArticleTeaser";
import {LabelButton} from "../../../buttons/label/LabelButton";
import {StringUtils} from "../../../../../../shared/utils/StringUtils";
import rehypeExternalLinks from "rehype-external-links";

/******************************************************************
 * ArticleFeatureBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleFeatureBlock(props: {
    articleID: MongoObjectIDType
    data: IArticleFeatureBlockData
    editing: boolean
    language: LanguageType
    onChange: (data: IArticleFeatureBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)
    const copyRef = useRef<HTMLDivElement>(null)
    const h2Ref = useRef<HTMLHeadingElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api, state} = useServices()
    const {authUserHasPermission} = useAuthUser()
    const [useMobileEditStyle] = useMobileStyle(rootRef, 600)
    const [useMobileViewerStyle] = useMobileStyle(rootRef, 550)
    const [copyWidth] = useElementSize(copyRef)
    const [rootWidth] = useElementSize(rootRef)
    const h2Height = useElementSize(h2Ref)[1]

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [uploading, setUploading] = useState<boolean>(false)
    const [blockTypeIndex, setBlockTypeIndex] = useState<number>(0)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateBlockTypeIndex()
    }, [rootRef.current])

    useEffect(() => {
        if (copyWidth && rootRef.current) {
            rootRef.current.style.setProperty("--copy-width", `${copyWidth}px`)
        }
    }, [copyWidth, rootRef.current])

    useEffect(() => {
        if (rootWidth && rootRef.current) {
            rootRef.current.style.setProperty("--root-width", `${rootWidth}px`)
        }
    }, [rootWidth, rootRef.current])

    useEffect(() => {
        if (h2Height && rootRef.current) {
            rootRef.current.style.setProperty("--h2-height", `${h2Height}px`)
        }
    }, [h2Height, rootRef.current])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateBlockTypeIndex() {
        if (!rootRef.current) return
        const articleMainElement = rootRef.current.parentElement?.parentElement?.parentElement
        if (!articleMainElement) return
        const testimonialBlocks = articleMainElement.querySelectorAll(".article-feature-block")
        const index = Array.from(testimonialBlocks).indexOf(rootRef.current)
        setBlockTypeIndex(index)
    }

    async function updateTitle(title: string, translate?: boolean): Promise<Response> {
        if (translate) {
            title = await api.translate.text(title, null, props.language)
        }
        props.data.title = title
        props.onChange(props.data)
        return null
    }

    async function updateImage(file: File) {
        setUploading(true)
        const fileData = await api.file.uploadImage({
            ownerID: props.articleID,
            ownerType: "article",
            fileID: props.data.file?._id,
            file: file
        })
        if (!fileData) {
            setUploading(false)
            return null
        }
        setUploading(false)
        props.data.file = fileData._id
        props.onChange(props.data)
        return null
    }

    async function updateAbstract(abstract: string, translate?: boolean): Promise<Response> {
        if (translate) {
            abstract = await api.translate.text(abstract, null, props.language)
        }
        props.data.abstract = abstract
        props.onChange(props.data)
        return null
    }

    async function updateAlt(alt: string, translate?: boolean): Promise<Response> {
        if (translate) {
            alt = await api.translate.text(alt, null, props.language)
        }
        props.data.alt = alt
        props.onChange(props.data)
        return null
    }

    async function updateArticleID(articleID: MongoObjectIDType): Promise<Response> {
        props.data.articleID = articleID
        props.onChange(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="article-feature-block"
            gap="small">
            {props.editing
                ? <ContentLayout gap="small">
                    <ContentLayout
                        columnTemplate={useMobileEditStyle ? null : "1fr 2fr"}
                        gap="small">
                        <ContentLayout gap="small">
                            <UploadableImage
                                onChange={updateImage}
                                progressing={uploading}
                                framing="rounded-4"
                                showUploadButton={props.editing}
                                editable={props.editing}
                                style="relative"
                                imageSrc={api.file.getImageURLByFile(props.data.file)}
                                fit="contain"
                                buttonLabel={dict("form.upload.article.select")}/>
                            <Input
                                type="text"
                                size="small"
                                label={dict("article.feature.alt")}
                                defaultValue={props.data.alt}
                                customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                onCustomLinkClick={() => updateAlt(props.data.alt, true)}
                                action={updateAlt}/>
                        </ContentLayout>
                        <ContentLayout gap="small">
                            <Input
                                type="textarea"
                                size="small"
                                label={dict("article.feature.title")}
                                defaultValue={props.data.title}
                                customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                onCustomLinkClick={() => updateTitle(props.data.title, true)}
                                action={updateTitle}/>
                            <Input
                                type="textarea"
                                size="small"
                                label={dict("article.feature.abstract")}
                                defaultValue={props.data.abstract}
                                customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                                onCustomLinkClick={() => updateAbstract(props.data.abstract, true)}
                                action={updateAbstract}/>
                        </ContentLayout>
                    </ContentLayout>
                    <ContentLayout gap="small">
                        <ArticleTeaser
                            articleID={props.data.articleID}
                            editable={true}
                            onSelected={updateArticleID}/>
                    </ContentLayout>
                </ContentLayout>
                : <div
                    className="article-feature-block-viewer"
                    data-position-index={blockTypeIndex % 2}
                    data-use-mobile-style={useMobileViewerStyle ? "true" : "false"}>
                    <div className="article-feature-block-visual">
                        <Picture
                            file={props.data.file}
                            alt={props.data.alt}
                            framing="rounded-12"
                            shadow="large"
                            allowMediaViewer={true}/>
                    </div>
                    <div className="article-feature-block-copy" ref={copyRef}>
                        <h2 ref={h2Ref}>
                            <ReactMarkdown>{props.data.title}</ReactMarkdown>
                        </h2>
                        <h3>
                            <ReactMarkdown
                                rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>
                                {StringUtils.preprocessMarkdown(props.data.abstract)}
                            </ReactMarkdown>
                        </h3>
                        {props.data?.articleID &&
                            <LabelButton
                                label={dict("article.feature.articleButtonLabel")}
                                style="action-main"
                                onClick={() => state.showArticle.setValue(props.data.articleID)}/>}
                    </div>
                </div>}
        </ContentLayout>
    );
}
