import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {SpeakerLanguageSetting} from "../user/settings/language/speaker/SpeakerLanguageSetting";
import {LanguageSelector} from "../../../ui/form/elements/language/LanguageSelector";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";

/******************************************************************
 * SpeakerSettingsModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SpeakerSettingsModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict} = useServices();
    const {authUserLanguage} = useAuthUser()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            closeAction={() => state.showSpeakerSettings.setValue(false)}>
            <Headline text={dict("speaker.settings.title")} style="modal"/>
            <ContentLayout>
                <ContentLayout framed={true}>
                    <Headline
                        text={dict("user.language.settings.label")}
                        style="h5-underlined"/>
                    <LanguageSelector showReloadInfo={true}/>
                </ContentLayout>
                <SpeakerLanguageSetting speakerLanguage={authUserLanguage}/>
            </ContentLayout>
        </Modal>
    );

}
