import * as React from "react";

/******************************************************************
 * ClipboardCheckIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ClipboardCheckIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="clipboard-check-icon">
            <svg width={14 * (props.scale ?? 1)} height={20 * (props.scale ?? 1)} viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 2.5H9.33333C9.33333 1.12109 8.28698 0 7 0C5.71302 0 4.66667 1.12109 4.66667 2.5H1.75C0.783854 2.5 0 3.33984 0 4.375V18.125C0 19.1602 0.783854 20 1.75 20H12.25C13.2161 20 14 19.1602 14 18.125V4.375C14 3.33984 13.2161 2.5 12.25 2.5ZM7 1.5625C7.4849 1.5625 7.875 1.98047 7.875 2.5C7.875 3.01953 7.4849 3.4375 7 3.4375C6.5151 3.4375 6.125 3.01953 6.125 2.5C6.125 1.98047 6.5151 1.5625 7 1.5625ZM11.4188 10.6172L6.20521 16.1563C6.03385 16.3398 5.75677 16.3359 5.58542 16.1523L2.57396 12.8984C2.4026 12.7148 2.40625 12.418 2.5776 12.2344L3.61302 11.1328C3.78437 10.9492 4.06146 10.9531 4.23281 11.1367L5.9099 12.9492L9.77448 8.83984C9.94583 8.65625 10.2229 8.66016 10.3943 8.84375L11.4224 9.95313C11.5937 10.1406 11.5901 10.4336 11.4188 10.6172Z" fill="white"/>
            </svg>
        </div>
    );

}
