import * as React from "react";
import {useEffect, useState} from "react";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {IMailData} from "../../../../../../../shared/models/IMailData";
import {useServices} from "../../../../../hooks/useServices";
import {Modal} from "../../../Modal";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ComponentInitializer} from "../../../../../ui/utils/init/ComponentInitializer";
import {DriverList} from "../../../../../ui/list/driver/DriverList";

/******************************************************************
 * MailCustomRecipientsListModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailCustomRecipientsListModal(props: {
    mailData: IMailData
    onRecipientAdded: (mailData: IMailData) => void
    onRequestClose?: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [users, setUsers] = useState<IUserData[]>()
    const [availableUsers, setAvailableUsers] = useState<IUserData[]>()
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
    const [isAddingRecipient, setIsAddingRecipient] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadVerifiedUsers()
    }, [])

    useEffect(() => {
        filterData()
    }, [props.mailData, users])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadVerifiedUsers() {
        setUsers(await api.user.getAll())
        setIsLoadingData(false)
    }

    function filterData() {
        const filteredUsers = users?.filter(user => {
            return !isUserAlreadySelected(user)
        })
        setAvailableUsers(filteredUsers)
    }

    async function addCustomRecipent(user: IUserData) {
        if (isUserAlreadySelected(user)) {
            return
        }
        setIsAddingRecipient(true)
        const customRecipients = props.mailData.customRecipients || []
        customRecipients.push(user)
        const updatedMailData = await api.mail.updateMail(props.mailData._id, {
            customRecipients: customRecipients
        })
        props.onRecipientAdded(updatedMailData)
        setIsAddingRecipient(false)
    }

    function isUserAlreadySelected(user: IUserData): boolean {
        return !!props.mailData?.customRecipients?.find(selectedUser => {
            return (selectedUser._id ?? selectedUser) === user._id
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="mail-custom-recipients-list-modal"
            width="medium"
            closeAction={props.onRequestClose}>
            <Headline
                text={dict("mail.custom.recipients.title")}
                style="modal"/>
            <ComponentInitializer isPropertyAvailable={!isLoadingData}>
                <DriverList
                    data={availableUsers}
                    isBusy={isAddingRecipient}
                    primaryIconType="add"
                    emptyDataInfo={dict("mail.custom.recipients.emtpy.info")}
                    onPrimaryIconClick={addCustomRecipent}/>
            </ComponentInitializer>
        </Modal>
    );

}
