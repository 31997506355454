import * as React from "react";
import {ReactElement} from "react";
import {IInvalidEnterProp} from "../../../../../../../shared/types/IInvalidEnterProp";
import {useServices} from "../../../../../hooks/useServices";
import {WarningIcon} from "../../../../icons/WarningIcon";
import {InvalidSetupPropsUtils} from "../../../../../../utils/InvalidSetupPropsUtils";

/******************************************************************
 * DriverSetupInvalidPropsInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverSetupInvalidPropsInfo(props: {
    invalidProps: IInvalidEnterProp[]
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function getPropElement(prop: IInvalidEnterProp): ReactElement {
        return (
            <div
                key={prop.name}
                className="driver-setup-invalidprops-info-prop">
                <WarningIcon scale={0.7}/>{dict(InvalidSetupPropsUtils.getPropDictKey(prop))}
            </div>
        )
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="driver-setup-invalidprops-info">
            {props.invalidProps?.map((invalidProp: IInvalidEnterProp) => {
                return getPropElement(invalidProp)
            })}
        </div>
    );

}
