import * as React from "react";

/******************************************************************
 * DownIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DownIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="down-icon">
            <svg width={12 * (props.scale ?? 8)} height={16 * (props.scale ?? 1)} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.2937 0.950139C10.9044 0.560867 10.2734 0.560523 9.88371 0.949371L6.70542 4.12073C6.31508 4.51022 5.6831 4.51022 5.29275 4.12073L2.11447 0.94937C1.72477 0.560522 1.09374 0.560866 0.704472 0.950139V0.950139C0.314899 1.33971 0.314899 1.97133 0.704472 2.36091L5.29198 6.94842C5.68251 7.33894 6.31567 7.33894 6.70619 6.94842L11.2937 2.36091C11.6833 1.97134 11.6833 1.33971 11.2937 0.950139V0.950139Z" fill="white"/>
            </svg>
        </div>
    );

}
