import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useEventState} from "../../../../../hooks/useEventState";
import {TeamInfo} from "../../../../../ui/info/team/TeamInfo";

/******************************************************************
 * EventTeamsInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventTeamsInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {eventTeams} = useEventState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-teams-info">
            {eventTeams?.map((team) => {
                return <TeamInfo key={team._id} team={team}/>
            })}
        </ContentLayout>
    );

}
