import * as React from "react";
import {Form} from "../Form";
import {useServices} from "../../../hooks/useServices";
import {Input} from "../elements/input/Input";
import {ITabbedContentChildProps} from "../../layout/tabbed/ITabbedContentChildProps";
import {IKeyValuePair} from "../../../../../shared/types/IKeyValuePair";

/******************************************************************
 * LoginForm
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LoginForm(props: {
    tabbedContent: ITabbedContentChildProps
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function login(formData: IKeyValuePair | any): Promise<Response> {
        return await api.user.login(formData)
    }

    function forgotPassword(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        state.showForgotPassword.setValue(true);
        state.showLogin.setValue(null);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Form
            actionLabel={dict("form.login.sendButton")}
            onSuccess={async () => state.showLogin.setValue(null)}
            action={login}>
            <Input
                type="email"
                id="email"
                label={dict("form.element.mail.label")}
                required={true}/>
            <Input
                type="password"
                id="password"
                label={dict("form.element.password.label")}
                customLinkLabel={dict("form.element.password.forgot")}
                onCustomLinkClick={forgotPassword}
                required={true}/>
        </Form>
    );

}
