import * as React from "react";
import {Headline} from "../../../../text/headings/Headline";
import {MultiSelectOptionType, MultiSelectPresetOptionType} from "../options/MultiSelectOption";
import {Modal} from "../../../../../systems/modals/Modal";
import {MultiSelectOptionsList} from "../list/MultiSelectOptionsList";

/******************************************************************
 * MultiSelectModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MultiSelectModal(props: {
    label: string,
    options: MultiSelectOptionType[]
    presetOptions?: MultiSelectPresetOptionType[]
    defaultValue?: string[]
    minSelected?: number,
    showFilter?: boolean,
    filterPlaceholder?: string
    keepFilterOnOptionsChange?: boolean,
    onClose: () => void
    onChange: (value: string[]) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            position="global"
            closeAction={props.onClose}>
            <Headline
                text={props.label}
                style="modal"/>
            <MultiSelectOptionsList
                options={props.options}
                presetOptions={props.presetOptions}
                keepFilterOnOptionsChange={props.keepFilterOnOptionsChange}
                defaultValues={props.defaultValue}
                minSelected={props.minSelected}
                showHeader={props.showFilter}
                filterPlaceholder={props.filterPlaceholder}
                onChange={props.onChange}/>
        </Modal>
    );

}
