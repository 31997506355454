import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Input} from "../../../ui/form/elements/input/Input";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../ui/utils/divider/Divider";
import {UploadableImage} from "../../../ui/image/uploadable/UploadableImage";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {FormMessage} from "../../../ui/form/elements/message/FormMessage";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useGroupState} from "../../../hooks/useGroupState";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {TrackOwnerType} from "../../../../../shared/types/TrackOwnerType";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * CreateTrackModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CreateTrackModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api, error, router} = useServices();
    const {routeType} = useRouteStates()
    const {routeOwnerID, isRouteOwnerAuthUser, routeOwnerPath} = useRouteOwner()
    const {groupPath, groupID, isGroupOwner, isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isValid, setIsValid] = useState<boolean>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [busy, setBusy] = useState<boolean>()
    const [name, setName] = useState<string>()
    const [layout, setLayout] = useState<string>()
    const [file, setFile] = useState<File>()
    const [imageAspectRatio, setImageAspectRatio] = useState<number>(3 / 2)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        api.track.canCreateTrack().then((canAddTrack) => {
            if (!canAddTrack) {
                state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.tracks")})
                state.showCreateTrack.setValue(null)
            }
        })
    }, [])

    useEffect(() => {
        if (file) {
            const image = new Image()
            image.onload = () => {
                setImageAspectRatio(image.naturalWidth / image.naturalHeight)
            }
            image.src = URL.createObjectURL(file)
        }
    }, [file])

    useEffect(() =>{
        setIsValid(!!name && !!file)
    }, [name, layout, file])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        setBusy(false)
        state.showCreateTrack.setValue(null);
    }

    function trackOwnerType(): TrackOwnerType {
        switch (routeType) {
            case "member":
                return "user"
            case "group":
                return "group"
        }
        return null
    }

    function trackOwnerID(): MongoObjectIDType {
        switch (routeType) {
            case "member":
                return routeOwnerID
            case "group":
                return groupID
        }
        return null
    }

    function closeModalAndShowTrack(trackPath: string) {
        close()
        switch (routeType) {
            case "member":
                router.showRoute(FrontendRoute.TRACK("user", routeOwnerPath, trackPath))
                break;
            case "group":
                router.showRoute(FrontendRoute.TRACK("group", groupPath, trackPath))
        }
    }

    async function createTrack() {
        setBusy(true)
        const response = await api.track.create({
            name: name ?? null,
            layout: layout ?? null,
            ownerID: trackOwnerID(),
            ownerType: trackOwnerType()
        })
        const responseJSON = await response.json()
        if (response.status == 200) {
            const trackData: ITrackData = responseJSON;
            const formData = new FormData()
            formData.append(SharedConfig.FILE_UPLOAD_KEY, file)
            formData.append("trackID", trackData._id)
            formData.append("ownerType", trackData.ownerType)
            await api.track.uploadImage(formData)
            closeModalAndShowTrack(trackData.path)
        } else {
            setBusy(false)
            setErrorMessage(error.createMessage(responseJSON))
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            width="medium"
            className="create-track-modal"
            position="global">
            <Headline
                text={dict('track.create.title')}
                style="modal"/>
            <ContentLayout>
                <Input
                    type="text"
                    label={dict("track.name.label")}
                    helpTopic="track.name"
                    defaultValue={null}
                    onChange={(value) => setName(value)}/>
                <Input
                    type="text"
                    label={dict("track.layout.label")}
                    helpTopic="track.layout"
                    placeholder={dict("input.optional")}
                    defaultValue={null}
                    onChange={(value) => setLayout(value)}/>
                <div
                    className="create-track-modal-image"
                    style={{aspectRatio: imageAspectRatio}}>
                    <UploadableImage
                        id="trackfile"
                        editable={isRouteOwnerAuthUser || isGroupAdmin || isGroupOwner}
                        fit="contain"
                        onChange={(formData) => setFile(formData)}
                        buttonLabel={dict("track.image.select.label")}/>
                </div>
                <Divider style="modal"/>
                {errorMessage && <FormMessage message={errorMessage} type="error"/>}
                <ContentLayout columns={2}>
                    <LabelButton
                        label={dict("button.abort")}
                        style="secondary"
                        onClick={close}/>
                    <LabelButton
                        label={dict("track.button.create")}
                        style="primary"
                        progressing={busy}
                        disabled={!isValid}
                        onClick={createTrack}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    )
}
