import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useEventState} from "../../../hooks/useEventState";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {FrontendRouteUtils} from "../../../../../shared/utils/FrontendRouteUtils";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {FormUtils} from "../../../../utils/FormUtils";
import {EditableField} from "../../form/editable/EditableField";
import {useGroupState} from "../../../hooks/useGroupState";
import {IResultData} from "../../../../../shared/models/IResultData";
import {ResultContextMenu} from "../../context/result/ResultContextMenu";
import {Icon} from "../../icons/Icon";
import {StateInfo} from "../../info/state/StateInfo";
import {ResultStateType} from "../../../../../shared/types/ResultStateType";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";


/******************************************************************
 * ResultButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ResultButton(props: {
    data: IResultData
    isDragging: boolean
    folded?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, api} = useServices();
    const {eventPath, eventState, eventOwnerType, eventOwner} = useEventState();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {routePath} = useRouteStates();
    const {isGroupAdmin} = useGroupState()
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const inputRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isCurrentResult, setIsCurrentResult] = useState<boolean>();
    const [hover, setHover] = useState<boolean>(hasTouch);
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!routePath) return;
        const eventRoute = FrontendRouteUtils.parseEventRoute(routePath);
        setIsCurrentResult(eventRoute?.resultPath == props.data.path)
    }, [routePath])

    useEffect(() => {
        if (contextMenuOpen == false) {
            setHover(hasTouch);
        }
    }, [contextMenuOpen])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoResult(e: React.MouseEvent) {
        if (isRenaming) return;
        switch (e.detail) {
            case 1:
                router.showRoute(FrontendRoute.EVENT_RESULT(eventOwnerType, eventOwner.path, eventPath, props.data.path));
                break;
            case 2:
                editResultName();
                break;
        }
    }

    async function editResultName() {
        if (!isRouteOwnerAuthUser && !isGroupAdmin) return;
        if (isRenaming) return;
        setIsRenaming(true);
        await PromisedDelay.wait(0.1);
        FormUtils.focusAndPositionCaretToEnd(inputRef.current);
    }

    async function updateName(value: string): Promise<Response> {
        return await api.result.update(props.data._id, {name: value});
    }

    function stateInfoText(): string {
        const finished = props.data?.sessions?.filter(session => session.state == "finished")?.length ?? 0
        const total = props.data?.sessions?.length ?? 0
        if (finished == total) {
            return total.toString()
        }
        return `${finished} / ${total}`
    }

    function stateInfoProgress(): ResultStateType {
        const notFinished = props.data?.sessions?.some(session => session.state !== "finished") ?? false
        if (notFinished) {
            return "progress"
        }
        return "complete"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="result-button"
            data-folded={props.folded}
            data-hover={hover}
            data-renaming={isRenaming}
            data-is-owner={isRouteOwnerAuthUser || isGroupAdmin}
            data-event-live={eventState == "live"}
            data-dragging={props.isDragging}
            data-selected={isCurrentResult}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(hasTouch)}
            onClick={gotoResult}>
            <div className="result-button-label">
                <Icon type="trophy" scale={0.7}/>
                <EditableField
                    text={props.data.name}
                    action={updateName}
                    disabled={!isRenaming}
                    useEnterToSave={true}
                    ui="right"
                    onInputRef={(ref) => inputRef.current = ref.current}
                    onExit={() => setIsRenaming(false)}/>
            </div>
            {!isRenaming &&
                <div className="result-button-meta">
                    {(isRouteOwnerAuthUser || isGroupAdmin) && hover &&
                        <ResultContextMenu
                            onRequestEditName={editResultName}
                            onToggle={setContextMenuOpen}
                            onClicked={() => {
                                setHover(hasTouch)
                            }}
                            data={props.data}/>}
                    <StateInfo
                        state={stateInfoProgress()}
                        text={stateInfoText()}
                    />
                </div>}
        </div>
    );

}
