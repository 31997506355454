import * as React from "react";
import {IStintData} from "../../../../../shared/models/IStintData";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {StintRaceDirectorEntry} from "./entry/StintRaceDirectorEntry";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessType} from "../../../../../shared/types/PlanAccessType";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {useSessionState} from "../../../hooks/useSessionState";

/******************************************************************
 * RaceDirectorModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RaceDirectorModal(props: IStintData) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()
    const {sessionMode} = useSessionState()
    const {authUserValidDriverLicense, authUserUseSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function hasRequiredDriverLicense(): boolean {
        return PlanFeatureDefinitions.getFeatureAccess<PlanAccessType>("racedirector", authUserValidDriverLicense)?.type == "full";
    }

    function close() {
        state.showRaceDirector.setValue(null)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            className="race-director-modal"
            closeAction={close}>
            <Headline
                text={dict("raceDirector.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict(sessionMode == "race" ? "raceDirector.info" : "raceDirector.messageonly.info")}
                    size="small"/>
                {(hasRequiredDriverLicense() || !authUserUseSubscription)
                    ? <StintRaceDirectorEntry
                        stint={props}
                        requestClose={close}/>
                    : <ContentLayout framed={true}>
                        <InfoText
                            text={dict("raceDirector.noAccess")}/>
                        <LabelButton
                            label={dict("subscription.upgrade.info.button.showPlans")}
                            style="primary"
                            onClick={() => {
                                state.showSubscriptionPlans.setValue(true)
                                close()
                            }}/>
                    </ContentLayout>}
            </ContentLayout>
        </Modal>
    );

}
