import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {SplitView} from "../../../ui/splitview/SplitView";
import {SessionBasicSetup} from "./settings/basic/SessionBasicSetup";
import {useSessionState} from "../../../hooks/useSessionState";
import {SessionSetupSectionType} from "../../../../../shared/types/SessionSetupSectionType";
import {SessionSetupSectionOrder} from "./settings/SessionSetupSectionOrder";
import {SessionSetupCompatibility} from "../../../ui/utils/session/SessionSetupCompatibility";
import {SessionTimingSetup} from "./settings/timing/SessionTimingSetup";
import {SessionTrackSetup} from "./settings/track/SessionTrackSetup";
import {SessionPointsSetup} from "./settings/points/SessionPointsSetup";
import {SessionStartOrderSetup} from "./settings/startorder/SessionStartOrderSetup";
import {SessionFalseStartSetup} from "./settings/falsestart/SessionFalseStartSetup";
import {SessionCarSetup} from "./settings/car/SessionCarSetup";
import {SidebarSpacer} from "../../../ui/splitview/spacer/SidebarSpacer";
import {SessionDrivers} from "./settings/drivers/SessionDrivers";
import {SessionTeams} from "./settings/teams/SessionTeams";
import {SessionSetupPresetSelectControl} from "./presets/SessionSetupPresetSelectControl";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {SessionAnnouncementsSetup} from "./settings/announcements/SessionAnnouncementsSetup";
import {SessionRightsSetup} from "./settings/rights/SessionRightsSetup";

/******************************************************************
 * SessionSettingsModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionSettingsModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {
        sessionData,
        sessionClassificationMode,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [availableSections, setAvailableSections] = useState<SessionSetupSectionType[]>()
    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const availableTypes: SessionSetupSectionType[] = []
        SessionSetupSectionOrder.list.forEach(sectionType => {
            if (SessionSetupCompatibility.isSectionAvailable(sectionType, sessionData)) {
                availableTypes.push(sectionType);
            }
        })
        setAvailableSections(availableTypes)
    }, [sessionData])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function getSetupSection(sectionType: SessionSetupSectionType) {
        switch (sectionType) {
            case "basic":
                return <SessionBasicSetup/>
            case "timing":
                return <SessionTimingSetup/>
            case "track":
                return <SessionTrackSetup/>
            case "points":
                return <SessionPointsSetup/>
            case "startOrder":
                return <SessionStartOrderSetup/>
            case "falseStart":
                return <SessionFalseStartSetup/>
            case "car":
                return <SessionCarSetup/>;
            case "announcements":
                return <SessionAnnouncementsSetup/>;
            case "rights":
                return <SessionRightsSetup/>;
        }
    }

    function sortedVisibleSections(): SessionSetupSectionType[] {
        if (!availableSections) return null;
        const sortedSections: SessionSetupSectionType[] = [];
        availableSections.forEach(sectionType => {
            sortedSections.push(sectionType);
        })
        return sortedSections;
    }

    function driversOrTeamSettings(): ReactElement {
        switch (sessionClassificationMode) {
            case "drivers":
                return <SplitViewContent
                    label={dict("session.settings.drivers")}
                    content={<SessionDrivers/>}/>
            case "teams":
                return <SplitViewContent
                    label={dict("session.settings.teams")}
                    content={<SessionTeams/>}/>
            case "solo":
                return null

        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="extra-large"
            height={splitViewMobileMode ? "auto" : "medium"}
            className="session-settings-modal"
            padding="none"
            closeAction={() => state.showSessionSettings.setValue(false)}>
            <SplitView
                title={dict("session.settings.title")}
                padding="modal"
                addPaddingForContentActionBar={true}
                onMobileMode={setSplitViewMobileMode}>
                {sortedVisibleSections()?.map((sectionType) => {
                    return <SplitViewContent
                        key={sectionType}
                        label={dict("session.settings." + sectionType)}
                        content={getSetupSection(sectionType)}/>
                })}
                {!!driversOrTeamSettings() && <SidebarSpacer/>}
                {driversOrTeamSettings()}
            </SplitView>
            {(isGroupAdmin || isRouteOwnerAuthUser) && !sessionIsFinished &&
                <ActionBar location="modal">
                    <ActionBarSpacer/>
                    <SessionSetupPresetSelectControl mode="edit"/>
                </ActionBar>}
        </Modal>
    );

}
