import * as React from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {SessionHeader} from "./header/SessionHeader";
import {useSessionState} from "../../../hooks/useSessionState";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {SessionLeaderboard} from "./leaderboard/SessionLeaderboard";
import {SessionActionBar} from "./actionbar/SessionActionBar";

/******************************************************************
 * SessionPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionPage() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {sessionID} = useSessionState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            isPropertyAvailable={!!sessionID}>
            <ContentLayout
                className="session-page"
                alignItems="stretch"
                rowTemplate="min-content 1fr">
                <SessionHeader/>
                <SessionLeaderboard/>
            </ContentLayout>
            <SessionActionBar/>
        </ComponentInitializer>
    );

}
