import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {HelpButton} from "../../../buttons/help/HelpButton";
import {ContextMenu} from "../../../context/ContextMenu";
import {ContextMenuSelectable} from "../../../context/elements/ContextMenuSelectable";
import {ContextMenuGroup} from "../../../context/elements/ContextMenuGroup";
import {DriverLicenseType} from "../../../../../../shared/types/DriverLicenseType";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * SelectInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type SelectInputOptionType<T = string | number> = {
    value: T
    text: string
    disabled?: boolean
    badge?: ReactElement
    requiredDriverLicense?: DriverLicenseType
};

export type SelectInputOptionGroupType<T = string | number> = {
    groupLabel: string;
    options: SelectInputOptionType<T>[];
}

export function SelectInput(props: {
    label?: string
    id?: string
    options: (SelectInputOptionType | SelectInputOptionGroupType)[]
    onChange?: (value: string | string[] | number) => void
    onHigherDriverLicenseRequired?: () => void
    defaultValue?: any
    size?: "normal" | "small"
    style?: "normal" | "important"
    alignSelf?: "start" | "center" | "end"
    required?: boolean
    readonly?: boolean
    minWidth?: number
    removeGapWhenReadonly?: boolean
    helpTopic?: string
    inlineHelp?: string
    processing?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [currentOption, setCurrentOption] = useState<SelectInputOptionType>(computeCurrentOption(props.defaultValue));

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setCurrentOption(computeCurrentOption(props.defaultValue))
    }, [props.defaultValue, props.options])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onChange(value: any, needHigherDriverLicense?: boolean) {
        if (!props.onChange || props.readonly) return null;
        if (needHigherDriverLicense) {
            if (props.onHigherDriverLicenseRequired) {
                props.onHigherDriverLicenseRequired()
            }
            return null;
        }
        setCurrentOption(computeCurrentOption(value))
        props.onChange(value);
    }

    function showHeader() {
        return props.label || props.helpTopic;
    }

    function computeCurrentOption(value: string | number): SelectInputOptionType {
        let selectedOption: SelectInputOptionType = null
        props.options?.forEach(item => {
            const isGroup = (item as SelectInputOptionGroupType).groupLabel != null;
            if (isGroup) {
                const group = item as SelectInputOptionGroupType;
                if (!value) {
                    value = group.options[0].value
                }
                group.options.forEach(option => {
                    if (option.value == value) {
                        selectedOption = option
                    }
                });
            }
            if ((item as SelectInputOptionType).value != null) {
                const option = item as SelectInputOptionType
                if (!value) {
                    value = option.value
                }
                if (option.value == value) {
                    selectedOption = option
                }
            }
        })
        return selectedOption
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="select-field"
            data-type="select"
            data-align-self={props.alignSelf ?? "start"}
            data-size={props.size ?? "normal"}
            data-style={props.style ?? "normal"}
            data-readonly={props.readonly}
            data-remove-gap-when-readonly={props.removeGapWhenReadonly}>
            {showHeader() &&
                <div className="select-field-header">
                    {props.label && <label htmlFor={props.id}>{props.label}</label>}
                    {props.helpTopic && <HelpButton helpTopic={props.helpTopic}/>}
                </div>}
            <div
                className="select-field-body"
                style={{minWidth: props.minWidth}}>
                <>{props.id &&
                    <input
                        type="hidden"
                        id={props.id}
                        value={currentOption?.value ?? ""}/>}</>
                <ContextMenu
                    type="label"
                    isBusy={props.processing}
                    enabled={!props.readonly}
                    labelSize={props.size ?? "normal"}
                    labelImportant={props.style == "important"}
                    stopPropagation={true}
                    label={currentOption?.text}>
                    {props.options?.map(item => {
                        const isGroup = (item as SelectInputOptionGroupType).groupLabel != null;
                        if (isGroup) {
                            const group = item as SelectInputOptionGroupType;
                            return <div key={group.groupLabel}>
                                <ContextMenuGroup name={group.groupLabel}/>
                                {group.options.map(option => {
                                    return <ContextMenuSelectable
                                        key={option.value}
                                        label={option.text}
                                        badgeElement={option.badge}
                                        onClick={needHigherDriverLicense => onChange(option.value, needHigherDriverLicense)}
                                        requiredDriverLicense={option.requiredDriverLicense}
                                        selected={currentOption?.value == option.value}/>
                                })}
                            </div>
                        }
                        const option = item as SelectInputOptionType;
                        return <ContextMenuSelectable
                            key={option.value}
                            label={option.text}
                            badgeElement={option.badge}
                            onClick={needHigherDriverLicense => onChange(option.value, needHigherDriverLicense)}
                            requiredDriverLicense={option.requiredDriverLicense}
                            selected={currentOption?.value == option.value}/>
                    })}
                </ContextMenu>
            </div>
            {props.inlineHelp &&
                <ReactMarkdown className="select-field-inline-help">{props.inlineHelp}</ReactMarkdown>}
        </div>
    );
}
