import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {UploadableImage} from "../../../ui/image/uploadable/UploadableImage";
import {Input} from "../../../ui/form/elements/input/Input";
import {FormMessage} from "../../../ui/form/elements/message/FormMessage";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {CarChassisType} from "../../../../../shared/types/CarChassisType";
import {CarPropertyType} from "../../../../../shared/types/CarPropertyType";
import {ICarData} from "../../../../../shared/models/ICarData";
import {SelectInput} from "../../../ui/form/elements/select/SelectInput";
import {CarUtils} from "../../../../utils/CarUtils";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {MultiSelectModal} from "../../../ui/form/elements/select/modal/MultiSelectModal";
import {ICarProperty} from "../../../../../shared/models/submodels/ICarProperty";
import {TextValidation} from "../../../../../shared/validation/TextValidation";
import {EmptyDataInfo} from "../../../ui/info/empty/EmptyDataInfo";
import {CarStateType} from "../../../../../shared/types/CarStateType";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ResponseStyle} from "../../../../utils/types/ResponseStyle";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {EditModeActionBar} from "../academy/bars/EditModeActionBar";

/******************************************************************
 * CarModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type CarModalMode = "create" | "edit" | "view"

export function CarModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state, error, router} = useServices();
    const {authUserPath} = useAuthUser()
    const {showUserGarage} = useRouteStates()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {windowWidth, windowHeight} = useWindowSize();

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const propertiesRef = useRef<HTMLDivElement>()
    const imageRef = useRef<HTMLDivElement>()
    const contentRef = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [data, setData] = useState<ICarData>()
    const [mode, setMode] = useState<CarModalMode>()
    const [isValid, setIsValid] = useState<boolean>()
    const [showSelectPropertiesModal, setShowSelectPropertiesModal] = useState<boolean>()
    const [errorMessage, setErrorMessage] = useState<string>()
    const [busy, setBusy] = useState<boolean>()
    const [name, setName] = useState<string>()
    const [chassis, setChassis] = useState<CarChassisType>("sturmkind_d1")
    const [carState, setCarState] = useState<CarStateType>("original")
    const [properties, setProperties] = useState<ICarProperty[]>()
    const [selectedProperties, setSelectedProperties] = useState<CarPropertyType[]>()
    const [file, setFile] = useState<File>()
    const [columnsTemplate, setColumnsTemplate] = useState<string>()
    const [responseStyle, setResponseStyle] = useState<ResponseStyle>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (state.showCar.getValue()) {
            const isEditMode = typeof state.showCar.getValue() == "object"
            setData(isEditMode ? state.showCar.getValue() as ICarData : null)
            setMode(isEditMode ? "view" : "create")
            if (!isEditMode) {
                api.car.canCreateCar().then(allowed => {
                    if (!allowed) {
                        state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.garage")})
                        state.showCar.setValue(null)
                    }
                })
            }
            return
        }
    }, [])

    useEffect(() => {
        updateResponseStyles()
    }, [imageRef.current, windowWidth, windowHeight])

    useEffect(() => {
        if (!data) return
        setSelectedProperties(data.properties.map(prop => prop.type))
        setName(data.name)
        setChassis(data.chassis)
        setProperties(data.properties)
        setCarState(data.state)
    }, [data])

    useEffect(() => {
        switch (mode) {
            case "create":
                setIsValid(!!name && !!file && !!chassis && arePropertiesValid())
                break
            case "edit":
                setIsValid(!!name && !!chassis && arePropertiesValid())
        }
    }, [name, file, chassis, properties])

    useEffect(() => {
        updateProperties()
    }, [selectedProperties])

    useEffect(() => {
        if (errorMessage) {
            setBusy(false)
        }
    }, [errorMessage])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function saveCar() {
        setBusy(true)
        let response: Response = data
            ? await saveUpdate()
            : await saveCreate()
        if (response.status == 200) {
            const carData: ICarData = data ?? await response.json();
            if (file) {
                await saveImage(carData)
            } else {
                state.showCar.setValue(null);
            }
            if (showUserGarage) return
            router.showRoute(FrontendRoute.USER_GARAGE(authUserPath))
        } else {
            const responseJSON = await response.json()
            setErrorMessage(error.createMessage(responseJSON))
        }
    }

    async function saveUpdate(): Promise<Response> {
        return await api.car.update(
            data._id, {
                name: name,
                chassis: chassis,
                state: carState,
                properties: properties ?? []
            })
    }

    async function saveCreate(): Promise<Response> {
        return await api.car.create({
            name: name,
            chassis: chassis,
            state: carState,
            properties: properties ?? []
        })
    }

    async function saveImage(carData: ICarData) {
        const formData = new FormData()
        formData.append(SharedConfig.FILE_UPLOAD_KEY, file)
        formData.append("carID", carData._id)
        if (mode && carData.images[0]?._id) {
            formData.append("imageID", carData.images[0]._id)
        }
        const fileResponse = await api.car.uploadImage(formData)
        if (fileResponse.status != 200) {
            if (mode == "create") {
                await api.car.delete(carData._id)
            }
            const fileResponseJSON = await fileResponse.json()
            setErrorMessage(error.createMessage(fileResponseJSON))
            setBusy(false)
        } else {
            state.showCar.setValue(null);
        }
    }

    function deleteCar() {
        state.showConfirm.setValue({
            type: "deleteCar",
            payload: {
                carName: data.name,
                carID: data._id
            }
        })
    }

    function updateProperties() {
        const propertiesData = selectedProperties?.map(property => {
            const inputElement = propertiesRef.current.querySelectorAll("#" + property)[0] as HTMLInputElement
            return {
                type: property,
                text: inputElement.value
            }
        })
        setProperties(propertiesData)
    }

    function arePropertiesValid(): boolean {
        if (!selectedProperties || selectedProperties.length == 0) {
            return true;
        }
        const invalidProperties = properties?.filter(property => {
            switch (property.type) {
                case "weight":
                    return !TextValidation.isValid(property.text)
                default:
                    return !TextValidation.isValid(property.text)
            }
        })
        return invalidProperties?.length == 0 ?? false
    }

    function updateResponseStyles() {
        if (contentRef.current?.offsetWidth < 600) {
            setColumnsTemplate("1fr")
            setResponseStyle("mobile")
            return
        }
        setResponseStyle("desktop")
        setColumnsTemplate("2fr 1fr")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="extra-large"
            className="car-modal"
            responseStyle={responseStyle}
            initialized={responseStyle !== null}
            closeAction={() => state.showCar.setValue(null)}>
            <ContentLayout
                ref={contentRef}
                alignItems="start"
                columnTemplate={columnsTemplate}>
                <UploadableImage
                    ref={imageRef}
                    id="car-image"
                    editable={mode !== "view"}
                    fit="cover"
                    mediaViewerFile={data?.images[0]}
                    imageSrc={api.file.getImageURLByFile(data?.images[0])}
                    onChange={setFile}
                    buttonLabel={dict("car.image.select.label")}/>
                <ContentLayout>
                    <ContentLayout framed={true} className="car-modal-general">
                        <Headline
                            text={dict("car.general.label")}
                            style="h5-underlined"/>
                        <Input
                            type="text"
                            required={true}
                            readonly={mode === "view"}
                            removeGapWhenReadonly={true}
                            defaultValue={name}
                            onChange={setName}
                            inlineHelp={mode === "view" ? null : dict("car.name.help")}
                            helpTopic={"car.name"}
                            label={dict("car.name.label")}/>
                        <SelectInput
                            label={dict("car.chassis.label")}
                            required={true}
                            readonly={mode === "view"}
                            removeGapWhenReadonly={true}
                            defaultValue={chassis}
                            options={CarUtils.chassisOptions(dict)}
                            onChange={(value) => setChassis(value as CarChassisType)}/>
                        <SelectInput
                            label={dict("car.state.label")}
                            required={true}
                            readonly={mode === "view"}
                            removeGapWhenReadonly={true}
                            defaultValue={carState}
                            options={CarUtils.stateOptions(dict)}
                            onChange={(value) => setCarState(value as CarStateType)}/>
                    </ContentLayout>
                    {(mode !== "view" || selectedProperties?.length > 0) &&
                        <ContentLayout framed={true} className="car-model-properties">
                            <ContentLayout
                                columns={mode === "view" ? 1 : 2}
                                justifyContent="space-between"
                                columnTemplate="auto min-content">
                                <Headline
                                    text={dict("car.properties.label")}
                                    style="h5-underlined"/>
                                {mode !== "view" &&
                                    <LabelButton
                                        label={dict("car.addProperty.button.label")}
                                        disabled={busy}
                                        style="primary-small"
                                        onClick={() => setShowSelectPropertiesModal(true)}/>}
                            </ContentLayout>
                            <ContentLayout
                                ref={propertiesRef}
                                fullHeight={true}>
                                {selectedProperties && selectedProperties.length > 0
                                    ? selectedProperties.map(property => {
                                        return <Input
                                            key={property}
                                            id={property}
                                            type="text"
                                            readonly={mode === "view"}
                                            removeGapWhenReadonly={true}
                                            required={true}
                                            defaultValue={data?.properties.filter(prop => prop.type == property)[0]?.text}
                                            onChange={updateProperties}
                                            label={dict("car.properties." + property)}/>
                                    })
                                    : <EmptyDataInfo
                                        size="small"
                                        text={dict("car.properties.empty.info")}/>}
                            </ContentLayout>
                        </ContentLayout>}
                    {mode !== "view" && <ContentLayout gap="small">
                        {errorMessage && <FormMessage message={errorMessage} type="error"/>}
                        <LabelButton
                            label={dict("car.save.button.label")}
                            style="primary"
                            progressing={busy}
                            disabled={!isValid}
                            onClick={saveCar}/>
                        {mode === "edit" && <LabelButton
                            label={dict("car.delete.label")}
                            style="secondary"
                            progressing={busy}
                            onClick={deleteCar}/>}
                    </ContentLayout>}
                </ContentLayout>
            </ContentLayout>
            {isRouteOwnerAuthUser && mode != "create" &&
                <EditModeActionBar
                    editMode={mode == "edit"}
                    onEditModeChange={isEditMode => setMode(isEditMode ? "edit" : "view")}/>}
            {showSelectPropertiesModal && <MultiSelectModal
                label={dict("car.properties.label")}
                minSelected={0}
                options={CarUtils.propertyOptions(dict)}
                defaultValue={selectedProperties}
                onClose={() => setShowSelectPropertiesModal(false)}
                onChange={properties => setSelectedProperties(properties as CarPropertyType[])}/>}
        </Modal>

    );

}
