import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {VerifyForm} from "../../../ui/form/forms/VerifyForm";
import {useServices} from "../../../hooks/useServices";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * VerifySubscriptionChangeModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function VerifySubscriptionChangeModal(props: {
    requestedLicense: DriverLicenseType
    requestClose: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {authUserMail} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [processing, setProcessing] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const abortController = new AbortController()
        api.user.requestVerificationCode({email: authUserMail}, abortController)
        return () => {
            abortController.abort()
        }
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function acceptSubscriptionChange(code: string): Promise<Response> {
        setProcessing(true)
        const success = await api.paddle.updateSubscription(props.requestedLicense, false, code)
        if (!success) {
            setProcessing(false)
        }
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={props.requestClose}>
            <Headline
                text={dict("verify.subscription.change.title")}
                style="modal"/>
            <VerifyForm
                forceProcessing={processing}
                info={dict("verify.subscription.change.info")}
                actionLabel={dict("verify.subscription.change.button.label")}
                action={acceptSubscriptionChange}/>
        </Modal>
    );

}
