import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {UserPermissionType} from "../../../../../../../shared/types/UserPermissionType";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {useServices} from "../../../../../hooks/useServices";
import {Icon} from "../../../../../ui/icons/Icon";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {UserUtils} from "../../../../../../../shared/utils/UserUtils";
import {FrontendConfig} from "../../../../../../core/FrontendConfig";

/******************************************************************
 * UserPermissionSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserPermissionSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {authUserPermissions} = useAuthUser()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<UserPermissionType>[] {
        return [{
            type: "custom",
            size: "40px",
            customCell: (key) => (
                <ContentLayout
                    key={key}
                    justifyItems="center">
                    <Icon type="check"/>
                </ContentLayout>)
        }, {
            columnName: dict("user.settings.permission.columnname"),
            type: "text-bold",
            size: "2fr",
            value: (permission: UserPermissionType) => dict("user.permission." + permission)
        },];
    }

    function relevantPermissions(): UserPermissionType[] {
        return UserUtils.availableUserPermissions(
            FrontendConfig.PADDLE_ENVIRONMENT,
            FrontendConfig.PADDLE_ENABLED_PER_DEFAULT
        ).filter(permission => authUserPermissions?.includes(permission))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="user-permission-settings">
            <InfoText text={dict("user.settings.permission.info")}/>
            <Table<UserPermissionType>
                rowsData={relevantPermissions()}
                style="admin-compact"
                columnsDefinition={columnsDefinition()}/>
        </ContentLayout>
    );

}
