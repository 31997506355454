import * as React from "react";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * TableLeaderboardLapsCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardLapsCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue(): string {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        switch (session.setup.timing) {
            case "sync":
                if (entry.isStartOrder) {
                    return entry.overall?.drivenLaps?.toString() ?? "-"
                }
                switch (entry.state)  {
                    case "ready":
                    case "invalid":
                        return "-"
                    case "driving":
                    case "finished":
                        return entry.overall?.drivenLaps?.toString() ?? "-"
                }
                break
            case "async":
                if (entry.isStartOrder) {
                    return "";
                }
                if (entry.state === "finished") {
                    switch (session.setup.mode) {
                        case "lap":
                            return entry.overall.drivenLaps?.toString() ?? "-"
                        case "race":
                            switch (session.setup.finishType) {
                                case "laps":
                                    return (entry.bestDrivenTimeStint.drivenLaps?.toString() ?? "–") + "/" + availableLaps()
                                case "duration":
                                    return entry.bestDrivenTimeStint.drivenLaps?.toString() ?? "–"
                            }

                    }
                } else {
                    switch (session.setup.mode) {
                        case "lap":
                        case "race":
                            switch (session.setup.finishType) {
                                case "laps":
                                    if (entry.team) {
                                        return entry.overall?.drivenLaps?.toString() ?? "–"
                                    }
                                    return (entry.latestStint?.drivenLaps ?? "–") + "/" + availableLaps()
                                case "duration":
                                    return entry.latestStint?.drivenLaps?.toString() ?? "–"
                            }
                            break
                    }
                }
        }
        return props.entry.overall?.drivenLaps?.toString() ?? "-"
    }

    function secondaryValue(): string {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        const totalLaps = entry.overall.drivenLaps
        switch (session.setup.mode) {
            case "lap":
                switch (session.setup.timing) {
                    case "async":
                        if (entry.team) {
                            return undefined
                        }
                        if (entry.state !== "finished") {
                            if (totalLaps == undefined || totalLaps === 0) {
                                return undefined
                            }
                            return totalLaps.toString()
                        }
                }
        }
        return undefined
    }

    function availableLaps(): number {
        const sessionLaps = props.entry.session.setup.laps
        return sessionLaps === 0 ? SharedConfig.DRIFT_MAX_LAPS : sessionLaps
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-laps-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-size={size()}
            data-theme={theme()}
            data-is-startorder={props.entry.isStartOrder}>
            <div className="table-leaderboard-laps-cell-primary">
                {CharUtils.replaceZeroWithO(primaryValue())}
            </div>
            {secondaryValue() && <div className="table-leaderboard-laps-cell-secondary">
                {CharUtils.replaceZeroWithO(secondaryValue())}
            </div>}
        </div>
    );

}
