import * as React from "react";

/******************************************************************
 * Divider
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Divider(props: {
    style:
        | "metabar"
        | "metabar-home"
        | "sidebar-menu"
        | "sidebar-menu-full"
        | "splitview-menu-full"
        | "date-seperator"
        | "modal"
        | "main-meta-header-level"
        | "main-meta-header-authuser"
        | "action-bar"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="divider" data-style={props.style}>
            {props.style == "main-meta-header-level" && <svg width="7" height="18" viewBox="0 0 7 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.35196" y1="0.887603" x2="5.85196" y2="8.68183" stroke="white" strokeLinecap="round"/>
                <line x1="1.34421" y1="16.4835" x2="5.84421" y2="8.68926" stroke="white" strokeLinecap="round"/>
            </svg>}
        </div>
    );

}
