import * as React from "react";
import {useEffect, useState} from "react";
import {LabelText} from "../../text/label/LabelText";
import {IconButton} from "../../buttons/icon/IconButton";
import {ITeamData} from "../../../../../shared/models/submodels/ITeamData";
import {IconType} from "../../icons/IconType";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {TeamInfo} from "../../info/team/TeamInfo";

/******************************************************************
 * TeamList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TeamList(props: {
    data: ITeamData[],
    emptyDataInfo: string,
    iconType: IconType,
    readonly?: boolean,
    onIconClick: (teamID: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    const [hasData, setHasData] = useState<boolean>();

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setHasData(props.data?.length > 0)
    }, [props.data])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="team-list">
            {!hasData && <LabelText label={props.emptyDataInfo}/>}
            {hasData && <ul>
                {props.data?.map((team, i) => {
                    return <li key={team?._id ?? i}>
                        <TeamInfo
                            team={team}/>
                        {team?._id && !props.readonly &&
                            <IconButton
                                type={props.iconType}
                                opacityMode="none"
                                disabled={props.readonly}
                                onClick={props.onIconClick ? () => props.onIconClick(team._id) : null}/>}
                    </li>
                })}
            </ul>}
        </div>
    );

}
