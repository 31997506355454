import * as React from "react";
import {useState} from "react";
import {InputDivider} from "../elements/InputDivider";
import {RegisterLegals} from "../elements/legal/RegisterLegals";
import {Input} from "../elements/input/Input";
import {Form} from "../Form";
import {useServices} from "../../../hooks/useServices";
import {ITabbedContentChildProps} from "../../layout/tabbed/ITabbedContentChildProps";
import {PasswordValidationIndicator} from "../validation/PasswordValidationIndicator";
import {ValidationIndicatorElement} from "../validation/ValidationIndicatorElement";
import {MailValidation} from "../../../../../shared/validation/MailValidation";
import {NickValidation} from "../../../../../shared/validation/NickValidation";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {InfoText} from "../../text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";

/******************************************************************
 * RegisterForm
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RegisterForm(props: {
    tabbedContent: ITabbedContentChildProps
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [password, setPassword] = useState<string>();
    const [mail, setMail] = useState<string>();
    const [nick, setNick] = useState<string>();


    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function login(formData: any) {
        state.showLogin.setValue(null);
        const loginResponse = await api.user.login(formData);
        if (loginResponse.status == 200) {
            await api.user.requestVerificationCode({email: state.authUser.getValue().email})
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Form
            action={(formData) => api.user.signup(formData)}
            actionLabel={dict("register.create.account.label")}
            onSuccess={formData => login(formData)}>
            {!FrontendConfig.PADDLE_ENABLED_PER_DEFAULT && <>
                <InfoText
                    text={dict("beta.disclaimer.text")}
                    style="warning"
                    size="small"/>
                <InputDivider/></>}
            <ContentLayout gap="small">
                <Input
                    type="text"
                    id="nick"
                    label={dict("register.nick.label")}
                    onChange={setNick}
                    required={true}/>
                <ValidationIndicatorElement
                    label={dict("nick.validation.valid.label")}
                    valid={NickValidation.REGEXP.test(nick ?? "")}/>
            </ContentLayout>
            <InputDivider/>
            <ContentLayout gap="small">
                <Input
                    type="email"
                    id="email"
                    label={dict("form.element.mail.label")}
                    onChange={setMail}
                    required={true}/>
                <ValidationIndicatorElement
                    label={dict("email.validation.valid.label")}
                    valid={MailValidation.REGEXP.test(mail ?? "")}/>
            </ContentLayout>
            <InputDivider/>
            <ContentLayout gap="small">
                <Input
                    type="password"
                    id="password"
                    label={dict("form.element.password.label")}
                    required={true}
                    onChange={setPassword}/>
                <PasswordValidationIndicator password={password ?? ""}/>
            </ContentLayout>
            <InputDivider/>
            <RegisterLegals/>
        </Form>
    );
}
