import * as React from "react";
import {TargetContextMenu} from "../../../context/target/TargetContextMenu";
import {ICapiTargetData} from "../../../../../../shared/types/ICapiTargetData";
import {IStintData} from "../../../../../../shared/models/IStintData";

/******************************************************************
 * TableStintTargetMenuCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableStintTargetMenuCell(props: {
    target: ICapiTargetData
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-stint-target-menu-cell">
            <TargetContextMenu
                target={props.target}
                stint={props.stint}/>
        </div>
    );

}
