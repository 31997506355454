import {ITrackFilterData} from "../../shared/models/submodels/ITrackFilterData";
import {TrackType} from "../../shared/types/TrackType";
import {DriverLicenseType} from "../../shared/types/DriverLicenseType";

/******************************************************************
 * TrackUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TrackUtils {

    static trackFilterName(
        trackFilter: ITrackFilterData,
        trackType: TrackType,
        dict: (key: string) => string
    ): string {
        if (!trackFilter) return ""
        return trackFilter.isPreset
            ? trackFilter.presetType == "default"
                ? trackType == "lap"
                    ? dict("track.filter.besttimes.name")
                    : dict("track.filter.bestscores.name")
                : dict(trackFilter.name)
            : trackFilter.name
    }

    static isHigherDriverLicenseRequired(
        authUserUseSubscription: boolean,
        authUserValidDriverLicense: DriverLicenseType,
        trackFilter: ITrackFilterData
    ): boolean {
        if (!authUserUseSubscription) return false
        if (trackFilter?.isPreset && trackFilter?.presetType == "default") return false
        switch (authUserValidDriverLicense) {
            case "free":
                if (trackFilter?.isPreset && trackFilter?.presetType == "tuning") {
                    return false
                }
                return true
            case "basic":
                return !trackFilter?.isPreset
            case "premium":
                return false
        }
    }

}
