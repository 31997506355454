import * as React from "react";
import {IconType} from "../../../icons/IconType";
import {IconButton} from "../../../buttons/icon/IconButton";
import {ContentAlignType} from "../../../../../utils/types/ContentAlignType";

/******************************************************************
 * TableIconButtonCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableIconButtonCell(props: {
    icon: IconType
    align: ContentAlignType
    action: () => Promise<Response>
}) {

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function onClicked() {
        await props.action()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-iconbutton-cell" data-align={props.align}>
            <IconButton
                type={props.icon}
                onClick={onClicked}/>
        </div>
    );

}
