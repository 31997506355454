import * as React from "react";
import {ISplitViewEntry} from "../ISplitViewEntry";

/******************************************************************
 * SplitViewContent
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SplitViewContent(props: ISplitViewEntry) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="splitview-content">
            {props.content}
        </div>
    );

}
