import * as React from "react";

/******************************************************************
 * VisibleIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function VisibleIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="visible-icon">
            <svg width={20 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99002 9.60864C11.4642 9.60864 12.6593 8.41355 12.6593 6.93933C12.6593 5.46511 11.4642 4.27002 9.99002 4.27002C8.5158 4.27002 7.32071 5.46511 7.32071 6.93933C7.32071 8.41355 8.5158 9.60864 9.99002 9.60864Z" fill="white"/>
                <path d="M19.7855 6.21318C18.6819 4.50649 17.2501 3.05672 15.6451 2.02028C13.8696 0.872476 11.9094 0.265625 9.97661 0.265625C8.20318 0.265625 6.45937 0.772377 4.79356 1.7717C3.09479 2.79063 1.55577 4.27918 0.219026 6.19566C0.0681201 6.41226 -0.0149897 6.66882 -0.0197426 6.93276C-0.0244955 7.19669 0.049323 7.45608 0.192333 7.67797C1.29384 9.40176 2.71149 10.8536 4.29139 11.8759C6.07024 13.0283 7.98463 13.6122 9.97661 13.6122C11.9248 13.6122 13.8892 13.0103 15.6572 11.8721C17.2613 10.839 18.6902 9.38382 19.7897 7.66295C19.9277 7.44622 20.0007 7.19443 20 6.93746C19.9993 6.68048 19.9248 6.42911 19.7855 6.21318ZM9.99079 10.9429C9.19888 10.9429 8.42475 10.708 7.7663 10.2681C7.10785 9.82812 6.59466 9.20278 6.29161 8.47115C5.98855 7.73952 5.90926 6.93446 6.06376 6.15777C6.21825 5.38107 6.59959 4.66763 7.15956 4.10767C7.71952 3.54771 8.43296 3.16636 9.20965 3.01187C9.98635 2.85738 10.7914 2.93667 11.523 3.23972C12.2547 3.54277 12.88 4.05597 13.32 4.71442C13.7599 5.37287 13.9948 6.14699 13.9948 6.9389C13.9935 8.00045 13.5713 9.01817 12.8207 9.76879C12.0701 10.5194 11.0523 10.9417 9.99079 10.9429Z" fill="white"/>
            </svg>
        </div>
    );

}
