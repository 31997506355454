import * as React from "react";
import {LoginForm} from "../../../ui/form/forms/LoginForm";
import {RegisterForm} from "../../../ui/form/forms/RegisterForm";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * LoginModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LoginModal() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showLogin.setValue(null);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal closeAction={close} width="small">
            <TabbedContent
                showContentByID={state.showLogin.isValue("signup") ? 1 : 0}>
                <LoginForm
                    tabbedContent={{name: dict("modal.tab.login")}}/>
                <RegisterForm
                    tabbedContent={{name: dict("modal.tab.signup")}}/>
            </TabbedContent>
        </Modal>
    );

}
