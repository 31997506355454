import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {ReactElement} from "react";
import {Icon} from "../../../icons/Icon";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * TableLeaderboardScoreCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardScoreCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue(): string {
        return score()?.toString() ?? "–"
    }

    function secondaryValue(): string {
        const entry: ISessionLeaderboardEntry = props.entry
        switch (entry.state) {
            case "ready":
            case "invalid":
            case "driving":
                if (entry.latestStint.falseStart) {
                    return dict("driver.falseStart")
                }
                return entry.bestGymkhanaStint?.score?.toString() ?? undefined
            case "finished":
                if (entry.bestGymkhanaStint?.falseStart) {
                    return dict("driver.falseStart")
                }
                const scoreGap = scoreGapToLeader()
                if (scoreGap > 0) {
                    return `+${scoreGap}`
                }
                break
        }
        return undefined
    }

    function scoreGapToLeader(): number {
        const leaderScore = score(props.entry.entries[0])
        const thisScore = score(props.entry)
        return leaderScore - thisScore
    }

    function isFalseStart(): boolean {
        const entry: ISessionLeaderboardEntry = props.entry
        switch (entry.state) {
            case "ready":
            case "invalid":
            case "driving":
                return entry.latestStint?.falseStart
            case "finished":
                return entry.bestGymkhanaStint?.falseStart
        }
    }

    function score(otherEntry?: ISessionLeaderboardEntry): number {
        const session: ISessionData = props.entry.session
        const entry = otherEntry ?? props.entry
        if (entry.state !== "finished") {
            return entry.latestStint.score ?? undefined;
        }
        switch (session.setup.classificationMode) {
            case "teams":
                switch (entry.type) {
                    case "primary":
                        switch (session.setup.teamScoringMode) {
                            case "best":
                                return entry.bestGymkhanaStint?.score ?? undefined
                            case "average":
                                return entry.overall?.avgTeamScore !== undefined ? Math.round(entry.overall?.avgTeamScore) : undefined
                            case "sum":
                                return entry.overall?.sumTeamScore ?? undefined
                        }
                        break
                    case "secondary-team":
                        return entry.bestGymkhanaStint?.score ?? undefined
                }
                break
            case "drivers":
            case "solo":
                return entry.bestGymkhanaStint?.score ?? undefined
        }
    }

    function primaryPrefixIcon(): ReactElement{
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (entry.state !== "finished") {
            return null
        }
        switch (session.setup.classificationMode) {
            case "teams":
                switch (entry.type) {
                    case "primary":
                        switch (session.setup.teamScoringMode) {
                            case "best":
                                return <Icon type="star" scale={0.5}/>
                            case "average":
                                return <Icon type="avg" scale={0.5}/>
                            case "sum":
                                return <Icon type="sum" scale={0.5}/>
                        }
                        break
                    case "secondary-team":
                        return null
                }
        }
        return null
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-score-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-falsestart={isFalseStart()}
            data-theme={theme()}>
            <div className="table-leaderboard-score-cell-primary">
                {primaryPrefixIcon()}{CharUtils.replaceZeroWithO(primaryValue())}
            </div>
            {secondaryValue() && <div className="table-leaderboard-score-cell-secondary">
                {CharUtils.replaceZeroWithO(secondaryValue())}
            </div>}
        </div>
    );

}
