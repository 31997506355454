import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IArticleTestimonialBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {SelectUserModal} from "../../../../systems/modals/user/SelectUserModal";
import {LabelButton} from "../../../buttons/label/LabelButton";
import {useServices} from "../../../../hooks/useServices";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {UserInfo} from "../../../info/user/UserInfo";
import {Input} from "../../../form/elements/input/Input";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {Spacer} from "../../../utils/spacer/Spacer";
import {Picture} from "../../../image/Picture";
import ReactMarkdown from "react-markdown";
import {useElementSize} from "../../../../hooks/useElementSize";
import {StringUtils} from "../../../../../../shared/utils/StringUtils";

/******************************************************************
 * ArticleTestimonialBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleTestimonialBlock(props: {
    data: IArticleTestimonialBlockData
    editing: boolean
    language: LanguageType
    onChange: (data: IArticleTestimonialBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserHasPermission} = useAuthUser()
    const [isMobileStyle] = useMobileStyle(rootRef, 500)
    const [rootWidth] = useElementSize(rootRef)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showSelectUserModal, setShowSelectUserModal] = useState<boolean>(false)
    const [blockTypeIndex, setBlockTypeIndex] = useState<number>(0)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateBlockTypeIndex()
    }, [rootRef.current])

    useEffect(() => {
        if (rootWidth && rootRef.current) {
            rootRef.current.style.setProperty("--root-width", `${rootWidth}px`)
        }
    }, [rootWidth, rootRef.current])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateBlockTypeIndex() {
        if (!rootRef.current) return
        const articleMainElement = rootRef.current.parentElement?.parentElement?.parentElement
        if (!articleMainElement) return
        const testimonialBlocks = articleMainElement.querySelectorAll(".article-testimonial-block")
        const index = Array.from(testimonialBlocks).indexOf(rootRef.current)
        setBlockTypeIndex(index)
    }

    async function updateUser(user: IUserData): Promise<Response> {
        setShowSelectUserModal(false)
        if (!user || !user._id) {
            return null
        }
        props.data.user = user._id
        props.onChange(props.data)
        return null
    }

    async function updateMessage(message: string, translate?: boolean): Promise<Response> {
        if (translate) {
            message = await api.translate.text(message, null, props.language)
        }
        props.data.message = message
        props.onChange(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={rootRef}
            className="article-testimonial-block"
            gap="small">
            {props.editing
                ? <ContentLayout
                    gap="small"
                    columnTemplate={isMobileStyle ? null : "1fr 3fr"}>
                    <ContentLayout
                        justifyItems="center"
                        gap="small">
                        <Spacer height="small"/>
                        {props.data?.user &&
                            <UserInfo
                                user={props.data.user}
                                style="avatar"/>}
                        <LabelButton
                            label={dict("users.select.button.label")}
                            style="primary-small"
                            onClick={() => setShowSelectUserModal(true)}/>
                    </ContentLayout>
                    <Input
                        type="textarea"
                        size="small"
                        label={dict("article.testimonial.message")}
                        defaultValue={props.data.message}
                        customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                        onCustomLinkClick={() => updateMessage(props.data.message, true)}
                        action={updateMessage}/>
                </ContentLayout>
                : <div
                    className="article-testimonial-block-viewer"
                    data-position-index={blockTypeIndex % 6}
                    data-use-mobile-style={isMobileStyle ? "true" : "false"}>
                    <Picture
                        className="article-testimonial-block-viewer-avatar"
                        file={props.data?.user?.avatar}
                        alt={props.data?.user?.nick}
                        framing="circle"/>
                    <div>
                        <h3 className="article-testimonial-block-viewer-nick">
                            {props.data?.user?.nick}
                        </h3>
                        <ReactMarkdown className="article-testimonial-block-viewer-message">
                            {StringUtils.preprocessMarkdown(props.data?.message)}
                        </ReactMarkdown>
                    </div>
                </div>}
        </ContentLayout>
        {showSelectUserModal &&
            <SelectUserModal
                onRequestClose={() => setShowSelectUserModal(false)}
                onUserSelect={updateUser}/>}
    </>

}
