import {useEffect, useState} from "react";
import {useServices} from "./useServices";

/******************************************************************
 * useMenuState
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useMenuState() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(state.showMobileMenu.getValue());
    const [swipingMobileMenuDeltaX, setSwipingMobileMenuDeltaX] = useState<number>(state.swipingMobileMenuDeltaX.getValue());
    const [isSwipingMenu, setIsSwipingMenu] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        state.showMobileMenu.onChangeSignal.add(updateShowMobileMenu)
        state.swipingMobileMenuDeltaX.onChangeSignal.add(updateSwipingMobileMenuDeltaX)
        return () => {
            state.showMobileMenu.onChangeSignal.remove(updateShowMobileMenu)
            state.swipingMobileMenuDeltaX.onChangeSignal.remove(updateSwipingMobileMenuDeltaX)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateShowMobileMenu() {
        setShowMobileMenu(state.showMobileMenu.getValue())
    }

    function updateSwipingMobileMenuDeltaX() {
        setIsSwipingMenu(state.swipingMobileMenuDeltaX.getValue() !== 0
            && state.swipingMobileMenuDeltaX.getValue() !== undefined
            && state.swipingMobileMenuDeltaX.getValue() !== null)
        setSwipingMobileMenuDeltaX(state.swipingMobileMenuDeltaX.getValue())

    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        showMobileMenu,
        swipingMobileMenuDeltaX,
        isSwipingMenu
    }

}
