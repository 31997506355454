import {useEffect, useState} from "react";
import {useServices} from "./useServices";
import {IMailData} from "../../../shared/models/IMailData";

/******************************************************************
 * useSocketSignals
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useSocketSignals() {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {signal} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [serverAdminUpdate, setServerAdminUpdate] = useState<number>(0);
    const [serverAdminMailerUpdate, setServerAdminMailerUpdate] = useState<IMailData>(null);
    const [serverEventUpdate, setServerEventUpdate] = useState<number>(0);
    const [serverTrackUpdate, setServerTrackUpdate] = useState<number>(0);
    const [serverGroupUpdate, setServerGroupUpdate] = useState<number>(0);
    const [serverGarageUpdate, setServerGarageUpdate] = useState<number>(0);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        signal.onServerAdminUpdate.add(onServerAdminUpdate);
        signal.onServerAdminMailerUpdate.add(onServerAdminMailerUpdate);
        signal.onServerEventUpdate.add(onServerEventUpdate);
        signal.onServerTrackUpdate.add(onServerTrackUpdate);
        signal.onServerGroupUpdate.add(onServerGroupUpdate);
        signal.onServerGarageUpdate.add(onServerGarageUpdate);
        return () => {
            signal.onServerAdminUpdate.remove(onServerAdminUpdate);
            signal.onServerAdminMailerUpdate.remove(onServerAdminMailerUpdate);
            signal.onServerEventUpdate.remove(onServerEventUpdate)
            signal.onServerEventUpdate.remove(onServerTrackUpdate)
            signal.onServerGroupUpdate.remove(onServerGroupUpdate);
            signal.onServerGarageUpdate.remove(onServerGarageUpdate);
        }
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function onServerAdminUpdate() {
        setServerAdminUpdate(prevState => prevState + 1)
    }

    function onServerAdminMailerUpdate(mailData: IMailData) {
        setServerAdminMailerUpdate(mailData)
    }

    function onServerEventUpdate() {
        setServerEventUpdate(prevState => prevState + 1)
    }

    function onServerTrackUpdate() {
        setServerTrackUpdate(prevState => prevState + 1)
    }

    function onServerGroupUpdate() {
        setServerGroupUpdate(prevState => prevState + 1)
    }

    function onServerGarageUpdate() {
        setServerGarageUpdate(prevState => prevState + 1)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return {
        serverAdminUpdate,
        serverAdminMailerUpdate,
        serverEventUpdate,
        serverTrackUpdate,
        serverGroupUpdate,
        serverGarageUpdate
    }

}
