import * as React from "react";
import {useState} from "react";
import {ITableColumnDefinition} from "../../ITableColumnDefinition";
import {SortDirectionType} from "../../../../../utils/types/SortDirectionType";
import {Icon} from "../../../icons/Icon";
import {IconType} from "../../../icons/IconType";

/******************************************************************
 * TableHeaderCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableHeaderCell(props: {
    columnDefinition: ITableColumnDefinition<any>
    activeSortKey?: string
    activeSortDirection?: SortDirectionType
    onClick?: (sortKey: string, sortDirection: SortDirectionType) => void
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [sortDirection, setSortDirection] = useState<SortDirectionType>(
        props.activeSortKey === props.columnDefinition.sortKey
            ? props.activeSortDirection
            : "asc"
    );

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function sortable(): boolean {
        return !!props.onClick && !!props.columnDefinition.sortKey;
    }

    function onClick() {
        if (!sortable()) {
            return;
        }
        let sortDir: SortDirectionType | undefined = undefined;
        if (isActiveSort()) {
            sortDir = sortDirection === "asc" ? "desc" : "asc";
            setSortDirection(sortDir);
        }
        props.onClick(props.columnDefinition.sortKey, sortDir ?? sortDirection)
    }

    function sortIcon(): IconType {
        if (isActiveSort()) {
            return sortDirection === "asc" ? "up" : "down";
        }
        return "sortable";
    }

    function isActiveSort() {
        if (!props.activeSortKey || !props.columnDefinition.sortKey) {
            return false;
        }
        return props.activeSortKey === props.columnDefinition.sortKey;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-header-cell"
            data-type={props.columnDefinition.type}
            data-align={props.columnDefinition.align}
            data-key={props.columnDefinition.dataKey}
            data-sortable={sortable()}
            data-is-active-sort={isActiveSort()}
            onClick={onClick}>
            <div className="table-header-cell-label">
                {props.columnDefinition.columnName}
            </div>
            {sortable() && <div className="table-header-cell-sortdirection">
                <Icon
                    type={sortIcon()}
                    scale={0.7}/>
            </div>}
        </div>
    );

}
