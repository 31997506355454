import * as React from "react";
import {CSSProperties, ReactElement, RefObject, SyntheticEvent, useEffect, useRef, useState} from "react";
import {IconButton} from "../../ui/buttons/icon/IconButton";
import {useWindowSize} from "../../hooks/useWindowSize";
import {ResponseStyle} from "../../../utils/types/ResponseStyle";
import {useSwipeable} from "react-swipeable";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {DOMUtils} from "@webfruits/toolbox/dist/utils/DOMUtils";

/******************************************************************
 * Modal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type ModalWidthType = "small" | "medium" | "large" | "extra-large" | "super-large" | "max"
export type ModalType = "normal" | "error" | "data" | "viewer" | "article"

export function Modal(props: {
    width: ModalWidthType
    children?: ReactElement | ReactElement[]
    footerChildren?: ReactElement | ReactElement[]
    height?: "auto" | "small" | "medium" | "medium-large" | "large" | "max" | number
    minHeight?: "small" | "medium"
    type?: ModalType
    position?: "main" | "global"
    padding?: "default" | "none"
    responseStyle?: ResponseStyle
    className?: string
    initialized?: boolean
    closeAction?: () => void
    allowHorizontalScroll?: boolean
    preventSwipeToClose?: boolean
    onFrameRef?: (ref: RefObject<HTMLDivElement>) => void
    onDialogRef?: (ref: RefObject<HTMLDialogElement>) => void
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const dialogRef = useRef<HTMLDialogElement>()
    const frameRef = useRef<HTMLDivElement>()
    const preventSwipeToClose = useRef<boolean>(false)
    const scrollCheckTimeoutID = useRef<number>()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {windowWidth} = useWindowSize()
    const swipeToCloseHandlers = useSwipeable({
        onSwipedDown: onSwipeToClose,
        preventScrollOnSwipe: false,
        trackTouch: true,
        trackMouse: false,
        delta: FrontendConfig.SWIPE_DELTA,
        swipeDuration: FrontendConfig.SWIPE_DURATION
    });

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isHoverCloseButton, setIsHoverCloseButton] = useState<boolean>()
    const [modalFrameStyle, setModalFrameStyle] = useState<CSSProperties>({})
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [closing, setClosing] = useState<boolean>(false)
    const [isMobile, setIsMobile] = useState<boolean>(computeMobileMode())

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!dialogRef.current.open) {
            dialogRef.current.close();
            dialogRef.current.showModal();
        }
        document.addEventListener("click", onClickAnywhere)
        document.addEventListener("keydown", onKeyDown);
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("click", onClickAnywhere);
            document.removeEventListener("keydown", onKeyDown);
        };
    }, [])

    useEffect(() => {
        setIsInitialized(props.initialized == undefined ? true : props.initialized)
    }, [props.initialized, isMobile])

    useEffect(() => {
        updateModalFrameStyle()
        setIsMobile(computeMobileMode())
    }, [windowWidth, props.height])

    useEffect(() => {
        props.onFrameRef?.(frameRef)
    }, [frameRef.current])

    useEffect(() => {
        props.onDialogRef?.(dialogRef)
    }, [dialogRef.current])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function close() {
        if (closing) return;
        setClosing(true)
        await PromisedDelay.wait(0.2)
        props.closeAction?.();
    }

    function onKeyDown(event: KeyboardEvent) {
        if (!!props.closeAction) return;
        if (event.key != "Escape") return;
        event.preventDefault();
    }

    function computeMobileMode(): boolean {
        return windowWidth < 800
    }

    function onClickAnywhere(e: MouseEvent) {
        if (DOMUtils.isTextInputElementActive()) return;
        if (!props.closeAction) return;
        if (e.target != dialogRef.current) return;
        close();
    }

    function updateModalFrameStyle() {
        const style = {} as CSSProperties
        if (typeof props.height == "number" && frameRef.current) {
            const frameStyle = window.getComputedStyle(frameRef.current)
            const paddingTop = parseInt(frameStyle.paddingTop)
            const paddingBottom = parseInt(frameStyle.paddingBottom)
            style.maxHeight = props.height + paddingTop + paddingBottom + "px"
        }
        setModalFrameStyle(style)
    }

    function onSwipeToClose() {
        if (props.preventSwipeToClose) return;
        if (preventSwipeToClose.current || !isMobile) return;
        const openDialogs = document.querySelectorAll('dialog[open]');
        const lastDialog = openDialogs[openDialogs.length - 1];
        if (lastDialog != dialogRef.current) return;
        close()
    }

    function onScrolled(e: SyntheticEvent) {
        clearTimeout(scrollCheckTimeoutID.current)
        if (e.currentTarget.scrollTop <= 0) {
            preventSwipeToClose.current = false
            return
        }
        preventSwipeToClose.current = true
        scrollCheckTimeoutID.current = window.setTimeout(() => {
            preventSwipeToClose.current = false
        }, FrontendConfig.SWIPE_DURATION * 2)
    }

    function computeStyle(): CSSProperties {
        const style = {} as CSSProperties
        if (typeof props.height == "number") {
            style.height = props.height + "px"
        }
        return style
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <dialog
            className={"modal" + (props.className ? " " + props.className : "")}
            ref={dialogRef}
            onClose={close}
            style={computeStyle()}
            data-padding={props.padding ?? "default"}
            data-initialized={isInitialized}
            data-closing={closing}
            data-mobile-mode={isMobile}
            data-type={props.type ?? "normal"}
            data-response-style={props.responseStyle ?? "desktop"}
            data-width={props.width}
            data-height={props.height ?? "auto"}
            data-min-height={props.minHeight}
            data-hovered-close-button={isHoverCloseButton}
            data-position={props.position ?? "global"}>
            {!!props.closeAction &&
                <div className="close-button-fixed-wrapper">
                    <IconButton
                        type="close"
                        scale={0.8}
                        stopPropagation={true}
                        onClick={close}
                        onHoverChange={(isHover) => setIsHoverCloseButton(isHover)}
                    />
                </div>}
            <div
                className="modal-frame"
                ref={frameRef}
                data-allow-horizontal-scroll={props.allowHorizontalScroll}
                style={modalFrameStyle}
                onScroll={onScrolled}
                {...swipeToCloseHandlers}>
                {props.children}
            </div>
            {props.footerChildren && <div className="modal-footer">
                <div className="modal-footer-fixed-frame">
                    {props.footerChildren}
                </div>
            </div>}
        </dialog>
    </>

}
