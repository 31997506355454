import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {IUserData} from "../../../../../shared/models/IUserData";
import {ConvertDataUtils} from "../../../../../shared/utils/ConvertDataUtils";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {IconType} from "../../icons/IconType";

/******************************************************************
 * UserContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserContextMenu(props: {
    user: IUserData
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function deleteUser() {
        state.showConfirm.setValue({
            type: "deleteUser",
            payload: {
                userID: props.user._id,
                userNick: props.user.nick
            }
        })
    }

    function showDetails() {
        state.showMessage.setValue({
            type: "data",
            message: ConvertDataUtils.convertObjectToMarkdownJSON(props.user)
        })
    }

    async function clearUserCapiQueue() {
        await api.admin.clearUserCapiQueue(props.user._id)
    }

    async function resetCheckoutState() {
        await api.user.update({
            _id: props.user._id,
            checkoutState: "none"
        })
    }

    function showPermissions() {
        state.showUserPermissions.setValue(props.user)
    }

    function showRoles() {
        state.showUserRoles.setValue(props.user)
    }

    async function logoutFromAllDevices() {
        await api.user.logout(true, props.user._id)
    }

    async function refreshSubscription() {
        await api.user.refreshUserSubscription(props.user._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconScale={0.9}
            iconSize="small"
            stopPropagation={true}>
            <ContextMenuLabel
                label={dict("user.details.label")}
                icon="info"
                onClick={showDetails}/>
            {props.user.role != "admin" &&
                <ContextMenuLabel
                    label={dict("user.roles.label")}
                    icon="person"
                    onClick={showRoles}/>}
            <ContextMenuLabel
                label={dict("user.permissions.label")}
                icon="blueprint"
                onClick={showPermissions}/>
            <ContextMenuDivider style="normal"/>
            <ContextMenuLabel
                label={dict("user.refresh.subscription.label")}
                icon={DriverLicenseUtils.getLicenseIconName(DriverLicenseUtils.validLicenseType(props.user.subscription)) as IconType}
                onClick={refreshSubscription}/>
            <ContextMenuLabel
                label={dict("user.clear.capi.queue.label")}
                icon="warning"
                onClick={clearUserCapiQueue}/>
            <ContextMenuLabel
                label={dict("user.reset.checkoutState.label")}
                icon="warning"
                onClick={resetCheckoutState}/>
            <ContextMenuDivider style="normal"/>
            <ContextMenuLabel
                label={dict("user.logoutFromAllDevices.label")}
                icon="logout"
                warning={true}
                onClick={logoutFromAllDevices}/>
            <ContextMenuDivider style="normal"/>
            <ContextMenuLabel
                label={dict("user.delete.label")}
                icon="delete"
                warning={true}
                onClick={deleteUser}/>
        </ContextMenu>
    );

}
