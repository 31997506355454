import * as React from "react";
import {IconType} from "../../icons/IconType";
import {IconButton} from "../../buttons/icon/IconButton";
import {IconColorType} from "../../icons/Icon";
import {IKeyShortcut} from "../../../../utils/interfaces/IKeyShortcut";
import {KeyShortcutInfo} from "../../keyboard/KeyShortcutInfo";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {DriverLicenseBadge} from "../../info/license/DriverLicenseBadge";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * ContextMenuLabel
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ContextMenuLabel(props: {
    label: string
    icon?: IconType
    iconColor?: IconColorType
    iconScale?: number
    stateIconType?: IconType,
    stateIconColor?: IconColorType,
    stateIconScale?: number,
    warning?: boolean
    stopPropagation?: boolean
    keyShortcut?: IKeyShortcut
    onClick?: () => void
    requiredDriverLicense?: DriverLicenseType
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserValidDriverLicense, authUserRole} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(e: React.MouseEvent) {
        if (props.stopPropagation) {
            e.stopPropagation();
        }
        props.onClick?.();
    }

    function needHigherLicenseType() {
        return DriverLicenseUtils.needHigherLicenseType(
            authUserValidDriverLicense,
            props.requiredDriverLicense,
            authUserRole)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="context-menu-label"
            data-clickable={!!props.onClick}
            data-warning={props.warning}
            data-has-keyshortcut={!!props.keyShortcut}
            onClick={onClick}>
            {needHigherLicenseType() &&
                <DriverLicenseBadge licenseType={props.requiredDriverLicense}/>}
            {props.icon && !needHigherLicenseType() &&
                <IconButton
                    type={props.icon}
                    iconColor={props.iconColor}
                    stateIconType={props.stateIconType}
                    stateIconColor={props.stateIconColor}
                    stateIconScale={props.stateIconScale}
                    opacityMode="none"
                    scale={props.iconScale}
                    warning={props.warning}/>}
            <div className="context-menu-label-text">
                {props.label}
                {props.keyShortcut &&
                    <KeyShortcutInfo
                        keyShortcut={props.keyShortcut}/>}
            </div>
        </div>
    );
}
