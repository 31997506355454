import * as React from "react";

/******************************************************************
 * LinkIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LinkIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="link-icon">
            <svg width={16 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9628 11.0143L13.4465 9.53062C14.1132 8.86392 14.4877 7.95968 14.4877 7.01683C14.4877 6.07398 14.1132 5.16974 13.4465 4.50304C12.7798 3.83635 11.8756 3.4618 10.9327 3.4618C9.98986 3.4618 9.08562 3.83635 8.41892 4.50304L6.968 5.95397" stroke="white" strokeWidth="1.77752" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.00435 8.91774L2.55342 10.3687C1.88672 11.0354 1.51218 11.9396 1.51218 12.8825C1.51218 13.8253 1.88672 14.7295 2.55342 15.3962C3.22012 16.0629 4.12435 16.4375 5.06721 16.4375C6.01006 16.4375 6.9143 16.0629 7.58099 15.3962L9.06465 13.9126" stroke="white" strokeWidth="1.77752" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.2763 7.6731L5.67133 12.278" stroke="white" strokeWidth="1.77752" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );

}
