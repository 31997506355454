import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useEventState} from "../../../../../hooks/useEventState";
import {TrackInfo} from "../../../../../ui/info/track/TrackInfo";

/******************************************************************
 * EventTracksInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventTracksInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {eventData, eventTracks} = useEventState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-tracks-info">
            {eventTracks?.map((trackData) => {
                return <TrackInfo
                    key={trackData._id}
                    eventData={eventData}
                    trackData={trackData} />
            })}
        </ContentLayout>
    );

}
