import * as React from "react";
import {useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useServices} from "../../../../../hooks/useServices";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {MailValidation} from "../../../../../../../shared/validation/MailValidation";

/******************************************************************
 * SendTestmail
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SendTestmail(props: {
    mailID: string
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserNick, authUserMail} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [sending, setSending] = useState<boolean>(false)
    const [overriddenMail, setOverriddenMail] = useState<string>(null)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function sendTestMail() {
        setSending(true)
        await api.mail.sendTestMail(props.mailID, overriddenMail)
        setSending(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            framed={true}
            gap="small"
            className="send-testmail">
            <Headline
                text={dict("mail.send.testmail.title").replaceAll("{NICK}", authUserNick)}
                style="h5-underlined"/>
            <ContentLayout
                columnTemplate="1fr min-content"
                gap="small"
                alignItems="center">
                <Input
                    type="text"
                    size="small"
                    defaultValue={authUserMail}
                    onChange={setOverriddenMail}/>
                <LabelButton
                    label={dict("mail.send.testmail.send.button")}
                    style="primary-small"
                    progressing={sending}
                    disabled={overriddenMail ? !MailValidation.isValid(overriddenMail) : false}
                    onClick={sendTestMail}/>
            </ContentLayout>
        </ContentLayout>
    );

}
