import * as React from "react";
import {CheckIcon} from "../../../../icons/CheckIcon";

/******************************************************************
 * MultiSelectOption
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type MultiSelectOptionType<T = string> = {
    text: string,
    value: T | "any"
}

export type MultiSelectPresetOptionType = {
    label: string,
    optionValues: string[]
}

export function MultiSelectOption(props: {
    data: MultiSelectOptionType
    selected: boolean
    onChange: (value) => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="multiselect-option"
            data-value={props.data.value}
            data-selected={props.selected}
            onClick={() => props.onChange(props.data.value)}>
            <div className="multiselect-option-icon">
                <CheckIcon scale={0.7}/>
            </div>
            {props.data.text}
        </div>
    );
}
