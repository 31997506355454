import * as React from "react";
import {IArticleTextBlockData} from "../../../../../../shared/models/IArticleData";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {Input} from "../../../form/elements/input/Input";
import {InfoText} from "../../../text/infotext/InfoText";
import {useServices} from "../../../../hooks/useServices";
import {LanguageType} from "../../../../../../shared/types/LanguageType";
import {useAuthUser} from "../../../../hooks/useAuthUser";

/******************************************************************
 * ArticleMarkdownBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleMarkdownBlock(props: {
    data: IArticleTextBlockData
    language: LanguageType
    editing: boolean
    onChange: (data: IArticleTextBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateText(text: string, translate?: boolean): Promise<Response> {
        if (translate) {
            text = await api.translate.text(text, null, props.language)
        }
        props.data.text = text
        props.onChange(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-text-block"
            gap="small"
            useFrameMargin={!props.editing}
            justifyItems={props.editing ? "stretch" : "center"}>
            {props.editing
                ? <Input
                    type="textarea"
                    size="small"
                    defaultValue={props.data.text}
                    required={true}
                    customLinkLabel={authUserHasPermission("api:translate") ? dict("input.translate.label") : null}
                    onCustomLinkClick={() => updateText(props.data.text, true)}
                    action={updateText}/>
                : <InfoText text={props.data.text} style="longText"/>
            }
        </ContentLayout>
    )
}
