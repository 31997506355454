import * as React from "react";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {useServices} from "../../../hooks/useServices";
import {Divider} from "../../../ui/utils/divider/Divider";
import {UserInfo} from "../../../ui/info/user/UserInfo";
import {ImageButton} from "../../../ui/buttons/image/ImageButton";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";

/******************************************************************
 * AuthUserMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AuthUserMenu() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {router, state, dict} = useServices();
    const {
        authUserData,
        authUserFavorites
    } = useAuthUser();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showGroup(groupPath: string) {
        router.showRoute(FrontendRoute.GROUP(groupPath))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <nav className="auth-user-menu">
            <UserInfo
                user={authUserData}
                clickToShowProfile={true}
                style="metabar"/>
            <Divider style="metabar"/>
            <div className="auth-user-menu-favorites">
                {authUserFavorites?.map(favorite => {
                    if (favorite.group) {
                        return <ImageButton
                            key={favorite.group._id}
                            file={favorite.group.avatar}
                            tooltip={favorite.group.name}
                            alt={favorite.group.name}
                            size="small"
                            onClick={() => showGroup(favorite.group.path)}/>
                    }
                    return null
                })}
                <IconButton
                    type="star"
                    tooltip={dict("favorites.modal.title")}
                    scale={1}
                    onClick={() => state.showFavorites.setValue(true)}/>
            </div>
        </nav>
    );

}
