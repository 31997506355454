import * as React from "react";
import {AdminSidebar} from "./admin/AdminSidebar";
import {UserSidebar} from "./user/UserSidebar";
import {EventSidebar} from "./event/EventSidebar";
import {useRouteStates} from "../../hooks/useRouteStates";
import {TrackSidebar} from "./track/TrackSidebar";
import {GroupSidebar} from "./group/GroupSidebar";
import {ScrollBox} from "../../ui/utils/scrollbox/ScrollBox";

/******************************************************************
 * SideBarSystem
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SideBarSystem() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {
        showAdmin,
        showSideBar,
        showUser,
        showGroup,
        showEvent,
        showTrack
    } = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {showSideBar &&
            <ScrollBox scrollY={true} className="sidebar-system">
                {showUser && <UserSidebar/>}
                {showGroup && <GroupSidebar/>}
                {showEvent && <EventSidebar/>}
                {showTrack && <TrackSidebar/>}
                {showAdmin && <AdminSidebar/>}
            </ScrollBox>}
    </>

}
