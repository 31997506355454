import * as React from "react";
import {IMailBodyMarkdownBlockData} from "../../../../../../../shared/models/IMailData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";

/******************************************************************
 * MailMarkdownBodyBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailMarkdownBodyBlock(props: {
    data: IMailBodyMarkdownBlockData
    onChange: (data: IMailBodyMarkdownBlockData) => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateText(text: string): Promise<Response> {
        props.data.markdown = text
        props.onChange(props.data)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="mail-text-block"
            gap="small"
            justifyItems={"stretch"}>
            <Input
                type="textarea"
                size="small"
                defaultValue={props.data.markdown}
                required={true}
                action={updateText}/>
        </ContentLayout>
    )
}
