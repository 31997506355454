import * as React from "react"
import {useCallback, useEffect, useRef} from "react"

/******************************************************************
 * useDelayedInterval
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

type DelayedInterval = {
    clear: () => void
}

function delayedInterval(args: {
    callback: () => void,
    intervalInMilliseconds: number,
    delayInMiliseconds: number}
): DelayedInterval {
    let intervalID: number
    const timeoutID = window.setTimeout(() => {
        args.callback()
        if (!args.intervalInMilliseconds) return
        intervalID = window.setInterval(args.callback, args.intervalInMilliseconds)
    }, args.delayInMiliseconds ?? 0)
    return {
        clear: () => {
            clearTimeout(timeoutID)
            if (intervalID) {
                clearInterval(intervalID)
            }
        },
    }
}

export function useDelayedInterval() {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const delayedIntervalsRef = useRef<Map<string, DelayedInterval>>(new Map());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        return () => removeAllIntervals()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    const addDelayedInterval = useCallback((args: {
        key: string,
        callback: () => void,
        intervalInSeconds?: number,
        delayInSeconds?: number
    }): void => {
        if (delayedIntervalsRef.current.has(args.key)) {
            delayedIntervalsRef.current.get(args.key)?.clear()
        }
        delayedIntervalsRef.current.set(args.key, delayedInterval({
            callback: args.callback,
            intervalInMilliseconds: args.intervalInSeconds ? args.intervalInSeconds * 1000 : undefined,
            delayInMiliseconds: args.delayInSeconds ? args.delayInSeconds * 1000 : undefined
        }))
    }, [])

    const removeDelayedInterval = useCallback((key: string): void => {
        if (delayedIntervalsRef.current.has(key)) {
            delayedIntervalsRef.current.get(key)?.clear()
            delayedIntervalsRef.current.delete(key)
        }
    }, [])

    const removeAllIntervals = useCallback((): void => {
        delayedIntervalsRef.current.forEach((interval) => interval.clear())
        delayedIntervalsRef.current.clear()
    }, [])

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        addDelayedInterval,
        removeDelayedInterval,
        removeAllIntervals
    }

}
