import * as React from "react";
import {PlanHeadline} from "../../../../../ui/text/headings/PlanHeadline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {DriverLicenseType} from "../../../../../../../shared/types/DriverLicenseType";
import {useServices} from "../../../../../hooks/useServices";
import {CheckoutEventsItem} from "@paddle/paddle-js/types/checkout/events";
import {PaymentCurrencyType} from "../../../../../../../shared/types/PaymentCurrencyType";
import {PaymentInfo} from "../../../../../ui/info/payment/PaymentInfo";
import {CheckoutEventsData} from "@paddle/paddle-js";
import {useState} from "react";
import {Divider} from "../../../../../ui/utils/divider/Divider";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";

/******************************************************************
 * SubscriptionCheckoutSummary
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SubscriptionCheckoutSummary(props: {
    type: DriverLicenseType
    checkoutData: CheckoutEventsData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [item] = useState<CheckoutEventsItem>(props.checkoutData.items[0])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function taxRate(isRecurring: boolean) {
        if (isRecurring) {
            return Math.round(props.checkoutData.recurring_totals.tax / props.checkoutData.recurring_totals.subtotal * 100) / 100
        }
        return Math.round(props.checkoutData.totals.tax / props.checkoutData.totals.subtotal * 100) / 100
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="subscription-checkout-item">
            <PlanHeadline
                title={dict("plan.type." + props.type + ".title")}
                size="large"/>
            <Divider style={"modal"}/>
            <PaymentInfo data={{
                type: "first",
                currency: props.checkoutData.currency_code as PaymentCurrencyType,
                subTotal: props.checkoutData.totals.subtotal,
                taxRate: taxRate(false),
                credit: props.checkoutData.totals.credit,
                total: props.checkoutData.totals.total,
                balance: props.checkoutData.totals.balance,
                billingAt: new Date().toISOString()
            }}/>
            <Divider style={"modal"}/>
            <PaymentInfo data={{
                type: "recurring",
                currency: props.checkoutData.currency_code as PaymentCurrencyType,
                subTotal: props.checkoutData.recurring_totals.subtotal,
                taxRate: taxRate(true),
                total: props.checkoutData.recurring_totals.total,
                billingInterval: item.billing_cycle.interval,
                billingFrequency: item.billing_cycle.frequency
            }}/>
            <Divider style={"modal"}/>
            <InfoText
                text={dict("plan.checkout.payment.info")}
                size="small"/>
        </ContentLayout>
    );

}
