import {FrontendServices} from "../FrontendServices"

/******************************************************************
 * FrontendTime
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendTime {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _timeOffset: number;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this.updateTimeFromServer()
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async updateTimeFromServer() {
        const clientSendTime = Date.now();
        const serverTime = await this._frontend.api.info.getServerTime()
        const clientReceiveTime = Date.now();
        const latency = (clientReceiveTime - clientSendTime)
        this._timeOffset = Date.now() - serverTime - latency
    }

    public get isAvailable(): boolean {
        return this._timeOffset !== undefined
    }

    public get now(): number {
        if (!this._timeOffset) {
            return Date.now()
        }
        return Date.now() - this._timeOffset
    }

    public get date(): Date {
        return new Date(this.now)
    }

}
