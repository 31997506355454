import * as React from "react";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * TableFooter
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableFooter(props: {
    onIncreaseVisibleRows: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <footer className="table-footer">
            <LabelButton
                label={dict("table.footer.showMore")}
                style="secondary-small"
                onClick={props.onIncreaseVisibleRows}/>
        </footer>
    );

}
