import * as React from "react";
import ReactMarkdown from "react-markdown";

/******************************************************************
 * PlanTitle
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PlanHeadline(props: {
    title: string
    size?: "tiny" | "small" | "large" | "medium"
    onClick?: () => void
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <h3
            className="plan-headline"
            data-size={props.size ?? "large"}
            data-clickable={!!props.onClick}
            onClick={props.onClick}>
            <ReactMarkdown>
                {props.title}
            </ReactMarkdown>
        </h3>
    );

}
