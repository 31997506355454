import * as React from "react";
import {ITeamData} from "../../../../../shared/models/submodels/ITeamData";
import {UserInfo} from "../user/UserInfo";
import {IUserData} from "../../../../../shared/models/IUserData";
import {SessionDriverStateType} from "../../../../../shared/types/SessionDriverStateType";

/******************************************************************
 * TeamInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type TeamInfoDriverState = {
    user: IUserData,
    state: SessionDriverStateType
}

export function TeamInfo(props: {
    team: ITeamData
    teamState?: SessionDriverStateType
    driverStates?: TeamInfoDriverState[]
    style?: "normal" | "leaderboard" | "leaderboard-entry-modal"
    theme?: "dark" | "light"
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function userSize() {
        switch (props.style) {
            case "leaderboard":
                return "avatar-only-mini"
            case "leaderboard-entry-modal":
                return "avatar-only-small"
            default:
                return "small-framed"
        }
    }

    function showMembers() {
        return props.team.members?.length > 0
    }

    function driverState(member: IUserData): SessionDriverStateType {
        if (props.driverStates) {
            const driverState = props.driverStates.find(driverState => {
                return driverState.user._id === member._id
            })
            if (driverState) {
                return driverState.state
            }
        }
        return undefined
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="team-info"
            data-state={props.teamState}
            data-style={props.style ?? "normal"}
            data-theme={props.theme ?? "dark"}>
            <div className="team-info-color" style={{backgroundColor: props.team.color}}/>
            <div className="team-info-content">
                <div className="team-info-name">
                    {props.team.name}
                </div>
                {showMembers() &&
                    <div className="team-info-members">
                        {props.team.members?.map(member => {
                            return <UserInfo
                                key={member._id}
                                user={member}
                                driverState={driverState(member)}
                                clickToShowProfile={true}
                                style={userSize()}/>
                        })}
                    </div>}
            </div>
        </div>
    );

}
