import * as React from "react";
import {useRef} from "react";
import {RegisteredDrivers} from "./lists/RegisteredDrivers";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {EventAccessType} from "../../../../../../../shared/types/EventAccessType";
import {EventRequestsKanban} from "../../requests/EventRequestsKanban";

/******************************************************************
 * EventDriversSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventDriversSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {
        eventID,
        eventAccess,
        eventOwnerType,
        eventIsFinished,
    } = useEventState();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateAccess(value: EventAccessType) {
        let clearTeams = false
        switch (eventAccess as EventAccessType) {
            case "any-member":
                clearTeams = true
                break;
            case "only-group-members":
                clearTeams = true
                break;

        }
        await api.event.update(eventID, {
            drivers: {
                access: value,
            }
        });
        if (clearTeams) {
            await api.event.update(eventID, {teams: []});
        }
    }

    function options(): SelectInputOptionType[] {
        switch (eventOwnerType) {
            case "group":
                return [
                    {
                        value: "any-member",
                        text: dict("event.drivers.access.members")
                    }, {
                        value: "only-group-members",
                        text: dict("event.drivers.access.groupMembers")
                    }, {
                        value: "only-registered-drivers",
                        text: dict("event.drivers.access.registered")
                    },
                    {
                        value: "only-registered-drivers-after-approval",
                        text: dict("event.drivers.access.registeredAfterApproval"),
                        requiredDriverLicense: "premium"
                    },
                    {
                        value: "only-registered-drivers-after-paid",
                        text: dict("event.drivers.access.registeredAfterPaid"),
                        requiredDriverLicense: "premium"
                    }
                ]
            case "user":
                return [
                    {value: "any-member", text: dict("event.drivers.access.members")},
                    {value: "only-registered-drivers", text: dict("event.drivers.access.registered")}
                ]
        }
    }

    function showRegisteredDrivers() {
        switch (eventAccess) {
            case "only-registered-drivers":
                return true
            default:
                return false
        }
    }

    function showRequestKanban() {
        switch (eventAccess) {
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return true
            default:
                return false
        }
    }

    function showUpgradeInfo() {
        state.showSubscriptionUpgradeInfo.setValue({
            message: dict("event.drivers.access.needHigherDriverLicense"),
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="event-drivers-settings"
            size="content">
            <SelectInput
                label={dict("event.drivers.select.access")}
                defaultValue={eventAccess}
                readonly={eventIsFinished}
                onChange={updateAccess}
                onHigherDriverLicenseRequired={showUpgradeInfo}
                options={options()}/>
            {showRegisteredDrivers() &&
                <RegisteredDrivers/>}
            {showRequestKanban() &&
                <EventRequestsKanban/>}
        </ContentLayout>
    );

}
