import {IMailData} from "../../../../../../shared/models/IMailData";
import {BadgeColorType} from "../../../../ui/info/badge/BadgeColorType";

/******************************************************************
 * MailUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendMailUtils {

    static stateColor(data: IMailData): BadgeColorType {
        switch (data?.state) {
            case "draft":
                return "yellow"
            case "sent":
                return "blue"
            case "sending":
                return "green"
            case "failed":
                return "red"
            default:
                return "white"
        }
    }

}
