import * as React from "react";
import {useEffect} from "react";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {Headline} from "../../text/headings/Headline";
import {Table} from "../../table/Table";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition";
import {Badge} from "../../info/badge/Badge";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {useServices} from "../../../hooks/useServices";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * ReleaseNotesList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ReleaseNotesList(props: {
    showFrame?: boolean,
    ignoreVersion?: string
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api, language} = useServices();
    const {authUserExists, authUserSeenReleaseNoteVersions} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [releaseNotes, setReleaseNotes] = React.useState<IArticleData[]>(null);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadData()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadData() {
        let releaseNotes = await api.article.getReleaseNotes(language)
        if (props.ignoreVersion) {
            releaseNotes = releaseNotes.filter(article => article.title !== props.ignoreVersion)
        }
        setReleaseNotes(releaseNotes)
    }

    function releaseNotesTitle() {
        return dict("app.release.notes.title").replaceAll("{VERSION}", FrontendConfig.SERVER_VERSION);
    }

    function columnDefinition(): ITableColumnDefinition<IArticleData>[] {
        return [{
            type: "text-bold",
            dataKey: "title",
            size: "1fr"
        }, {
            type: "custom",
            size: authUserExists ? "100px" : null,
            customCell: (key, article: IArticleData) => {
                const hasSeen = authUserSeenReleaseNoteVersions.includes(article.title)
                return <ContentLayout key={key} justifyItems="center">
                    <Badge
                        size="small"
                        label={hasSeen
                            ? dict("app.release.notes.seen")
                            : dict("app.release.notes.unseen")}
                        color={hasSeen ? "blue" : "white"}/>
                </ContentLayout>
            }
        }]
    }

    async function updateSeenResleaseNotes(article: IArticleData) {
        if (!authUserExists) return
        if (authUserSeenReleaseNoteVersions.includes(article.title)) return
        const versions = authUserSeenReleaseNoteVersions
        versions.push(article.title)
        await api.user.update({seenReleaseNoteVersions: versions})
    }

    function showArticle(article: IArticleData) {
        if (!article || !article._id) return
        state.showArticle.setValue(article._id)
        updateSeenResleaseNotes(article)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {releaseNotes && releaseNotes.length > 0 &&
            <ContentLayout framed={props.showFrame} gap="small">
                <Headline
                    text={releaseNotesTitle()}
                    style="h5-underlined"/>
                <Table<IArticleData>
                    showHeader={false}
                    sortKey="title"
                    visibleRows={5}
                    sortDirection="desc"
                    style="standard-compact"
                    rowsData={releaseNotes}
                    onRowClick={showArticle}
                    columnsDefinition={columnDefinition()}/>
            </ContentLayout>}
    </>
}
