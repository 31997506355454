import * as React from "react";

/******************************************************************
 * SelectedOption
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SelectedOption(props: {
    label: string
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="selected-option">
            {props.label}
        </div>
    );

}
