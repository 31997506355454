import * as React from "react";

/******************************************************************
 * SettingsIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SettingsIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="settings-icon">
            <svg width={18 * (props.scale ?? 1)} height={20 * (props.scale ?? 1)} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.776 11.1957C15.8124 10.8927 15.8398 10.5849 15.8398 10.2724C15.8398 9.95989 15.8124 9.65212 15.776 9.34908L17.7047 7.78181C17.8779 7.63976 17.9281 7.38408 17.8141 7.17574L15.9903 3.89442C15.8763 3.69081 15.6392 3.60558 15.434 3.69081L13.1633 4.64254C12.6937 4.26848 12.1785 3.95123 11.6222 3.70975L11.2802 1.20023C11.2392 0.977684 11.0522 0.80249 10.8243 0.80249H7.17659C6.94861 0.80249 6.76167 0.977684 6.72519 1.20023L6.38322 3.70975C5.82695 3.95123 5.31172 4.26374 4.84208 4.64254L2.57141 3.69081C2.36623 3.61032 2.12913 3.69081 2.01514 3.89442L0.191307 7.17574C0.0773169 7.37934 0.127472 7.63503 0.300737 7.78181L2.22488 9.34908C2.1884 9.65212 2.16105 9.95989 2.16105 10.2724C2.16105 10.5849 2.1884 10.8927 2.22488 11.1957L0.300737 12.763C0.127472 12.905 0.0773169 13.1607 0.191307 13.3691L2.01514 16.6504C2.12913 16.854 2.36623 16.9392 2.57141 16.854L4.84208 15.9023C5.31172 16.2763 5.82695 16.5936 6.38322 16.835L6.72519 19.3446C6.76167 19.5671 6.94861 19.7423 7.17659 19.7423H10.8243C11.0522 19.7423 11.2392 19.5671 11.2757 19.3446L11.6176 16.835C12.1739 16.5936 12.6891 16.2811 13.1588 15.9023L15.4294 16.854C15.6346 16.9345 15.8717 16.854 15.9857 16.6504L17.8095 13.3691C17.9235 13.1655 17.8734 12.9098 17.7001 12.763L15.776 11.1957V11.1957ZM9.00042 13.5869C7.23587 13.5869 5.80871 12.1048 5.80871 10.2724C5.80871 8.43997 7.23587 6.95793 9.00042 6.95793C10.765 6.95793 12.1921 8.43997 12.1921 10.2724C12.1921 12.1048 10.765 13.5869 9.00042 13.5869Z" fill="white"/>
            </svg>
        </div>
    );

}
