import * as React from "react";
import {useState} from "react";
import {DriverInfo} from "../../../info/driver/DriverInfo";
import {
    ISessionLeaderboardEntry,
    ISessionLeaderboardEntryBestDrivenTimeData,
    ISessionLeaderboardEntryBestGymkhanaData,
    ISessionLeaderboardEntryBestLapData, ISessionLeaderboardEntryLatestStintData
} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {DriverSetupInvalidPropsInfo} from "./setup/DriverSetupInvalidPropsInfo";
import {IntersectingPixel} from "../../../utils/observer/IntersectingPixel";
import {useMainframeSystemContentStyles} from "../../../../hooks/useMainframeSystemContentStyles";

/******************************************************************
 * TableLeaderboardDriverCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardDriverCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {mainFramePaddingLeft} = useMainframeSystemContentStyles()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSticky, setIsSticky] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    function driver() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        let dataRef:
            | ISessionLeaderboardEntryLatestStintData
            | ISessionLeaderboardEntryBestLapData
            | ISessionLeaderboardEntryBestDrivenTimeData
            | ISessionLeaderboardEntryBestGymkhanaData
        if (entry.state == "finished") {
            switch (session.setup.mode) {
                case "lap":
                    dataRef = entry.bestLapStint
                    break
                case "race":
                    dataRef = entry.bestDrivenTimeStint
                    break
                case "gymkhana":
                    dataRef = entry.bestGymkhanaStint
                    break
            }
        } else {
            dataRef = entry.latestStint
        }
        const isStartOrder = entry.isStartOrder
        return {
            user: dataRef?.user,
            carName: isStartOrder ? null : dataRef?.carName,
            carEngine: isStartOrder ? null : dataRef?.carEngine,
            carTuning: isStartOrder ? null : dataRef?.carTuning
        }
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function showInvalidProps(): boolean {
        return props.entry.latestStint.invalidEnterProps
            && props.entry.latestStint.invalidEnterProps.length > 0
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-driver-cell"
            data-is-startorder={props.entry.isStartOrder}
            data-is-sticky={isSticky}>
            <IntersectingPixel
                showRedPixel={false}
                leftOffset={-mainFramePaddingLeft - 5}
                onIntersectionChange={isIntersecting => setIsSticky(!isIntersecting)}/>
            <DriverInfo
                user={driver().user}
                size={size()}
                theme={theme()}
                carName={driver().carName}
                carEngine={driver().carEngine}
                carTuning={driver().carTuning}/>
            {showInvalidProps() && <>
                <DriverSetupInvalidPropsInfo
                    invalidProps={props.entry.latestStint.invalidEnterProps}/>
            </>}
        </div>
    );

}
