import {useEffect, useState} from "react";
import {useSessionState} from "../../hooks/useSessionState";
import {ISessionLeaderboardEntry} from "../../../../shared/types/ISessionLeaderboardEntry";
import {useServices} from "../../hooks/useServices";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {LeaderboardUtils} from "../../../../shared/utils/LeaderboardUtils";
import {useDelayedInterval} from "../../hooks/useDelayedInterval";
import {TimeUtils} from "../../../../shared/utils/TimeUtils";
import {SessionUtils} from "../../../../shared/utils/SessionUtils";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";

/******************************************************************
 * useSessionAnnouncer
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useSessionAnnouncer(props: {
    visibleSettings: StateValue<boolean>[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {speaker, time, language} = useServices()
    const {addDelayedInterval, removeDelayedInterval, removeAllIntervals} = useDelayedInterval()
    const {
        sessionID,
        sessionData,
        sessionLeaderboard,
        sessionState,
        sessionLaps,
        sessionDuration,
        sessionStartTime,
        sessionFinishType,
        sessionAnnounceLapsRemainingEveryLap,
        sessionAnnounceTimeRemainingEverySeconds
    } = useSessionState();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [previousLeaderboard, setPreviousLeaderboard] = useState<ISessionLeaderboardEntry[]>()
    const [currentLeaderboard, setCurrentLeaderboard] = useState<ISessionLeaderboardEntry[]>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (sessionState !== "running") return
        announceStartTimeCountdown()
    }, [sessionStartTime])

    useEffect(() => {
        setPreviousLeaderboard(currentLeaderboard?.map(entry => entry))
        setCurrentLeaderboard(sessionLeaderboard?.map(entry => entry))
    }, [sessionLeaderboard]);

    useEffect(() => {
        if (sessionState !== "running") return
        if (!previousLeaderboard) return
        announceNewBestLapTime()
        announceNewBestScore()
        announceSessionLapsRemaining()
    }, [currentLeaderboard])

    useEffect(() => {
        if (sessionState !== "running") {
            removeAllIntervals()
            return
        }
        announceSessionTimeRemaining()
        announceSessionTimeOver()
    }, [sessionState, currentLeaderboard])

    useEffect(() => {
        if (sessionState !== "running") return
        if (!previousLeaderboard) return
        announcePositionChange()
        announceJokerLap()
        announceFalseStart()
    }, [previousLeaderboard, currentLeaderboard])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function isSettingVisible(annouceSettingState: StateValue<boolean>) {
        return props.visibleSettings?.some(setting => setting === annouceSettingState)
    }

    function announceJokerLap() {
        if (!isSettingVisible(speaker.session.jokerLap)) return
        currentLeaderboard?.forEach((currentEntry) => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, currentEntry.latestStint?._id)
            if (prevEntry) {
                const currDrivenJokerLaps = currentEntry.latestStint?.drivenJokerLaps ?? 0
                const prevDrivenJokerLaps = prevEntry.latestStint?.drivenJokerLaps ?? 0
                if (currDrivenJokerLaps > prevDrivenJokerLaps) {
                    const nick = SpeakerUtils.nickToSpeak(currentEntry.latestStint.user, language)
                    speaker.session.announceJokerLap(nick, currDrivenJokerLaps, sessionData.setup.numJokerLaps)
                }
            }
        })
    }

    function announceFalseStart() {
        if (!isSettingVisible(speaker.session.falseStart)) return
        currentLeaderboard?.forEach((currentEntry) => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, currentEntry.latestStint?._id)
            if (prevEntry) {
                const currFalseStart = currentEntry.latestStint?.falseStart ?? false
                const prevFalseStart = prevEntry.latestStint?.falseStart ?? false
                if (currFalseStart && currFalseStart !== prevFalseStart) {
                    const nick = SpeakerUtils.nickToSpeak(currentEntry.latestStint.user, language)
                    speaker.session.announceFalseStart(nick)
                }
            }
        })
    }

    function announceStartTimeCountdown() {
        const intervallKey = `${sessionID}-startTimeCountdown`
        removeDelayedInterval(intervallKey)
        if (!isSettingVisible(speaker.session.startTimeCountdown)) return
        if (!sessionStartTime || !TimeUtils.isFuture(sessionStartTime, time.date)) return
        addDelayedInterval({
            key: intervallKey,
            intervalInSeconds: 1,
            callback: () => {
                const secondsUntilStart = Math.floor(TimeUtils.calcTimeInSeconds(sessionStartTime, time.date))
                const countdownSetup = SpeakerUtils.getStartTimeCountdownSetup(secondsUntilStart)
                if (!countdownSetup) {
                    removeDelayedInterval(intervallKey)
                    return
                }
                if (secondsUntilStart % countdownSetup.intervalInSeconds === 0) {
                    speaker.session.announceStartTimeCountdown(secondsUntilStart)
                }
            }
        })
    }

    function announceNewBestLapTime() {
        if (!isSettingVisible(speaker.session.newBestLapTime)) return
        const currentEntry = LeaderboardUtils.getBestLapTimeEntry(currentLeaderboard)
        const previousEntry = LeaderboardUtils.getBestLapTimeEntry(previousLeaderboard)
        if (!currentEntry?.overall?.bestLapTime) return
        if (previousEntry?.state !== "driving") return
        if (currentEntry.overall.bestLapTime < (previousEntry?.overall?.bestLapTime ?? Number.MAX_SAFE_INTEGER)) {
            if (currentEntry.team) {
                const bestLapMemberEntry = LeaderboardUtils.getBestLapTimeEntry(currentEntry.team.leaderboardEntries)
                const nick = SpeakerUtils.nickToSpeak(bestLapMemberEntry.latestStint.user, language)
                speaker.session.announceNewBestLapTime(nick, currentEntry.overall.bestLapTime)
                return
            }
            const nick = SpeakerUtils.nickToSpeak(currentEntry.latestStint.user, language)
            speaker.session.announceNewBestLapTime(nick, currentEntry.overall.bestLapTime)
        }
    }

    function announceNewBestScore() {
        if (!isSettingVisible(speaker.session.newBestScore)) return
        const currentEntry = LeaderboardUtils.getBestScoreEntry(currentLeaderboard)
        const previousEntry = LeaderboardUtils.getBestScoreEntry(previousLeaderboard)
        if (!currentEntry || !previousEntry) return
        if (currentEntry.state === "finished" && previousEntry.state !== "finished") {
            const currentScore = currentEntry.latestStint?.score ?? 0
            const previousScore = previousEntry?.bestGymkhanaStint?.score ?? 0
            if (currentScore <= previousScore) return
            const nick = SpeakerUtils.nickToSpeak(currentEntry.latestStint.user, language)
            speaker.session.announceNewBestScore(nick, currentEntry.latestStint.score)
        }
    }

    function announceSessionLapsRemaining() {
        if (sessionFinishType !== "laps") return
        if (!isSettingVisible(speaker.session.lapsRemaining)) return
        const leaderEntry = currentLeaderboard?.[0]
        const previousLeaderEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, leaderEntry?.latestStint?._id)
        const leaderDrivenLaps = leaderEntry?.overall?.drivenLaps ?? 0
        const previousLeaderDrivenLaps = previousLeaderEntry?.overall?.drivenLaps ?? 0
        if (leaderDrivenLaps <= previousLeaderDrivenLaps) return
        const lapsRemaining = sessionLaps - leaderDrivenLaps
        if (lapsRemaining % sessionAnnounceLapsRemainingEveryLap === 0
            || lapsRemaining === 1) {
            speaker.session.announceLapsRemaining(lapsRemaining)
        }
    }

    function announceSessionTimeRemaining() {
        if (sessionFinishType !== "duration") return
        const isCurrentStarted = SessionUtils.hasSessionStarted(currentLeaderboard)
        const wasPreviousStarted = SessionUtils.hasSessionStarted(previousLeaderboard)
        if (previousLeaderboard && isCurrentStarted && wasPreviousStarted) return
        const intervallKey = `${sessionID}-timeRemaining`
        removeDelayedInterval(intervallKey)
        if (!isSettingVisible(speaker.session.timeRemaining)) return
        const intervalTime = sessionAnnounceTimeRemainingEverySeconds
        const remainingSeconds = remainingSessionSeconds()
        if (!remainingSeconds) return
        const delayInSeconds = remainingSeconds % intervalTime
        const delayOffset = (delayInSeconds == 0 && drivenSeconds() < intervalTime)
            ? intervalTime
            : 0
        addDelayedInterval({
            key: intervallKey,
            delayInSeconds: delayInSeconds + delayOffset,
            intervalInSeconds: intervalTime,
            callback: () => {
                let remainingSeconds = remainingSessionSeconds()
                if (remainingSeconds < FrontendConfig.ANNOUNCE_TIME_REMAINING_MIN_SECONDS) {
                    removeDelayedInterval(intervallKey)
                    return
                }
                if (!isSettingVisible(speaker.session.timeRemaining)) return
                speaker.session.announceTimeRemaining(remainingSeconds)
            }
        })
    }

    function announceSessionTimeOver() {
        const isCurrentStarted = SessionUtils.hasSessionStarted(currentLeaderboard)
        const wasPreviousStarted = SessionUtils.hasSessionStarted(previousLeaderboard)
        if (previousLeaderboard && isCurrentStarted && wasPreviousStarted) return
        const intervallKey = `${sessionID}-timeOver`
        removeDelayedInterval(intervallKey)
        if (!isSettingVisible(speaker.session.timeRemaining)) return
        const remainingSeconds = remainingSessionSeconds()
        if (!remainingSeconds) return
        addDelayedInterval({
            key: intervallKey,
            intervalInSeconds: 1,
            callback: () => {
                if (!isSettingVisible(speaker.session.timeRemaining)) return
                let remainingSeconds = remainingSessionSeconds()
                if (remainingSeconds == 0) {
                    removeDelayedInterval(intervallKey)
                    speaker.session.announceTimeRemaining(0)
                    return
                }
            }
        })
    }

    function announcePositionChange() {
        if (!SessionUtils.hasSessionStarted(currentLeaderboard)
            || SessionUtils.hasSessionFullfilled(currentLeaderboard, sessionData)) {
            return
        }
        const positionGainedEntries: ISessionLeaderboardEntry[] = []
        const positionLostEntries: ISessionLeaderboardEntry[] = []
        currentLeaderboard?.forEach((entry) => {
            const prevEntry = LeaderboardUtils.findEntryByStintID(previousLeaderboard, entry.latestStint._id)
            if (prevEntry) {
                const currPosition = entry.position
                const prevPosition = prevEntry.position
                if (currPosition < prevPosition) {
                    positionGainedEntries.push(entry)
                } else if (currPosition > prevPosition) {
                    positionLostEntries.push(entry)
                }
            }
        })
        positionGainedEntries?.sort((a, b) => a.position - b.position)
        positionLostEntries?.sort((a, b) => a.position - b.position)
        positionGainedEntries?.forEach(entry => {
            if (isSettingVisible(speaker.session.positionGained)) {
                speaker.session.announcePositionGained(nameToAnnounce(entry), entry.position)
            }
        })
        positionLostEntries?.forEach(entry => {
            if (isSettingVisible(speaker.session.positionLost)) {
                speaker.session.announcePositionLost(nameToAnnounce(entry), entry.position)
            }
        })
    }

    function remainingSessionSeconds() {
        return Math.round(TimeUtils.getRemainingSeconds(LeaderboardUtils.getEarliestSignalTimestamp(sessionLeaderboard), sessionDuration, time.date))
    }

    function drivenSeconds() {
        return TimeUtils.durationToSeconds(sessionDuration) - remainingSessionSeconds()
    }

    function nameToAnnounce(entry: ISessionLeaderboardEntry): string {
        if (entry.team) {
            return entry.team.name
        }
        return SpeakerUtils.nickToSpeak(entry.latestStint.user, language)
    }
}
