import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IGroupData} from "../../shared/models/IGroupData";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {GroupType} from "../../shared/types/GroupType";
import {ILocationData} from "../../shared/types/ILocationData";
import {PrivacyType} from "../../shared/types/PrivacyType";

/******************************************************************
 * GroupAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class GroupAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async createGroup(data: {
        type: GroupType,
        privacy: PrivacyType,
        name: string,
        location: ILocationData,
    }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.GROUP, data, true);
    }

    public async getGroupDataByPath(groupPath: string): Promise<IGroupData> {
        const response = await this._frontend.api.request("GET", APIRoute.GROUP, {groupPath}, true);
        const result = await response.json()
        if (response.status == 200) {
            return result
        } else {
            this._frontend.state.showMessage.setValue({
                type: "normal",
                message: this._frontend.error.createMessage(result)
            });
        }
        return null;
    }

    public async getGroupDataByID(groupID: MongoObjectIDType): Promise<IGroupData> {
        const response = await this._frontend.api.request("GET", APIRoute.GROUP, {groupID}, true);
        const result = await response?.json()
        if (response.status == 200) {
            return result
        } else {
            this._frontend.state.showMessage.setValue({
                type: "normal",
                message: this._frontend.error.createMessage(result)
            });
        }
        return null;
    }

    public async getUserGroups(userID: MongoObjectIDType): Promise<IGroupData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.GROUPS, {userID}, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getPublicGroups(): Promise<IGroupData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.GROUPS, null, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async canCreateGroup(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.GROUP_CAN_CREATE, {}, true)
        if (response.status == 200) {
            return true
        }
        return false
    }

    public async uploadAvatar(data: FormData): Promise<Response> {
        return await this._frontend.api.upload(APIRoute.GROUP_AVATAR, data);
    }

    public async update(groupID: MongoObjectIDType, data: IGroupData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.GROUP, {groupID: groupID, ...data}, true);
    }

    public async delete(groupID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.GROUP, {groupID}, true);
    }
}
