import * as React from "react";
import {useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {IEventData} from "../../../../../shared/models/IEventData";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {SelectInput} from "../../../ui/form/elements/select/SelectInput";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * EventParticipationRequestModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventParticipationRequestModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, api} = useServices()
    const {authUserValidDriverLicense} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [eventData] = useState<IEventData>(state.showEventParticipationRequest.getValue())
    const [progressing, setProgressing] = useState<boolean>(false);
    const [isConfirmed, setIsConfirmed] = useState<boolean>(state.showConfirm.getValue()?.confirmed)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function infoText(): string {
        let text = dict("event.participation.request.none.text")
        switch (eventData?.drivers?.access) {
            case "only-registered-drivers-after-approval":
                text = dict("event.participation.request.approval.text")
                break
            case "only-registered-drivers-after-paid":
                text = dict("event.participation.request.fee.text")
                break
        }
        return text.replaceAll("[EVENT_NAME]", eventData?.name)
    }

    async function sendEventParticipationRequest() {
        setProgressing(true)
        const success = await api.event.driverRequest(eventData._id)
        setProgressing(false)
        if (success) {
            state.showEventParticipationRequest.setValue(null)
        }
    }

    function confirmCheckText(confirmed: boolean) {
        return confirmed
            ? dict("event.participation.request.yes")
            : dict("event.participation.request.no")
    }

    function close() {
        state.showEventParticipationRequest.setValue(null)
    }

    function hasNeededDriverLicense() {
        return authUserValidDriverLicense != "free"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            className={"event-participation-request-modal"}
            closeAction={close}>
            <Headline
                text={dict("event.participation.request.modal.title")}
                style="modal"/>
            {!hasNeededDriverLicense() &&
                <ContentLayout justifyItems="center">
                    <InfoText
                        text={dict("event.participation.request.no.driver.license.text")}
                        align="center"/>
                    <LabelButton
                        label={dict("subscription.upgrade.info.button.showPlans")}
                        style="primary"
                        onClick={() => {
                            close()
                            state.showSubscriptionPlans.setValue(true)
                        }}/>
                </ContentLayout>}
            {hasNeededDriverLicense() &&
                <ContentLayout>
                    <InfoText
                        text={infoText()}/>
                    <SelectInput
                        onChange={(value) => setIsConfirmed(value == "yes")}
                        defaultValue={isConfirmed ? "yes" : "no"}
                        options={[
                            {value: "no", text: confirmCheckText(false)},
                            {value: "yes", text: confirmCheckText(true)}
                        ]}/>
                    <ContentLayout columns={2}>
                        <LabelButton
                            onClick={close}
                            label={dict("generic.abort")}
                            style="secondary"/>
                        <LabelButton
                            onClick={sendEventParticipationRequest}
                            disabled={!isConfirmed}
                            progressing={progressing}
                            label={dict("event.participation.request.send.button.label")}
                            style="primary"/>
                    </ContentLayout>
                </ContentLayout>}
        </Modal>
    )

}
