import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Input} from "../../../ui/form/elements/input/Input";
import {ColorUtils} from "../../../ui/utils/color/ColorUtils";
import {LabelText} from "../../../ui/text/label/LabelText";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {DriverList} from "../../../ui/list/driver/DriverList";
import {Divider} from "../../../ui/utils/divider/Divider";
import {useEventState} from "../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {IUserData} from "../../../../../shared/models/IUserData";
import {FormMessage} from "../../../ui/form/elements/message/FormMessage";
import {ITeamData} from "../../../../../shared/models/submodels/ITeamData";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * TeamModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TeamModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api, error, speaker} = useServices();
    const {eventID, eventAccess, eventRegisteredDrivers, eventTeams} = useEventState()
    const {groupMembers} = useGroupState()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const colorRef = useRef(ColorUtils.getRandomColor())

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [busy, setBusy] = useState<boolean>()
    const [selectDrivers, setSelectDrivers] = useState<boolean>()
    const [selectedDrivers, setSelectedDrivers] = useState<IUserData[]>()
    const [availableDrivers, setAvailableDrivers] = useState<IUserData[]>()
    const [name, setName] = useState<string>()
    const [color, setColor] = useState<string>()
    const [errorMessage, setErrorMessage] = useState<string>();
    const [mode, setMode] = useState<"create" | "edit">("create")
    const [teamData, setTeamData] = useState<ITeamData>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (typeof state.showTeam.getValue() == "object") {
            setTeamData(state.showTeam.getValue() as ITeamData)
            setMode("edit")
        } else {
            setMode("create")
        }
    }, [])

    useEffect(() => {
        setSelectedDrivers(availableDrivers?.filter(registeredDriver => {
            return teamData?.members.some((member) => member._id === registeredDriver._id)
        }))
    }, [teamData])

    useEffect(() => {
        let drivers: IUserData[] = groupMembers?.map(member => member.user) ?? []
        switch (eventAccess) {
            case "only-registered-drivers":
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                drivers = drivers.concat(eventRegisteredDrivers.filter(driver => !drivers.some(member => member._id == driver._id)))
                break
        }
        setAvailableDrivers(drivers?.filter((driver) => {
            const notInSelectedDrivers = !selectedDrivers?.some((selectedDriver) => selectedDriver._id === driver._id);
            if (FrontendConfig.ALLOW_MULTIPLE_TEAMS_PER_USER) {
                return notInSelectedDrivers
            }
            const notInEventTeamMembers = !eventTeams?.some((team) =>
                team.members.some((member) => member._id === driver._id)
            );
            return notInSelectedDrivers && notInEventTeamMembers;
        }))
    }, [eventRegisteredDrivers, selectedDrivers, mode, groupMembers])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        setBusy(false)
        state.showTeam.setValue(null);
    }

    async function save() {
        setBusy(true)
        let teams = eventTeams.concat()
        const localTeamData = {
            name: name?.toString(),
            color: color?.toString(),
            members: selectedDrivers?.map(user => user._id)
        }
        switch (mode) {
            case "create":
                teams.push(localTeamData)
                break;
            case "edit":
                const updateTeam = teams.filter(team => team._id == teamData._id)[0]
                updateTeam.name = localTeamData.name
                updateTeam.color = localTeamData.color
                updateTeam.members = localTeamData.members
                break;
        }
        const response = await api.event.update(eventID, {teams: teams})
        if (response.status != 200) {
            const jsonError = await response.json()
            setErrorMessage(error.createMessage(jsonError))
            setBusy(false)
        } else {
            close()
        }
    }

    function addDriver(userID: MongoObjectIDType) {
        const user = availableDrivers.filter(user => user._id == userID)[0]
        const drivers: IUserData[] = selectedDrivers?.concat() ?? []
        if (!drivers.includes(user)) {
            drivers.push(user)
            setSelectedDrivers(drivers)
        }
    }

    function removeDriver(userID: MongoObjectIDType) {
        const userToRemove = selectedDrivers?.filter(user => user._id == userID)[0]
        const drivers: IUserData[] = selectedDrivers?.concat() ?? []
        if (drivers.includes(userToRemove)) {
            setSelectedDrivers(drivers.filter(user => {
                return user._id != userToRemove._id
            }))
        }
    }

    async function deleteTeam() {
        setBusy(true);
        const remainingTeams = eventTeams.filter(team => team._id != teamData._id)
        const response = await api.event.update(eventID, {teams: remainingTeams})
        if (response.status != 200) {
            const jsonError = await response.json()
            setErrorMessage(error.createMessage(jsonError))
            setBusy(false)
        } else {
            close()
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={eventID}>
            <Modal
                closeAction={close}
                width="medium"
                position="global"
                className="team-modal">
                <Headline
                    text={dict("event.team." + mode)}
                    style="modal"/>
                <ContentLayout>
                    <div className="team-modal-name-color">
                        <Input
                            type="text"
                            label={dict("event.team.name.label")}
                            defaultValue={teamData?.name}
                            onChange={(value) => setName(value)}
                            required={true}/>
                        <IconButton
                            type="speaker"
                            onClick={() => speaker.speak(name)}/>
                        <Input
                            type="color"
                            label={dict("event.team.color.label")}
                            onChange={(value) => setColor(value)}
                            defaultValue={teamData?.color ?? colorRef.current}
                            required={true}/>
                    </div>
                    <ContentLayout gap="none">
                        <div className="team-modal-drivers-header">
                            <LabelText
                                label={dict("event.team.drivers.label")}/>
                            <LabelButton
                                label={dict("event.team.driver.add")}
                                style="primary-small"
                                onClick={() => setSelectDrivers(true)}
                            />
                        </div>
                        <DriverList
                            data={selectedDrivers}
                            primaryIconType="cancel"
                            onPrimaryIconClick={removeDriver}
                            emptyDataInfo={dict("event.team.drivers.empty")}/>
                    </ContentLayout>
                    <Divider style="modal"/>
                    <ContentLayout columns={mode == "edit" ? 3 : 2}>
                        {mode == "edit" && <LabelButton
                            label={dict("event.team.button.delete")}
                            style="primary"
                            progressing={busy}
                            onClick={deleteTeam}/>}
                        <LabelButton
                            label={dict("button.abort")}
                            style="secondary"
                            onClick={close}/>
                        <LabelButton
                            label={dict("event.team.button." + mode)}
                            style="primary"
                            progressing={busy}
                            onClick={save}/>
                    </ContentLayout>
                    <FormMessage message={errorMessage} type={"error"}/>
                </ContentLayout>
            </Modal>
            {selectDrivers &&
                <Modal
                    width="medium"
                    position="global"
                    closeAction={() => setSelectDrivers(false)}>
                    <Headline
                        text={dict("event.team.drivers.select")}
                        style="modal"/>
                    <DriverList
                        data={availableDrivers}
                        primaryIconType="add"
                        onPrimaryIconClick={addDriver}
                        emptyDataInfo={dict("event.team.drivers.empty.info")}/>
                </Modal>}
        </ComponentInitializer>
    )
}
