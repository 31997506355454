import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useServices} from "../../../../../hooks/useServices";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useState} from "react";

/******************************************************************
 * UserNewsletterInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserNewsletterInfo() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function subscribe() {
        setIsBusy(true)
        await api.user.update({newsletter: "subscribed"})
        showPostMessage()
    }

    async function unsubscribe() {
        setIsBusy(true)
        await api.user.update({newsletter: "unsubscribed"})
        showPostMessage()
    }

    function showPostMessage() {
        setIsBusy(false)
        state.showMessage.setValue({
            message: dict("user.newsletter.info.postMessage"),
            type: "normal"
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="user-newsletter-info"
            framed={true}
            frameColor="black">
            <Headline
                text={dict("user.newsletter.info.title")}
                style="h5-underlined"/>
            <InfoText
                text={dict("user.newsletter.info.text")}
                size="small"/>
            <ContentLayout
                gap="small"
                columnTemplate="min-content min-content">
                <LabelButton
                    label={dict("user.newsletter.info.subscribe")}
                    style="primary-small"
                    disabled={isBusy}
                    onClick={subscribe}/>
                <LabelButton
                    label={dict("user.newsletter.info.unsubscribe")}
                    style="secondary-small"
                    disabled={isBusy}
                    onClick={unsubscribe}/>
            </ContentLayout>
        </ContentLayout>
    );

}
