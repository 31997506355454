import * as React from "react";
import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

/******************************************************************
 * LabelText
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LabelText(props: {
    label: string
    backgroundColor?: "none" | "black"
    style?: "default" | "sidebar"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="label-text"
            data-style={props.style ?? "default"}
            data-background-color={props.backgroundColor ?? "none"}>
            <ReactMarkdown
                rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }]]}>
                {props.label}
            </ReactMarkdown>
        </div>
    );

}
