/******************************************************************
 * MailTemplateTypes
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const PADDLE_PRICE_OFFER_TYPES = [
    "earlybird"
] as const

export type PaddlePriceOfferType = typeof PADDLE_PRICE_OFFER_TYPES[number]

export function isPaddlePriceOfferType(type: string): boolean {
    return PADDLE_PRICE_OFFER_TYPES.includes(type as PaddlePriceOfferType)
}
