import * as React from "react";
import {IconType} from "../../icons/IconType";
import {Icon, IconColorType} from "../../icons/Icon";
import {Tooltip} from "../../tooltip/Tooltip";
import {useEffect, useRef, useState} from "react";

/******************************************************************
 * IconButton
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type IconButtonSizeType = "normal" | "small" | "large" | "huge" | "tiny"

export function IconButton(props: {
    type: IconType,
    tooltip?: string,
    iconColor?: IconColorType,
    stateIconType?: IconType,
    stateIconColor?: IconColorType,
    stateIconScale?: number,
    playFlashAnimation?: boolean,
    stopPropagation?: boolean
    scale?: number,
    size?: IconButtonSizeType
    disabled?: boolean,
    selected?: boolean,
    opacityMode?: "medium" | "none"
    warning?: boolean
    onClick?: () => void
    onHoverChange?: (isHover: boolean) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [hovered, setHovered] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        props.onHoverChange?.(hovered)
    }, [hovered])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function iconScale(): number {
        const propsScale = props.scale ?? 1
        const propsSize = props.size ?? "normal"
        switch (propsSize) {
            case "normal":
                return propsScale
            case "small":
                return propsScale * 0.9
            case "tiny":
                return propsScale * 0.7
            case "large":
                return propsScale * 2
            case "huge":
                return propsScale * 4

        }
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function onClicked(e) {
        if (props.stopPropagation) {
            e.stopPropagation()
        }
        if (props.onClick) {
            props.onClick();
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="icon-button"
            ref={rootRef}
            data-type={props.type}
            data-selected={props.selected}
            data-size={props.size ?? "normal"}
            data-disabled={props.disabled}
            data-flashing={props.playFlashAnimation}
            data-interactive={!!props.onClick && !props.disabled}
            data-warning={props.warning}
            data-opacity-mode={props.opacityMode ?? "medium"}
            tabIndex={-1}
            onClick={onClicked}
            onPointerEnter={() => setHovered(true)}
            onPointerLeave={() => setHovered(false)}>
            <Icon
                type={props.type}
                iconColor={props.iconColor}
                stateIconType={props.stateIconType}
                stateIconColor={props.stateIconColor}
                stateIconScale={props.stateIconScale}
                scale={iconScale()}
                warning={props.warning}/>
            {props.tooltip &&
                <Tooltip
                    label={props.tooltip}
                    hoverElement={rootRef.current}/>}
        </div>
    );

}
