import {FrontendServices} from "../FrontendServices";
import {UIComponent} from "@webfruits/core";
import * as ReactDOM from 'react-dom/client';
import {Root} from 'react-dom/client';
import {ReactElement} from "react";
import {ModalSystem} from "../../components/systems/modals/ModalSystem";
import {AppLayout} from "../../components/ui/layout/app/AppLayout";
import {OverlaySystem} from "../../components/systems/overlay/OverlaySystem";
import React = require("react");
import {UpdateChecker} from "../update/UpdateChecker";
import {DeviceUtils} from "../../utils/DeviceUtils";
import {FrontendConfig} from "../FrontendConfig";

/******************************************************************
 * FrontendView
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendView extends UIComponent {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _reactRoot: Root;

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        super(FrontendConfig.ROOT_ELEMENT_NAME);
        this.preventBodyScrollingOnMobile();
        this.initSafeAreaMode();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public updateStyles() {
        this.applyStyle({
            position: "absolute",
            width: "100%",
            height: "100%"
        })
    }

    public init() {
        this._reactRoot = ReactDOM.createRoot(this.view);
        if (this._services.state.coreErrorMessage.hasValue()) {
            this.showCoreErrorMessage()
            return
        }
        const reactSystems = this.initReactSystems();
        this._reactRoot.render(reactSystems);
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initReactSystems(): ReactElement {
        return (
            <React.StrictMode>
                <AppLayout/>
                <OverlaySystem/>
                <ModalSystem/>
                <UpdateChecker/>
            </React.StrictMode>
        )
    }

    private initSafeAreaMode() {
        if (!DeviceUtils.isRunningOnIPadOS() && !DeviceUtils.isRunningOnIOS()) {
            return
        }
        if (DeviceUtils.isRunningAsPWA()
            || DeviceUtils.isRunningOnIPad()) {
            this.view.setAttribute("data-safe-area-mode", "true")
        }
    }

    private showCoreErrorMessage() {
        this._reactRoot.render(<React.StrictMode>
            <div className="core-error-message">
                {this._services.state.coreErrorMessage.getValue()}
            </div>
        </React.StrictMode>);
    }

    private preventBodyScrollingOnMobile() {
        document.body.addEventListener('touchmove', function (e) {
            e.preventDefault();
        });
    }
}
