import * as React from "react";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {FavoriteButton} from "../../../../ui/buttons/favorite/FavoriteButton";
import {Icon} from "../../../../ui/icons/Icon";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {Table} from "../../../../ui/table/Table";
import {LanguageUtils} from "../../../../../../shared/utils/LanguageUtils";

/******************************************************************
 * GroupFinderList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupFinderList(props: {
    tabbedContent?: ITabbedContentChildProps
    isLoading: boolean
    showPrivacyInfo?: boolean
    showMemberInfo?: boolean
    groups: IGroupData[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, language} = useServices()
    const {authUserID} = useAuthUser()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<IGroupData>[] {
        return [{
            type: "privacy",
            dataKey: "privacy",
            size: props.showPrivacyInfo ? "35px" : null
        }, {
            type: "image",
            dataKey: "avatar",
            size: "50px"
        }, {
            columnName: dict("group.name.label"),
            sortKey: "name",
            dataKey: "name",
            type: "text-bold",
            size: "3fr",
        }, {
            columnName: dict("group.place.label"),
            type: "text",
            sortKey: "location.place",
            size: "4fr",
            value: group => {
                if (group.type == "online") {
                    return dict("group.type.online")
                }
                const country = LanguageUtils.getValueFromMultilangString(group.location?.country, language)
                const place = LanguageUtils.getValueFromMultilangString(group.location?.place, language)
                const zip = group.location?.postalCode
                if (!country && !place && !zip) return "–"
                return `${place}${zip ? ", " + zip : ""}${country ? ", " + country : ""}`
            },
        }, {
            type: "custom",
            size: props.showMemberInfo ? "35px" : null,
            customCell: (key, group) => {
                const isUserGroup: boolean = group.members.some(member => member.user === authUserID)
                return <ContentLayout
                    key={key}
                    justifyItems="center">
                    {isUserGroup && <Icon type="person" scale={0.8}/>}
                </ContentLayout>
            }
        }, {
            type: "custom",
            size: authUserID ? "45px" : null,
            customCell: (key, data) => {
                return <ContentLayout key={key} justifyItems="center">
                    <FavoriteButton favoriteID={data._id} type={"group"}/>
                </ContentLayout>
            }
        }]
    }

    function showGroupPreview(groupData: IGroupData) {
        state.showGroupPreview.setValue(groupData._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="group-finder-list">
            <Table<IGroupData>
                rowsData={props.groups}
                showHeader={true}
                showFilter={props.groups?.length > 3}
                visibleRows={25}
                scrollContainerType="modal"
                isLoadingData={props.isLoading}
                noDataInfo={dict("group.finder.list.noDataInfo")}
                columnsDefinition={columnsDefinition()}
                onRowClick={showGroupPreview}/>
        </ContentLayout>
    );

}
