/******************************************************************
 * StringUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class StringUtils {

    static toCamelCase(str: string): string {
        if (!str) return null;
        return str.toLowerCase().replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase()
                .replace('-', '')
                .replace('_', '');
        });
    }

    static unbreakDriftExclamationMark(string: string): string {
        if (!string) return null;
        return string.replaceAll("DR!FT", "DR&#8288;!&#8288;FT");
    }

    static makeLineBreaksMarkdownCompatible(string: string): string {
        if (!string) return null;
        return string
            .replaceAll("\n", "  \n")
            .replaceAll("---", "***")
            .replaceAll("--", "***")
    }

    static preprocessMarkdown(string: string, mapHeadingDown: boolean = false): string {
        if (!string) return null;
        if (mapHeadingDown) {
            string = string
                .replace(/^##\s/gm, '### ')
                .replace(/^#\s/gm, '## ');
        }
        string = StringUtils.unbreakDriftExclamationMark(string)
        return StringUtils.makeLineBreaksMarkdownCompatible(string)
    }

    static removeHTML(string: string): string {
        return string?.toString()?.replace(/(<([^>]+)>)/gi, "");
    }

    static ellipsis(string: string, maxLength: number): string {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "...";
        }
        return string;
    }

}
