import * as React from "react";
import {ReactElement, useEffect, useRef} from "react";

/******************************************************************
 * Overlay
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Overlay(props: {
    children?: ReactElement | ReactElement[] | null
    className?: string
    clickToClose?: boolean
    onClose?: () => void
    closeIt?: boolean
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const dialogRef = useRef<HTMLDialogElement>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (props.closeIt == true) {
            close()
        }
    }, [props.closeIt])

    useEffect(() => {
        if (!dialogRef.current.open) {
            dialogRef.current.close()
            dialogRef.current.showModal()
        }
        if (props.clickToClose && dialogRef.current) {
            document.addEventListener("click", onClickAnywhere)
        }
        return () => {
            document.removeEventListener("click", onClickAnywhere)
        }
    }, [dialogRef])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClickAnywhere(e: MouseEvent) {
        if (e.target != dialogRef.current) return;
        close()
    }

    function close() {
        dialogRef.current.close()
        if (props.onClose) {
            props.onClose()
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <dialog
            className={"overlay" + (props.className ? " " + props.className : "")}
            onClose={close}
            ref={dialogRef}>
            {props.children}
        </dialog>
    );

}
