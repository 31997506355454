import * as React from "react";
import {useEffect, useState} from "react";
import {ITabbedContentChildProps} from "../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {useServices} from "../../../../hooks/useServices";
import {useSessionState} from "../../../../hooks/useSessionState";
import {IStintData} from "../../../../../../shared/models/IStintData";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {TableCellType} from "../../../../ui/table/TableCellType";
import {DriftUtils} from "../../../../../../shared/utils/DriftUtils";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {YouTubeUtils} from "../../../../../utils/YouTubeUtils";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {PlanFeatureDefinitions} from "../../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../../../../../../shared/types/PlanAccessType";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {StintsOverviewPlanUpgradeInfo} from "./plan/StintsOverviewPlanUpgradeInfo";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {RaceDirectorUtils} from "../../../../../../shared/utils/RaceDirectorUtils";
import {TableValueCell} from "../../../../ui/table/cells/value/TableValueCell";
import {ApprovalStateIcon} from "../../../../ui/info/approval/ApprovalStateIcon";

/******************************************************************
 * StintsOverview
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintsOverview(props: {
    stints: IStintData[]
    availableStintsIDs: MongoObjectIDType[]
    tabbedContent: ITabbedContentChildProps
    onStintClick: (stintData: IStintData) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {authUserSubscription, authUserUseSubscription} = useAuthUser()
    const {sessionData, sessionMode, sessionClassificationMode, sessionUseStintApproval} = useSessionState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showPlanUpgradeInfo, setShowPlanUpgradeInfo] = useState(computeShowPlanUpgradeInfo())
    const [maxAccessableStints, setMaxAccessableStints] = useState(getMaxAccessableStints())

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setShowPlanUpgradeInfo(computeShowPlanUpgradeInfo())
        setMaxAccessableStints(getMaxAccessableStints())
    }, [props.stints, props.availableStintsIDs?.length, authUserSubscription])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function computeShowPlanUpgradeInfo(): boolean {
        if (!authUserUseSubscription) return false
        const maxAccessableStints: number = getMaxAccessableStints()
        return maxAccessableStints > 0 && props.availableStintsIDs?.length > maxAccessableStints
    }

    function getMaxAccessableStints(): number {
        if (!authUserUseSubscription) return null
        const authUserDriverLicense = DriverLicenseUtils.validLicenseType(authUserSubscription)
        return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("stint-analysis", authUserDriverLicense)?.value ?? null
    }

    function columsDefinition(): ITableColumnDefinition<IStintData>[] {
        const scoreSize = sessionMode == "gymkhana" ? "1.7fr" : null;
        const bestLapSize = sessionMode == "gymkhana" ? null : "120px";
        const medianSize = sessionMode == "gymkhana" ? null : "100px";
        const lapsSize = sessionMode == "gymkhana" ? null : "100px";
        const userSize = sessionClassificationMode == "teams" ? "3.75fr" : null;
        return [
            {
                sortKey: "rowID",
                columnName: dict("table.head.rowID"),
                type: "number",
                size: "50px",
                value: (stintData: IStintData) => {
                    const stintIndex = props.availableStintsIDs?.indexOf(stintData._id)
                    if (stintIndex === -1 || stintIndex === undefined) {
                        return undefined
                    }
                    return stintIndex + 1 + "."
                }
            },
            {
                dataKey: "user",
                sortKey: "user.nick",
                columnName: dict("table.head.driver"),
                type: "driver",
                size: userSize
            },
            {
                sortKey: "enterData.car_name",
                columnName: dict("table.head.car"),
                type: "stint-car",
                size: "3.75fr"
            },
            {
                sortKey: "enterData.engine_type",
                columnName: dict("table.head.engine"),
                type: "text",
                size: "2.75fr",
                value: (stintData: IStintData) => {
                    return dict("drift.engine." + stintData.enterData.engine_type)
                }
            },
            {
                sortKey: "enterData.tuning_type",
                columnName: dict("table.head.tuning"),
                type: "text",
                size: "2.75fr",
                value: (stintData: IStintData) => {
                    return DriftUtils.getTuningWithoutEngine(
                        dict("drift.tuning." + stintData.enterData.tuning_type))
                }
            },
            {
                sortKey: "drivenLaps",
                columnName: dict("table.head.laps"),
                type: "custom",
                size: lapsSize,
                customCell: (key, stintData) => {
                    return <TableValueCell
                        key={key}
                        type="number"
                        align="right"
                        warning={RaceDirectorUtils.hasLapsOffset(stintData, sessionData)}
                        value={RaceDirectorUtils.drivenLaps(stintData, sessionData, true)}
                        style={sessionMode == "race" ? "bold" : "normal"}/>
                }
            },
            {
                sortKey: "drivenTime",
                columnName: dict("table.head.time"),
                type: "custom",
                size: "120px",
                customCell: (key, stintData) => {
                    return <TableValueCell
                        key={key}
                        type="number"
                        align="right"
                        warning={RaceDirectorUtils.hasTimeOffset(stintData, sessionData)}
                        value={RaceDirectorUtils.drivenTime(stintData, sessionData, true)}
                        style={sessionMode == "race" ? "bold" : "normal"}/>
                }
            },
            {
                sortKey: "bestLapTime",
                columnName: dict("table.head.bestLap"),
                type: bestLaptimeColumnType(),
                size: bestLapSize,
                align: "right",
                value: (stintData: IStintData) => {
                    return TimeUtils.formatDrivenTime(stintData.bestLapTime)
                }
            },
            {
                sortKey: "medianLapTime",
                columnName: dict("table.head.median"),
                type: "number",
                size: medianSize,
                align: "right",
                value: (stintData: IStintData) => {
                    return TimeUtils.formatDrivenTime(stintData.medianLapTime)
                }
            },
            {
                sortKey: "score",
                columnName: dict("table.head.score"),
                type: "number-bold",
                align: "right",
                size: scoreSize,
                value: (stintData: IStintData) => {
                    return stintData.score?.toString()
                }
            },
            {
                type: "custom",
                size: hasYoutubeURLs() ? "80px" : null,
                columnName: dict("table.head.youtube"),
                align: "center",
                sortKey: "youtubeURL",
                customCell: (key, stintData) => {
                    return <ContentLayout key={key} justifyItems="center">
                        {stintData.youtubeURL &&
                            <IconButton
                                type="youtube"
                                stopPropagation={true}
                                onClick={() => YouTubeUtils.gotoYoutubeURL(stintData.youtubeURL)}/>}
                    </ContentLayout>
                }
            },
            {
                dataKey: "state",
                sortKey: "state",
                columnName: dict("table.head.state"),
                type: "state",
                align: "center",
                size: "100px"
            },
            {
                dataKey: "approvalState",
                sortKey: "stintNumber",
                type: "custom",
                size: sessionUseStintApproval ? "40px" : null,
                customCell: (key, stintData) => {
                    return <ApprovalStateIcon key={key} state={stintData.approvalState}/>
                }
            },
            {
                type: "stint-menu",
                size: "40px"
            }
        ];
    }

    function hasYoutubeURLs(): boolean {
        return props.stints.some(stintData => stintData.youtubeURL != null)
    }

    function bestLaptimeColumnType(): TableCellType {
        switch (sessionMode) {
            case "lap":
                return "number-bold"
        }
        return "number"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="stints-overview"
            gap="small">
            <Table<IStintData>
                style="standard"
                rowsData={props.stints}
                showFilter={props.stints.length > 1}
                sortKey={"rowID"}
                sortDirection={"desc"}
                visibleRows={25}
                onRowClick={props.onStintClick}
                columnsDefinition={columsDefinition()}/>
            {showPlanUpgradeInfo &&
                <StintsOverviewPlanUpgradeInfo
                    availableStintsLength={props.availableStintsIDs?.length}
                    accessableStintsLength={maxAccessableStints}/>}
        </ContentLayout>
    );

}
