import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * SessionPointsValditation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionPointsValditation {

    static get STRING_INPUT_REGEXP() {
        return /^(\d{1,3})(-\d{1,3})*$/;
    }

    static get REGEXP() {
        return /^(\d{1,3})$/;
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: value => SessionPointsValditation.isValid(value),
                message: errorCode ?? ErrorCode.SESSION_POINTS_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        return SessionPointsValditation.REGEXP.test(value?.toString());
    }
}
