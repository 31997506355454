import * as React from "react";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {DriverInfo} from "../../../info/driver/DriverInfo";
import {IntersectingPixel} from "../../../utils/observer/IntersectingPixel";
import {useMainframeSystemContentStyles} from "../../../../hooks/useMainframeSystemContentStyles";
import {useState} from "react";

/******************************************************************
 * TableDriverCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableDriverCell(props: {
    user: IUserData
    sticky?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {mainFramePaddingLeft} = useMainframeSystemContentStyles()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSticky, setIsSticky] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-driver-cell"
            data-sticky-cell={props.sticky}
            data-is-sticky={isSticky}>
            {props.sticky &&
                <IntersectingPixel
                    showRedPixel={false}
                    leftOffset={-mainFramePaddingLeft - 5}
                    onIntersectionChange={isIntersecting => setIsSticky(!isIntersecting)}/>}
            <DriverInfo
                user={props.user}
                size="medium"
                theme="dark"/>
        </div>
    );

}
