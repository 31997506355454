import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {Headline} from "../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";

/******************************************************************
 * LogoutModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LogoutModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="logout-modal"
            closeAction={() => state.showLogout.setValue(false)}
            width="medium">
            <Headline
                text={dict("logout.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    text={dict("logout.modal.infoText")}
                    size="small"/>
                <ContentLayout gap="small">
                    <LabelButton
                        label={dict("logout.button.label")}
                        style="secondary"
                        onClick={() => api.user.logout()}/>
                    <LabelButton
                        label={dict("logout.logoutFromAllDevices.button.label")}
                        style="primary"
                        onClick={() => api.user.logout(true)}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
