import * as React from "react";

/******************************************************************
 * SaveEditIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SaveEditIcon(props: {
    scale?: number
}) {

    return (
        <div className="save-edit-icon">
            <svg width={24 * (props.scale ?? 1)} height={23 * (props.scale ?? 1)} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 11.7725C20 16.1908 16.4183 19.7725 12 19.7725C7.58171 19.7725 4 16.1908 4 11.7725C4 7.35417 7.58171 3.77246 12 3.77246C16.4183 3.77246 20 7.35417 20 11.7725ZM11.0746 16.0084L17.0101 10.0729C17.2117 9.87136 17.2117 9.54456 17.0101 9.34301L16.2802 8.61311C16.0787 8.41153 15.7519 8.41153 15.5503 8.61311L10.7097 13.4537L8.44971 11.1937C8.24816 10.9922 7.92135 10.9922 7.71977 11.1937L6.98987 11.9236C6.78832 12.1252 6.78832 12.452 6.98987 12.6535L10.3447 16.0084C10.5463 16.2099 10.8731 16.2099 11.0746 16.0084V16.0084Z" fill="white"/>
            </svg>
        </div>
    );

}
