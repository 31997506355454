import * as React from "react";

/******************************************************************
 * LiveIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LiveIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="live-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.0153809" y="6.45898" width="19.9693" height="8.98193" rx="4.49097" fill="var(--red-100)"/>
                <path
                    d="M3.5816 8.70723H4.7696L4.1216 11.9652H6.1256L5.9336 12.9072H2.7416L3.5816 8.70723ZM7.24371 8.70723H8.43171L7.59171 12.9072H6.40371L7.24371 8.70723ZM13.5192 8.70723L10.8852 12.9072H9.71519L8.76119 8.70723H9.97919L10.5792 11.4372L12.2952 8.70723H13.5192ZM14.7551 9.62523L14.6171 10.3332H16.4831L16.3091 11.2212H14.4371L14.2871 11.9892H16.4891L16.2971 12.9072H12.9311L13.7711 8.70723H17.0591L16.8731 9.62523H14.7551Z"
                    fill="white"/>
            </svg>
        </div>
    );

}
