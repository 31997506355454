import * as React from "react";
import {Children, ReactElement, useEffect, useState} from "react";
import {TabBarItem} from "./TabBarItem";
import {ITabbedContentChildProps} from "./ITabbedContentChildProps";
import {ContentLayout} from "../content/ContentLayout";
import {ScrollBox} from "../../utils/scrollbox/ScrollBox";

/******************************************************************
 * TabbedContent
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TabbedContent(props: {
    showContentByItemName?: string
    showContentByID?: number
    style?: "normal" | "small",
    contentHeight?: "normal" | "stretch",
    children?: ReactElement | ReactElement[],
    onTabChanged?: (name: string) => void
}) {

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [selectedID, setSelectedID] = useState<number>(0)
    const [style] = useState<"normal" | "small">(props.style ?? "normal")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.showContentByItemName) return;
        Children.forEach(props.children, (child: ReactElement, i) => {
            if (child?.props && child.props.tabbedContent.name == props.showContentByItemName) {
                setSelectedID(i)
            }
        })
    }, [props.showContentByItemName])

    useEffect(() => {
        setSelectedID(props.showContentByID ?? 0)
    }, [props.showContentByID])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="tabbed-content"
            fullHeight={props.contentHeight == "stretch" ? true : null}
            rowTemplate={props.contentHeight == "stretch" ? "min-content 1fr" : null}
            gap={style == "normal" ? "medium" : "small"}>
            <nav
                className="tabbed-content-nav"
                data-style={style}>
                <ScrollBox
                    scrollX={true}
                    scrollY={false}>
                    {Children.map(props.children, (child, i) => {
                        const tabbedContent: ITabbedContentChildProps = child?.props?.tabbedContent;
                        if (!tabbedContent || tabbedContent.disabled) return null;
                        return <TabBarItem
                            key={tabbedContent.name}
                            size={style}
                            name={tabbedContent.name}
                            selected={i == selectedID}
                            onClick={() => {
                                props.onTabChanged?.(tabbedContent.name)
                                if (tabbedContent.onClick) {
                                    tabbedContent.onClick(i)
                                    return
                                }
                                setSelectedID(i)
                            }
                            }
                        />
                    })}
                </ScrollBox>
            </nav>
            <>{Children.toArray(props.children)[selectedID]}</>
        </ContentLayout>
    );

}
