import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";

/******************************************************************
 * TableLeaderboardJokerLapCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardJokerLapCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue(): string {
        const session: ISessionData = props.entry.session
        let drivenJokerLaps = getDrivenJokerLaps()
        const numJokerLaps = session.setup.numJokerLaps ?? 0
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        switch (props.entry.type) {
                            case "secondary-team":
                                return drivenJokerLaps.toString()
                        }
                }
        }
        return drivenJokerLaps + "/" + numJokerLaps
    }

    function secondaryValue(): string {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        switch (props.entry.type) {
                            case "secondary-team":
                                return undefined
                        }
                }
        }
        const excessJokerLaps = computeExcessJokerLaps()
        if (excessJokerLaps > 0) {
            const penaltyTime = excessJokerLaps * session.setup.jokerLapPenalty
            return "+" + penaltyTime + "s"
        }
        return undefined
    }

    function getDrivenJokerLaps() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "async":
                        if (entry.state === "finished") {
                            return entry.bestDrivenTimeStint.drivenJokerLaps ?? 0
                        }
                        return entry.latestStint.drivenJokerLaps ?? 0
                    case "sync":
                        return entry.overall.drivenJokerLaps ?? 0
                }
        }
        return 0
    }

    function computeExcessJokerLaps(): number {
        const session: ISessionData = props.entry.session
        switch (session.setup.mode) {
            case "race":
                switch (session.setup.timing) {
                    case "sync":
                        switch (props.entry.type) {
                            case "secondary-team":
                                return 0
                        }
                }
        }
        let drivenJokerLaps = getDrivenJokerLaps()
        const numJokerLaps = session.setup.numJokerLaps ?? 0
        const excessJokerLaps = drivenJokerLaps - numJokerLaps
        if (excessJokerLaps > 0) {
            return excessJokerLaps
        }
        return 0
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-joker-lap-cell"
            data-size={size()}
            data-theme={theme()}
            data-has-penalty={computeExcessJokerLaps() > 0}>
            <div className="table-leaderboard-joker-lap-cell-primary">
                {primaryValue()}
            </div>
            {secondaryValue() && <div className="table-leaderboard-joker-lap-cell-secondary">
                {secondaryValue()}
            </div>}
        </div>
    );

}
