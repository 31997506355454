import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../ui/text/headings/Headline";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";

/******************************************************************
 * ReloadFrontendModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ReloadFrontendModal() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function reloadFrontend() {
        window.location.reload();
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            type="error">
            <ContentLayout gap="none">
                <Headline
                    text={dict("error.reload.frontend.title")} style="modal"/>
                <ContentLayout>
                    <InfoText
                        text={dict("error.reload.frontend.text")}/>
                    <LabelButton
                        label={dict("error.reload.frontend.reload.button.label")}
                        style="primary"
                        onClick={reloadFrontend}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
