import * as React from "react";

/******************************************************************
 * PublicIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PublicIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="public-icon">
            <svg width={18 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 0.53479C4.032 0.53479 0 4.56679 0 9.53479C0 14.5028 4.032 18.5348 9 18.5348C13.968 18.5348 18 14.5028 18 9.53479C18 4.56679 13.968 0.53479 9 0.53479ZM8.1 16.6718C4.545 16.2308 1.8 13.2068 1.8 9.53479C1.8 8.97679 1.872 8.44579 1.989 7.92379L6.3 12.2348V13.1348C6.3 14.1248 7.11 14.9348 8.1 14.9348V16.6718ZM14.31 14.3858C14.076 13.6568 13.41 13.1348 12.6 13.1348H11.7V10.4348C11.7 9.93979 11.295 9.53479 10.8 9.53479H5.4V7.73479H7.2C7.695 7.73479 8.1 7.32979 8.1 6.83479V5.03479H9.9C10.89 5.03479 11.7 4.22479 11.7 3.23479V2.86579C14.337 3.93679 16.2 6.51979 16.2 9.53479C16.2 11.4068 15.48 13.1078 14.31 14.3858Z" fill="white"/>
            </svg>
        </div>
    );

}
