import * as React from "react";
import {EventStateType} from "../../../../../shared/types/EventStateType";
import {SessionStateType} from "../../../../../shared/types/SessionStateType";
import {useServices} from "../../../hooks/useServices";
import {CarStateType} from "../../../../../shared/types/CarStateType";
import {SessionDriverStateType} from "../../../../../shared/types/SessionDriverStateType";
import {ResultStateType} from "../../../../../shared/types/ResultStateType";
import {LogLevelType} from "../../../../../shared/types/LogLevelType";

/******************************************************************
 * StateInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StateInfo(props: {
    state:
        | EventStateType
        | SessionStateType
        | CarStateType
        | SessionDriverStateType
        | ResultStateType
        | LogLevelType
    text?: string,
    onClick?: (currentState) => void,
    stopPropagation?: boolean,
    compact?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(e: React.MouseEvent<HTMLDivElement>) {
        if (!props.onClick) {
            return
        }
        if (props.stopPropagation) {
            e.stopPropagation()
        }
        props.onClick(props.state)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="state-info"
            onClick={onClick}
            data-clickable={!!props.onClick}
            data-state={props.state}
            data-compact={props.compact}>
            {props.compact ? "" : props.text ?? dict("state." + props.state)}
        </div>
    );

}
