import {useEffect, useState} from "react";
import {CheckoutEventsData} from "@paddle/paddle-js";
import {useServices} from "./useServices";

/******************************************************************
 * usePaddleJS
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function usePaddleJS() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {paddle} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [checkoutData, setCheckoutData] = useState<CheckoutEventsData>()
    const [paddleAvailable, setPaddleAvailable] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        paddle.init().then(() => setPaddleAvailable(true))
        paddle.onCheckoutDataSignal.add(setCheckoutData)
        return () => {
            paddle.onCheckoutDataSignal.remove(setCheckoutData)
        }
    }, [])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return {
        checkoutData,
        paddleAvailable
    }

}
