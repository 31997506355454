/******************************************************************
 * UserRoleType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const USER_ROLES = ["member", "judge", "admin"] as const;

export type UserRoleType = typeof USER_ROLES[number];

export function isUserRoleType(value: any): value is UserRoleType {
    return USER_ROLES.includes(value);
}
