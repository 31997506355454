import * as React from "react";
import {useState} from "react";
import {ContextMenu} from "../ContextMenu";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useServices} from "../../../hooks/useServices";
import {SessionStateType} from "../../../../../shared/types/SessionStateType";
import {ContextMenuState} from "../elements/ContextMenuState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {useEventState} from "../../../hooks/useEventState";
import {useGroupState} from "../../../hooks/useGroupState";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {KeyController} from "../../keyboard/KeyController";
import {useSessionState} from "../../../hooks/useSessionState";
import {InfoText} from "../../text/infotext/InfoText";
import {LabelButton} from "../../buttons/label/LabelButton";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";

/******************************************************************
 * SessionStateSelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionStateSelector(props: {
    sessionData: ISessionData,
    compactStateInfo: boolean,
    preventShortcutAction?: boolean,
    onToggle?: (showMenu: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {eventState} = useEventState();
    const {sessionID} = useSessionState()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {hasTouch} = useDeviceInfo();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(false);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changeState(state: SessionStateType) {
        setIsBusy(true)
        await api.session.update(props.sessionData._id, {state: state})
        setIsBusy(false)
    }

    async function removeActiveFromUntil() {
        setIsBusy(true)
        await api.session.update(sessionID, {
            setup: {
                activeUntil: null,
                activeFrom: null,
                startTime: null
            }
        })
        setIsBusy(false)
    }

    function state() {
        if (eventState == "upcoming") {
            if (props.sessionData?.state == "running") {
                return "upcoming"
            }
        }
        return props.sessionData?.state
    }

    function enabled() {
        return (isRouteOwnerAuthUser || isGroupAdmin) && eventState != "finished"
    }

    function enableKeyShortcuts(state: SessionStateType): boolean {
        if (hasTouch) return false
        if (isAutoState()) return false
        if (!enabled()) return false
        if (props.sessionData?._id != sessionID) return false
        switch (state) {
            case "upcoming":
                return showUpcomingStateSelector()
        }
        return true
    }

    function isAutoState(): boolean {
        const {hasActiveFrom, hasActiveUntil, hasStartTime} = getTimeStates()
        if (hasActiveUntil && (hasActiveFrom || hasStartTime)) {
            return true
        }
        if (hasActiveFrom || hasStartTime) {
            if (hasActiveFrom) {
                return TimeUtils.isFuture(props.sessionData?.setup.activeFrom)
            }
            if (hasStartTime) {
                const startTime = new Date(props.sessionData?.setup.startTime)
                startTime.setTime(startTime.getTime() - SharedConfig.SESSION_TIME_OFFSET_BEFORE_AUTO_START)
                return TimeUtils.isFuture(startTime.toISOString())
            }
        }
        if (hasActiveUntil) {
            return true
        }
        return false
    }

    function getTimeStates(): { hasActiveFrom: boolean, hasActiveUntil: boolean, hasStartTime: boolean } {
        return {
            hasActiveFrom: TimeUtils.isValidDate(props.sessionData?.setup.activeFrom),
            hasActiveUntil: TimeUtils.isValidDate(props.sessionData?.setup.activeUntil),
            hasStartTime: TimeUtils.isValidDate(props.sessionData?.setup.startTime)
        }
    }

    function showAutoStateStartTimeInfo(): boolean {
        const {hasActiveFrom, hasStartTime} = getTimeStates()
        if (hasActiveFrom) return false
        return hasStartTime
    }

    function showAutoStateEndTimeInfo(): boolean {
        const {hasActiveUntil} = getTimeStates()
        return hasActiveUntil
    }

    function showAutoStateOpenInfo(): boolean {
        const {hasActiveFrom} = getTimeStates()
        return hasActiveFrom
    }

    function autoEndInfoText() {
        if (TimeUtils.isPast(props.sessionData?.setup.activeUntil)) {
            return dict("session.stateSelector.auto.endtime.past")
                .replaceAll("{ACTIVE_UNTIL}", TimeUtils.formatDate(props.sessionData?.setup.activeUntil))
        }
        return dict("session.stateSelector.auto.endtime.future")
            .replaceAll("{ACTIVE_UNTIL}", TimeUtils.formatDate(props.sessionData?.setup.activeUntil))
    }

    function autoOpenInfoText() {
        if (TimeUtils.isPast(props.sessionData?.setup.activeFrom)) {
            return dict("session.stateSelector.auto.opentime.past")
                .replaceAll("{ACTIVE_FROM}", TimeUtils.formatDate(props.sessionData?.setup.activeFrom))
        }
        return dict("session.stateSelector.auto.opentime.future")
            .replaceAll("{ACTIVE_FROM}", TimeUtils.formatDate(props.sessionData?.setup.activeFrom))
    }

    function autoStateStartTime() {
        const startTime = new Date(props.sessionData?.setup.startTime)
        const startTimeMinusOffset = startTime.getTime() - SharedConfig.SESSION_TIME_OFFSET_BEFORE_AUTO_START
        const startTimeOffsetInMinutes = SharedConfig.SESSION_TIME_OFFSET_BEFORE_AUTO_START / 1000 / 60
        if (TimeUtils.isPast(props.sessionData?.setup.startTime)) {
            return dict("session.stateSelector.auto.starttime.past")
                .replaceAll("{STARTTIME_MINUS_OFFSET}", TimeUtils.formatDate(startTimeMinusOffset))
                .replaceAll("{STARTTIME_OFFSET}", startTimeOffsetInMinutes.toString())
        }
        return dict("session.stateSelector.auto.starttime.future")
            .replaceAll("{STARTTIME_MINUS_OFFSET}", TimeUtils.formatDate(startTimeMinusOffset))
            .replaceAll("{STARTTIME_OFFSET}", startTimeOffsetInMinutes.toString())
    }

    function showUpcomingStateSelector(): boolean {
        const {hasActiveFrom, hasStartTime} = getTimeStates()
        return !hasActiveFrom && !hasStartTime
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContextMenu
            type="state"
            state={state()}
            compactStateInfo={props.compactStateInfo}
            stopPropagation={true}
            isBusy={isBusy}
            onToggle={props.onToggle}
            enabled={enabled()}>
            {isAutoState() && <>
                {showAutoStateStartTimeInfo() &&
                    <InfoText
                        text={autoStateStartTime()}
                        size="tiny"
                        style="warning"
                        width="tiny"
                        disableSelect={true}/>}
                {showAutoStateOpenInfo() &&
                    <InfoText
                        text={autoOpenInfoText()}
                        size="tiny"
                        style="warning"
                        width="tiny"
                        disableSelect={true}/>}
                {showAutoStateEndTimeInfo() &&
                    <InfoText
                        text={autoEndInfoText()}
                        size="tiny"
                        style="warning"
                        width="tiny"
                        disableSelect={true}/>}
                <InfoText
                    text={dict("session.stateSelector.auto.remove")}
                    size="tiny"
                    style="warning"
                    width="tiny"
                    disableSelect={true}/>
                <LabelButton
                    label={dict("session.stateSelector.auto.remove.button")}
                    style="primary-tiny"
                    onClick={removeActiveFromUntil}/>
            </>}
            {!isAutoState() && <>
                {showUpcomingStateSelector() && <>
                    <ContextMenuState
                        state="upcoming"
                        selected={props.sessionData?.state == "upcoming"}
                        keyShortcut={enableKeyShortcuts("upcoming") ? FrontendConfig.SHORTCUT_SESSION_STATE_UPCOMING : null}
                        onClick={() => changeState("upcoming")}/>
                    <ContextMenuDivider style="small"/>
                </>}
                {!showUpcomingStateSelector() &&
                    <InfoText
                        text={dict("session.stateSelector.upcoming.not.available")}
                        size="tiny"
                        style="warning"
                        width="tiny"
                        disableSelect={true}/>}
                {FrontendConfig.SHOW_SESSION_READY_STATE && <>
                    <ContextMenuState
                        state="ready"
                        selected={props.sessionData?.state == "ready"}
                        onClick={() => changeState("ready")}/>
                    <ContextMenuDivider style="small"/>
                </>}
                {eventState == "live" && <>
                    <ContextMenuState
                        state="running"
                        selected={props.sessionData?.state == "running"}
                        keyShortcut={enableKeyShortcuts("running") ? FrontendConfig.SHORTCUT_SESSION_STATE_OPEN : null}
                        onClick={() => changeState("running")}/>
                    <ContextMenuDivider style="small"/>
                </>}
                {eventState != "upcoming" &&
                    <ContextMenuState
                        state="finished"
                        selected={props.sessionData?.state == "finished"}
                        keyShortcut={enableKeyShortcuts("finished") ? FrontendConfig.SHORTCUT_SESSION_STATE_FINISHED : null}
                        onClick={() => changeState("finished")}/>}
            </>}
        </ContextMenu>
        <KeyController
            enabled={enableKeyShortcuts("upcoming") && !props.preventShortcutAction}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_UPCOMING}
            onKey={() => changeState("upcoming")}/>
        <KeyController
            enabled={enableKeyShortcuts("running") && !props.preventShortcutAction && eventState == "live"}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_OPEN}
            onKey={() => changeState("running")}/>
        <KeyController
            enabled={enableKeyShortcuts("finished") && !props.preventShortcutAction && eventState != "upcoming"}
            preventOnInputElement={true}
            shortcut={FrontendConfig.SHORTCUT_SESSION_STATE_FINISHED}
            onKey={() => changeState("finished")}/>
    </>

}
