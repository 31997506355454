import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {UploadableImage} from "../uploadable/UploadableImage";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {useEventState} from "../../../hooks/useEventState";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {EventDateInfo} from "../../info/date/EventDateInfo";
import {useGroupState} from "../../../hooks/useGroupState";
import {Banderole} from "../../info/banderole/Banderole";
import {PictureFramingType} from "../Picture";
import {useElementSize} from "../../../hooks/useElementSize";

/******************************************************************
 * EventSidebarImage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventSidebarImage(props: {
    enableMediaView?: boolean
    editable?: boolean
    hideDateInfo?: boolean
    framing?: PictureFramingType
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const imageRef = useRef<HTMLImageElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {isRouteOwnerAuthUser, routeOwnerAvatar} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {eventID, eventImage, eventDate, eventDateUntil, eventOwnerType} = useEventState();
    const [imageElementWidth]= useElementSize(imageRef)

    /* ----------------------------------------------------------------
    * STATES
    * --------------------------------------------------------------*/

    const [imageSrc, setImageSrc] = useState<string>();
    const [isTraining] = useState<boolean>(eventOwnerType === "user")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateImageSource()
    }, [eventImage, imageElementWidth])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function upload(formData: FormData): Promise<Response> {
        formData.append("eventID", eventID);
        return await api.event.uploadImage(formData);
    }

    async function updateImageSource() {
        if (eventImage || routeOwnerAvatar) {
            setImageSrc(api.file.getImageURLByFile(eventImage ?? routeOwnerAvatar, imageElementWidth))
            return;
        }
        setImageSrc(require(isTraining
            ? "ASSETS/images/default-training-image.png"
            : "ASSETS/images/default-event-image.png"))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            key={eventID}
            className="event-image"
            style={{
                aspectRatio: SharedConfig.IMAGE_EVENT_HERO_ASPECT_RATIO,
                position: "relative"
            }}>
            {eventDate && !props.hideDateInfo &&
                <EventDateInfo
                    date={eventDate}
                    dateUntil={eventDateUntil}/>}
            <UploadableImage
                ref={imageRef}
                uploadMethode={upload}
                framing={props.framing}
                showUploadButton={!eventImage}
                mediaViewerFile={props.enableMediaView ? eventImage : null}
                editable={props.editable && (isRouteOwnerAuthUser || isGroupAdmin)}
                imageSrc={imageSrc}
                buttonLabel={dict(isTraining
                    ? "form.upload.event.training"
                    : "form.upload.event.select")}/>
            {isTraining &&
                <Banderole
                    label={dict("training.banderole.label")}
                    color="red"
                    position="local-left"/>}
        </div>
    );

}
