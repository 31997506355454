import * as React from "react";
import {PaddlePriceIntervalType} from "../../../../../shared/types/PaddlePriceIntervalType";
import {useServices} from "../../../hooks/useServices";
import {AmountUtils} from "../../../../utils/AmountUtils";

/******************************************************************
 * PlanAmount
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PlanAmount(props: {
    amount: number
    currency: string
    interval: PaddlePriceIntervalType
    size?: "small" | "large"
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="plan-amount"
            data-size={props.size ?? "large"}>
            {props.amount > 0
                ? <div>
                    <div>
                        <span className="amount-value">{AmountUtils.format(props.amount, props.currency)}</span>
                        <span className="amount-interval">{dict("amount.interval." + props.interval)}</span>
                    </div>
                    <div className="cancel-period">{dict("plan.cancel." + props.interval)}</div>
                </div>
                : <span className="amount-free">{dict("amount.free")}</span>}
        </div>
    );

}
