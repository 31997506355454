import * as React from "react";
import {useRef} from "react";
import {useServices} from "../../../../hooks/useServices";
import {useTrackState} from "../../../../hooks/useTrackState";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {UploadableImage} from "../../../../ui/image/uploadable/UploadableImage";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../hooks/useGroupState";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {Input} from "../../../../ui/form/elements/input/Input";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {Headline} from "../../../../ui/text/headings/Headline";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";

/******************************************************************
 * TrackConstructSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackConstructSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {trackID, trackInstructionImage, trackInstructionInfo} = useTrackState()
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState()
    const [isMobileMode] = useMobileStyle(rootRef, 600)
    const [deleting, setDeleting] = React.useState(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function uploadInstructionImage(formData: FormData): Promise<Response> {
        formData.append("trackID", trackID);
        return await api.track.uploadInstructionImage(formData);
    }

    async function updateInstructionInfo(info: string): Promise<Response> {
        await api.track.update(trackID, {instructionInfo: info})
        return null
    }

    async function deleteInstructionImage() {
        setDeleting(true)
        await api.track.deleteInstructionImage(trackID);
        setDeleting(false)
    }

    function editable(): boolean {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-general-settings"
            ref={rootRef}
            columns={isMobileMode ? 1 : 2}>
            {(editable() || trackInstructionInfo) &&
                <Input
                    type="textarea"
                    label={dict("track.instructionInfo.label")}
                    readonly={!editable()}
                    defaultValue={trackInstructionInfo}
                    action={updateInstructionInfo}/>}
            <ContentLayout
                gap="small"
                framed={true}>
                <ContentLayout
                    columnTemplate="auto min-content"
                    justifyContent="space-between"
                    alignItems="center">
                    <Headline
                        text={dict("track.instructionImage.label")}
                        style="h6"/>
                    {trackInstructionImage && editable() &&
                        <LabelButton
                            label={dict("track.instructionImage.delete.label")}
                            style="secondary-small"
                            progressing={deleting}
                            onClick={deleteInstructionImage}/>}
                </ContentLayout>
                <div style={{
                    aspectRatio: trackInstructionImage?.metaData.aspectRatio ?? SharedConfig.IMAGE_TRACK_ASPECT_RATIO,
                    position: "relative"
                }}>
                    <UploadableImage
                        uploadMethode={uploadInstructionImage}
                        showUploadButton={!trackInstructionImage}
                        editable={editable()}
                        framing="rounded-8"
                        imageSrc={api.file.getImageURLByFile(trackInstructionImage)}
                        fit="contain"
                        buttonLabel={dict("form.upload.track.instruction.select")}/>
                </div>
            </ContentLayout>

        </ContentLayout>
    );

}
