import * as React from "react";

/******************************************************************
 * RestoreIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RestoreIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="add-icon">
            <svg width={16 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.13905 0.0927734C5.34857 0.0927734 2.28571 3.16325 2.28571 6.94992H0L2.96762 9.91754L3.02095 10.028L6.09524 6.94992H3.80952C3.80952 4.00515 6.1981 1.61658 9.14286 1.61658C12.0876 1.61658 14.4762 4.00515 14.4762 6.94992C14.4762 9.89468 12.0876 12.2832 9.14286 12.2832C7.66857 12.2832 6.33905 11.6813 5.37524 10.7175L4.29714 11.7956C5.53524 13.0375 7.24571 13.8071 9.13905 13.8071C12.9295 13.8071 16 10.7366 16 6.94992C16 3.16325 12.9295 0.0927734 9.13905 0.0927734ZM8.38095 3.9023V7.71182L11.6419 9.64706L12.1905 8.72134L9.52381 7.14039V3.9023H8.38095Z" fill="white"/>
            </svg>
        </div>
    );

}
