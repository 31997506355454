import * as React from "react";
import {useEffect, useState} from "react";
import {Article} from "../Article";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../text/headings/Headline";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {Spacer} from "../../utils/spacer/Spacer";
import {IArticleData} from "../../../../../shared/models/IArticleData";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * ArticleReaderBox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleReaderBox(props: {
    type: "group" | "event"
    articleID: MongoObjectIDType
    onVisibilityChange?: (isCollapsed: boolean) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isCollapsed, setIsCollapsed] = useState<boolean>(visibiliyState())
    const [version, setVersion] = useState<number>(0)
    const [hasContent, setHasContent] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        function updateVersion() {
            if (!state.showGroupSettings.getValue() || !state.showEventSettings.getValue()) {
                setVersion(prevState => prevState + 1)
            }
        }
        state.showGroupSettings.onChangeSignal.add(updateVersion)
        state.showEventSettings.onChangeSignal.add(updateVersion)
        return () => {
            state.showGroupSettings.onChangeSignal.remove(updateVersion)
            state.showEventSettings.onChangeSignal.remove(updateVersion)
        }
    }, [])

    useEffect(() => {
        localStorage.setItem(FrontendConfig.LOCALSTORAGE_ARTICLE_READER_BOX_VISIBILITY, isCollapsed ? "collapsed" : "expanded")
        props.onVisibilityChange?.(isCollapsed)
    }, [isCollapsed])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function toggleVisibilty() {
        setIsCollapsed(!isCollapsed)
    }

    function visibiliyState() {
        return localStorage.getItem(FrontendConfig.LOCALSTORAGE_ARTICLE_READER_BOX_VISIBILITY) === "collapsed"
    }

    function onArticleData(article: IArticleData) {
        if (article?.abstract?.length > 0) {
            return setHasContent(true)
        }
        if (article?.blocks?.length > 0) {
            return setHasContent(true)
        }
        setHasContent(false)
    }

    function title(): string {
        switch (props.type) {
            case "group":
                return dict("group.about.title")
            case "event":
                return dict("event.about.title")

        }
    }


    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="article-reader-box"
            key={version}
            hide={!hasContent}
            framed={isCollapsed}>
            <ContentLayout
                justifyItems="end"
                alignItems="center"
                justifyContent="space-between"
                columnTemplate={isCollapsed ? "1fr min-content" : null}>
                {isCollapsed &&
                    <Headline
                        text={title()}
                        style={"h5-underlined"}/>}
                <LabelButton
                    label={isCollapsed
                        ? dict("article.reader.box.expand")
                        : dict("article.reader.box.collapse")}
                    style={isCollapsed ? "primary-small" : "secondary-small"}
                    onClick={toggleVisibilty}/>
            </ContentLayout>
            <ContentLayout
                justifyItems={"center"}
                hide={isCollapsed}>
                <Article
                    key={version}
                    hideTypeSelect={true}
                    hideTitle={true}
                    hideSubTitle={true}
                    articleID={props.articleID}
                    style="compact"
                    onArticleData={onArticleData}/>
                <Spacer height="normal"/>
            </ContentLayout>
        </ContentLayout>
    )

}
