import {IMailBodyBlockData, IMailData, MailBodyBlockType} from "../models/IMailData";
import {IUserData} from "../models/IUserData";
import {SharedConfig} from "../config/SharedConfig";

/******************************************************************
 * MailUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class MailUtils {

    static getBodyBlockType(block: IMailBodyBlockData): MailBodyBlockType | "undefined" {
        if (!block) {
            return null;
        }
        return (Object.keys(block)?.find(key => {
            return key !== '_id' && block[key] !== null && block[key] !== undefined;
        }) as MailBodyBlockType) ?? "undefined";
    }

    static isUnknownBlockType(block: IMailBodyBlockData): boolean {
        if (!block) {
            return false
        }
        switch (MailUtils.getBodyBlockType(block)) {
            case "image":
            case "markdown":
            case "verificationCode":
                return false;
        }
        return true;
    }

    static replacePlaceholders(props: {
        renderedMail: string,
        recipientNick?: string
        recipientVerificationCode?: string
        baseURL?: string
        plansRoute?: string
        newsletterRoute?: string
    }): string {
        if (!props.renderedMail || typeof props.renderedMail !== "string") {
            return ""
        }
        let renderedMail = props.renderedMail
        if (props.recipientNick)  {
            renderedMail = renderedMail.replaceAll("{NICK}", props.recipientNick)
        }
        if (props.recipientVerificationCode) {
            renderedMail = renderedMail.replaceAll("{VCODE}", props.recipientVerificationCode)
        }
        if (props.baseURL) {
            renderedMail = renderedMail.replaceAll("{BASE_URL}", props.baseURL)
        }
        if (props.plansRoute) {
            renderedMail = renderedMail.replaceAll("{PLANS_ROUTE}", props.plansRoute)
        }
        if (props.newsletterRoute) {
            renderedMail = renderedMail.replaceAll("{NEWSLETTER_ROUTE}", props.newsletterRoute)
        }
        return renderedMail
    }

    static filterRecipientsForMailLanguage(mailData: IMailData, recipients: IUserData[]): IUserData[] {
        if (!mailData || !recipients) {
            return null
        }
        const mailLanguage = mailData.language
        if (!mailLanguage) {
            return recipients
        }
        return recipients.filter(recipient => (recipient.language ?? SharedConfig.DEFAULT_LANGUAGE) === mailLanguage)
    }

}
