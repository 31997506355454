import * as React from "react";
import {useRef} from "react";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {ContextMenuGroup} from "../elements/ContextMenuGroup";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {useForceUpdate} from "../../../hooks/useForceUpdate";
import {useSessionState} from "../../../hooks/useSessionState";
import {useStintAnnouncer} from "../../../audio/annoucer/useStintAnnouncer";
import {IStintData} from "../../../../../shared/models/IStintData";
import {StintSpeakerMode} from "../../../../utils/types/StintSpeakerMode";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * StintSpeakerContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintSpeakerContextMenu(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, speaker} = useServices()
    const forceUpdate = useForceUpdate()
    const {sessionMode} = useSessionState()
    const {authUserValidDriverLicense, authUserRole} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * SPEAKER
 	 * --------------------------------------------------------------*/

    useStintAnnouncer({
        stint: props.stint,
        mode: stintSpeakerMode()
    })

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const settings = useRef<StateValue<boolean>[]>([])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function toggle(annoucementSetting: StateValue<boolean>) {
        annoucementSetting.setValue(!annoucementSetting.getValue())
        forceUpdate()
    }

    function speakerDisabled() {
        return settings.current.every(setting => !setting.getValue())
    }

    function setting(
        dictKey: string,
        setting: StateValue<boolean>,
        requiredDriverLicense: DriverLicenseType,
        stintTypes: StintSpeakerMode[] = ["laps", "targets"],
    ) {
        if (!stintTypes.some(type => type === stintSpeakerMode())) {
            return null
        }
        if (!settings.current.some(item => item === setting)) {
            settings.current.push(setting)
        }
        const needHigherLicenseType = DriverLicenseUtils.needHigherLicenseType(
            authUserValidDriverLicense,
            requiredDriverLicense,
            authUserRole
        )
        if (needHigherLicenseType) {
            setting.setValue(false)
        }
        return <ContextMenuSelectable
            label={dict(`speaker.menu.stint.${dictKey}`)}
            onClick={needHigherDriverLicense => needHigherDriverLicense
                ? state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.speaker.announcement")})
                : toggle(setting)}
            stopPropagation={true}
            requiredDriverLicense={requiredDriverLicense}
            selected={setting.getValue()}/>
    }

    function stintSpeakerMode(): StintSpeakerMode {
        switch (sessionMode) {
            case "lap":
            case "race":
                return "laps"
            case "gymkhana":
                return "targets"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType={speakerDisabled() ? "speakerDisabled" : "speaker"}
            iconSize="small">
            <ContextMenuGroup
                name={dict("speaker.menu.common.announcements")}/>
            {setting("invalid", speaker.stint.invalid, "free")}
            {setting("ready", speaker.stint.ready, "free")}
            {setting("started", speaker.stint.started, "free")}
            {setting("finished", speaker.stint.finished, "free")}
            {setting("falseStart", speaker.stint.falseStart, "basic")}
            {setting("drivenTime", speaker.stint.drivenTime, "free")}
            <ContextMenuDivider
                style={"small"}/>
            {stintSpeakerMode() == "laps" && <ContextMenuGroup
                name={dict("speaker.menu.laps.announcements")}/>}
            {setting("bestLap", speaker.stint.bestLap, "basic", ["laps"])}
            {setting("lapTime", speaker.stint.lapTime, "free", ["laps"])}
            {setting("joker", speaker.stint.joker, "basic", ["laps"])}
            {stintSpeakerMode() == "targets" && <ContextMenuGroup
                name={dict("speaker.menu.targets.announcements.label")}/>}
            {setting("targetName", speaker.stint.targetName, "basic", ["targets"])}
            {setting("targetScore", speaker.stint.targetScore, "basic", ["targets"])}
            {setting("totalScore", speaker.stint.totalScore, "free", ["targets"])}
            <ContextMenuDivider
                style={"normal"}/>
            <ContextMenuLabel
                label={dict("speaker.menu.voice.help")}
                icon="help"
                iconScale={0.8}
                onClick={() => state.showHelp.setValue("speaker.menu.stint")}/>
            <ContextMenuLabel
                label={dict("speaker.menu.voice.settings")}
                icon="settings"
                iconScale={0.8}
                onClick={() => state.showSpeakerSettings.setValue(true)}/>
        </ContextMenu>
    );

}
