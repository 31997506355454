import * as React from "react";
import {useServices} from "../../../hooks/useServices";
import {YouTubeUtils} from "../../../../utils/YouTubeUtils";
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo";
import {IconButton} from "../../buttons/icon/IconButton";

/******************************************************************
 * YoutubeThumbnailLink
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function YoutubeThumbnailLink(props: {
    videoID: string
    alt?: string
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="youtube-thumbnail-link">
            {props.videoID
                ? <>
                    <IconButton
                        type="youtube"
                        iconColor="red"
                        size="huge"
                        opacityMode="none"
                        onClick={() => YouTubeUtils.gotoYoutubeURL(props.videoID)}/>
                    <img
                        src={api.proxy.getYoutubeThumbnailURL(props.videoID)}
                        onClick={() => YouTubeUtils.gotoYoutubeURL(props.videoID)}
                        alt={props.alt}/>
                </>
                : <EmptyDataInfo
                    text={dict("youtube.no.url.info")}
                    size="small"/>}
        </div>
    );

}
