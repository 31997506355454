import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {IMailData} from "../../shared/models/IMailData";
import {LanguageType} from "../../shared/types/LanguageType";

/******************************************************************
 * MailAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class MailAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getMailByID(mailID: MongoObjectIDType): Promise<IMailData> {
        const response = await this._frontend.api.request("GET", APIRoute.MAIL, {mailID}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async createMail(isTemplate: boolean, mailLanguage?: LanguageType): Promise<IMailData> {
        const response = await this._frontend.api.request("POST", APIRoute.MAIL, {isTemplate, language: mailLanguage}, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return null
        }
        return await response.json()
    }

    public async sendTestMail(mailID: MongoObjectIDType, overrideRecipientMail?: string): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.MAIL_TEST, {
            mailID,
            overrideRecipientMail
        }, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true
    }

    public async sendMailing(mailID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.MAILING_SEND, {
            mailID
        }, true)
        if (response.status !== 200) {
            const errorMessage = this._frontend.error.createMessage(await response.json())
            this._frontend.state.showMessage.setValue({type: "error", message: errorMessage})
            return false
        }
        return true
    }

    public async render(
        mailID: MongoObjectIDType,
        recipientID: MongoObjectIDType,
        skipFooter?: boolean
    ): Promise<string> {
        const response = await this._frontend.api.request("GET", APIRoute.MAIL_RENDER, {mailID, recipientID, skipFooter}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.text()
    }

    public async deleteMail(mailID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.MAIL, {mailID}, true)
    }

    public async updateMail(mailID: MongoObjectIDType, data: IMailData): Promise<IMailData> {
        const response = await this._frontend.api.request("PATCH", APIRoute.MAIL, {mailID, data}, true)
        return await response.json()
    }

    public async getAllMailings(): Promise<IMailData[]> {
        const type = "mailings"
        const response = await this._frontend.api.request("POST", APIRoute.MAILS, {type}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async getNewsletterMails(): Promise<IMailData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.NEWSLETTER_MAILS, {}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

    public async getAllMailTemplates(): Promise<IMailData[]> {
        const type = "templates"
        const response = await this._frontend.api.request("POST", APIRoute.MAILS, {type}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json()
    }

}
