import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendConfig} from "../core/FrontendConfig";

/******************************************************************
 * ProxyAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ProxyAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public getYoutubeThumbnailURL(videoID: string): string {
        if (!videoID) {
            return null
        }
        return FrontendConfig.BASE_URL + APIRoute.PROXY_YOUTUBE_THUMBNAIL + "?videoID=" + videoID
    }

}
