import * as React from "react";

/******************************************************************
 * CancelEditIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CancelEditIcon(props: {
    scale?: number
}) {
    return (
        <div className="cancel-edit-icon">
            <svg width={24 * (props.scale ?? 1)} height={23 * (props.scale ?? 1)} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3.77246C7.58 3.77246 4 7.35246 4 11.7725C4 16.1925 7.58 19.7725 12 19.7725C16.42 19.7725 20 16.1925 20 11.7725C20 7.35246 16.42 3.77246 12 3.77246ZM16 14.6425L14.87 15.7725L12 12.9025L9.13 15.7725L8 14.6425L10.87 11.7725L8 8.90246L9.13 7.77246L12 10.6425L14.87 7.77246L16 8.90246L13.13 11.7725L16 14.6425Z" fill="white"/>
            </svg>
        </div>
    );

}
