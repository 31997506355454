import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useSimulatorStore} from "../hooks/useSimulatorStore";
import {SimulatorSessionProperty} from "./prop/SimulatorSessionProperty";
import {ISessionData} from "../../../../../../../shared/models/ISessionData";
import {Headline} from "../../../../../ui/text/headings/Headline";

/******************************************************************
 * AdminSimulatorGeneral
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AdminSimulatorGeneral(props: {
    session: ISessionData
    isMobileMode: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {
        gameID, setGameID,
        timeScale, setTimeScale,
        minLapTime, setMinLapTime,
        maxLapTime, setMaxLapTime,
        sectors, setSectors,
        sectorTargetCodes, setSectorTargetCodes,
        finishFailureRate, setFinishFailureRate,
        sectorsFailureRate, setSectorsFailureRate,
    } = useSimulatorStore()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateTimeScale(value): Promise<Response> {
        setTimeScale(value)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            columns={props.isMobileMode ? 1 : 2}
            alignItems="stretch">
            <ContentLayout
                className="admin-simulator-general"
                alignItems="start">
                <ContentLayout columns={props.isMobileMode ? 1 : 2}>
                    <Input
                        type="text"
                        label="Game-ID"
                        size="small"
                        required={true}
                        defaultValue={gameID}
                        onChange={setGameID}/>
                    <Input
                        type="text"
                        defaultValue={timeScale?.toString()}
                        required={true}
                        size="small"
                        pattern={"^(2(\\.0+)?|1(\\.\\d+)?|0\\.[0-9]{1,3}|0\\.0+1)$"}
                        action={updateTimeScale}
                        label="TimeScale"/>
                    <Input
                        type="number"
                        label="MinLapTime"
                        size="small"
                        defaultValue={minLapTime?.toString()}
                        onChange={setMinLapTime}/>
                    <Input
                        type="number"
                        label="MaxLapTime"
                        size="small"
                        defaultValue={maxLapTime?.toString()}
                        onChange={setMaxLapTime}/>
                    <Input
                        type="number"
                        minNumber={1}
                        maxNumber={3}
                        size="small"
                        label="Sectors"
                        defaultValue={sectors?.toString()}
                        onChange={setSectors}/>
                    <Input
                        type="text"
                        label="SectorTargetCodes"
                        size="small"
                        defaultValue={sectorTargetCodes?.toString()}
                        onChange={setSectorTargetCodes}/>
                    {/*<Input*/}
                    {/*    type="number"*/}
                    {/*    minNumber={0}*/}
                    {/*    maxNumber={1}*/}
                    {/*    readonly={true}*/}
                    {/*    size="small"*/}
                    {/*    label="Finish-Failure Rate"*/}
                    {/*    defaultValue={finishFailureRate?.toString()}*/}
                    {/*    onChange={setFinishFailureRate}/>*/}
                    {/*<Input*/}
                    {/*    type="number"*/}
                    {/*    minNumber={0}*/}
                    {/*    maxNumber={1}*/}
                    {/*    readonly={true}*/}
                    {/*    size="small"*/}
                    {/*    label="Sector-Failure Rate"*/}
                    {/*    defaultValue={sectorsFailureRate?.toString()}*/}
                    {/*    onChange={setSectorsFailureRate}/>*/}
                </ContentLayout>
            </ContentLayout>
            <ContentLayout className="admin-simulator-session-setup">
                <Headline text="Session-Data" style="h5"/>
                <div>
                    <SimulatorSessionProperty
                        label="Name"
                        value={props.session?.name}/>
                    <SimulatorSessionProperty
                        label="State"
                        value={props.session?.state}/>
                    <SimulatorSessionProperty
                        label="Mode"
                        value={props.session?.setup.mode}/>
                    <SimulatorSessionProperty
                        label="FinishType"
                        value={props.session?.setup.finishType}/>
                    <SimulatorSessionProperty
                        label="Laps"
                        value={props.session?.setup.laps?.toString()}/>
                    <SimulatorSessionProperty
                        label="Duration"
                        value={props.session?.setup.duration?.toString()}/>
                    <SimulatorSessionProperty
                        label="Setup"
                        value={props.session?.setup.carSetup}/>
                    <SimulatorSessionProperty
                        label="Track Conditions"
                        value={props.session?.setup.trackConditions}/>
                    <SimulatorSessionProperty
                        label="Tires"
                        value={props.session?.setup.tires}/>
                    <SimulatorSessionProperty
                        label="Track Bundle"
                        value={props.session?.setup.trackBundle}/>
                    <SimulatorSessionProperty
                        label="Starttime"
                        value={props.session?.setup.startTime}/>
                    <SimulatorSessionProperty
                        label="Starttime Delay"
                        value={props.session?.setup.startDelay?.toString()}/>
                </div>
            </ContentLayout>
        </ContentLayout>

    );

}
