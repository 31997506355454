import {DriftTrackBundleType} from "../types/DriftTrackBundleType";
import {DriftTargetCodeType} from "../types/DriftTargetCodeType";
import {TrackLapCountModeType} from "../types/TrackLapCountModeType";
import {TargetPreProcessorMode} from "../types/TargetPreProcessorMode";

/******************************************************************
 * TrackPreset
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TrackPreset {
    static readonly DEFAULT_TRACK_TRACKBUNDLE: DriftTrackBundleType = "none"
    static readonly DEFAULT_TRACK_JOKER_TARGET: DriftTargetCodeType | "none" = "none"
    static readonly DEFAULT_TRACK_NUM_JOKER_LAPS = 0;
    static readonly DEFAULT_TRACK_MINLAPTIME = 1;
    static readonly DEFAULT_TRACK_NUM_SECTORS = 1
    static readonly DEFAULT_TRACK_SECTOR_TARGETS: DriftTargetCodeType[] = ["targetCode_4", "targetCode_6", "targetCode_7"]
    static readonly DEFAULT_TRACK_LAP_COUNT_MODE: TrackLapCountModeType = "only-finish-targets"
    static readonly DEFAULT_TARGET_PREPROCESSOR: TargetPreProcessorMode = "autoDisableTargetsNotAllowed"
}
