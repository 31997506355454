import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * SessionStintsValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionStintsValidation {

    static get REGEXP() {
        return /^\d+$/;
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: value => SessionStintsValidation.isValid(value),
                message: errorCode ?? ErrorCode.SESSION_STINTS_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        return SessionStintsValidation.REGEXP.test(value.toString());
    }
}
