import * as React from "react";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {EventPage} from "./EventPage";
import {SessionPage} from "../session/SessionPage";
import {ResultPage} from "../result/ResultPage";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {EventTrackPage} from "./track/EventTrackPage";
import {EventRequestsPage} from "./requests/EventRequestsPage";

/******************************************************************
 * EventMain
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventMain() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {
        showEventHome,
        showEventSession,
        showEventResult,
        showEventTrack,
        showEventRequests} = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-main" alignItems="stretch">
            {showEventHome && <EventPage/>}
            {showEventSession && <SessionPage/>}
            {showEventResult && <ResultPage/>}
            {showEventTrack && <EventTrackPage/>}
            {showEventRequests && <EventRequestsPage/>}
        </ContentLayout>
    );

}
