import * as React from "react";
import {MutableRefObject, useEffect, useRef, useState} from "react";
import {InlineVerifyAction} from "../inlineaction/verify/InlineVerifyAction";
import {IconButtonSizeType} from "../../buttons/icon/IconButton";

/******************************************************************
 * EditableField
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EditableField(props: {
    text: string
    action: (value: string) => Promise<Response>
    ui: "right" | "below" | "chromeless"
    style?: "none" | "small"
    useEnterToSave?: boolean
    disabled?: boolean
    onExit?: () => void
    buttonSize?: IconButtonSizeType
    onInputRef?: (inputRef: MutableRefObject<HTMLDivElement>) => void
}) {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const inputRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showUI, setIsEditing] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        inputRef.current.innerText = props.text ?? "";
    }, [props.text])

    useEffect(() => {
        if (props.onInputRef) {
            props.onInputRef(inputRef)
        }
    }, [inputRef])

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        };

        const currentRef = inputRef.current;
        if (currentRef && props.useEnterToSave) {
            currentRef.addEventListener('keypress', handleKeyPress);
            currentRef.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('keypress', handleKeyPress);
                currentRef.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [props.useEnterToSave]);

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function reset() {
        inputRef.current.innerText = props.text;
        blur()
    }

    async function save() {
        setIsSending(true);
        setIsEditing(true);
        let text = inputRef.current.innerText;
        if (props.useEnterToSave) {
            text = text.replace(/(\r\n|\n|\r)/gm, "");
        }
        const response = await props.action(text);
        if (!response || response.status != 200) {
            reset();
        }
        setIsSending(false);
        setIsEditing(false);
        if (props.onExit) {
            props.onExit()
        }
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    function onFocus() {
        setIsEditing(true)
    }

    function blur() {
        inputRef.current.blur();
        if (inputRef.current.innerText !== props.text) {
            return
        }
        setIsEditing(false)
        if (props.onExit) {
            props.onExit()
        }
    }

    function insertTextFromClipboard(e) {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        const formattedText = text.replace(/\n/g, '<br>');
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        range.deleteContents();
        const el = document.createElement('div');
        el.innerHTML = formattedText;
        const frag = document.createDocumentFragment();
        while (el.firstChild) {
            frag.appendChild(el.firstChild);
        }
        const lastNode = frag.lastChild;
        range.insertNode(frag);
        if (lastNode) {
            range.setStartAfter(lastNode);
            range.collapse(true);
        }
        selection.removeAllRanges();
        selection.addRange(range);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="editable-field"
            data-style={props.style ?? "none"}
            data-ui={props.ui}
            data-disable={props.disabled}>
            <div
                className="editable-field-input"
                ref={inputRef}
                spellCheck={false}
                style={{pointerEvents: isSending || props.disabled ? "none" : "all"}}
                contentEditable={!isSending && !props.disabled}
                onPaste={insertTextFromClipboard}
                onFocus={onFocus}
                onBlur={blur}/>
            <InlineVerifyAction
                isBusy={isSending}
                show={showUI}
                size={props.buttonSize ?? "normal"}
                useEnterForSave={props.useEnterToSave}
                onReset={reset}
                onSave={save}/>
        </div>
    );

}
