import {StateValue} from "@webfruits/toolbox/dist/state/StateValue"
import {FrontendServices} from "../FrontendServices"
import {LanguageType} from "../../../shared/types/LanguageType"
import {StintAnnouncements} from "../../components/audio/annoucements/StintAnnouncements"
import {RaceAnnouncements} from "../../components/audio/annoucements/RaceAnnouncements"
import {DriverAnnouncements} from "../../components/audio/annoucements/DriverAnnouncements"
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {SessionAnnouncements} from "../../components/audio/annoucements/SessionAnnouncements";
import {SpeakerUtils} from "../../utils/SpeakerUtils";
import {StateAnnouncements} from "../../components/audio/annoucements/StateAnnouncements";
import {InaudibleAudioLoop} from "../../components/audio/loop/InaudibleAudioLoop";

/******************************************************************
 * FrontendSpeaker
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type FrontendSpeakerStateType = "not-available" | "not-allowed" | "no-voices" | "ok"
export type FrontendSpeakerFixType = "none" | "underscore"

export class FrontendSpeaker {

    /******************************************************************
     * Properties
     *****************************************************************/

    public speakerState = new StateValue<FrontendSpeakerStateType>("not-available")
    public voiceName = new StateValue<string>()
    public fixSpeechType = new StateValue<FrontendSpeakerFixType>("none")

    private _availableVoices: SpeechSynthesisVoice[];
    private _inaudibleAudioLoop: InaudibleAudioLoop;
    private _stintAnnouncements: StintAnnouncements
    private _raceAnnouncements: RaceAnnouncements
    private _driverAnnouncements: DriverAnnouncements
    private _sessionAnnouncements: SessionAnnouncements
    private _stateAnnouncements: StateAnnouncements
    private _speakQueue: { text: string, lang?: LanguageType, voiceName?: string }[] = []
    private static MAX_SPEAKER_QUEUE_LENGTH = 4

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this._frontend.initState.onChangeSignal.addOnce(() => {
            this.initInaudibleAudioLoop()
            this.initAvailableVoices()
            this.initSpeakerSetup()
            this.initSpeakerInitializer()
            this.initAnnoucements()
        })
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public speak(text: string, hasPriority: boolean = false, lang?: LanguageType, voiceName?: string) {
        this._speakQueue.push({text, lang, voiceName})
        if (this._speakQueue.length == 1) {
            this.speakNext()
        }
        if (hasPriority) return
        if (this._speakQueue.length > FrontendSpeaker.MAX_SPEAKER_QUEUE_LENGTH) {
            this._speakQueue.shift()
        }
    }

    get stint(): StintAnnouncements {
        return this._stintAnnouncements
    }

    get race(): RaceAnnouncements {
        return this._raceAnnouncements
    }

    get driver(): DriverAnnouncements {
        return this._driverAnnouncements
    }

    get session(): SessionAnnouncements {
        return this._sessionAnnouncements;
    }

    get state(): StateAnnouncements {
        return this._stateAnnouncements;
    }

    public setUserAnnounceableState(userID: MongoObjectIDType, state: boolean) {
        if (!userID) return
        const key = SpeakerUtils.getUserAnnouncementLocalStorageKey(userID)
        if (state) {
            const value = "enabled"
            localStorage.setItem(key, value)
            this._frontend.signal.onLocalStorageChanged.dispatch({key: key, value: value})
        } else {
            localStorage.removeItem(key)
            this._frontend.signal.onLocalStorageChanged.dispatch({key: key, value: undefined})
        }
    }

    public setTeamAnnounceableState(teamName: string, state: boolean) {
        if (!teamName) return
        const key = SpeakerUtils.getTeamAnnouncementLocalStorageKey(teamName)
        if (state) {
            const value = "enabled"
            localStorage.setItem(key, value)
            this._frontend.signal.onLocalStorageChanged.dispatch({key: key, value: value})
        } else {
            localStorage.removeItem(key)
            this._frontend.signal.onLocalStorageChanged.dispatch({key: key, value: undefined})
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private async initAvailableVoices() {
        this._availableVoices = await SpeakerUtils.availableVoices(this._frontend.state.language.getValue())
    }

    private initSpeakerInitializer() {
        this.speak(" ")
        window.addEventListener("click", () => {
            if (!this.speakerState.isValue("ok")) {
                this.stopAll()
                this._inaudibleAudioLoop.start()
                this._speakQueue = []
                this.speak(" ")
            }
        })
    }

    private initSpeakerSetup() {
        this.voiceName.setValue(localStorage.getItem("speaker.voice"))
        this.voiceName.onChangeSignal.add(() => {
            localStorage.setItem("speaker.voice", this.voiceName.getValue())
        })
        this.fixSpeechType.setValue(localStorage.getItem("speaker.replaceSpaces") as FrontendSpeakerFixType || "none")
        this.fixSpeechType.onChangeSignal.add(() => {
            localStorage.setItem("speaker.replaceSpaces", this.fixSpeechType.getValue())
        })
        this._frontend.state.language.onChangeSignal.add(() => {
            this.initAvailableVoices()
        })
    }

    private initAnnoucements() {
        this._stintAnnouncements = new StintAnnouncements(this._frontend)
        this._raceAnnouncements = new RaceAnnouncements(this._frontend)
        this._driverAnnouncements = new DriverAnnouncements(this._frontend)
        this._sessionAnnouncements = new SessionAnnouncements(this._frontend)
        this._stateAnnouncements = new StateAnnouncements(this._frontend)
    }

    private async speakNext() {
        if (!SpeakerUtils.isSpeechAPIAvailable) return
        if (this._speakQueue.length == 0) return
        let voices = this._availableVoices
        let voiceName = this._speakQueue[0].voiceName ?? this.voiceName.getValue()
        if (!this._frontend.state.language.isValue(this._speakQueue[0].lang)) {
            voices = await SpeakerUtils.availableVoices(this._speakQueue[0].lang)
        }
        const announcement = new SpeechSynthesisUtterance()
        announcement.text = this.fixText(this._speakQueue[0]?.text)
        announcement.lang = this._speakQueue[0].lang ?? this._frontend.state.language.getValue()
        announcement.volume = 1
        announcement.voice = SpeakerUtils.getVoice(voiceName, voices, announcement.lang as LanguageType)
        this.voiceName.setValue(announcement?.voice?.name)
        let endTimeout: number
        this.speakerState.setValue(announcement.voice ? "ok" : "no-voices")
        announcement.onstart = () => {
            clearTimeout(errorTimeout)
            this.speakerState.setValue(announcement.voice ? "ok" : "no-voices")
            announcement.onstart = null
            endTimeout = window.setTimeout(() => {
                announcement?.onend(null)
            }, 5000);
        }
        announcement.onend = () => {
            clearTimeout(errorTimeout)
            clearTimeout(endTimeout)
            this._speakQueue.shift()
            this.speakNext()
            announcement.onend = null
        }
        announcement.onerror = () => {
            clearTimeout(errorTimeout)
            clearTimeout(endTimeout)
            this._speakQueue.shift()
            this.speakerState.setValue("not-allowed")
            announcement.onerror = null
        }
        const errorTimeout = window.setTimeout(() => {
            clearTimeout(errorTimeout);
            clearTimeout(endTimeout)
            announcement?.onerror(null)
        }, 1000);
        speechSynthesis.speak(announcement)
    }

    private stopAll() {
        speechSynthesis.cancel()
    }

    private fixText(text: string) {
        if (text == " ") return " "
        if (this.fixSpeechType.isValue("none")) return text
        return "_" + text.replaceAll(" ", "_")
    }

    private initInaudibleAudioLoop() {
        this._inaudibleAudioLoop = new InaudibleAudioLoop();
    }
}
