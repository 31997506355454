import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";

/******************************************************************
 * TableLeaderboardPointsCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardPointsCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function points(): string {
        const session: ISessionData = props.entry.session
        const entry: ISessionLeaderboardEntry = props.entry
        switch (entry.type) {
            case "secondary-team":
                return ""
        }
        if (!entry.position
            || !session.setup.points
            || session.setup.points.length == 0) {
            return "–"
        }
        let points: number = session.setup.points[entry.position - 1] ?? 0
        if (points == 0) {
            return "–"
        }
        if (hasFastestLap() && session.setup.mode == "race") {
            points += session.setup.fastestLapPoints ?? 0
        }
        return points.toString()
    }

    function hasFastestLap() {
        const entry = props.entry
        const entries: ISessionLeaderboardEntry[] = [...props.entry.entries]
        entries.sort((entryA: ISessionLeaderboardEntry, entryB: ISessionLeaderboardEntry) => {
            return entryA.overall?.bestLapTime - entryB.overall?.bestLapTime
        })
        return entries[0].overall?.bestLapTime == entry.overall?.bestLapTime
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-points-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}>
            {points()}
        </div>
    );

}
