import * as React from "react";
import {IGroupData} from "../../../../../shared/models/IGroupData";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../hooks/useServices";
import {Picture} from "../../image/Picture";

/******************************************************************
 * GroupInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupInfo(props: {
    group: IGroupData,
    style: "metabar" | "normal" | "small" | "small-light" | "table-cell"
    clickable?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, dict} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function defaultImageSource() {
        if (props.group?.avatar) return null
        if (props.group) {
            return require("ASSETS/images/default-group-avatar.png")
        }
        return require("ASSETS/images/default-group-avatar-not-found.png")
    }

    function showGroup(e: React.MouseEvent) {
        if (!props.group || !props.clickable) return null;
        e.stopPropagation()
        router.showRoute(FrontendRoute.GROUP(props.group.path))
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="group-info"
            data-style={props.style}
            data-interactive={props.group && props.clickable}
            onClick={showGroup}>
            <Picture
                file={props.group?.avatar}
                alt={props.group?.name}
                defaultSource={defaultImageSource()}/>
            {props.style != "metabar" && <>
                <h6>{props.group?.name ?? dict("user.not.found")}</h6>
            </>}
        </div>
    );

}
