import {DriverLicenseType} from "../types/DriverLicenseType";
import {PlanAccessType} from "../types/PlanAccessType";
import {PlanFeatureType} from "../types/PlanFeatureType";

/******************************************************************
 * PlanFeatureDefinitions
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

type FeatureGroup = {
    [key in DriverLicenseType]: PlanAccessType;
};

export class PlanFeatureDefinitions {

    private static readonly features: Record<PlanFeatureType, FeatureGroup> = {
        "speechSynthesis": {
            "free": {type: "partial"},
            "basic": {type: "partial"},
            "premium": {type: "full"}
        },
        "driving-school": {
            "free": {type: "full"},
            "basic": {type: "full"},
            "premium": {type: "full"}
        },
        "training": {
            "free": {type: "full"},
            "basic": {type: "full"},
            "premium": {type: "full"}
        },
        "garage": {
            "free": {type: "max", value: 3, dictKey: "plan.feature.garage.max.cars"},
            "basic": {type: "max", value: 15, dictKey: "plan.feature.garage.max.cars"},
            "premium": {type: "full"}
        },
        "tracks": {
            "free": {type: "max", value: 2, dictKey: "plan.feature.tracks.max"},
            "basic": {type: "max", value: 10, dictKey: "plan.feature.tracks.max"},
            "premium": {type: "full"}
        },
        "stint-analysis": {
            "free": {type: "max", value: 3, dictKey: "plan.feature.stint-analysis.max"},
            "basic": {type: "max", value: 10, dictKey: "plan.feature.stint-analysis.max"},
            "premium": {type: "full"}
        },
        "groups": {
            "free": {type: "partial"},
            "basic": {type: "full"},
            "premium": {type: "full"}
        },
        "events": {
            "free": {type: "none"},
            "basic": {type: "full"},
            "premium": {type: "full"}
        },
        "event-results": {
            "free": {type: "none"},
            "basic": {type: "none"},
            "premium": {type: "full"}
        },
        "event-requests": {
            "free": {type: "none"},
            "basic": {type: "none"},
            "premium": {type: "full"}
        },
        "session-presets": {
            "free": {type: "none"},
            "basic": {type: "none"},
            "premium": {type: "full"}
        },
        "racedirector": {
            "free": {type: "none"},
            "basic": {type: "none"},
            "premium": {type: "full"}
        },
        "stint-approval": {
            "free": {type: "none"},
            "basic": {type: "none"},
            "premium": {type: "full"}
        }
    }

    static getFeatures(): PlanFeatureType[] {
        return Object.keys(PlanFeatureDefinitions.features) as PlanFeatureType[];
    }

    static getFeatureAccess<T>(feature: PlanFeatureType, license: DriverLicenseType): T {
        return PlanFeatureDefinitions.features[feature][license] as T;
    }

    static getAccessType(
        feature: PlanFeatureType,
        driverType: DriverLicenseType
    ): PlanAccessType {
        return PlanFeatureDefinitions.features[feature][driverType];
    }
}
