import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelText} from "../../../../../ui/text/label/LabelText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {ITeamData} from "../../../../../../../shared/models/submodels/ITeamData";
import {TeamList} from "../../../../../ui/list/team/TeamList";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {useRouteOwner} from "../../../../../hooks/useRouteOwner";
import {useGroupState} from "../../../../../hooks/useGroupState";

/******************************************************************
 * SessionTeams
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionTeams() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {eventTeams} = useEventState()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {sessionID, sessionForbiddenTeams, sessionIsFinished} = useSessionState()
    const [isMobileMode] = useMobileStyle(ref, 500)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [allowedTeams, setAllowedTeams] = useState<ITeamData[]>()
    const [forbiddenTeams, setForbiddenTeams] = useState<ITeamData[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const allowedTeams: ITeamData[] = []
        const forbiddenTeams: ITeamData[] = []
        eventTeams?.forEach(user => {
            if (sessionForbiddenTeams?.includes(user._id)) {
                forbiddenTeams.push(user);
            } else {
                allowedTeams.push(user)
            }
        })
        setAllowedTeams(allowedTeams);
        setForbiddenTeams(forbiddenTeams);
    }, [eventTeams, sessionForbiddenTeams])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function removeAllTeams() {
        setIsBusy(true)
        await api.session.update(sessionID, {
            forbiddenTeams: eventTeams.map(team => team._id)
        })
        setIsBusy(false)
    }

    async function forbidTeam(teamID: MongoObjectIDType) {
        setIsBusy(true)
        const forbiddenTeam = sessionForbiddenTeams?.concat() ?? []
        if (!forbiddenTeam.includes(teamID)) {
            forbiddenTeam.push(teamID);
            await api.session.update(sessionID, {forbiddenTeams: forbiddenTeam})
        }
        setIsBusy(false)
    }

    async function allowAllTeam() {
        setIsBusy(true)
        await api.session.update(sessionID, {forbiddenTeams: null})
        setIsBusy(false)
    }

    async function allowTeam(teamID: MongoObjectIDType) {
        setIsBusy(true)
        let forbiddenTeams = sessionForbiddenTeams?.concat() ?? []
        forbiddenTeams = forbiddenTeams.filter(uid => {
            return uid != teamID
        })
        await api.session.update(sessionID, {forbiddenTeams: forbiddenTeams})
        setIsBusy(false)
    }

    function isReadonly() {
        if (sessionIsFinished) return true;
        return !isRouteOwnerAuthUser && !isGroupAdmin;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            columns={isMobileMode ? 1 : 2}
            className="session-teams"
            size="content">
            <div className="session-teams-allowed">
                <div className="session-teams-allowed-header">
                    <LabelText
                        label={dict("session.teams.allowed")}/>
                    {allowedTeams && allowedTeams.length > 0 && !isReadonly() &&
                        <LabelButton
                            label={dict("session.teams.remove")}
                            disabled={isBusy}
                            style="primary-small"
                            onClick={removeAllTeams}/>}
                </div>
                <TeamList
                    data={allowedTeams}
                    iconType="cancel"
                    readonly={isReadonly()}
                    onIconClick={forbidTeam}
                    emptyDataInfo={dict("session.teams.allow.nodata")}/>
            </div>
            <div className="session-teams-forbidden">
                <div className="session-teams-forbidden-header">
                    <LabelText
                        label={dict("session.teams.forbidden")}/>
                    {forbiddenTeams && forbiddenTeams.length > 0 && !isReadonly() &&
                        <LabelButton
                            label={dict("session.teams.add")}
                            disabled={isBusy}
                            style="primary-small"
                            onClick={allowAllTeam}/>}
                </div>
                <TeamList
                    data={forbiddenTeams}
                    iconType="add"
                    readonly={isReadonly()}
                    onIconClick={allowTeam}
                    emptyDataInfo={dict("session.teams.forbidden.nodata")}/>
            </div>
        </ContentLayout>
    );

}
