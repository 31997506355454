import * as React from "react";

/******************************************************************
 * PlusIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PlusIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="plus-icon">
            <svg width={11 * (props.scale ?? 1)} height={11 * (props.scale ?? 1)} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2326 4.34911H6.77955V0.896042C6.77955 0.472321 6.43592 0.128693 6.0122 0.128693H5.24485C4.82113 0.128693 4.4775 0.472321 4.4775 0.896042V4.34911H1.02443C0.600709 4.34911 0.25708 4.69274 0.25708 5.11646V5.88381C0.25708 6.30753 0.600709 6.65116 1.02443 6.65116H4.4775V10.1042C4.4775 10.528 4.82113 10.8716 5.24485 10.8716H6.0122C6.43592 10.8716 6.77955 10.528 6.77955 10.1042V6.65116H10.2326C10.6563 6.65116 11 6.30753 11 5.88381V5.11646C11 4.69274 10.6563 4.34911 10.2326 4.34911Z" fill="white"/>
            </svg>
        </div>
    );

}
