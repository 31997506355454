import * as React from "react";
import {AvatarImage} from "../../../ui/image/avatar/AvatarImage";
import {UserSidebarInfo} from "./info/UserSidebarInfo";
import {UserSidebarMenu} from "./menu/UserSidebarMenu";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {IUserData} from "../../../../../shared/models/IUserData";

/******************************************************************
 * UserSidebar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserSidebar() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {
        isRouteOwnerAuthUser,
        isRouteOwnerUser,
        routeOwnerNick,
        routeOwnerName,
        routeOwnerData,
        routeOwnerSubscription,
        routeOwnerUseSubscription
    } = useRouteOwner();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="user-sidebar">
            <AvatarImage
                user={routeOwnerData}
                editable={isRouteOwnerAuthUser}/>
            <UserSidebarInfo
                nick={routeOwnerNick}
                name={routeOwnerName}
                role={isRouteOwnerUser ? (routeOwnerData as IUserData).role : null}
                isRouteOwner={isRouteOwnerAuthUser}
                subscription={routeOwnerSubscription}
                useSubscription={routeOwnerUseSubscription}/>
            <UserSidebarMenu/>
        </section>
    );

}
