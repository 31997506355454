import * as React from "react"
import {useEffect, useRef, useState} from "react"

/******************************************************************
 * useWindowState
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useWindowState() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const windowFocusedTimeoutRef = useRef<number>()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isWindowFocused, setIsWindowFocused] = useState<boolean>(window.document.hasFocus())
    const [isWindowFocusedForAtLeast10Seconds, setIsWindowFocusedForAtLeast10Seconds] = useState<boolean>(false)
    const [hasFocusOnInputElement, setHasFocusOnInputElement] = useState<boolean>(false);

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        window.addEventListener('focus', handleWindowFocus, true)
        window.addEventListener('blur', handleWindowFocus, true)
        return () => {
            clearTimeout(windowFocusedTimeoutRef.current)
            window.removeEventListener('focus', handleWindowFocus, true)
            window.removeEventListener('blur', handleWindowFocus, true)
        }
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function handleWindowFocus() {
        setHasFocusOnInputElement(
            document.activeElement?.tagName === 'INPUT' ||
            document.activeElement?.['contentEditable'] === 'true')
        setIsWindowFocused(window.document.hasFocus())
        if (windowFocusedTimeoutRef.current) {
            clearTimeout(windowFocusedTimeoutRef.current)
        }
        if (!window.document.hasFocus()) {
            setIsWindowFocusedForAtLeast10Seconds(false)
            return
        }
        windowFocusedTimeoutRef.current = window.setTimeout(() => {
            setIsWindowFocusedForAtLeast10Seconds(window.document.hasFocus())
        }, 10000)
    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        isWindowFocused,
        isWindowFocusedForAtLeast10Seconds,
        hasFocusOnInputElement
    }

}
