import * as React from "react";
import {useRef, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../text/headings/Headline";
import {LabelValueText} from "../../text/labelvalue/LabelValueText";
import {EventDateInfo} from "../../info/date/EventDateInfo";
import {StateInfo} from "../../info/state/StateInfo";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {Picture} from "../../image/Picture";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {Banderole} from "../../info/banderole/Banderole";
import {ActionBar} from "../../bar/ActionBar";
import {LabelButton} from "../../buttons/label/LabelButton";
import {EventUtils} from "../../../../../shared/utils/EventUtils";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {LocationUtils} from "../../../../utils/LocationUtils";
import {IEventData} from "../../../../../shared/models/IEventData";
import {Badge} from "../../info/badge/Badge";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Spacer} from "../../utils/spacer/Spacer";
import {ILocationData} from "../../../../../shared/types/ILocationData";
import {MarkerFactory} from "../../map/marker/MarkerFactory";

/******************************************************************
 * EventTeaser
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventTeaser(props: {
    event: IEventData
    size?: "normal" | "small"
    usedAsEventHeader?: boolean
    clickToGoToEvent?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLElement>(null);

    /* ----------------------------------------------------------------
 	 * HOOK
 	 * --------------------------------------------------------------*/

    const {dict, router, state, language} = useServices();
    const [isMobileMode, isMobileModeAvailable] = useMobileStyle(ref, 500)
    const {authUserData} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isTraining] = useState<boolean>(props.event?.ownerType == "user")

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoOwner() {
        switch (props.event?.ownerType) {
            case "user":
                router.showRoute(FrontendRoute.USER(props.event?.owner?.path))
                break;
            case "group":
                router.showRoute(FrontendRoute.GROUP(props.event?.owner?.path))
                break;
        }
    }

    function gotoEvent() {
        router.showRoute(FrontendRoute.EVENT(props.event?.ownerType, props.event?.owner?.path, props.event?.path))
    }

    function showActionBar(): boolean {
        return EventUtils.isRequestAvailable(authUserData, props.event)
    }

    function showParticipationtButton() {
        return EventUtils.isRequestAvailable(authUserData, props.event, true ,true)
    }

    function mapLocationText() {
        return LocationUtils.mapLocationString(dict("event.mapLocation.text"), locationData(), language)
    }

    function showMaxDrivers() {
        return props.event?.state == "upcoming"
            && props.event?.drivers?.maxDrivers
            && (props.event?.drivers?.access == "only-registered-drivers-after-paid"
                || props.event?.drivers?.access == "only-registered-drivers-after-approval")
    }

    function locationData(): ILocationData {
        if (props.event?.mapLocation) {
            return props.event.mapLocation
        }
        if (props.event?.owner?.location) {
            return props.event.owner.location
        }
        return null
    }

    function showEntryFee() {
        return props.event?.state == "upcoming" && props.event?.drivers?.entryFee && props.event?.drivers?.access == "only-registered-drivers-after-paid"
    }

    function enabledParticipationButton() {
        if (!EventUtils.isRequestAvailable(authUserData, props.event)) {
            return false
        }
        if (EventUtils.hasUserAlreadyRequestedParticipation(props.event, authUserData._id)) {
            return false
        }
        if (EventUtils.isUserAlreadyRegistered(props.event, authUserData._id)) {
            return false
        }
        if (EventUtils.isParticipationRequestPending(props.event, authUserData._id)) {
            return false
        }
        if (!props.event?.drivers?.maxDrivers) {
            return true
        }
        return (props.event?.drivers?.maxDrivers ?? 0) > totalDrivers()
    }

    function totalDrivers() {
        const requestedDrivers = props.event?.drivers?.requesting?.length ?? 0
        const pendingDrivers = props.event?.drivers?.pending?.length ?? 0
        const registeredDrivers = props.event?.drivers?.registered?.length ?? 0
        return requestedDrivers + pendingDrivers + registeredDrivers
    }

    function maxDriversValue(): string {
        switch (props.event.state) {
            case "upcoming":
                return totalDrivers() + " / " + props.event?.drivers?.maxDrivers?.toString()
            case "live":
            case "finished":
                return (props.event?.drivers?.registered?.length ?? 0).toString()
        }

    }

    function participationButtonLabel() {
        if (EventUtils.hasUserAlreadyRequestedParticipation(props.event, authUserData._id)) {
            return dict("event.teaser.participation.button.requested.label")
        }
        if (EventUtils.isUserAlreadyRegistered(props.event, authUserData._id)) {
            return dict("event.teaser.participation.button.registered.label")
        }
        if (EventUtils.isParticipationRequestPending(props.event, authUserData._id)) {
            return dict("event.teaser.participation.button.pending.label")
        }
        if (!EventUtils.isRequestAvailable(authUserData, props.event)) {
            return dict("event.teaser.participation.button.notAvailable.label")
        }
        if (enabledParticipationButton()) {
            return dict("event.teaser.participation.button.label")
        }
        return dict("event.teaser.participation.button.noSeatsLeft.label")
    }

    function showEventState(): boolean {
        if (props.usedAsEventHeader) {
            return true
        }
        if (EventUtils.isLiveEvent(props.event)
            || EventUtils.isPermanentEvent(props.event)) {
            return true
        }
        return false
    }

    function showMapModal() {
        if (!locationData()) return
        state.showMapLocation.setValue({
            type: "event",
            location: locationData(),
            markers: [MarkerFactory.createEventMarker(props.event)]
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer
            isPropertyAvailable={!!props.event?.state && !!ref.current && isMobileModeAvailable}>
            {isTraining &&
                <Banderole
                    label={dict("training.banderole.label")}
                    color="red"
                    position="local-left"/>}
            <figure
                ref={ref}
                className="event-teaser"
                data-size={props.size ?? "normal"}
                data-type={isTraining ? "training" : "event"}
                data-use-as-goto-event={props.clickToGoToEvent}
                data-mobile-mode={isMobileMode}
                onClick={() => props.clickToGoToEvent ? gotoEvent() : null}>
                <div className="event-teaser-image">
                    <Picture
                        file={props.event?.image ?? props.event?.owner?.avatar}
                        alt={props.event?.name}
                        allowMediaViewer={props.clickToGoToEvent ? false : (!!props.event?.image ?? !!props.event?.owner?.avatar)}
                        defaultSource={require(isTraining
                            ? "ASSETS/images/default-training-image.png"
                            : "ASSETS/images/default-event-image.png")}/>
                    {props.event?.date &&
                        <EventDateInfo date={props.event?.date} dateUntil={props.event?.dateUntil}/>}
                </div>
                <div className="event-teaser-info">
                    {!isTraining &&
                        <div className="event-teaser-info-badges">
                            {props.event?.drivers?.access &&
                                <Badge
                                    label={dict("event.drivers.access.badge." + props.event?.drivers?.access)}
                                    hideBackground={true}
                                    showBorder={!showEventState()}
                                    color="white"
                                    size="small"/>}
                            {showEventState() &&
                                <StateInfo state={props.event?.state}/>}
                        </div>}
                    <Headline
                        text={props.event?.name}
                        hyphens={true}
                        style="h1"/>
                    {!isTraining &&
                        <div className="event-teaser-info-meta">
                            <LabelValueText
                                label={dict("event.owner.label")}
                                onClick={props.clickToGoToEvent ? null : gotoOwner}
                                value={props.event?.owner?.nick ?? props.event?.owner?.name}/>
                            {locationData() &&
                                <LabelValueText
                                    label={dict("event.location.label")}
                                    onClick={showMapModal}
                                    stopPropagation={true}
                                    value={mapLocationText()}/>}
                            {props.event?.owner?.type == "online" &&
                                <LabelValueText
                                    label={dict("event.location.label")}
                                    value={dict("event.location.online")}/>}
                            {(showMaxDrivers() || showEntryFee()) &&
                                <ContentLayout
                                    gap="large"
                                    columnTemplate={showMaxDrivers() && showEntryFee() ? "min-content 1fr" : null}>
                                    {showEntryFee() &&
                                        <LabelValueText
                                            label={dict("event.entryfee.label")}
                                            value={props.event?.drivers?.entryFee}/>}
                                    {showMaxDrivers() &&
                                        <LabelValueText
                                            label={dict("event.maxDrivers.info.label")}
                                            value={maxDriversValue()}/>}
                                </ContentLayout>}
                            {showActionBar() &&
                                <Spacer height={isMobileMode ? "normal" : "small"}/>}
                        </div>}
                </div>
            </figure>
            {showActionBar() &&
                <ActionBar location="event-teaser">
                    {showParticipationtButton() &&
                        <LabelButton
                            label={participationButtonLabel()}
                            style={"primary-small"}
                            disabled={!enabledParticipationButton()}
                            onClick={() => state.showEventParticipationRequest.setValue(props.event)}/>}
                </ActionBar>}
        </ComponentInitializer>
    );

}
