import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IKeyValuePair} from "../../shared/types/IKeyValuePair";

/******************************************************************
 * InfoAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class InfoAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    async getChangeLog(): Promise<string> {
        const response = await this._frontend.api.request("GET", APIRoute.INFO_CHANGELOG)
        if (response.status !== 200) {
            return this._frontend.error.createMessage(await response.json())
        }
        return await response.text();
    }

    async getServerVersion(): Promise<string> {
        const response = await this._frontend.api.request("GET", APIRoute.INFO_SERVER_VERSION)
        if (response.status !== 200) {
            return this._frontend.error.createMessage(await response.json())
        }
        return await response.text();
    }

    async getFrontendEnvVars(): Promise<IKeyValuePair> {
        const response = await this._frontend.api.request("GET", APIRoute.INFO_FRONTEND_ENV_VARS)
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({type: "error", message: "Error while loading environment variables"})
            return null
        }
        return await response.json();
    }

    async getServerTime(): Promise<number> {
        const response = await this._frontend.api.request("GET", APIRoute.INFO_SERVER_TIME, null, false)
        if (response.status == 200) {
            const body = await response.json();
            return body.time;
        }
        return null;
    }

}
