import * as React from "react";

/******************************************************************
 * InfoIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function InfoIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="info-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0.949707C3.58203 0.949707 0 4.53303 0 8.94971C0 13.369 3.58203 16.9497 8 16.9497C12.418 16.9497 16 13.369 16 8.94971C16 4.53303 12.418 0.949707 8 0.949707ZM8 4.49809C8.74826 4.49809 9.35484 5.10467 9.35484 5.85293C9.35484 6.60119 8.74826 7.20777 8 7.20777C7.25174 7.20777 6.64516 6.60119 6.64516 5.85293C6.64516 5.10467 7.25174 4.49809 8 4.49809ZM9.80645 12.6916C9.80645 12.9054 9.63313 13.0787 9.41935 13.0787H6.58064C6.36687 13.0787 6.19355 12.9054 6.19355 12.6916V11.9174C6.19355 11.7037 6.36687 11.5304 6.58064 11.5304H6.96774V9.46584H6.58064C6.36687 9.46584 6.19355 9.29251 6.19355 9.07874V8.30455C6.19355 8.09077 6.36687 7.91745 6.58064 7.91745H8.64516C8.85894 7.91745 9.03226 8.09077 9.03226 8.30455V11.5304H9.41935C9.63313 11.5304 9.80645 11.7037 9.80645 11.9174V12.6916Z" fill="white"/>
            </svg>
        </div>
    );

}
