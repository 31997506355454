import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {MailBodyBlockType} from "../../../../../shared/models/IMailData";
import {useRouteStates} from "../../../hooks/useRouteStates";

/******************************************************************
 * MailAddBodyBlockContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailAddBodyBlockContextMenu(props: {
    onAddBlock?: (blockType: MailBodyBlockType) => void
    onToggle?: (isOpen: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {showAdminMailTemplates} = useRouteStates()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="add"
            iconSize="small"
            onToggle={props.onToggle}
            stopPropagation={true}>
            <ContextMenuLabel
                label={dict("mail.body.block.add.markdown")}
                icon="text"
                iconScale={0.9}
                stateIconType="add"
                stateIconScale={0.4}
                onClick={() => props.onAddBlock("markdown")}/>
            <ContextMenuLabel
                label={dict("mail.body.block.add.image")}
                icon="image"
                iconScale={0.9}
                stateIconType="add"
                stateIconScale={0.4}
                onClick={() => props.onAddBlock("image")}/>
            {showAdminMailTemplates &&
                <ContextMenuLabel
                    label={dict("mail.body.block.add.verficicationCode")}
                    icon="image"
                    iconScale={0.9}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("verificationCode")}/>}
        </ContextMenu>
    );

}
