import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendServices} from "../core/FrontendServices";
import {UserRoleType} from "../../shared/types/UserRoleType";
import {IUserData} from "../../shared/models/IUserData";
import {FrontendRoute} from "../../shared/routes/FrontendRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {LanguageType} from "../../shared/types/LanguageType";
import {LanguageFrontendUtils} from "../utils/LanguageFrontendUtils";

/******************************************************************
 * UserAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class UserAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    /* ----------------------------------------------------------------
 	 * AUTH METHODES
 	 * --------------------------------------------------------------*/

    public async signup(data: {
        email: string,
        password: string,
        role: UserRoleType,
        name: string,
        nick: string,
        language?: LanguageType
    }): Promise<Response> {
        await this._frontend.api.user.logout();
        data.language = data.language ?? LanguageFrontendUtils.getLanguageFromHTML(null);
        return await this._frontend.api.request("POST", APIRoute.USER_SIGNUP, data)
    }

    public async login(data: { email: string, password: string }): Promise<Response> {
        await this.logout();
        const response = await this._frontend.api.request("POST", APIRoute.USER_LOGIN, data)
        if (response.status == 200) {
            const body = await response.json();
            this._frontend.api.saveToken(body.token);
            await this.loadAuthUserData();
            if (this._frontend.state.authUser.getValue()?.state == "verified") {
                this.refreshAuthUserSubscription();
            }
            this._frontend.router.showRoute(FrontendRoute.USER(this._frontend.state.authUser.getValue()?.path));
        }
        return response;
    }

    public async requestVerificationCode(data: { email: string }, abortController: AbortController = null): Promise<Response> {
        const response = await this._frontend.api.request("POST", APIRoute.USER_REQUEST_VERIFICATION_CODE, data, true, abortController)
        if (response && response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
        }
        return response;
    }

    public async changePassword(data: { email: string, password: string, code: string }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.USER_CHANGE_PASSWORD, data)
    }

    public async verifyAccount(code: string): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.USER_VERIFY_ACCOUNT, {code}, true)
    }

    public async addMailToWhitelist(mail: string): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.USER_ADD_MAIL_TO_WHITELIST, {mail}, true)
    }

    public async logout(fromAllDevices: boolean = false, foreignUserID?: MongoObjectIDType) {
        if (fromAllDevices) {
            await this._frontend.api.request("POST", APIRoute.USER_LOGOUT_FROM_ALL_DEVICES, {foreignUserID}, true);
        }
        if (foreignUserID && foreignUserID != this._frontend.state.authUser.getValue()?._id) {
            return;
        }
        this._frontend.api.removeTokenFromLocalStorage();
        this._frontend.state.authUser.setValue(null);
        this._frontend.router.showRoute(FrontendRoute.HOME);
        this._frontend.socket.send("frontend.authUser.change", {authUserID: null})
    }

    public async delete(data: { _id: string }): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.USER, data, true);
    }

    /* ----------------------------------------------------------------
 	 * LOGGED IN USER DATA
 	 * --------------------------------------------------------------*/

    public async loadAuthUserData(): Promise<IUserData> {
        if (!this._frontend.api.getToken()) {
            this._frontend.state.authUser.setValue(null);
            this._frontend.socket.send("frontend.authUser.change", {authUserID: null})
            return null;
        }
        const response = await this._frontend.api.request("GET", APIRoute.AUTH_USER, null, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "normal",
                message: this._frontend.error.createMessage(jsonResponse)
            });
            this.logout()
            return null;
        }
        try {
            let userData = await response.json() as IUserData;
            if (!!userData.error) userData = null;
            this._frontend.state.authUser.setValue(userData);
            this._frontend.socket.send("frontend.authUser.change", {authUserID: userData?._id})
            return userData;
        } catch (error) {
            return undefined
        }
    }

    /* ----------------------------------------------------------------
 	 * REFRESH SUBSCRIPTION
 	 * --------------------------------------------------------------*/

    public async refreshAuthUserSubscription(abortController?: AbortController): Promise<void> {
        await this._frontend.api.request("GET", APIRoute.AUTH_USER_REFRESH_SUBSCRIPTION, null, true, abortController);
    }

    public async refreshUserSubscription(userID: MongoObjectIDType): Promise<void> {
        const response = await this._frontend.api.request("GET", APIRoute.USER_REFRESH_SUBSCRIPTION, {userID}, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
        }
    }

    /* ----------------------------------------------------------------
 	 * UPDATE METHODES
 	 * --------------------------------------------------------------*/

    public async update(data: IUserData): Promise<Response> {
        const response = await this._frontend.api.request("PATCH", APIRoute.USER, data, true);
        if (response.status == 200) {
            if (data.email || data.nick) {
                await this.logout(true);
                return response;
            }
        }
        return response;
    }

    public async uploadAvatar(data: FormData): Promise<Response> {
        const response = await this._frontend.api.upload(APIRoute.USER_AVATAR, data);
        if (response.status == 200) {
            await this.loadAuthUserData();
        }
        return response;
    }

    public async createNewCapiPassword(): Promise<Response> {
        const response = await this._frontend.api.request("PATCH", APIRoute.USER_CAPI_PASSWORD, null, true);
        if (response.status == 200) {
            await this.loadAuthUserData();
        }
        return response;
    }

    /* ----------------------------------------------------------------
 	 * GET USER DATA
 	 * --------------------------------------------------------------*/

    public async getUserDataByID(userID: MongoObjectIDType): Promise<IUserData> {
        const response = await this._frontend.api.request("GET", APIRoute.USER, {userID: userID}, true);
        if (response.status != 200) {
            return null;
        }
        return await response.json();
    }

    public async getUserDataByPath(userPath: string): Promise<IUserData> {
        const response = await this._frontend.api.request("GET", APIRoute.USER, {userPath: userPath});
        if (response.status != 200) {
            return null;
        }
        return await response.json();
    }

    /* ----------------------------------------------------------------
 	 * LEGAL CHECK
 	 * --------------------------------------------------------------*/

    public async isNewLegalVersionAvailableForUser(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.AUTH_USER_LEGAL_VERSION_CHECK, null, true);
        if (response.status != 200) {
            return false;
        }
        const result = await response.json();
        return result?.newVersionAvailable ?? false;
    }

    public async getLatestLegalVersion(): Promise<number> {
        const response = await this._frontend.api.request("GET", APIRoute.AUTH_USER_LEGAL_VERSION_CHECK, null, true);
        if (response.status != 200) {
            return null;
        }
        const result = await response.json();
        return result?.latestVersion ?? null;
    }

    /* ----------------------------------------------------------------
 	 * RELEASE NOTES
 	 * --------------------------------------------------------------*/

    async hasLatestReleaseNotesBeenSeen(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.AUTH_USER_SEEN_SERVERVERSION_RELEASE_NOTE, null, true);
        if (response.status != 200) {
            return false;
        }
        const result = await response.json();
        return result?.seen ?? false;
    }

    async getServerVersionReleaseNoteArticleIDAndSetItToSeen(abortController?: AbortController): Promise<MongoObjectIDType> {
        const response = await this._frontend.api.request("GET", APIRoute.AUTH_USER_SERVER_VERSION_RELEASE_NOTE_ARTICLE_ID, {
            language: this._frontend.state.language.getValue()
        }, true, abortController);
        if (!response) return null;
        if (response.status != 200) {
            return null;
        }
        const result = await response.json();
        return result?.articleID
    }

    /* ----------------------------------------------------------------
 	 * GET ALL USERS
 	 * --------------------------------------------------------------*/

    public async getAll(): Promise<IUserData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.USERS, null, true);
        if (response.status != 200) {
            return null;
        }
        return await response.json();
    }


}
