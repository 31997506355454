import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {useEffect, useState} from "react";

/******************************************************************
 * TableLeaderboardTimerCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardTimerCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [value, setValue] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setValue(getValue())
        const interval = setInterval(() => {
            setValue(getValue())
        }, 50)
        return () => clearInterval(interval)
    }, [props.entry])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function getValue(): string {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (session.setup.classificationMode == "teams"
            && entry.type == "primary") {
            return ""
        }
        if (entry.state === "finished") {
            return "–"
        }
        const remainingTime = TimeUtils.getFormattedRemainingTimeFromDuration(entry.latestStint.signalTimestamp, session.setup.duration)
        return remainingTime ?? session.setup.duration
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-timer-cell"
            data-theme={theme()}>
            {value}
        </div>
    );

}
