import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * MailValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class MailValidation {

    static schemaValidator() {
        return {
            validate: {
                validator: value => MailValidation.isValid(value),
                message: ErrorCode.USER_MAIL_INVALID
            },
        }
    };

    static get REGEXP() {
        return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    }

    static isValid(mail: string): boolean {
        return MailValidation.REGEXP.test(mail.toLowerCase());
    }

}
