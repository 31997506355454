import * as React from "react";
import {useState} from "react";
import {LabelButton} from "../../../buttons/label/LabelButton";

/******************************************************************
 * TableLabelButtonCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLabelButtonCell(props: {
    label: string
    align?: "left" | "center" | "right"
    action: () => Promise<Response>
}) {

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function onClicked() {
        setIsBusy(true)
        await props.action()
        setIsBusy(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-labelbutton-cell" data-align={props.align}>
            <LabelButton
                label={props.label}
                style="primary-small"
                progressing={isBusy}
                onClick={onClicked}/>
        </div>
    );
}
