import {FrontendServices} from "../core/FrontendServices";
import {ISessionPresetData} from "../../shared/models/ISessionPresetData";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";

/******************************************************************
 * PresetsAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class PresetsAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    async getSessionPresets(userID: MongoObjectIDType): Promise<ISessionPresetData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.PRESETS_SESSION, {userID}, true)
        if (response.status !== 200) {
            return null
        }
        return await response.json();
    }

    async addSessionPreset(presetData: ISessionPresetData): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.PRESET_SESSION, {preset: presetData}, true)
    }

    async deleteSessionPreset(presetID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("DELETE", APIRoute.PRESET_SESSION, {presetID}, true)
        return response.status === 200
    }

}
