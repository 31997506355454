import * as React from "react";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../../hooks/useServices";
import {useRouteStates} from "../../../../hooks/useRouteStates";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * GroupSidebarMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupSidebarMenu() {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const {dict, router, state} = useServices();
    const {groupPath, isGroupAdmin} = useGroupState()
    const {
        showGroupEvents,
        showGroupTracks,
        showGroupMembers,
        showGroupHome
    } = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <nav className="group-sidebar-menu">
            <main>
                <LabelButton
                    label={dict("group.menu.home")}
                    style="sidebar"
                    selected={showGroupHome}
                    onClick={() => router.showRoute(FrontendRoute.GROUP(groupPath))}/>
                <Divider style="sidebar-menu"/>
                <LabelButton
                    label={dict("group.menu.events")}
                    style="sidebar"
                    icon={(isGroupAdmin) && showGroupEvents ? "add" : null}
                    iconScale={0.8}
                    selected={showGroupEvents}
                    onClick={() => router.showRoute(FrontendRoute.GROUP_EVENTS(groupPath))}
                    onIconClick={(isGroupAdmin) && showGroupEvents && (() => state.showCreateEvent.setValue(true))}/>
                <LabelButton
                    label={dict("group.menu.tracks")}
                    style="sidebar"
                    icon={(isGroupAdmin) && showGroupTracks ? "add" : null}
                    iconScale={0.8}
                    selected={showGroupTracks}
                    onClick={() => router.showRoute(FrontendRoute.GROUP_TRACKS(groupPath))}
                    onIconClick={(isGroupAdmin) && showGroupTracks && (() => state.showCreateTrack.setValue(true))}/>
                <LabelButton
                    label={dict("group.menu.members")}
                    style="sidebar"
                    icon={(isGroupAdmin) && showGroupMembers ? "add" : null}
                    iconScale={0.8}
                    selected={showGroupMembers}
                    onClick={() => router.showRoute(FrontendRoute.GROUP_MEMBERS(groupPath))}
                    onIconClick={(isGroupAdmin)
                        && showGroupMembers
                        && (() => state.showSelectDrivers.setValue(true))}/>
                {isGroupAdmin && <>
                  <Divider style="sidebar-menu"/>
                  <LabelButton
                    label={dict("user.menu.settings")}
                    style="sidebar"
                    onClick={() => state.showGroupSettings.setValue(true)}/>
                </>}
            </main>
        </nav>
    );

}
