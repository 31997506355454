import * as React from "react";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {DriftCarSetupType} from "../../../../../../shared/types/DriftCarSetupType";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * DriftCarSetupInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriftCarSetupInput(props: {
    carSetups: DriftCarSetupType[]
    onChange: (value: DriftCarSetupType[]) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <MultiSelectInput
            label={dict("drift.carsetup")}
            defaultValue={props.carSetups}
            readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly}
            helpTopic="drift.carsetup"
            minSelected={1}
            onChange={props.onChange}
            options={[
                {value: "RACE", text: dict("drift.carsetup.race")},
                {value: "DRIFT", text: dict("drift.carsetup.drift")}
            ]}/>
    );

}
