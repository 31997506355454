import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {ArticleBlockType, IArticleBlockData, IArticleData} from "../../../../../shared/models/IArticleData";
import {ArticleUtils} from "../../../../../shared/utils/ArticleUtils";
import {ArticleUnsupportedBlock} from "./unsupported/ArticleUnsupportedBlock";
import {ArticleBlockActionBar} from "../actionbar/ArticleBlockActionBar";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {useServices} from "../../../hooks/useServices";
import {ArticleMarkdownBlock} from "./markdown/ArticleMarkdownBlock";
import {Headline} from "../../text/headings/Headline";
import {ArticleImageBlock} from "./image/ArticleImageBlock";
import {ArticleYouTubeBlock} from "./youtube/ArticleYouTubeBlock";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {ArticleStyleType} from "../Article";
import {ArticleLinkBlock} from "./link/ArticleLinkBlock";
import {ArticleHeroBlock} from "./hero/ArticleHeroBlock";
import {ArticleFeatureBlock} from "./feature/ArticleFeatureBlock";
import {ArticleTestimonialBlock} from "./testimonial/ArticleTestimonialBlock";

/******************************************************************
 * ArticleBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleBlock(props: {
    article: IArticleData
    block: IArticleBlockData
    style: ArticleStyleType
    editing: boolean
    availableBlocks: ArticleBlockType[]
    onChange: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices()
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [hover, setHover] = useState<boolean>(hasTouch);
    const [addMenuOpen, setAddMenuOpen] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (addMenuOpen == false) {
            setHover(hasTouch);
        }
    }, [addMenuOpen])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function update(): Promise<Response> {
        const response = await api.article.updateArticle(props.article._id, props.article)
        props.onChange()
        return response
    }

    function blockComponent(): ReactElement {
        const blockType = ArticleUtils.getBlockType(props.block)
        if (props.block.hidden && !props.editing) return null
        switch (blockType) {
            case "text":
                return <ArticleMarkdownBlock
                    editing={props.editing}
                    language={props.article.language}
                    data={props.block.text}
                    onChange={update}/>
            case "image":
                return <ArticleImageBlock
                    articleID={props.article._id}
                    editing={props.editing}
                    language={props.article.language}
                    image={props.block.image}
                    onChange={update}/>
            case "youtube":
                return <ArticleYouTubeBlock
                    editing={props.editing}
                    youtube={props.block.youtube}
                    onChange={update}/>
            case "link":
                return <ArticleLinkBlock
                    editing={props.editing}
                    data={props.block.link}
                    onChange={update}/>
            case "hero":
                return <ArticleHeroBlock
                    articleID={props.article._id}
                    editing={props.editing}
                    data={props.block.hero}
                    language={props.article.language}
                    onChange={update}/>
            case "feature":
                return <ArticleFeatureBlock
                    articleID={props.article._id}
                    editing={props.editing}
                    data={props.block.feature}
                    language={props.article.language}
                    onChange={update}/>
            case "testimonial":
                return <ArticleTestimonialBlock
                    editing={props.editing}
                    data={props.block.testimonial}
                    language={props.article.language}
                    onChange={update}/>
            default:
                return <ArticleUnsupportedBlock
                    block={props.block}/>
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="article-block"
            style={{opacity: props.block.hidden && !hover ? 0.3 : null}}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}>
            <ContentLayout framed={props.editing}>
                {props.editing &&
                    <Headline
                        text={dict(`article.block.${ArticleUtils.getBlockType(props.block)}.title`)}
                        style="h5-underlined"
                        nowrap={true}/>}
                {blockComponent()}
                {props.editing && (hover || hasTouch) &&
                    <ArticleBlockActionBar
                        article={props.article}
                        block={props.block}
                        availableBlocks={props.availableBlocks}
                        onToggleAddMenu={setAddMenuOpen}
                        onChange={props.onChange}/>}
            </ContentLayout>
        </div>
    )
}
