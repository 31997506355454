import * as React from "react";
import {useState} from "react";
import {SelectInput, SelectInputOptionType} from "../select/SelectInput";
import {useServices} from "../../../../hooks/useServices";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {isValidLanguage, LanguageType} from "../../../../../../shared/types/LanguageType";
import {SharedConfig} from "../../../../../../shared/config/SharedConfig";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {InfoText} from "../../../text/infotext/InfoText";
import {LanguageFrontendUtils} from "../../../../../utils/LanguageFrontendUtils";
import {ContextMenuSelectable} from "../../../context/elements/ContextMenuSelectable";

/******************************************************************
 * LanguageSelector
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LanguageSelector(props: {
    size?: "small" | "normal",
    style?: "dropdown" | "list"
    showReloadInfo?: boolean
}) {

    /* ----------------------------------------------------------------
      * HOOKS
      * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {authUserData, authUserID, authUserIsUpdating, setAuthUserIsUpdating} = useAuthUser();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [language, setLanguage] = useState<LanguageType>(LanguageFrontendUtils.getLanguageFromHTML(authUserData));
    const [style] = useState<"dropdown" | "list">(props.style ?? "dropdown");

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function languageOptions(): SelectInputOptionType[] {
        return SharedConfig.LANGUAGES.map((language: LanguageType) => {
            return {value: language, text: dict("language." + language)}
        })
    }

    async function updateLanguage(value: LanguageType) {
        if (!isValidLanguage(value)) {
            return
        }
        if (authUserID) {
            setAuthUserIsUpdating(true)
            await api.user.update({language: value})
        }
        localStorage.setItem("language", value)
        state.language.setValue(value)
        setLanguage(value)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout gap="small">
            {style == "dropdown" &&
                <SelectInput
                    options={languageOptions()}
                    processing={!!authUserIsUpdating}
                    size={props.size ?? "normal"}
                    defaultValue={language}
                    onChange={updateLanguage}/>}
            {style == "list" &&
                <ContentLayout gap="none">
                    {languageOptions().map((option: SelectInputOptionType) => {
                        return (
                            <ContextMenuSelectable
                                key={option.value}
                                label={option.text}
                                onClick={() => updateLanguage(option.value as LanguageType)}
                                selected={option.value == language}/>
                        )
                    })}
                </ContentLayout>}
            {props.showReloadInfo &&
                <InfoText
                    text={dict("language.change.reload.info")}
                    size="small"/>}
        </ContentLayout>
    );

}
