import * as React from "react";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {Icon} from "../../icons/Icon";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {IconType} from "../../icons/IconType";
import {UserRoleType} from "../../../../../shared/types/UserRoleType";

/******************************************************************
 * DriverLicenseBadge
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type DriverLicenseBadgeSize = "extra-large" | "large" | "normal" | "tiny"

export function DriverLicenseBadge(props: {
    licenseType: DriverLicenseType
    role?: UserRoleType
    size?: DriverLicenseBadgeSize
    onClick?: () => void
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function scale(): number {
        switch (props.size) {
            case "extra-large":
                return 1.4
            case "large":
                return 1.2
            case "tiny":
            default:
                return 1
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="driverlicense-badge"
            onClick={props.onClick}
            style={{
                cursor: props.onClick ? "pointer" : "default"
            }}>
            <Icon
                type={DriverLicenseUtils.getLicenseIconName(
                    props.licenseType,
                    props.size == "tiny",
                    props.role) as IconType}
                scale={scale()}/>
        </div>
    );

}
