import * as React from "react";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {Table} from "../../../../../ui/table/Table";
import {ISessionPresetData} from "../../../../../../../shared/models/ISessionPresetData";
import {Modal} from "../../../Modal";
import {useServices} from "../../../../../hooks/useServices";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Icon} from "../../../../../ui/icons/Icon";

/******************************************************************
 * DeletePresetsModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DeletePresetsModal(props: {
    onClose: () => void
    onDeleted: () => void
    userPresets: ISessionPresetData[]
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict} = useServices();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function presetsTableColumnDefinition(): ITableColumnDefinition<ISessionPresetData>[] {
        return [{
            dataKey: "name",
            type: "text-bold",
            sortKey: "name",
            columnName: dict("session.presets.name"),
        }, {
            type: "custom",
            columnName: dict("session.presets.training.only"),
            align: "center",
            size: "120px",
            customCell: (key, data) => (
                <ContentLayout
                    key={key}
                    justifyItems="center"
                    alignContent="center">
                    {data.requireSolo ? <Icon type="check"/> : null}
                </ContentLayout>)
        }, {
            type: "delete",
            action: deletePreset,
            size: "40px"
        }]
    }

    async function deletePreset(presetData: ISessionPresetData): Promise<Response> {
        await api.presets.deleteSessionPreset(presetData._id)
        props.onDeleted()
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            className="delete-presets-modal"
            closeAction={props.onClose}>
            <Headline text={dict("session.preset.edit.title")} style="modal"/>
            <ContentLayout framed={true} gap="small">
                <Table<ISessionPresetData>
                    rowsData={props.userPresets}
                    sortKey="name"
                    sortDirection="asc"
                    style="standard-compact"
                    showFilter={props.userPresets.length > 10}
                    visibleRows={10}
                    columnsDefinition={presetsTableColumnDefinition()}/>
            </ContentLayout>
        </Modal>
    );

}
