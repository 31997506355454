import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendServices} from "../core/FrontendServices";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {ContactMessageType} from "../../shared/types/ContactMessageType";

/******************************************************************
 * ContactAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ContactAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async sendMessage(data: {
        senderID: MongoObjectIDType,
        recipientIDs?: MongoObjectIDType[],
        sendToService?: boolean,
        messageType: ContactMessageType,
        message: string
    }): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.CONTACT, data, true)
        if (response && response.status !== 200) {
            const result = await response.json()
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(result)
            })
            return false
        }
        return true
    }
}
