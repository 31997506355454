import * as React from "react";
import {IFileData} from "../../../../../../shared/models/IFileData";
import {Picture, PictureFramingType} from "../../../image/Picture";
import {ContentLayout} from "../../../layout/content/ContentLayout";

/******************************************************************
 * TableImageCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableImageCell(props: {
    file: IFileData
    framing?: PictureFramingType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="table-image-cell"
            justifyItems="center">
            <Picture
                file={props.file}
                width={props.framing == "circle" ? 32 : 40}
                height={props.framing == "circle" ? 32 : 40}
                framing={props.framing}/>
        </ContentLayout>
    );

}
