import * as React from "react";
import {InfoText} from "../../text/infotext/InfoText";

/******************************************************************
 * EmptyDataInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EmptyDataInfo(props: {
    text: string
    size?: "normal" | "small"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="empty-data-info"
            data-size={props.size ?? "normal"}>
            <InfoText
                text={props.text}
                width="small"
                size={props.size ?? "normal"}
                align="center"/>
        </div>
    );

}
