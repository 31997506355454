import {useServices} from "../../../hooks/useServices";
import {useTrackState} from "../../../hooks/useTrackState";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import * as React from "react";
import {useRef} from "react";
import {InfoText} from "../../text/infotext/InfoText";
import {Picture} from "../../image/Picture";
import {useMobileStyle} from "../../../hooks/useMobileStyle";

/******************************************************************
 * TrackConstructInfobox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackConstructInfobox() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {trackInstructionInfo, trackInstructionImage} = useTrackState()
    const [isMobileMode] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="track-construct-infobox"
            framed={true}
            columns={isMobileMode ? 1 : 2}>
            <ContentLayout>
                <Headline
                    text={dict("infobox.track.construct.title")}
                    style="h5-underlined"/>
                <InfoText
                    text={trackInstructionInfo}
                    size="small"/>
            </ContentLayout>
            <Picture
                file={trackInstructionImage}
                framing="rounded-8"
                shadow="large"
                allowMediaViewer={true}/>
        </ContentLayout>
    );

}
