import * as React from "react";
import {useEffect} from "react";
import {Picture} from "../../../image/Picture";
import {Tooltip} from "../../../tooltip/Tooltip";
import {IEventData} from "../../../../../../shared/models/IEventData";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../../hooks/useServices";
import {EventDateInfo} from "../../../info/date/EventDateInfo";
import {EventUtils} from "../../../../../../shared/utils/EventUtils";

/******************************************************************
 * EventMarker
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventMarker(props: {
    event: IEventData
    markerElement: HTMLElement
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = React.useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, dict} = useServices()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        function bringMarkerToFront() {
            if (!props.markerElement) return
            props.markerElement.style.zIndex = "1"
        }

        function resetMarkerZIndex() {
            if (!props.markerElement) return
            props.markerElement.style.zIndex = null
        }

        const markerElement = props.markerElement
        markerElement?.addEventListener("mouseover", bringMarkerToFront)
        markerElement?.addEventListener("mouseout", resetMarkerZIndex)
        return () => {
            markerElement?.removeEventListener("mouseover", bringMarkerToFront)
            markerElement?.removeEventListener("mouseout", resetMarkerZIndex)
        }
    }, [props.markerElement])

    function gotoEvent() {
        router.showRoute(FrontendRoute.EVENT(props.event?.ownerType, props.event?.owner?.path, props.event?.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="event-marker"
            ref={rootRef}
            data-is-live={EventUtils.isLiveEvent(props.event)}
            data-is-upcoming={EventUtils.isUpcomingEvent(props.event)}
            onClick={gotoEvent}>
            <Picture
                file={props.event.image ?? props.event.owner?.avatar}
                defaultSource={require("ASSETS/images/default-event-image.png")}
                fit="cover"
                defaultAspectRatio={1}
                framing="circle"/>
            {EventUtils.isUpcomingEvent(props.event) &&
                <EventDateInfo
                    date={props.event.date}
                    dateUntil={props.event.dateUntil}
                    style="marker"/>
            }
            {EventUtils.isLiveEvent(props.event) &&
                <div className="event-marker-live-indicator">
                    {dict("state.live")}
                </div>}
            <Tooltip
                label={props.event.name}
                positionMode="size"
                hoverElement={props.markerElement}/>
        </div>
    );

}
