import {useEffect, useState} from "react";
import {useServices} from "./useServices";
import {INotification} from "../../utils/interfaces/INotification";

/******************************************************************
 * useNotificationState
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useNotificationState() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [notification, setNotification] = useState<INotification>(state.notification.getValue());

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateState();
        state.notification.onChangeSignal.add(updateState)
        return () => state.notification.onChangeSignal.remove(updateState)
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateState() {
        setNotification(state.notification.getValue())
    }

    /* ----------------------------------------------------------------
     * RETURN
     * --------------------------------------------------------------*/

    return {
        notification,
        setNotification: (notification: INotification) => state.notification.setValue(notification)
    }

}
