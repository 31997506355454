import * as React from "react";

/******************************************************************
 * HiddenIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function HiddenIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="hidden-icon">
            <svg width={20 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.3318 16.943C17.2442 16.9431 17.1575 16.9259 17.0766 16.8924C16.9958 16.8589 16.9223 16.8097 16.8606 16.7476L2.19526 2.08229C2.07553 1.95626 2.00976 1.78844 2.01199 1.61461C2.01421 1.44079 2.08425 1.27471 2.20718 1.15179C2.3301 1.02886 2.49618 0.958823 2.67 0.956598C2.84383 0.954372 3.01165 1.02014 3.13768 1.13987L17.803 15.8052C17.8961 15.8984 17.9596 16.0172 17.9853 16.1464C18.011 16.2757 17.9978 16.4097 17.9473 16.5314C17.8969 16.6532 17.8115 16.7573 17.702 16.8305C17.5924 16.9038 17.4636 16.9429 17.3318 16.943Z" fill="white"/>
                <path d="M9.66583 11.4372L7.50812 9.27953C7.49575 9.26727 7.47986 9.25919 7.46267 9.25643C7.44548 9.25366 7.42785 9.25635 7.41227 9.26412C7.39669 9.27189 7.38393 9.28435 7.37579 9.29974C7.36765 9.31513 7.36453 9.33269 7.36688 9.34994C7.45399 9.90973 7.71684 10.4273 8.11744 10.8279C8.51803 11.2285 9.03563 11.4914 9.59542 11.5785C9.61268 11.5808 9.63023 11.5777 9.64563 11.5696C9.66102 11.5614 9.67347 11.5487 9.68124 11.5331C9.68901 11.5175 9.6917 11.4999 9.68894 11.4827C9.68618 11.4655 9.6781 11.4496 9.66583 11.4372Z" fill="white"/>
                <path d="M10.3324 6.44983L12.4935 8.61004C12.5058 8.62248 12.5217 8.63071 12.539 8.63357C12.5563 8.63643 12.574 8.63377 12.5897 8.62598C12.6054 8.61818 12.6182 8.60565 12.6264 8.59015C12.6345 8.57465 12.6376 8.55697 12.6351 8.53963C12.5482 7.97908 12.2852 7.46073 11.8841 7.05962C11.483 6.65852 10.9646 6.39548 10.4041 6.30859C10.3867 6.30591 10.369 6.30879 10.3533 6.31681C10.3377 6.32483 10.325 6.33758 10.3171 6.35324C10.3091 6.3689 10.3063 6.38668 10.3091 6.40402C10.3118 6.42137 10.32 6.4374 10.3324 6.44983Z" fill="white"/>
                <path d="M19.7899 9.66666C19.9278 9.45017 20.0007 9.19865 20 8.94195C19.9993 8.68525 19.9249 8.43415 19.7857 8.21846C18.6833 6.51362 17.253 5.06542 15.6499 4.0301C13.8738 2.88354 11.9156 2.27734 9.98579 2.27734C8.96843 2.27874 7.95799 2.44462 6.99357 2.76855C6.96658 2.77753 6.94233 2.79326 6.92315 2.81426C6.90396 2.83526 6.89046 2.86082 6.88395 2.88851C6.87743 2.9162 6.87811 2.9451 6.88592 2.97245C6.89374 2.9998 6.90842 3.0247 6.92858 3.04477L8.89673 5.01292C8.91717 5.03341 8.9426 5.04821 8.97051 5.05588C8.99842 5.06355 9.02784 5.06382 9.05588 5.05667C9.72301 4.89409 10.4208 4.90601 11.0819 5.09126C11.7431 5.27652 12.3455 5.62887 12.831 6.11441C13.3166 6.59995 13.6689 7.2023 13.8542 7.86349C14.0394 8.52468 14.0513 9.22243 13.8888 9.88955C13.8817 9.91754 13.882 9.94688 13.8897 9.9747C13.8973 10.0025 13.9121 10.0279 13.9325 10.0483L16.7635 12.8814C16.793 12.9109 16.8324 12.9283 16.874 12.9301C16.9157 12.932 16.9565 12.9181 16.9885 12.8914C18.0782 11.9625 19.0225 10.8755 19.7899 9.66666Z" fill="white"/>
                <path d="M9.99913 12.9434C9.39366 12.9434 8.79609 12.806 8.25147 12.5415C7.70685 12.2769 7.22939 11.8922 6.85509 11.4163C6.4808 10.9404 6.21944 10.3857 6.09071 9.79409C5.96199 9.20246 5.96927 8.58933 6.11199 8.00093C6.11907 7.97295 6.11876 7.94361 6.11109 7.91578C6.10342 7.88796 6.08866 7.8626 6.06825 7.8422L3.2835 5.0562C3.25398 5.02664 3.21442 5.00925 3.17267 5.00747C3.13093 5.0057 3.09004 5.01967 3.05811 5.04662C2.04195 5.91362 1.09996 6.96853 0.238787 8.20133C0.0880443 8.41769 0.00502446 8.67397 0.000276695 8.93762C-0.00447107 9.20127 0.0692676 9.46038 0.212122 9.68202C1.31244 11.4039 2.72814 12.8542 4.30674 13.8754C6.08533 15.0265 7.99598 15.6098 9.9858 15.6098C11.0134 15.607 12.0344 15.4447 13.0122 15.1286C13.0394 15.1199 13.064 15.1043 13.0834 15.0834C13.1029 15.0625 13.1167 15.0369 13.1234 15.0091C13.1302 14.9813 13.1296 14.9523 13.1219 14.9248C13.1142 14.8972 13.0995 14.8722 13.0793 14.852L11.1015 12.8746C11.0811 12.8542 11.0558 12.8395 11.0279 12.8318C11.0001 12.8241 10.9708 12.8238 10.9428 12.8309C10.6338 12.9058 10.317 12.9435 9.99913 12.9434Z" fill="white"/>
            </svg>
        </div>
    );

}
