import {useEffect, useState} from "react";
import {useServices} from "./useServices";

/******************************************************************
 * useLocalStorageState
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useLocalStorage(key: string) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {signal} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [value, setValue] = useState<string>(localStorage.getItem(key))

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        window.addEventListener('storage', onStorageChanged);
        signal.onLocalStorageChanged.add(onLocalStorageChanged)
        return () => {
            window.removeEventListener('storage', onStorageChanged);
            signal.onLocalStorageChanged.remove(onLocalStorageChanged)
        };
    }, [key]);

    /* ----------------------------------------------------------------
     * EVENTS
     * --------------------------------------------------------------*/

    function onStorageChanged(event: StorageEvent) {
        if (event.storageArea === localStorage && event.key === key) {
            setValue(localStorage.getItem(key));
        }
    }

    function onLocalStorageChanged() {
        setValue(localStorage.getItem(key));
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return [value]

}
