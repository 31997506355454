import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ContentLayout} from "../../layout/content/ContentLayout"
import {Table} from "../../table/Table"
import {IEventData} from "../../../../../shared/models/IEventData"
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute"
import {ITableColumnDefinition} from "../../table/ITableColumnDefinition"
import {useSocketSignals} from "../../../hooks/useSocketSignals"
import {useServices} from "../../../hooks/useServices"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {EmptyDataInfo} from "../../info/empty/EmptyDataInfo"
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {GroupInfo} from "../../info/group/GroupInfo";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {TableValueCell} from "../../table/cells/value/TableValueCell";
import {ContextMenu} from "../../context/ContextMenu";
import {ContextMenuLabel} from "../../context/elements/ContextMenuLabel";

/******************************************************************
 * JoinedEventsList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function JoinedEventsList(props: {
    filter?: "visible" | "hidden"
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, router, dict} = useServices()
    const {serverEventUpdate} = useSocketSignals()
    const {routeOwnerID, isRouteOwnerAuthUser, routeOwnerHiddenEvents} = useRouteOwner()
    const [isMobileMode] = useMobileStyle(rootRef, 500)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isInitialized, setIsInitialized] = useState(false)
    const [eventsData, setEventsData] = useState<IEventData[]>()
    const [filteredEventsData, setFilteredEventsData] = useState<IEventData[]>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadEvents()
    }, [serverEventUpdate, routeOwnerID])

    useEffect(() => {
        if (props.filter && routeOwnerHiddenEvents?.length > 0) {
            const filteredEvents = eventsData?.filter(event => isEventHidden(event) == (props.filter == "hidden"))
            setFilteredEventsData(filteredEvents)
        } else {
            setFilteredEventsData(eventsData)
        }
    }, [props.filter, eventsData, routeOwnerHiddenEvents])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadEvents() {
        const result = await api.event.getJoinedEvents(routeOwnerID)
        setEventsData(result)
        setIsInitialized(true)
    }

    async function toggleEventVisibilty(event: IEventData) {
        const eventID = event._id
        if (routeOwnerHiddenEvents.includes(eventID)) {
            const hiddenEvents = routeOwnerHiddenEvents?.filter(hiddenEventID => hiddenEventID != eventID)
            await api.user.update({hiddenEvents})
            return
        }
        const hiddenEvents = [...routeOwnerHiddenEvents, eventID]
        await api.user.update({hiddenEvents})
    }

    function isEventHidden(event: IEventData) {
        return routeOwnerHiddenEvents.includes(event._id)
    }

    function showEvent(data: IEventData) {
        const eventData = eventsData.filter((eventData: IEventData) => eventData._id == data._id)[0]
        router.showRoute(FrontendRoute.EVENT(eventData.ownerType, eventData.owner.path, eventData.path))
    }

    function columnsDefinition(): ITableColumnDefinition<IEventData>[] {
        const columnsDefinition: ITableColumnDefinition<IEventData>[] = [{
            columnName: isMobileMode ? null : dict("event.column.state"),
            type: isMobileMode ? "state-compact" : "state",
            sortKey: "state",
            dataKey: "state",
            size: isMobileMode ? "40px" : "100px",
            align: "center"
        }, {
            type: "image",
            size: "50px",
            value: event => event.image ?? event.owner?.avatar
        }, {
            dataKey: "name",
            sortKey: "name",
            columnName: dict("event.column.name"),
            type: "text-bold",
            size: "4fr"
        }, {
            columnName: dict("event.column.owner"),
            type: "custom",
            size: "3fr",
            sortKey: "owner.name",
            customCell: (key, event) => {
                if (event.ownerType == "user") return <div key={key}></div>
                return <GroupInfo
                    key={key}
                    group={event.owner}
                    style="table-cell"
                    clickable={true}/>
            }
        }, {
            columnName: dict("event.column.date"),
            type: "custom",
            size: "1.8fr",
            sortKey: "__sortTime",
            customCell: (key, event) => {
                let dedicatedDate = TimeUtils.formatFromUntilDate(event.date, event.dateUntil)
                const value = dedicatedDate?.length > 0
                    ? dedicatedDate
                    : "[ " + TimeUtils.formatFromUntilDate(
                    TimeUtils.getDateFromObjectID(event._id).toISOString(), null) + " ]"
                return <TableValueCell
                    key={key}
                    type="text"
                    align="right"
                    style={dedicatedDate ? "normal" : "light"}
                    value={value}/>
            }
        }]
        if (isRouteOwnerAuthUser) {
            columnsDefinition.push({
                size: "40px",
                type: "privacy",
                dataKey: "privacy",
                sortKey: "privacy"
            })
            columnsDefinition.push({
                size: "40px",
                type: "custom",
                customCell: (key, event) => {
                    return <ContentLayout key={key} justifyItems={"center"}>
                        <ContextMenu
                            type="icon"
                            iconType="menuDots"
                            stopPropagation={true}>
                            <ContextMenuLabel
                                icon={isEventHidden(event) ? "visible" : "hidden"}
                                iconScale={0.8}
                                label={isEventHidden(event) ? dict("event.context.show") : dict("event.context.hide")}
                                onClick={() => toggleEventVisibilty(event)}/>
                        </ContextMenu>
                    </ContentLayout>
                }
            })
        }
        return columnsDefinition
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={isInitialized} fullHeight={true}>
            <ContentLayout
                ref={rootRef}
                className="joined-events-list"
                alignSelf={filteredEventsData?.length > 0 ? "start" : "stretch"}
                alignContent={filteredEventsData?.length > 0 ? "start" : "center"}>
                {filteredEventsData?.length > 0
                    ? <Table
                        style="standard"
                        rowsData={filteredEventsData}
                        sortKey="state"
                        showFilter={filteredEventsData?.length > 1}
                        onRowClick={showEvent}
                        visibleRows={25}
                        columnsDefinition={columnsDefinition()}/>
                    : <EmptyDataInfo text={isRouteOwnerAuthUser
                        ? dict("user.events.joined.empty")
                        : dict("user.events.jointd.empty.visitor")}/>}
            </ContentLayout>
        </ComponentInitializer>
    )

}
