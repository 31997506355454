import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";

/******************************************************************
 * TableLeaderboardLastLapTimeCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardLastLapTimeCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function lastLapTime() {
        if (props.entry.isStartOrder) {
            return "";
        }
        return TimeUtils.formatDrivenTime(props.entry.latestStint?.lastLapTime);
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-lastlaptime-cell"
            data-size={size()}
            data-theme={theme()}>
            {lastLapTime()}
        </div>
    );

}
