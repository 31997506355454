import * as React from "react";

/******************************************************************
 * FilterIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FilterIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="add-icon">
            <svg width={14 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.89 0.449707H1.11008C0.568806 0.449707 0.295679 1.10646 0.679204 1.48999L5.375 6.18649V11.4185C5.375 11.6173 5.47201 11.8036 5.63492 11.9177L7.66617 13.3391C8.06691 13.6196 8.625 13.3353 8.625 12.8398V6.18649L13.3209 1.48999C13.7037 1.10722 13.4324 0.449707 12.89 0.449707Z" fill="white"/>
            </svg>
        </div>
    );

}
