import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {PlanHeadline} from "../../../../../ui/text/headings/PlanHeadline";
import {Icon} from "../../../../../ui/icons/Icon";
import {useServices} from "../../../../../hooks/useServices";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {DriverLicenseType} from "../../../../../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../../../../../shared/utils/DriverLicenseUtils";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";

/******************************************************************
 * SubscriptionChangePreviewHeader
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SubscriptionChangePreviewHeader(props: {
    requestedLicense: DriverLicenseType
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {authUserSubscription} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function validUntilText(): string {
        const isUpgrade = DriverLicenseUtils.isUpgrade(props.requestedLicense, DriverLicenseUtils.validLicenseType(authUserSubscription))
        if (isUpgrade) {
            return ""
        }
        return dict("plan.valid.until").replaceAll("{DATE}", TimeUtils.formatDate(authUserSubscription?.nextBilledAt, false, true))
    }

    function validFromText(): string {
        const isUpgrade = DriverLicenseUtils.isUpgrade(props.requestedLicense, DriverLicenseUtils.validLicenseType(authUserSubscription))
        if (isUpgrade) {
            return dict("plan.valid.from.now")
        }
        return dict("plan.valid.from").replaceAll("{DATE}", TimeUtils.formatDate(authUserSubscription?.nextBilledAt, false, true))

    }

    function currentActiveDriverLicenseTitle(): string {
        return dict(`plan.type.${authUserSubscription.driverLicense}.title`)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className={"subscription-change-preview-header"}
            gap="small"
            columnTemplate="1fr min-content 1fr"
            alignItems="center">
            <ContentLayout
                gap="small"
                framed={true}
                alignSelf="stretch"
                alignContent="start">
                <Headline
                    text={dict("plan.activated.label")}
                    style="h6"/>
                <PlanHeadline
                    title={currentActiveDriverLicenseTitle()}
                    size="medium"/>
                <Headline
                    text={validUntilText()}
                    style="h6"/>
            </ContentLayout>
            <Icon type="next" scale={1.2}/>
            <ContentLayout
                gap="small"
                framed={true}
                alignSelf="stretch"
                alignContent="start">
                <Headline
                    text={dict("plan.requested.label")}
                    style="h6"/>
                <PlanHeadline
                    title={dict(`plan.type.${props.requestedLicense}.title`)}
                    size="medium"/>
                <Headline
                    text={validFromText()}
                    style="h6"/>
            </ContentLayout>
        </ContentLayout>
    );

}
