import * as React from "react";
import {DriftTiresType} from "../../../../../../shared/types/DriftTiresType";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {useServices} from "../../../../hooks/useServices";
import {useGroupState} from "../../../../hooks/useGroupState";

/******************************************************************
 * DriftTiresInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriftTiresInput(props: {
    tires: DriftTiresType[]
    onChange: (value: DriftTiresType[]) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <MultiSelectInput
            label={dict("drift.tires")}
            defaultValue={props.tires}
            minSelected={1}
            readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly}
            helpTopic="drift.tires"
            onChange={props.onChange}
            options={[
                {value: "normal", text: dict("drift.tires.normal")},
                {value: "spikes", text: dict("drift.tires.spikes")},
                {value: "gravel_tires", text: dict("drift.tires.gravel")}
            ]}/>
    );

}
