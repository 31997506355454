import * as React from "react";
import {RefObject, useEffect, useRef, useState} from "react";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {ProgressingAnimation} from "../../utils/progress/ProgressingAnimation";
import {useServices} from "../../../hooks/useServices";
import {IFileData} from "../../../../../shared/models/IFileData";
import {Icon} from "../../icons/Icon";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {PictureFramingType} from "../Picture";

/******************************************************************
 * UploadableImage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const UploadableImage = React.forwardRef((props: {
    id?: string
    buttonLabel: string
    imageSrc?: string
    framing?: PictureFramingType
    showUploadButton?: boolean
    uploadMethode?: (formData: FormData) => Promise<Response>
    editable?: boolean
    progressing?: boolean
    aspectRatio?: number
    style?: "absolute" | "relative"
    fit?: "cover" | "contain"
    mediaViewerFile?: IFileData
    onChange?: (file: File) => void
}, ref: RefObject<HTMLDivElement>) => {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {error, state} = useServices();

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const fileInputRef = useRef<HTMLInputElement>();
    const randomIDRef = useRef(Math.random().toString(36).substring(7));

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [uploading, setUploading] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [imageSrc, setImageSrc] = useState<string>()
    const [hoveringUploadButton, setHoveringUploadButton] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setImageSrc(props.imageSrc)
    }, [props.imageSrc])

    useEffect(() => {
        setUploading(props.progressing)
    }, [props.progressing])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function uploadImage() {
        setUploading(true);
        setErrorMessage(null);
        const file = fileInputRef.current.files[0]
        const formData = new FormData()
        formData.append(SharedConfig.FILE_UPLOAD_KEY, file)
        const response = await props.uploadMethode(formData)
        if (response?.status != 200) {
            if (response) {
                const errorJSON = await response.json();
                setErrorMessage(error.createMessage(errorJSON));
            }
        }
        setUploading(false);
    }

    function previewImage() {
        const file = fileInputRef.current.files[0]
        if (!file) return
        setImageSrc(URL.createObjectURL(file))
        if (props.onChange) {
            props.onChange(file)
        }
    }

    function showInMediaViewer(e: React.MouseEvent<HTMLPictureElement, MouseEvent>) {
        if (!props.mediaViewerFile) return
        e.stopPropagation()
        state.showMediaViewer.setValue([{file: props.mediaViewerFile}])
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <figure
            ref={ref}
            className="uploadable-image"
            data-style={props.style ?? "absolute"}
            data-uploading={uploading}
            data-fit={props.fit ?? "cover"}
            data-framing={props.framing ?? "square"}
            data-allow-media-viewer={!!props.mediaViewerFile}
            data-editable={props.editable ? "true" : "false"}
            onClick={!hoveringUploadButton && !uploading && props.mediaViewerFile ? showInMediaViewer : null}
            style={props.aspectRatio ? {aspectRatio: props.aspectRatio} : null}>
            {!!imageSrc && <img src={imageSrc} alt=""/>}
            {props.editable && <>
                {!uploading &&
                    <form
                        className="uploadable-image-edit"
                        data-show-always={!imageSrc || props.showUploadButton}>
                        <label
                            onPointerEnter={() => setHoveringUploadButton(true)}
                            onPointerLeave={() => PromisedDelay.wait(0.5).then(() => setHoveringUploadButton(false))}
                            htmlFor={props.id ?? randomIDRef.current}>
                            {props.buttonLabel}
                        </label>
                        <input
                            onChange={props.uploadMethode ? uploadImage : previewImage}
                            ref={fileInputRef}
                            id={props.id ?? randomIDRef.current}
                            type="file"
                            accept="image/png, image/jpeg"/>
                    </form>}
                {uploading && <ProgressingAnimation/>}
                {!!errorMessage && <div className="uploadable-image-error">{errorMessage}</div>}
            </>}
            {!hoveringUploadButton && !!props.mediaViewerFile &&
                <div className="picture-zoom-icon">
                    <Icon type="zoom" scale={props.editable ? 0.8 : 1}/>
                </div>}
        </figure>
    );
})
