import * as React from "react";
import {useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {Article} from "../../../../../ui/article/Article";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {EditModeActionBar} from "../../../academy/bars/EditModeActionBar";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";

/******************************************************************
 * GroupAboutSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupAboutSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, language, state} = useServices()
    const {groupAboutArticle, groupID} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [editMode, setEditMode] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function buttonAction() {
        if (editMode) {
            return setEditMode(false)
        }
        return setEditMode(true)
    }

    async function createArticle() {
        const article = await api.article.createArticle("group.about." + groupID, language)
        if (article && article._id) {
            await api.group.update(groupID, {aboutArticle: article._id})
            setEditMode(true)
        }
    }

    function showConfirmModal() {
        state.showConfirm.setValue({
            type: "deleteGroupAboutArticle",
            payload: {
                groupID: groupID,
                articleID: groupAboutArticle?._id ?? groupAboutArticle
            }
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout className="group-about-settings" alignSelf="start">
            {!editMode &&
                <Spacer height="normal"/>}
            {groupAboutArticle &&
                <Article
                    style="compact"
                    hideTypeSelect={true}
                    hideTitle={true}
                    hideSubTitle={true}
                    availableBlocksWhenEditing={["text", "image", "youtube"]}
                    articleID={groupAboutArticle?._id ?? groupAboutArticle}
                    emptyDataInfo={dict("group.about.article.empty.data.info")}
                    edit={editMode}/>}
            {!groupAboutArticle &&
                <ContentLayout justifyItems="center">
                    <Spacer height="large"/>
                    <InfoText
                        text={dict("group.about.article.create")}
                        align="center"/>
                    <LabelButton
                        label={dict("group.about.article.create.button")}
                        style="primary"
                        onClick={createArticle}/>
                </ContentLayout>}
            {groupAboutArticle &&
                <EditModeActionBar
                    editMode={editMode}
                    onRequestDelete={showConfirmModal}
                    onEditModeChange={buttonAction}/>}
        </ContentLayout>
    </>

}
