import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useGroupState} from "../../../hooks/useGroupState";
import {IUserData} from "../../../../../shared/models/IUserData";
import {GroupMemberRoleType} from "../../../../../shared/types/GroupMemberRoleType";
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * MemberContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MemberContextMenu(props: {
    member: IUserData
    role: GroupMemberRoleType
    onChange?: (showFlyout: boolean) => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {authUserID} = useAuthUser();
    const {groupID, isGroupAdmin, groupOwner, groupMembers} = useGroupState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function changeMemberRole(role: GroupMemberRoleType) {
        const members = groupMembers.map(member => {
            if (member.user._id == props.member._id) {
                member.role = role
            }
            return member
        })
        await api.group.update(groupID, {members: members})
    }

    async function removeMember() {
        const members = groupMembers.filter(member => member.user._id != props.member._id)
        await api.group.update(groupID, {members: members})
    }

    function showRoles(): boolean {
        const isAuthUser = authUserID == props.member._id
        const isGroupOwner = groupOwner?._id == props.member._id
        if (isGroupOwner || isAuthUser) return false
        return isGroupAdmin
    }

    function showRemove(): boolean {
        const isAuthUser = authUserID == props.member._id
        const isGroupOwner = groupOwner?._id == props.member._id
        if (isGroupOwner) return false
        return isGroupAdmin || isAuthUser
    }

    function removeLabel(): string {
        const isAuthUser = authUserID == props.member._id
        if (isAuthUser) {
            return dict("group.member.leave.self.label")
        }
        return dict("group.member.leave.label");
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconSize="small"
            iconScale={0.7}
            stopPropagation={true}
            enabled={true}
            onToggle={props.onChange}>
            {showRoles() && <>
              <ContextMenuSelectable
                label={dict("group.member.role.admin")}
                onClick={() => changeMemberRole("admin")}
                selected={props.role == "admin"}/>
              <ContextMenuDivider style="small"/>
              <ContextMenuSelectable
                label={dict("group.member.role.member")}
                onClick={() => changeMemberRole("member")}
                selected={props.role == "member"}/>
              <ContextMenuDivider style="normal"/>
            </>}
            {showRemove() && <ContextMenuLabel
              label={removeLabel()}
              icon="leave"
              warning={true}
              onClick={removeMember}/>}
        </ContextMenu>
    );

}
