/******************************************************************
 * CharUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CharUtils {

    static replaceZeroWithO(fontText: string | number): string {
        if (fontText == undefined) return undefined
        if (!fontText) return null
        return fontText?.toString()?.replace(/0/g, "O")
    }

}
