import * as React from "react";
import {ReactElement} from "react";
import {StarIcon} from "../../../icons/StarIcon";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {CharUtils} from "../../../../../../shared/utils/CharUtils";

/******************************************************************
 * TableLeaderboardStintsCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardStintsCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function primaryValue(): string {
        const entry = props.entry
        if (entry.isStartOrder) {
            return "";
        }
        let totalStints = entry.overall.drivenStints?.toString();
        let availableStints = entry.session.setup.stints;
        if (totalStints === undefined || totalStints === "0") {
            totalStints = "–"
        }
        if (availableStints === undefined || availableStints === 0) {
            return totalStints;
        }
        switch (entry.type) {
            case "secondary-team":
                return totalStints;
        }
        return `${totalStints}/${availableStints}`;
    }

    function secondaryValue(): string {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        if (entry.isStartOrder) {
            return undefined
        }
        switch (session.setup.mode) {
            case "lap":
                if (!entry.bestLapStint) {
                    return undefined
                }
                return ((entry.bestLapStint.stintNumber ?? 0) + 1).toString()
            case "race":
                switch (session.setup.timing) {
                    case "async":
                        if (!entry.bestDrivenTimeStint) {
                            return undefined
                        }
                        return ((entry.bestDrivenTimeStint.stintNumber ?? 0) + 1).toString()
                }
                break
            case "gymkhana":
                if (!entry.bestGymkhanaStint?.score || entry.bestGymkhanaStint?.score == 0) {
                    return undefined
                }
                return ((entry.bestGymkhanaStint.stintNumber ?? 0) + 1).toString()
        }
        return undefined
    }

    function size() {
        switch (props.entry.type) {
            case "primary":
                return "normal"
            case "secondary-team":
                return "small"

        }
    }

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    function secondaryPrefixIcon(): ReactElement {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        switch (session.setup.classificationMode) {
            case "teams":
                switch (entry.type) {
                    case "primary":
                        switch (session.setup.teamScoringMode) {
                            case "average":
                            case "sum":
                                return null
                        }
                }
        }
        return <StarIcon scale={0.5}/>
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-stints-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && props.entry.session.setup.useStintApproval}
            data-theme={theme()}
            data-size={size()}>
            <div className="table-leaderboard-stints-cell-primary">
                {CharUtils.replaceZeroWithO(primaryValue())}
            </div>
            {secondaryPrefixIcon()
                && secondaryValue()
                && <div className="table-leaderboard-stints-cell-secondary">
                    {secondaryPrefixIcon()} {CharUtils.replaceZeroWithO(secondaryValue())}
                </div>}
        </div>
    );

}
