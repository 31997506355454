import {APIRoute} from "../../shared/routes/APIRoute";
import {FrontendServices} from "../core/FrontendServices";
import {IDictionaryEntryData} from "../../shared/models/IDictionaryEntryData";

/******************************************************************
 * DictionaryAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class DictionaryAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async addEntry(data: IDictionaryEntryData): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.DICTIONARY_ENTRY, data, true)
    }

    public async deleteEntry(data: { _id: string }): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.DICTIONARY_ENTRY, data, true)
    }

    public async updateEntry(data: IDictionaryEntryData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.DICTIONARY_ENTRY, data, true)
    }

    public async translateEntry(data: IDictionaryEntryData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.DICTIONARY_ENTRY_TRANSLATE, data, true)
    }

    public async getEntries(): Promise<IDictionaryEntryData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.DICTIONARY)
        return await response.json();
    }
}
