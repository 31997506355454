import * as React from "react"
import {useEffect, useRef, useState} from "react"
import {ContentLayout} from "../../../ui/layout/content/ContentLayout"
import {useServices} from "../../../hooks/useServices"
import {ActionBar} from "../../../ui/bar/ActionBar"
import {LabelButton} from "../../../ui/buttons/label/LabelButton"
import {useRouteOwner} from "../../../hooks/useRouteOwner"
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData"
import {TrackTeaser} from "../../../ui/teaser/track/TrackTeaser"
import {useSocketSignals} from "../../../hooks/useSocketSignals"
import {useRouteStates} from "../../../hooks/useRouteStates"
import {useGroupState} from "../../../hooks/useGroupState"
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {EmptyDataInfo} from "../../../ui/info/empty/EmptyDataInfo";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {Headline} from "../../../ui/text/headings/Headline";
import {Divider} from "../../../ui/utils/divider/Divider";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../../../../../shared/types/PlanAccessType";
import {GroupUtils} from "../../../../../shared/utils/GroupUtils";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {FrontendConfig} from "../../../../core/FrontendConfig";

/******************************************************************
 * TracksPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TracksPage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {routeType} = useRouteStates()
    const {
        routeOwnerID,
        isRouteOwnerAuthUser,
        routeOwnerValidDriverLicense,
        routeOwnerUseSubscription
    } = useRouteOwner()
    const {groupID, isGroupAdmin, groupData} = useGroupState()
    const {serverTrackUpdate} = useSocketSignals()
    const [isMobile] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [activeTracks, setActiveTracks] = useState<ITrackData[]>()
    const [inactiveTracks, setInactiveTracks] = useState<ITrackData[]>()
    const [columnData, setColumnData] = useState<ITrackData[][]>([]);
    const [numColumns, setNumColumns] = useState<number>(computeNumColumns())
    const [loading, setLoading] = useState<boolean>(true)
    const [maxTracksAllowed, setMaxTracksAllowed] = useState<number>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadOwnerTracks()
    }, [routeOwnerID, groupID, serverTrackUpdate, routeOwnerValidDriverLicense, routeOwnerUseSubscription])

    useEffect(() => {
        setNumColumns(computeNumColumns())
    }, [isMobile])

    useEffect(() => {
        const columns = Array.from({length: numColumns}, () => []);
        activeTracks?.sort((a, b) => {
            if (!a._id) return -1
            if (!b._id) return 1
            return b._id.localeCompare(a._id)
        })
        activeTracks?.forEach((track: ITrackData, i: number) => {
            const columnIndex = i % numColumns;
            columns[columnIndex].push(track);
        });
        setColumnData(columns);
    }, [numColumns, activeTracks]);

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadOwnerTracks() {
        setLoading(true)
        let tracks: ITrackData[]
        switch (routeType) {
            case "member":
                tracks = await api.track.ownerTracks(routeOwnerID, "user")
                break
            case "group":
                tracks = await api.track.ownerTracks(groupID, "group")
                break
        }
        setActiveTracks(tracks?.filter(track => track._notAllowedToUse != true))
        setInactiveTracks(tracks?.filter(track => track._notAllowedToUse == true))
        setMaxTracksAllowed(computeMaxTracksAllowed())
        setLoading(false)
    }

    function computeNumColumns(inactiveTracks?: boolean) {
        return isMobile ? (inactiveTracks ? 2 : 1) : (inactiveTracks ? 3 : 2)
    }

    function computeMaxTracksAllowed() {
        switch (routeType) {
            case "member":
                if (!routeOwnerUseSubscription) return undefined
                return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("tracks", routeOwnerValidDriverLicense)?.value
            case "group":
                const subscriptionUser = GroupUtils.getMemberWithHighestSubscription(groupData)
                if (!subscriptionUser) return undefined
                if (FrontendConfig.PADDLE_ENVIRONMENT == "sandbox"
                    && GroupUtils.hasMembersWithNoSubscription(groupData)) {
                    return undefined
                }
                const validDriverLicense = DriverLicenseUtils.validLicenseType(subscriptionUser?.subscription)
                return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("tracks", validDriverLicense)?.value
        }
        return null
    }

    function isOwner() {
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    function inactiveTracksTitle(): string {
        if (!inactiveTracks || inactiveTracks?.length == 0) {
            return dict("tracks.max.reached")
        }
        if (inactiveTracks?.length == 1) {
            return dict("track.inactive.single.title")
        }
        switch (routeType) {
            case "member":
                return dict("track.inactive.multiple.title")
                    .replaceAll("{NUM_INACTIVE_TRACKS}", inactiveTracks?.length.toString())
            case "group":
                return dict("track.inactive.multiple.group.title")
                    .replaceAll("{NUM_INACTIVE_TRACKS}", inactiveTracks?.length.toString())
        }
        return ""
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!loading}>
            <ContentLayout
                ref={ref}
                alignContent={activeTracks?.length > 0 ? "start" : "stretch"}
                alignItems={activeTracks?.length > 0 ? "start" : "center"}>
                {activeTracks?.length > 0 && <div>
                    <Spacer height="normal"/>
                    <Headline
                        text={dict("user.menu.tracks")}
                        align="center"
                        style="h1"/>
                    <Spacer height="large"/>
                </div>}
                {activeTracks?.length > 0
                    ? <ContentLayout>
                        <ContentLayout columns={numColumns} gap="large">
                            {columnData?.map((column: ITrackData[], index) => (
                                <ContentLayout
                                    key={index}
                                    gap="medium-to-large">
                                    {column.map(trackData =>
                                        <TrackTeaser
                                            key={trackData._id}
                                            data={trackData}/>)}
                                </ContentLayout>
                            ))}
                        </ContentLayout>
                        {!!maxTracksAllowed && activeTracks?.length >= maxTracksAllowed && <>
                            <Spacer height="normal"/>
                            <ContentLayout framed={isOwner()}>
                                {isOwner() && <Spacer height="small"/>}
                                <Headline
                                    text={inactiveTracksTitle()}
                                    align="center"
                                    style={isOwner() ? "h3" : "h5"}/>
                                {isOwner() &&
                                    <ContentLayout justifyItems={"center"}>
                                        <InfoText
                                            text={dict("subscription.upgrade.info.tracks")}
                                            align="center"/>
                                        <LabelButton
                                            label={dict("subscription.upgrade.info.button.showPlans")}
                                            style="primary-small"
                                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                                    </ContentLayout>}
                                {isOwner() &&
                                    <ContentLayout
                                        columns={computeNumColumns(true)}
                                        gap="medium">
                                        {inactiveTracks?.map((trackData) => {
                                            return <TrackTeaser
                                                key={trackData._id}
                                                inactive={true}
                                                data={trackData}/>
                                        })}
                                    </ContentLayout>}
                            </ContentLayout>
                        </>}
                    </ContentLayout>
                    : <ContentLayout gap="small" justifyItems="center">
                        <EmptyDataInfo text={isOwner()
                            ? dict("track.owner.empty.info")
                            : dict("track.guest.empty.info")}/>
                        <LabelButton
                            label={dict("track.empty.academy.button.label")}
                            style="secondary"
                            icon="academy"
                            onClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}/>
                    </ContentLayout>}
            </ContentLayout>
            {isOwner() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("track.create.button.label")}
                        icon="plus"
                        onClick={() => state.showCreateTrack.setValue(true)}
                        style={"action-main"}/>
                    <Divider style="action-bar"/>
                    <IconButton
                        type="academy"
                        size="small"
                        onClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}/>
                    <ActionBarSpacer/>
                </ActionBar>}
        </ComponentInitializer>
    )
}
