import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {useGroupState} from "../../../../hooks/useGroupState";
import {useServices} from "../../../../hooks/useServices";
import {OwnedEventsInfobox} from "../../../../ui/infobox/events/OwnedEventsInfobox";
import {TracksInfobox} from "../../../../ui/infobox/tracks/TracksInfobox";
import {GroupMembersInfobox} from "../../../../ui/infobox/members/GroupMembersInfobox";
import {GroupHomeHeader} from "./header/GroupHomeHeader";
import {useDeviceInfo} from "../../../../hooks/useDeviceInfo";
import {ArticleReaderBox} from "../../../../ui/article/reader/ArticleReaderBox";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {GroupUtils} from "../../../../../../shared/utils/GroupUtils";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";

/******************************************************************
 * GroupHomePage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GroupHomePage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserData, authUserID} = useAuthUser()
    const {state, dict} = useServices()
    const [isMobileMode, isMobileModeAvailable] = useMobileStyle(rootRef, 600)
    const {groupContact, groupData, groupPrivacy, isGroupAdmin, groupAboutArticle} = useGroupState()
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showContactButton(): boolean {
        if (!groupContact) return false
        if (groupContact._id == authUserID) return false
        if (groupPrivacy === "public") return true
        return GroupUtils.isMember(groupData, authUserData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={rootRef}
            className="group-home-page"
            gap={isMobile ? "large" : "medium"}
            alignContent="start">
            <GroupHomeHeader
                group={groupData}
                showAvatar={isMobile}
                editable={isGroupAdmin}/>
            <ComponentInitializer isPropertyAvailable={isMobileModeAvailable}>
                {isMobileMode
                    ? <ContentLayout>
                        {groupAboutArticle &&
                            <ArticleReaderBox
                                type="group"
                                articleID={groupAboutArticle._id ?? groupAboutArticle}/>}
                        <OwnedEventsInfobox/>
                        <GroupMembersInfobox
                            group={groupData}/>
                        <TracksInfobox group={groupData}/>
                    </ContentLayout>
                    : <ContentLayout>
                        {groupAboutArticle &&
                            <ArticleReaderBox
                                type="group"
                                articleID={groupAboutArticle._id ?? groupAboutArticle}/>}
                        <ContentLayout columns={2}>
                            <ContentLayout>
                                <OwnedEventsInfobox/>
                                <TracksInfobox group={groupData}/>
                            </ContentLayout>
                            <ContentLayout>
                                <GroupMembersInfobox
                                    group={groupData}/>
                            </ContentLayout>
                        </ContentLayout>
                    </ContentLayout>}
            </ComponentInitializer>
            {authUserData && (showContactButton() || isGroupAdmin) &&
                <ActionBar location="main">
                    {isGroupAdmin &&
                        <ActionBarSpacer/>}
                    {isGroupAdmin &&
                        <IconButton
                            type="settings"
                            size="small"
                            onClick={() => state.showGroupSettings.setValue(true)}/>}
                    {isGroupAdmin &&
                        <Divider style={"action-bar"}/>}
                    {isGroupAdmin &&
                        <IconButton
                            type="academy"
                            size="small"
                            onClick={() => state.showAcademy.setValue(dict("academy.id.groups"))}/>}
                    {isGroupAdmin && !showContactButton() &&
                        <ActionBarSpacer/>}
                    {isGroupAdmin && showContactButton() &&
                        <Divider style={"action-bar"}/>}
                    {showContactButton() &&
                        <LabelButton
                            label={dict("group.contact.label")}
                            style={"action-main"}
                            onClick={() => state.showContact.setValue({
                                sendMessageTo: groupData,
                                messageType: "group"
                            })}/>}
                </ActionBar>}
        </ContentLayout>
    </>

}
