import {SelectInputOptionType} from "../components/ui/form/elements/select/SelectInput";
import {CarPropertyTypeValidation} from "../../shared/validation/CarPropertyTypeValidation";
import {MultiSelectOptionType} from "../components/ui/form/elements/select/options/MultiSelectOption";
import {CarStateValidation} from "../../shared/validation/CarStateValidation";
import {CAR_CHASSIS} from "../../shared/types/CarChassisType";

/******************************************************************
 * CarUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CarUtils {

    static chassisOptions(dict: (key: string) => string): SelectInputOptionType[] {
        return CAR_CHASSIS.map(chassis => {
            return {
                value: chassis,
                text: dict("car.chassis." + chassis)
            }
        })
    }

    static stateOptions(dict: (key: string) => string): SelectInputOptionType[] {
        return CarStateValidation.STATES.map(state => {
            return {
                value: state,
                text: dict("car.state." + state)
            }
        })
    }

    static propertyOptions(dict: (key: string) => string): MultiSelectOptionType[] {
        return CarPropertyTypeValidation.PROPERTIES.map(prop => {
            return {
                value: prop,
                text: dict("car.properties." + prop)
            }
        })
    }
}
