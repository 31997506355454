/******************************************************************
 * MailRecipientsFilterType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const MailRecipientsFilters = ["verified", "newsletter", "custom"] as const

export type MailRecipientsFilterType = typeof MailRecipientsFilters[number]

export function isValidMailRecipientsFilter(mailRecipientsFilter: string): boolean {
    return MailRecipientsFilters.includes(mailRecipientsFilter as MailRecipientsFilterType)
}
