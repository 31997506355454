import * as React from "react";

/******************************************************************
 * FlagIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FlagIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="chart-icon">
            <svg width={16 * (props.scale ?? 1)} height={16 * (props.scale ?? 1)} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4009 3.17204C17.1468 2.84485 18 3.35874 18 4.13546V12.2071C18 12.5566 17.8186 12.8847 17.5133 13.0823C16.2534 13.8979 14.9 14.4389 13.2065 14.4389C10.8379 14.4389 9.28202 13.284 7.39811 13.284C5.6103 13.284 4.37031 13.618 3.375 14.0185V17.1529C3.375 17.593 2.99725 17.9498 2.53125 17.9498H1.96875C1.50275 17.9498 1.125 17.593 1.125 17.1529V4.33471C0.615129 3.99869 0.28125 3.44091 0.28125 2.80919C0.28125 1.75652 1.20744 0.908181 2.3322 0.951411C3.33302 0.98986 4.15255 1.74722 4.21485 2.69139C4.23313 2.96837 4.18637 3.23293 4.08878 3.47372C4.81778 3.22038 5.60366 3.07482 6.48098 3.07482C8.84957 3.07482 10.4055 4.22972 12.2894 4.22972C13.7131 4.22972 15.1937 3.70159 16.4009 3.17204V3.17204ZM3.375 5.41995V7.76044C4.39453 7.40616 5.1743 7.16843 5.9625 7.06616V4.68982C5.13633 4.76187 4.54078 5.01488 3.375 5.41995V5.41995ZM11.1375 5.72509C10.2088 5.56997 9.3837 5.26094 8.55 5.01654V7.25509C9.3999 7.47775 10.2222 7.80108 11.1375 7.99585V5.72509ZM5.9625 7.06616V9.39171C7.11548 9.29283 7.85777 9.37063 8.55 9.51788V7.25509C7.66241 7.02151 6.91239 6.94222 5.9625 7.06616V7.06616ZM8.55 11.7886C9.47595 11.9431 10.2984 12.2511 11.1375 12.4972V10.259C10.284 10.035 9.46617 9.71278 8.55 9.51788V11.7886V11.7886ZM3.375 12.3086C4.20539 12.03 5.05512 11.8491 5.9625 11.7588V9.39171C5.04457 9.46974 4.2975 9.65866 3.375 9.96812V12.3086ZM16.3125 4.95079C15.5637 5.24464 14.6746 5.5365 13.725 5.69687V8.0845C14.6851 7.93974 15.4839 7.61634 16.3125 7.29128V4.95079V4.95079ZM16.3125 11.9221V9.58163C15.5324 10.0531 14.5976 10.3324 13.725 10.4456V12.8223C14.6064 12.7432 15.4297 12.4557 16.3125 11.9221V11.9221ZM11.1375 7.99585V10.259C12.0397 10.4958 12.7763 10.5692 13.725 10.4456V8.0845C12.811 8.22353 11.9692 8.17336 11.1375 7.99585V7.99585Z" fill="white"/>
            </svg>
        </div>
    );

}
