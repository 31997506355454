import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IEventData} from "../../shared/models/IEventData";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {IFolderData} from "../../shared/models/submodels/IFolderData";
import {EventOwnerType} from "../../shared/types/EventOwnerType";
import {EventChildrenType} from "../../shared/types/EventChildrenType";
import {IUserData} from "../../shared/models/IUserData";

/******************************************************************
 * EventAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class EventAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async createEvent(data: {
        name: string
        ownerID: MongoObjectIDType
        ownerType: EventOwnerType
    }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.EVENT, data, true);
    }

    public async canCreateEvent(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENT_CAN_CREATE, {}, true)
        if (response.status == 200) {
            return true
        }
        return false
    }

    public async getEventByRoute(eventRoute: string): Promise<IEventData> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENT, {eventRoute}, true);
        const result = await response.json();
        if (response.status == 200) {
            return result
        } else {
            this._frontend.state.showMessage.setValue({
                type: "normal",
                message: this._frontend.error.createMessage(result)
            });
        }
        return null;
    }

    public async getEventByID(eventID: MongoObjectIDType) {
        const response = await this._frontend.api.request("GET", APIRoute.EVENT, {eventID}, true);
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getFinderEvents(): Promise<IEventData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENTS_FINDER, {}, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async arePublicEventsLive(): Promise<boolean> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENTS_PUBLIC_LIVE, {})
        if (response.status == 200) {
            return await response.json();
        }
        return false;
    }

    public async getOwnerEvents(ownerID: MongoObjectIDType, ownerType: EventOwnerType): Promise<IEventData[]> {
        if (!ownerID || !ownerType) return null;
        const response = await this._frontend.api.request("GET", APIRoute.EVENTS_CREATED, {
            ownerID: ownerID,
            ownerType: ownerType
        }, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getJoinedEvents(userID: MongoObjectIDType): Promise<IEventData[]> {
        if (!userID) return null;
        const response = await this._frontend.api.request("GET", APIRoute.EVENTS_JOINED, {userID}, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async driverRequest(eventID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.EVENT_DRIVER_REQUEST, {eventID}, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
            return false
        }
        return true
    }

    public async registerDriver(eventID: MongoObjectIDType, userID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.EVENT_DRIVER, {eventID, userID}, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
            return false
        }
        return true
    }

    public async changeDriverToPending(eventID: MongoObjectIDType, userID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.EVENT_DRIVER_PENDING, {eventID, userID}, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
            return false
        }
        return true
    }

    public async removeDriver(eventID: MongoObjectIDType, userID: MongoObjectIDType): Promise<boolean> {
        const response = await this._frontend.api.request("DELETE", APIRoute.EVENT_DRIVER, {eventID, userID}, true);
        if (response.status != 200) {
            const jsonResponse = await response.json();
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(jsonResponse)
            });
            return false
        }
        return true
    }

    public async getChildren(eventID: string): Promise<EventChildrenType> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENT_CHILDREN, {eventID}, true)
        if (response?.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getUsersDrivenByEvent(eventID: MongoObjectIDType): Promise<IUserData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.EVENT_DRIVERS_DRIVEN, {eventID}, true)
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async uploadImage(data: FormData): Promise<Response> {
        return await this._frontend.api.upload(APIRoute.EVENT_HERO_UPLOAD, data);
    }

    public async update(eventID: MongoObjectIDType, data: IEventData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.EVENT, {eventID: eventID, ...data}, true);
    }

    public async addFolder(eventID: MongoObjectIDType, data: IFolderData): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.EVENT_CHILDREN_FOLDER, {eventID: eventID, ...data}, true);
    }

    public async renameFolder(eventID: MongoObjectIDType, folderID: MongoObjectIDType, name: string): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.EVENT_CHILDREN_FOLDER, {
            eventID: eventID,
            folderID: folderID,
            name: name
        }, true);
    }

    public async deleteFolder(eventID: MongoObjectIDType, folderID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.EVENT_CHILDREN_FOLDER, {
            eventID: eventID,
            folderID: folderID
        }, true);
    }

    public async updateOrder(eventID: MongoObjectIDType, order: MongoObjectIDType[]): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.EVENT_CHILDREN_ORDER, {
            eventID: eventID,
            order: order
        }, true);
    }

    public async delete(eventID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.EVENT, {eventID: eventID}, true);
    }

}
