import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../ui/text/headings/Headline";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {IUserData} from "../../../../../shared/models/IUserData";
import {DriverList} from "../../../ui/list/driver/DriverList";

/******************************************************************
 * SelectUserModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SelectUserModal(props: {
    onRequestClose: () => void
    onUserSelect: (user: IUserData) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [allUsers, setAllUsers] = useState<IUserData[]>()
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadUsers()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadUsers() {
        setIsLoadingData(true)
        setAllUsers(await api.user.getAll())
        setIsLoadingData(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="user-select-modal"
            width="medium"
            closeAction={props.onRequestClose}>
            <ContentLayout>
                <Headline text={dict("modal.user.select.title")} style="modal"/>
                <DriverList
                    data={allUsers}
                    primaryIconType="add"
                    isBusy={isLoadingData}
                    userClickable={false}
                    emptyDataInfo={dict("users.empty")}
                    onPrimaryIconClick={(userID: string) => {
                        props.onUserSelect(allUsers.find(user => user._id === userID))
                    }}/>
            </ContentLayout>
        </Modal>
    );

}
