/******************************************************************
 * useEnviroment
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useEnviroment() {

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        isDevEnviroment: process.env.NODE_ENV === "development"
    }

}
