import {useEffect, useState} from "react";
import {IUserData} from "../../../shared/models/IUserData";
import {useServices} from "./useServices";
import {IFileData} from "../../../shared/models/IFileData";
import {IUserSubscription} from "../../../shared/models/submodels/IUserSubscription";
import {DriverLicenseType} from "../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../shared/utils/DriverLicenseUtils";
import {IGroupData} from "../../../shared/models/IGroupData";
import {SubscriptionUtils} from "../../utils/SubscriptionUtils";
import {UserRoleType} from "../../../shared/types/UserRoleType";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";

/******************************************************************
 * useRouteOwner Hook
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useRouteOwner() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [routeOwnerData, setRouteOwnerData] = useState<IUserData | IGroupData>(state.routeOwner.getValue());
    const [routeOwnerID, setRouteOwnerID] = useState<string>(state.routeOwner.getValue()?._id);
    const [routeOwnerPath, setRouteOwnerPath] = useState<string>(state.routeOwner.getValue()?.path);
    const [routeOwnerAvatar, setRouteOwnerAvatar] = useState<IFileData>(state.routeOwner.getValue()?.avatar);
    const [routeOwnerNick, setRouteOwnerNick] = useState<string>(userData()?.nick);
    const [routeOwnerRole, setRouteOwnerRole] = useState<UserRoleType>(userData()?.role);
    const [routeOwnerName, setRouteOwnerName] = useState<string>(state.routeOwner.getValue()?.name);
    const [routeOwnerHiddenEvents, setRouteOwnerHiddenEvents] = useState<MongoObjectIDType[]>(userData()?.hiddenEvents);
    const [isRouteOwnerAuthUser, setIsRouteOwnerAuthUser] = useState<boolean>(isAuthUser());
    const [isRouteOwnerGroup, setIsRouteOwnerGroup] = useState<boolean>(isGroup());
    const [isRouteOwnerUser, setIsRouteOwnerUser] = useState<boolean>(isUser());
    const [routeOwnerSubscription, setRouteOwnerSubscription] = useState<IUserSubscription>(getRouteOwnerSubscription());
    const [routeOwnerHasUnpaidSubscription, setRouteOwnerHasUnpaidSubscription] = useState<boolean>(hasUnpaidSubscription());
    const [routeOwnerValidDriverLicense, setRouteOwnerValidDriverLicense] = useState<DriverLicenseType>(getValidDriverLicense());
    const [routeOwnerUseSubscription, setRouteOwnerUseSubscription] = useState<boolean>(useSubscription());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        updateStates();
        state.routeOwner.onChangeSignal.add(updateStates);
        state.authUser.onChangeSignal.add(updateStates);
        return () => {
            state.routeOwner.onChangeSignal.remove(updateStates);
            state.authUser.onChangeSignal.remove(updateStates);
        }
    }, [])

    useEffect(() => {
        updateStates();
    }, [routeOwnerID])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateStates() {
        const routeOwner: IUserData = state.routeOwner.getValue();
        setRouteOwnerData(routeOwner);
        setRouteOwnerPath(routeOwner?.path);
        setRouteOwnerAvatar(routeOwner?.avatar);
        setRouteOwnerNick(routeOwner?.nick);
        setRouteOwnerName(routeOwner?.name);
        setRouteOwnerRole(routeOwner?.role);
        setRouteOwnerID(routeOwner?._id);
        setRouteOwnerHiddenEvents(routeOwner?.hiddenEvents);
        setRouteOwnerSubscription(getRouteOwnerSubscription());
        setRouteOwnerHasUnpaidSubscription(hasUnpaidSubscription());
        setRouteOwnerValidDriverLicense(getValidDriverLicense());
        setIsRouteOwnerAuthUser(isAuthUser());
        setIsRouteOwnerGroup(isGroup());
        setIsRouteOwnerUser(isUser());
        setRouteOwnerUseSubscription(useSubscription());
    }

    function useSubscription(): boolean {
        let user: IUserData = null
        if (isAuthUser()) user = state.authUser.getValue();
        if (isUser()) user = userData();
        if (!user) return false
        return SubscriptionUtils.useSubscriptionService(user)
    }

    function getRouteOwnerSubscription(): IUserSubscription {
        if (isAuthUser()) {
            const authUser = state.authUser.getValue();
            if (SubscriptionUtils.skipSubscriptionService(authUser)) {
                return {driverLicense: null}
            }
            if (!authUser?.subscription?.driverLicense) {
                return {driverLicense: "free"}
            }
            return authUser?.subscription ?? {driverLicense: "free"};
        }
        const user = userData();
        if (SubscriptionUtils.skipSubscriptionService(user)) {
            return {driverLicense: null}
        }
        if (!user?.subscription?.driverLicense) {
            return {driverLicense: "free"}
        }
        return user?.subscription ?? {driverLicense: "free"};
    }

    function getValidDriverLicense(): DriverLicenseType {
        if (isAuthUser()) {
            const authUser = state.authUser.getValue();
            if (SubscriptionUtils.skipSubscriptionService(authUser)) {
                return null
            }
            return DriverLicenseUtils.validLicenseType(authUser?.subscription)
        }
        if (isGroup() && groupData()?.owner) {
            const groupOwner = groupData()?.owner;
            return DriverLicenseUtils.validLicenseType(groupOwner?.subscription)
        }
        const user = userData();
        if (SubscriptionUtils.skipSubscriptionService(user)) {
            return null
        }
        return DriverLicenseUtils.validLicenseType(user?.subscription)
    }

    function hasUnpaidSubscription() {
        if (isAuthUser() && state.authUser.getValue()?.subscription) {
            const authUser = state.authUser.getValue();
            return authUser.subscription.driverLicense != "free" && !authUser.subscription.isPaid;
        }
        if (isUser() && userData()?.subscription) {
            const user = userData();
            return user?.subscription?.driverLicense != "free" && !user?.subscription?.isPaid;
        }
        if (isGroup() && groupData()?.owner) {
            const groupOwner = groupData()?.owner;
            return groupOwner?.subscription?.driverLicense != "free" && !groupOwner?.subscription?.isPaid;
        }
        return routeOwnerSubscription?.driverLicense != "free" && !routeOwnerSubscription?.isPaid
    }

    function isAuthUser() {
        let routeOwner: IUserData
        const authUser = state.authUser.getValue();
        if (isUser()) {
            routeOwner = userData();
        }
        if (isGroup()) {
            routeOwner = groupData()?.owner;
        }
        return routeOwner && authUser && routeOwner?._id === authUser?._id;
    }

    function isUser() {
        return !!userData()?.nick;
    }

    function isGroup() {
        return !!groupData()?.owner;
    }

    function userData(): IUserData {
        return state.routeOwner.getValue() as IUserData;
    }

    function groupData(): IGroupData {
        return state.routeOwner.getValue() as IGroupData;
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        routeOwnerData,
        routeOwnerID,
        routeOwnerPath,
        routeOwnerAvatar,
        routeOwnerNick,
        routeOwnerName,
        routeOwnerRole,
        routeOwnerHiddenEvents,
        routeOwnerSubscription,
        routeOwnerHasUnpaidSubscription,
        routeOwnerValidDriverLicense,
        routeOwnerUseSubscription,
        isRouteOwnerAuthUser,
        isRouteOwnerGroup,
        isRouteOwnerUser
    };
}
