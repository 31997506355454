import * as React from "react";

/******************************************************************
 * Banderole
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Banderole(props: {
    label: string
    color: "red" | "yellow"
    position: "global" | "local-left" | "local-right"
}) {

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <div
            className="banderole"
            data-color={props.color}
            data-position={props.position}>
            {props.label}
        </div>
    );

}
