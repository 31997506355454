import * as React from "react";

/******************************************************************
 * CloseIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CloseIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="close-icon">
            <svg width={12 * (props.scale ?? 1)} height={12 * (props.scale ?? 1)} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.443947 0.478737C0.751045 0.171638 1.24895 0.171638 1.55605 0.478737L11.556 10.4787C11.8631 10.7858 11.8631 11.2837 11.556 11.5908C11.249 11.8979 10.751 11.8979 10.4439 11.5908L0.443947 1.59084C0.136848 1.28374 0.136848 0.785835 0.443947 0.478737Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M11.556 0.478737C11.8631 0.785835 11.8631 1.28374 11.556 1.59084L1.55605 11.5908C1.24895 11.8979 0.751045 11.8979 0.443947 11.5908C0.136848 11.2837 0.136848 10.7858 0.443947 10.4787L10.4439 0.478737C10.751 0.171638 11.249 0.171638 11.556 0.478737Z" fill="white"/>
            </svg>
        </div>
    );

}
