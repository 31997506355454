import * as React from "react";

/******************************************************************
 * LogoutIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LogoutIcon() {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="logout-icon">
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#logout-icon-clip-ath)">
                    <path d="M16 9.69234C16 10.8173 15.7882 11.8919 15.3646 12.9159C14.941 13.9399 14.3715 14.8234 13.6562 15.5661C12.941 16.3089 12.0903 16.9003 11.1042 17.3402C10.1181 17.7801 9.08333 18 8 18C6.91667 18 5.88194 17.7801 4.89583 17.3402C3.90972 16.9003 3.05903 16.3089 2.34375 15.5661C1.62848 14.8234 1.05903 13.9399 0.635419 12.9159C0.211806 11.8919 0 10.8173 0 9.69234C0 8.37983 0.279514 7.14305 0.838544 5.98199C1.39757 4.82093 2.18403 3.84737 3.19792 3.06131C3.49653 2.83055 3.82812 2.7404 4.19271 2.79088C4.55729 2.84136 4.84722 3.02165 5.0625 3.33175C5.28472 3.63463 5.36979 3.97538 5.31771 4.35398C5.26562 4.73259 5.09375 5.03728 4.80208 5.26805C4.12153 5.8017 3.59549 6.45435 3.22396 7.22598C2.85243 7.99761 2.66667 8.81973 2.66667 9.69233C2.66667 10.4423 2.80729 11.1581 3.08854 11.8396C3.36979 12.5211 3.75 13.1106 4.22917 13.6082C4.70833 14.1058 5.27604 14.5006 5.93229 14.7927C6.58854 15.0848 7.27778 15.2308 8 15.2308C8.72222 15.2308 9.41146 15.0848 10.0677 14.7927C10.724 14.5006 11.2917 14.1058 11.7708 13.6082C12.25 13.1106 12.6302 12.5211 12.9115 11.8396C13.1927 11.1581 13.3333 10.4423 13.3333 9.69233C13.3333 8.81973 13.1476 7.99761 12.776 7.22598C12.4045 6.45435 11.8785 5.8017 11.1979 5.26805C10.9063 5.03728 10.7344 4.73259 10.6823 4.35398C10.6302 3.97538 10.7153 3.63463 10.9375 3.33175C11.1528 3.02165 11.4444 2.84136 11.8125 2.79088C12.1806 2.7404 12.5104 2.83054 12.8021 3.06131C13.816 3.84737 14.6024 4.82093 15.1615 5.98199C15.7205 7.14305 16 8.37983 16 9.69234H16ZM9.33333 1.38466V8.30776C9.33333 8.68276 9.20138 9.00728 8.9375 9.28132C8.67361 9.55536 8.3611 9.69238 7.99999 9.69238C7.63888 9.69238 7.32638 9.55536 7.06249 9.28132C6.7986 9.00728 6.66666 8.68276 6.66666 8.30776V1.38466C6.66666 1.00966 6.7986 0.685138 7.06249 0.411097C7.32638 0.137058 7.63888 3.8147e-05 7.99999 3.8147e-05C8.3611 3.8147e-05 8.6736 0.137058 8.9375 0.411097C9.20138 0.685136 9.33333 1.00966 9.33333 1.38466Z" fill="white"/>
                </g>
                <defs>
                    <clipPath id="logout-icon-clip-ath">
                        <rect width="16" height="18" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );

}
