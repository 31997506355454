import * as React from "react";
import {useRef} from "react";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {PathValidation} from "../../../../../../../shared/validation/PathValidation";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {EventSidebarImage} from "../../../../../ui/image/event/EventSidebarImage";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {PrivacyType} from "../../../../../../../shared/types/PrivacyType";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";

/******************************************************************
 * EventGeneralSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventGeneralSettings(props: {
    isTraining: boolean
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
       * HOOKS
       * --------------------------------------------------------------*/

    const {dict, api, router} = useServices();
    const [isMobile] = useMobileStyle(ref, 560)
    const {
        eventID,
        eventName,
        eventPath,
        eventOwner,
        eventOwnerType,
        eventIsFinished,
        eventPrivacy
    } = useEventState();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateName(value: string): Promise<Response> {
        return api.event.update(eventID, {name: value});
    }

    async function updatePath(value): Promise<Response> {
        const response = await api.event.update(eventID, {path: value});
        if (response.status == 200) {
            router.showRoute(FrontendRoute.EVENT(eventOwnerType, eventOwner.path, value))
        }
        return response
    }

    async function updatePrivacy(value: PrivacyType): Promise<Response> {
        return api.event.update(eventID, {privacy: value});
    }

    function privacyOptions(): SelectInputOptionType[] {
        return [
            {value: "public" as PrivacyType, text: dict("event.privacy.public")},
            {value: "private" as PrivacyType, text: dict("event.privacy.private")}
        ]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="event-name-settings"
            columnTemplate={isMobile ? null : "200px 1fr"}>
            <ContentLayout>
                <EventSidebarImage
                    editable={true}
                    hideDateInfo={true}
                    framing="rounded-8"/>
            </ContentLayout>
            <ContentLayout>
                <Input
                    type="text"
                    label={dict(props.isTraining
                        ? "training.name.input.label"
                        : "event.name.input.label")}
                    required={true}
                    readonly={eventIsFinished}
                    defaultValue={eventName}
                    action={updateName}/>
                <Input
                    type="text"
                    label={dict(props.isTraining
                        ? "training.path.input.label"
                        : "event.path.input.label")}
                    helpTopic={"event.path.help"}
                    required={true}
                    readonly={eventIsFinished}
                    pattern={PathValidation.REGEXP.source}
                    defaultValue={eventPath}
                    action={updatePath}/>
                <SelectInput
                    label={dict("event.privacy.name")}
                    defaultValue={eventPrivacy}
                    options={privacyOptions()}
                    onChange={updatePrivacy}/>
                <InfoText
                    size="small"
                    text={dict("event.privacy.info")}/>
            </ContentLayout>
        </ContentLayout>
    );

}
