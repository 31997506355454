import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {Article} from "../../../ui/article/Article";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {ReleaseNotesList} from "../../../ui/list/releasenotes/ReleaseNotesList";

/******************************************************************
 * UpdateAppModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UpdateAppModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [serverVersion, setServerVersion] = useState<string>("");
    const [releaseArticleID, setReleaseArticleID] = useState<MongoObjectIDType>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const abortController = new AbortController()
        loadData(abortController)
        return () => abortController.abort()
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadData(abortController: AbortController) {
        const serverVersion = await api.info.getServerVersion()
        const releaseArticleID = await api.user.getServerVersionReleaseNoteArticleIDAndSetItToSeen(abortController)
        setServerVersion(serverVersion)
        setReleaseArticleID(releaseArticleID)
        setIsLoading(false)
    }

    function updateToLatestVersion() {
        window.location.reload()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="medium"
            footerChildren={<>
                <ContentLayout>
                    <LabelButton
                        label={dict("update.app.button.label")}
                        style={"primary"}
                        onClick={updateToLatestVersion}/>
                </ContentLayout>
            </>}>
            <Headline text={dict("update.app.title")} style="modal"/>
            <InfoText
                text={dict("update.app.info")
                    .replace("{INSTALLED_VERSION}", FrontendConfig.SERVER_VERSION)
                    .replace("{LATEST_VERSION}", serverVersion)}/>
            <Spacer height="normal"/>
            <ComponentInitializer isPropertyAvailable={!isLoading}>
                <ContentLayout>
                    <ContentLayout framed={true}>
                        {releaseArticleID
                            ? <Article
                                articleID={releaseArticleID}/>
                            : <ContentLayout gap="small">
                                <InfoText
                                    text={dict("app.release.notes.no.article")}
                                    align="center"/>
                            </ContentLayout>}
                    </ContentLayout>
                    {serverVersion &&
                        <ReleaseNotesList
                        showFrame={true}
                        ignoreVersion={serverVersion}/>}
                    <Spacer height="extra-large"/>
                </ContentLayout>
            </ComponentInitializer>
        </Modal>
    );

}
