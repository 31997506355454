import * as React from "react";
import {useSessionState} from "../../../../../hooks/useSessionState";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {DriftEngineType} from "../../../../../../../shared/types/DriftEngineType";
import {DriftTuningType} from "../../../../../../../shared/types/DriftTuningType";
import {DriftEnginesInput} from "../../../../../ui/form/elements/drift/DriftEnginesInput";
import {DriftTuningsInput} from "../../../../../ui/form/elements/drift/DriftTuningsInput";
import {DriftCarSetupInput} from "../../../../../ui/form/elements/drift/DriftCarSetupInput";
import {DriftTiresInput} from "../../../../../ui/form/elements/drift/DriftTiresInput";
import {DriftCarSetupType} from "../../../../../../../shared/types/DriftCarSetupType";
import {DriftTiresType} from "../../../../../../../shared/types/DriftTiresType";
import {useRef} from "react";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * SessionCarSetup
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionCarSetup() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api} = useServices()
    const [isMobileMode] = useMobileStyle(ref, 500)
    const {
        sessionID,
        sessionEngines,
        sessionTunings,
        sessionCarSetup,
        sessionTires,
        sessionIsFinished
    } = useSessionState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateSessionEngines(engines: DriftEngineType[]) {
        await api.session.update(sessionID, {setup: {engines: engines}})
    }

    async function updateSessionTunings(tunings: DriftTuningType[]) {
        await api.session.update(sessionID, {setup: {tunings: tunings}})
    }

    async function updateSessionCarSetup(carSetup: DriftCarSetupType[]) {
        await api.session.update(sessionID, {setup: {carSetup: carSetup}})
    }

    async function updateSessionTires(tires: DriftTiresType[]) {
        await api.session.update(sessionID, {setup: {tires: tires}})
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="session-car-setup">
            <ContentLayout columns={isMobileMode ? 1 : 2}>
                <DriftCarSetupInput
                    carSetups={sessionCarSetup}
                    readonly={sessionIsFinished}
                    onChange={updateSessionCarSetup}/>
                <DriftTiresInput
                    tires={sessionTires}
                    readonly={sessionIsFinished}
                    onChange={updateSessionTires}/>
            </ContentLayout>
            <DriftEnginesInput
                engines={sessionEngines}
                readonly={sessionIsFinished}
                onChange={updateSessionEngines}/>
            <DriftTuningsInput
                tunings={sessionTunings}
                readonly={sessionIsFinished}
                onChange={updateSessionTunings}/>
        </ContentLayout>
    );

}
