import {FrontendServices} from "../FrontendServices"
import {io, Socket} from "socket.io-client"
import {IKeyValuePair} from "../../../shared/types/IKeyValuePair"
import {SocketMessageType} from "../../../shared/types/SocketMessageType"
import {LeaderboardAPI} from "../../api/LeaderboardAPI"
import {IUserData} from "../../../shared/models/IUserData";
import {DriverLicenseType} from "../../../shared/types/DriverLicenseType";

/******************************************************************
 * FrontendSocket
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendSocket {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _socket: Socket

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
        this._frontend.state.socket.setValue("connecting")
        this.initSocketIO()
        this.initSystemListeners()
        this.initServerListeners()
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public send(messageType: SocketMessageType, payload?: IKeyValuePair) {
        this._socket.emit(messageType, payload)
    }

    get isConnected(): boolean {
        return this._socket.connected
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initSocketIO() {
        this._socket = io({
            auth: (cb) => {
                cb({token: this._frontend.api.getToken()})
            }
        })
    }

    private initSystemListeners() {
        this._socket.on("connect", () => this.onConnected())
        this._socket.on("disconnect", () => this.onDisconnected())
    }

    private initServerListeners() {
        this.initServerAdminUpdate()
        this.initServerEventUpdate()
        this.initServerTrackUpdate()
        this.initServerGarageUpdate()
        this.initServerAuthUserUpdate()
        this.initServerUserUpdate()
        this.initServerUserForcedLogout()
        this.initServerGroupUpdate()
        this.initServerSessionUpdate()
        this.initServerResultUpdate()
        this.initServerPaddleSubscriptionUpdate()
        this.initServerLiveEventsUpdate()
    }

    private initServerPaddleSubscriptionUpdate() {
        this._socket.on(
            "server.subscription.update" as SocketMessageType,
            (data: IUserData) => {
                if (data?._id === this._frontend.state.authUser?.getValue()?._id) {
                    const currentAuthUserData = this._frontend.state.authUser.getValue()
                    const oldLicense: DriverLicenseType = currentAuthUserData.subscription?.driverLicense ?? "free"
                    const newLicense: DriverLicenseType = data.subscription?.driverLicense ?? "free"
                    this._frontend.state.authUser.getValue().subscription = data.subscription
                    this._frontend.state.authUser.getValue().checkoutState = data.checkoutState
                    this._frontend.state.authUser.onChangeSignal.dispatch()
                    this._frontend.state.showConfirm.setValue(null)
                    this._frontend.state.showSubscriptionChangePreview.setValue(null)
                    this._frontend.state.showSubscriptionCheckout.setValue(null)
                    this._frontend.state.showSubscriptionPlans.setValue(null)
                    if (oldLicense !== newLicense) {
                        this._frontend.state.showSubscriptionChanged.setValue({
                            oldLicense: oldLicense,
                            newLicense: newLicense
                        })
                    }
                }
            }
        )
    }

    private initServerAdminUpdate() {
        this._socket.on(
            "server.admin.update" as SocketMessageType,
            () => {
                this._frontend.signal.onServerAdminUpdate.dispatch()
            }
        )
        this._socket.on(
            "server.admin.mailings.update" as SocketMessageType,
            (payload) => {
                this._frontend.signal.onServerAdminMailerUpdate.dispatch(payload.mailData)
            }
        )
    }

    private initServerEventUpdate() {
        this._socket.on(
            "server.event.update" as SocketMessageType,
            (payload) => {
                if (payload?.eventData) {
                    this._frontend.state.event.setValue(payload.eventData)
                }
                if (payload?.eventChildren) {
                    this._frontend.state.eventChildren.setValue(payload.eventChildren)
                }
                this._frontend.signal.onServerEventUpdate.dispatch()
            }
        )
    }

    private initServerTrackUpdate() {
        this._socket.on(
            "server.track.update" as SocketMessageType,
            (payload) => {
                if (payload?.trackData) {
                    this._frontend.state.track.setValue(payload.trackData)
                }
                this._frontend.signal.onServerTrackUpdate.dispatch()
            }
        )
    }

    private initServerUserUpdate() {
        this._socket.on(
            "server.user.update" as SocketMessageType,
            (payload) => {
                this._frontend.state.routeOwner.setValue(payload.userData)
            }
        )
    }

    private initServerUserForcedLogout() {
        this._socket.on(
            "server.user.loggedOutFromAllDevices" as SocketMessageType,
            () => {
                this._frontend.api.user.logout()
                this._frontend.state.showMessage.setValue({
                    type: "error",
                    message: this._frontend.dict.get("user.loggedOutFromAllDevices.message")
                })
            }
        )
    }

    private initServerAuthUserUpdate() {
        this._socket.on(
            "server.authUser.update" as SocketMessageType,
            () => {
                this._frontend.api.user.loadAuthUserData();
            }
        )
    }

    private initServerGroupUpdate() {
        this._socket.on(
            "server.group.route.update" as SocketMessageType,
            (payload) => {
                if (!payload?.groupData) return
                this._frontend.state.group.setValue(payload.groupData)
            }
        )
        this._socket.on(
            "server.group.meta.update" as SocketMessageType,
            () => {
                this._frontend.signal.onServerGroupUpdate.dispatch()
                this._frontend.api.user.loadAuthUserData()
            }
        )
    }

    private initServerSessionUpdate() {
        this._socket.on(
            "server.session.update" as SocketMessageType,
            (payload) => {
                this._frontend.state.session.setValue(payload.sessionData)
            }
        )
        this._socket.on(
            "server.session.log.update" as SocketMessageType,
            (payload) => {
                this._frontend.state.sessionLogData.setValue(payload.sessionLogData)
            }
        )
        this._socket.on(
            "server.session.leaderboard.update" as SocketMessageType,
            (payload) => {
                if (payload?.sessionLeaderboard) {
                    const entries = LeaderboardAPI.postProcessSessionLeaderboard(
                        payload.sessionLeaderboard,
                        this._frontend.state.session.getValue(),
                        this._frontend.state.event.getValue(),
                        null,
                        null,
                        this._frontend.state.authUser.getValue())
                    this._frontend.state.sessionLeaderboard.setValue(entries)
                }
            }
        )
    }

    private initServerResultUpdate() {
        this._socket.on(
            "server.result.update" as SocketMessageType,
            (payload) => {
                this._frontend.state.result.setValue(payload.resultData)
            }
        )
    }

    private initServerGarageUpdate() {
        this._socket.on(
            "server.garage.update" as SocketMessageType,
            () => {
                this._frontend.signal.onServerGarageUpdate.dispatch()
            }
        )
    }

    private initServerLiveEventsUpdate() {
        this._socket.on(
            "server.liveEvents.update" as SocketMessageType,
            (payload) => {
                this._frontend.state.hasLiveEvents.setValue(payload.hasLiveEvents)
            }
        )
    }

    /******************************************************************
     * Events
     *****************************************************************/

    private onConnected() {
        this.send("frontend.route.change", {route: this._frontend.state.route?.getValue()?.route})
        this.send("frontend.authUser.change", {authUserID: this._frontend.state.authUser?.getValue()?._id})
        this._frontend.state.socket.setValue("connected")
    }

    private onDisconnected() {
        this._frontend.state.socket.setValue("disconnected")
    }

}
