import * as React from "react";
import {useRef, useState} from "react";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {IMailBodyBlockImage} from "../../../../../../../shared/models/IMailData";
import {useServices} from "../../../../../hooks/useServices";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {UploadableImage} from "../../../../../ui/image/uploadable/UploadableImage";
import {Input} from "../../../../../ui/form/elements/input/Input";

/******************************************************************
 * MailImageBodyBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailImageBodyBlock(props: {
    mailID: MongoObjectIDType
    image: IMailBodyBlockImage
    onChange: (data: IMailBodyBlockImage) => void
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict} = useServices()
    const [isMobileStyle] = useMobileStyle(rootRef, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [uploading, setUploading] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateImage(file: File) {
        setUploading(true)
        const fileData = await api.file.uploadImage({
            ownerID: props.mailID,
            ownerType: "mail",
            fileID: props.image.file?._id,
            file: file
        })
        if (!fileData) {
            setUploading(false)
            return null
        }
        setUploading(false)
        props.image.file = fileData._id
        props.onChange(props.image)
        return null
    }

    async function updateCaption(caption: string): Promise<Response> {
        props.image.caption = caption
        props.onChange(props.image)
        return null
    }

    async function updateHref(href: string): Promise<Response> {
        props.image.href = href
        props.onChange(props.image)
        return null
    }

    async function updateAlt(alt: string): Promise<Response> {
        props.image.alt = alt
        props.onChange(props.image)
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="mail-image-block"
            ref={rootRef}
            gap="small">
            <ContentLayout
                columnTemplate={isMobileStyle ? null : "1fr 2fr"}
                gap="small">
                <ContentLayout alignSelf="stretch">
                    <UploadableImage
                        onChange={updateImage}
                        progressing={uploading}
                        framing="rounded-4"
                        showUploadButton={true}
                        editable={true}
                        style="relative"
                        imageSrc={api.file.getImageURLByFile(props.image.file)}
                        fit="contain"
                        buttonLabel={dict("form.upload.mail.select")}/>
                </ContentLayout>
                <ContentLayout gap="small">
                    <Input
                        type="text"
                        size="small"
                        label={dict("mail.image.href")}
                        defaultValue={props.image.href}
                        action={updateHref}/>
                    <Input
                        type="textarea"
                        size="small"
                        label={dict("mail.image.caption")}
                        defaultValue={props.image.caption}
                        action={updateCaption}/>
                    <Input
                        type="text"
                        size="small"
                        label={dict("mail.image.alt")}
                        defaultValue={props.image.alt}
                        action={updateAlt}/>
                </ContentLayout>
            </ContentLayout>
        </ContentLayout>
    )
}
