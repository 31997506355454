import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {useRouteOwner} from "../../../../hooks/useRouteOwner";
import {MultiSelectInput} from "../select/MultiSelectInput";
import {DRIFT_ENGINES, DriftEngineType} from "../../../../../../shared/types/DriftEngineType";
import {useGroupState} from "../../../../hooks/useGroupState";
import {MultiSelectOptionType} from "../select/options/MultiSelectOption";

/******************************************************************
 * DriftEnginesInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriftEnginesInput(props: {
    engines: DriftEngineType[]
    onChange: (engines: DriftEngineType[]) => void
    readonly?: boolean
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function options(): MultiSelectOptionType<DriftEngineType>[] {
        return DRIFT_ENGINES.map(engine => ({
            value: engine, text: dict(`drift.engine.${engine}`)
        }))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <MultiSelectInput
            label={dict("drift.engines")}
            helpTopic="drift.engines"
            readonly={(!isRouteOwnerAuthUser && !isGroupAdmin) || props.readonly}
            showFilter={true}
            keepFilterOnOptionsChange={true}
            sortOptionsByText={true}
            defaultValue={props.engines}
            onChange={props.onChange}
            minSelected={1}
            options={options()}/>
    );

}
