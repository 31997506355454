import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {Map} from "../../../ui/map/Map";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {ILocationData} from "../../../../../shared/types/ILocationData";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {LabelValueText} from "../../../ui/text/labelvalue/LabelValueText";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {LanguageType} from "../../../../../shared/types/LanguageType";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {IMapLocationModal} from "./IMapLocationModal";

/******************************************************************
 * MapLocationModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MapLocationModal(props: IMapLocationModal) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, language, state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [zoom, setZoom] = useState<number>()
    const [center, setCenter] = useState<ILocationData>(props.location ?? null)
    const [country, setCountry] = useState<string>(props.location?.country[language] ?? null)
    const [postalCode, setPostalCode] = useState<string>(props.location?.postalCode ?? null)
    const [place, setPlace] = useState<string>(props.location?.place[language] ?? null)
    const [isInteractingWithMap, setIsInteractingWithMap] = useState<boolean>(false)
    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [readonly] = useState<boolean>(props.requestSave == null)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.location) return
        setCountry(props.location.country[language])
        setPlace(props.location.place[language])
        setPostalCode(props.location.postalCode)
    }, [props.location, language])

    useEffect(() => {
        if (readonly) {
            return
        }
        if (zoom < 12 || !center) {
            setCountry(null)
            setPlace(null)
            return
        }
        requestLocationDetails(language)
            .then(locationDetails => {
                setCountry(locationDetails.country[language])
                setPlace(locationDetails.place[language])
                setPostalCode(locationDetails.postalCode)
            })
    }, [center])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function requestLocationDetails(language: LanguageType): Promise<ILocationData> {
        const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'
            + center.longitude + ',' + center.latitude + '.json'
            + '?access_token=' + FrontendConfig.MAPBOX_ACCESS_TOKEN
            + '&language=' + language;
        const result = await fetch(url).then(response => response.json())
        const features = result?.features
        const country = features?.find((feature: any) => feature.id.includes('country'))?.text
        const place = features?.find((feature: any) => feature.id.includes('place'))?.text
        const postalCode = features?.find((feature: any) => feature.id.includes('postcode'))?.text
        return {
            latitude: center.latitude,
            longitude: center.longitude,
            country: {
                [language]: country
            },
            place: {
                [language]: place
            },
            postalCode: postalCode
        }
    }

    function hasValidLocation() {
        return country && place && (readonly || isZoomedEnough())
    }

    function isZoomedEnough() {
        return zoom > 12
    }

    async function save() {
        setIsBusy(true)
        const location: ILocationData = {
            latitude: center.latitude,
            longitude: center.longitude,
            country: {
                [language]: country
            },
            place: {
                [language]: place
            },
            postalCode: postalCode
        }
        for (const lang in SharedConfig.LANGUAGES) {
            if (SharedConfig.LANGUAGES[lang] !== language) {
                const info = await requestLocationDetails(SharedConfig.LANGUAGES[lang])
                const country = info.country[SharedConfig.LANGUAGES[lang]]
                const place = info.place[SharedConfig.LANGUAGES[lang]]
                location.country[SharedConfig.LANGUAGES[lang]] = country
                location.place[SharedConfig.LANGUAGES[lang]] = place
            }
        }
        await props.requestSave?.(location)
    }

    function title() {
        switch (props.type) {
            case "event":
                return dict("map.location.event.modal.title")
            case "group":
                return dict("map.location.group.modal.title")
        }
    }

    function helpKey() {
        switch (props.type) {
            case "event":
                return "map.location.event"
            case "group":
                return "map.location.group"
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="large"
            className="select-location-modal"
            preventSwipeToClose={isInteractingWithMap}
            closeAction={state.showMapLocation.hasValue()
                ? () => state.showMapLocation.setValue(null)
                : props.requestClose}>
            <ContentLayout columnTemplate={readonly ? null : "1fr min-content"}>
                <Headline
                    text={title()}
                    style="modal"/>
                {!readonly &&
                    <LabelButton
                        label={dict("form.input.help.label")}
                        style="secondary-small"
                        onClick={() => state.showHelp.setValue(helpKey())}/>}
            </ContentLayout>
            <ContentLayout gap="small">
                <ContentLayout columns={2} framed={true}>
                    <LabelValueText
                        label={dict("map.location.modal.country")}
                        value={hasValidLocation() ? (country ?? "–") : "–"}/>
                    <LabelValueText
                        label={dict("map.location.modal.place")}
                        value={hasValidLocation() ? (place ?? "–") : "–"}/>
                </ContentLayout>
                <Map
                    showSearch={!readonly}
                    badgeInfo={readonly || isZoomedEnough() ? null : dict("map.location.modal.zoom.more.info")}
                    showCenterTargetCrosshair={!readonly && isZoomedEnough()}
                    disabled={isBusy}
                    maxZoomLevel={readonly ? 12 : null}
                    initialZoomLevel={readonly ? 5 : null}
                    initialLocation={props.location}
                    onZoomChanged={setZoom}
                    onCenterChanged={setCenter}
                    markers={props.markers}
                    onTouchStart={() => setIsInteractingWithMap(true)}
                    onTouchEnd={() => setIsInteractingWithMap(false)}/>
                {!readonly &&
                    <LabelButton
                        label={dict("map.location.modal.save.button")}
                        disabled={!hasValidLocation()}
                        progressing={isBusy}
                        style="primary"
                        onClick={save}/>}
            </ContentLayout>
        </Modal>
    );

}
