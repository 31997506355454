import {ICapiTargetData} from "../types/ICapiTargetData";
import {SessionGameModeType} from "../types/SessionGameModeType";

/******************************************************************
 * CapiTargetUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CapiTargetUtils {

    static deleteUnwantedProps(target: ICapiTargetData, sessionMode: SessionGameModeType) {
        delete target.false_start
        switch (sessionMode) {
            case "race":
            case "lap":
                delete target.score
                delete target.orientations
                break;
        }
    }

    static isCrossingTimeUnique(crossingTime: string, targets: ICapiTargetData[]): boolean {
        return !targets.some(item => item.crossing_time === crossingTime);
    }

    static isFinishTarget(target: ICapiTargetData): boolean {
        if (!target) {
            return false
        }
        return CapiTargetUtils.getTargetCode(target) == 0
    }

    static sortByCrossingTime(targets: ICapiTargetData[]) {
        targets.sort((a, b) => {
            const dateA = new Date(a.crossing_time);
            const dateB = new Date(b.crossing_time);
            return dateA.getTime() - dateB.getTime();
        });
    }

    static getTargetCode(target: ICapiTargetData): number {
        if (!target) {
            return null
        }
        return target._overwrittenTargetCode ?? target.target_code ?? null
    }
}
