import * as React from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Input} from "../../form/elements/input/Input";
import {LanguageUtils} from "../../../../../shared/utils/LanguageUtils";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useServices} from "../../../hooks/useServices";
import {ILocationData} from "../../../../../shared/types/ILocationData";
import {MapLocationModal} from "../../../systems/modals/map/MapLocationModal";
import {useRef, useState} from "react";
import {useMobileStyle} from "../../../hooks/useMobileStyle";

/******************************************************************
 * MapInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MapInput(props: {
    type: "event" | "group"
    location: ILocationData
    helpKey?: string
    onSave: (location: ILocationData) => Promise<Response>
    onClear?: () => Promise<Response>
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, language} = useServices()
    const [isMobile] = useMobileStyle(rootRef, 400)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showSelectLocationModal, setShowSelectLocationModal] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function saveLocation(location: ILocationData): Promise<Response> {
        const response = await props.onSave(location)
        setShowSelectLocationModal(false)
        return response
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            framed={true}
            ref={rootRef}
            className="map-input">
            <ContentLayout columns={2}>
                <Input
                    type="text"
                    required={true}
                    readonly={true}
                    defaultValue={LanguageUtils.getValueFromMultilangString(props.location?.country, language) ?? "–"}
                    label={dict("map.country.label")}/>
                <Input
                    type="text"
                    required={true}
                    readonly={true}
                    defaultValue={LanguageUtils.getValueFromMultilangString(props.location?.place, language) ?? "–"}
                    label={dict("map.place.label")}/>
            </ContentLayout>
            <ContentLayout
                justifyItems="center"
                justifyContent="center"
                columnTemplate={!isMobile && props.location && props.onClear ? "min-content min-content" : null}>
                <LabelButton
                    label={dict("map.select.location.button.label")}
                    style={"primary-small"}
                    onClick={() => setShowSelectLocationModal(true)}/>
                {props.location && props.onClear &&
                    <LabelButton
                        label={dict("map.remove.location.button.label")}
                        style="secondary-small"
                        onClick={props.onClear}/>}
            </ContentLayout>
        </ContentLayout>
        {showSelectLocationModal &&
            <MapLocationModal
                type={props.type}
                location={props.location}
                requestClose={() => setShowSelectLocationModal(false)}
                requestSave={saveLocation}/>}
    </>
}
