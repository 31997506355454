import * as React from "react";

/******************************************************************
 * InputDivider
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function InputDivider() {
    return (
        <div className="input-divider"></div>
    );
}
