import * as React from "react";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {Modal} from "../../../../modals/Modal";
import {useServices} from "../../../../../hooks/useServices";

/******************************************************************
 * RemoveDriverModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RemoveDriverModal(props: {
    onRequestClose: () => void
    progressing: boolean
    onRequestRemoveDriver: (userID: string) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="small"
            closeAction={props.onRequestClose}>
            <Headline
                text={dict("event.request.participation.cancel.title")}
                style="modal"/>
            <ContentLayout>
                <InfoText
                    size="small"
                    text={dict("event.request.participation.cancel.text")}/>
                <ContentLayout columns={2}>
                    <LabelButton
                        label={dict("generic.abort")}
                        style="secondary-small"
                        onClick={props.onRequestClose}/>
                    <LabelButton
                        label={dict("event.request.participation.cancel.confirm")}
                        style="primary-small"
                        progressing={props.progressing}
                        onClick={props.onRequestRemoveDriver}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    );

}
