import * as React from "react";

/******************************************************************
 * TestDriverIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TestDriverIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="test-driver-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect x="0.0153809" y="6.45898" width="19.9693" height="8.98193" rx="4.49097" fill="white"/>
                <path
                    d="M4.40897 9.78694H3.22647L3.40247 8.92344H6.85097L6.67497 9.78694H5.49797L4.89847 12.7734H3.80947L4.40897 9.78694ZM8.15868 9.76494L8.03218 10.4139H9.74268L9.58318 11.2279H7.86718L7.72968 11.9319H9.74818L9.57218 12.7734H6.48668L7.25668 8.92344H10.2707L10.1002 9.76494H8.15868ZM11.6175 12.8504C11.2985 12.8504 10.996 12.8119 10.71 12.7349C10.424 12.6579 10.1912 12.5589 10.0115 12.4379L10.4405 11.6239C10.6348 11.7486 10.842 11.8439 11.062 11.9099C11.282 11.9723 11.502 12.0034 11.722 12.0034C11.931 12.0034 12.0923 11.9741 12.206 11.9154C12.3233 11.8531 12.382 11.7688 12.382 11.6624C12.382 11.5708 12.3288 11.4993 12.2225 11.4479C12.1198 11.3966 11.9585 11.3416 11.7385 11.2829C11.4892 11.2169 11.282 11.1509 11.117 11.0849C10.952 11.0153 10.809 10.9108 10.688 10.7714C10.5707 10.6321 10.512 10.4488 10.512 10.2214C10.512 9.94644 10.5872 9.70627 10.7375 9.50094C10.8878 9.29194 11.1023 9.1306 11.381 9.01694C11.6597 8.90327 11.986 8.84644 12.36 8.84644C12.635 8.84644 12.8917 8.87577 13.13 8.93444C13.3683 8.98944 13.5737 9.0701 13.746 9.17644L13.35 9.98494C13.1997 9.8896 13.0328 9.8181 12.8495 9.77044C12.6698 9.7191 12.4847 9.69344 12.294 9.69344C12.0777 9.69344 11.9072 9.72827 11.7825 9.79794C11.6615 9.8676 11.601 9.95744 11.601 10.0674C11.601 10.1628 11.6542 10.2361 11.7605 10.2874C11.8668 10.3388 12.0318 10.3938 12.2555 10.4524C12.5048 10.5148 12.7102 10.5789 12.8715 10.6449C13.0365 10.7109 13.1777 10.8118 13.295 10.9474C13.4123 11.0831 13.471 11.2609 13.471 11.4809C13.471 11.7523 13.394 11.9924 13.24 12.2014C13.0897 12.4068 12.8733 12.5663 12.591 12.6799C12.3123 12.7936 11.9878 12.8504 11.6175 12.8504ZM15.1028 9.78694H13.9203L14.0963 8.92344H17.5448L17.3688 9.78694H16.1918L15.5923 12.7734H14.5033L15.1028 9.78694Z"
                    fill="#303D4B"/>
            </svg>
        </div>
    );

}
