import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * Text
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class TextValidation {

    static get REGEXP() {
        return /^.{2,256}$/;
    }

    static schemaValidator(errorCode?: string) {
        return {
            validate: {
                validator: (value: string) => TextValidation.isValid(value),
                message: errorCode ?? ErrorCode.TEXT_INPUT_INVALID
            },
        }
    };

    static isValid(value: string): boolean {
        return TextValidation.REGEXP.test(value);
    }
}
