import * as React from "react";

/******************************************************************
 * YouTubeIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function YouTubeIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="youtube-icon">
            <svg width={16 * (props.scale ?? 1)} height={13 * (props.scale ?? 1)} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8972 3.59875C15.8972 2.19232 14.8627 1.06093 13.5844 1.06093C11.8529 0.979984 10.0871 0.94873 8.28248 0.94873H7.71991C5.91969 0.94873 4.15071 0.979984 2.41925 1.06124C1.14408 1.06124 0.109579 2.19889 0.109579 3.60532C0.031444 4.71764 -0.00168518 5.83028 0.000190058 6.94292C-0.00293534 8.05556 0.0324858 9.16925 0.106453 10.284C0.106453 11.6904 1.14096 12.8312 2.41612 12.8312C4.2351 12.9156 6.10096 12.9531 7.99807 12.9499C9.89831 12.9562 11.759 12.9166 13.58 12.8312C14.8583 12.8312 15.8928 11.6904 15.8928 10.284C15.9678 9.1682 16.0022 8.05556 15.9991 6.9398C16.0062 5.82716 15.9722 4.71348 15.8972 3.59875ZM6.46976 10.0089V3.86754L11.0016 6.93667L6.46976 10.0089Z" fill="white"/>
            </svg>
        </div>
    );

}
