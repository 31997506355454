import * as React from "react";
import {useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContactHeader} from "./header/ContactHeader";
import {Input} from "../../../ui/form/elements/input/Input";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {SharedConfig} from "../../../../../shared/config/SharedConfig";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {ContactMessageType} from "../../../../../shared/types/ContactMessageType";
import {ContactMessageToType} from "../../../../../shared/types/ContactMessageToType";

/******************************************************************
 * ContactModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ContactModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {authUserID, authUserExists} = useAuthUser()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [sendTo] = useState<ContactMessageToType>(state.showContact.getValue()?.sendMessageTo)
    const [messageType] = useState<ContactMessageType>(state.showContact.getValue()?.messageType)
    const [message, setMessage] = useState<string>("")
    const [isSending, setIsSending] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function updateCharCounter(value: string) {
        setMessage(value)
    }

    function isValid() {
        return message?.length > SharedConfig.MIN_MESSAGE_LENGTH
    }

    async function send() {
        setIsSending(true)
        const success = await api.contact.sendMessage({
            senderID: authUserID,
            recipientIDs: sendTo !== "service"
                ? (Array.isArray(sendTo)
                    ? sendTo.map(user => user._id)
                    : [sendTo?._id])
                : null,
            sendToService: sendTo === "service",
            message: message,
            messageType: messageType
        })
        setSuccess(success)
        setIsSending(false)
    }

    function title() {
        switch (messageType) {
            case "group":
                return dict("group.contact.modal.title")
            case "event":
                return dict("event.contact.modal.title")
            case "user":
                return dict("user.contact.modal.title")
            case "users":
                return dict("users.contact.modal.title")
            case "abuse":
                return dict("contact.abuse.modal.title")
            case "support":
                return dict("contact.support.modal.title")
            default:
                return "–"
        }
    }

    function disclaimer() {
        switch (messageType) {
            case "group":
                return dict("group.contact.message.info")
            case "event":
                return dict("event.contact.message.info")
            case "user":
                return dict("user.contact.message.info")
            case "users":
                return dict("users.contact.message.info")
            case "abuse":
                return dict("contact.abuse.disclaimer")
            case "support":
                return dict("contact.support.disclaimer")
            default:
                return "–"
        }
    }

    function introductionText() {
        switch (messageType) {
            case "abuse":
                return dict("contact.abuse.info")
            case "support":
                return dict("contact.support.info")
        }
        return ""
    }

    function showHeader() {
        return messageType !== "abuse" && messageType !== "support"
    }

    function showIntroductionText() {
        return !showHeader()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="contact-modal"
            width={success ? "small" : "medium"}
            closeAction={() => state.showContact.setValue(null)}>
            <Headline
                text={title()}
                style="modal"/>
            {!authUserExists &&
                <ContentLayout>
                    <InfoText
                        text={dict("contact.message.send.not.allowed")}
                        size="normal"/>
                </ContentLayout>}
            {authUserExists && !success &&
                <ContentLayout>
                    {showHeader() &&
                        <ContactHeader
                            sendTo={sendTo}
                            messageType={messageType}/>}
                    {showIntroductionText() &&
                        <InfoText
                            text={introductionText()}
                            size="normal"/>}
                    <Input
                        type="textarea"
                        label={dict("contact.message.label")}
                        readonly={isSending}
                        customLinkLabel={(SharedConfig.MAX_MESSAGE_LENGTH - (message?.length ?? 0)).toString()}
                        onCustomLinkClick={() => null}
                        maxTextareaLength={SharedConfig.MAX_MESSAGE_LENGTH}
                        onChange={updateCharCounter}/>
                    <InfoText
                        text={disclaimer()}
                        size="small"/>
                    <LabelButton
                        label={dict("contact.send.label")}
                        style="primary"
                        progressing={isSending}
                        disabled={!isValid()}
                        onClick={send}/>
                </ContentLayout>}
            {success &&
                <ContentLayout>
                    <InfoText
                        text={dict("contact.message.send.success")}
                        size="normal"/>
                    <LabelButton
                        label={dict("contact.message.send.success.button.label")}
                        style="primary"
                        onClick={() => state.showContact.setValue(null)}/>
                </ContentLayout>}
        </Modal>
    );

}
