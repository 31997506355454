import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * FollowSessionStartTimeDelayValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionStartTimeDelayValidation {

    static MIN_MINUTES = 1;
    static MAX_MINUTES = 60;

    static schemaValidator() {
        return {
            validate: {
                validator: value => SessionStartTimeDelayValidation.isValid(value),
                message: ErrorCode.EVENT_VALIDATION_FOLLOW_SESSION_START_TIME_DELAY_INVALID
            },
        }
    };

    static isValid(value: number): boolean {
        if (value % 1 !== 0) {
            return false;
        }
        return value >= SessionStartTimeDelayValidation.MIN_MINUTES && value <= SessionStartTimeDelayValidation.MAX_MINUTES;
    }

}
