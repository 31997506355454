import * as React from "react";
import {
    ISessionLeaderboardEntry,
    ISessionLeaderboardEntryBestDrivenTimeData,
    ISessionLeaderboardEntryBestGymkhanaData,
    ISessionLeaderboardEntryBestLapData
} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {useServices} from "../../../../hooks/useServices";
import {ISessionData} from "../../../../../../shared/models/ISessionData";
import {CarImage} from "../../../image/car/CarImage";
import {DriftUtils} from "../../../../../../shared/utils/DriftUtils";
import {IntersectingPixel} from "../../../utils/observer/IntersectingPixel";
import {useMainframeSystemContentStyles} from "../../../../hooks/useMainframeSystemContentStyles";
import {useState} from "react";

/******************************************************************
 * TableLeaderboardCarCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardCarCell(props: {
    entry: ISessionLeaderboardEntry
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {mainFramePaddingLeft} = useMainframeSystemContentStyles()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isSticky, setIsSticky] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function style(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    function car() {
        const session: ISessionData = props.entry.session
        const entry = props.entry
        let dataRef:
            | ISessionLeaderboardEntryBestLapData
            | ISessionLeaderboardEntryBestDrivenTimeData
            | ISessionLeaderboardEntryBestGymkhanaData
        switch (session.setup.mode) {
            case "lap":
                dataRef = entry.bestLapStint
                break
            case "race":
                dataRef = entry.bestDrivenTimeStint
                break
            case "gymkhana":
                dataRef = entry.bestGymkhanaStint
                break
        }
        return {
            name: dataRef?.carName,
            engine: dataRef?.carEngine,
            tuning: dataRef?.carTuning,
            ownerID: dataRef?.user?._id,
        }
    }

    function primaryValue() {
        const session: ISessionData = props.entry.session
        switch (session.setup.leaderboardGrouping) {
            case "stint":
            case "driver":
            case "team":
            case "engine-and-tuning":
                return carEngine() + " | " + carTuning()
            case "engine":
                return carEngine()
            case "tuning":
                return carTuning()
            case "car-name":
                return car()?.name
        }
    }

    function secondaryValue() {
        const session: ISessionData = props.entry.session
        switch (session.setup.leaderboardGrouping) {
            case "stint":
            case "driver":
            case "team":
            case "engine-and-tuning":
                return carName()
            case "engine":
                return carTuning() + " | " + carName()
            case "tuning":
                return carEngine() + " | " + carName()
            case "car-name":
                return carEngine() + " | " + carTuning()
        }
    }

    function carEngine() {
        return dict("drift.engine." + car()?.engine) ?? "–"
    }

    function carTuning() {
        if (!car()?.tuning) return "–"
        return DriftUtils.getTuningWithoutEngine(dict("drift.tuning." + car().tuning))
    }

    function carName() {
        return car()?.name ?? dict("car.not.found")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-car-cell"
            data-style={style()}
            data-is-sticky={isSticky}>
            <IntersectingPixel
                showRedPixel={false}
                leftOffset={-mainFramePaddingLeft - 5}
                onIntersectionChange={isIntersecting => setIsSticky(!isIntersecting)}/>
            <CarImage
                className="table-leaderboard-car-cell-image"
                carName={car()?.name}
                userID={car()?.ownerID}/>
            <div className="table-leaderboard-car-cell-primary">
                {primaryValue()}
            </div>
            <div className="table-leaderboard-car-cell-secondary">
                {secondaryValue()}
            </div>
        </div>
    );

}
