import * as React from "react";
import {PaddlePriceOfferType} from "../../../../../../shared/types/PaddlePriceOfferType";
import {useServices} from "../../../../hooks/useServices";
import {Icon} from "../../../icons/Icon";

/******************************************************************
 * PlanPriceOffer
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PlanPriceOffer(props: {
    offer: PaddlePriceOfferType
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showInfoModal() {
        state.showMessage.setValue({
            type: "normal",
            message: dict("plan.price.offer.info." + props.offer)
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="plan-price-offer"
            onClick={showInfoModal}>
            {dict("plan.price.offer.label." + props.offer)}
            <Icon type="info" scale={0.7} iconColor="black"/>
        </div>
    );

}
