import * as React from "react";

/******************************************************************
 * MenuDotsIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MenuDotsIcon() {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="menudots-icon">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="5.44971" r="1.5" fill="white"/>
                <circle cx="11" cy="10.4497" r="1.5" fill="white"/>
                <circle cx="11" cy="15.4497" r="1.5" fill="white"/>
            </svg>
        </div>
    );

}
