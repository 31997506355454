import * as React from "react";
import {IUserData} from "../../../../../shared/models/IUserData";
import {useServices} from "../../../hooks/useServices";
import {Picture} from "../../image/Picture";
import {DriftEngineType} from "../../../../../shared/types/DriftEngineType";
import {DriftTuningType} from "../../../../../shared/types/DriftTuningType";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {CarImage} from "../../image/car/CarImage";
import {DriftUtils} from "../../../../../shared/utils/DriftUtils";
import {DriverLicenseBadge} from "../license/DriverLicenseBadge";
import {SubscriptionUtils} from "../../../../utils/SubscriptionUtils";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {UserUtils} from "../../../../../shared/utils/UserUtils";

/******************************************************************
 * DriverInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DriverInfo(props: {
    user: IUserData
    theme: "dark" | "light"
    size?: "normal" | "medium" | "small"
    clickable?: boolean
    carName?: string
    carEngine?: DriftEngineType
    carTuning?: DriftTuningType
}) {

    /* ----------------------------------------------------------------
 	 * SERVIVES
 	 * --------------------------------------------------------------*/

    const {dict, router} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function defaultUserImage() {
        if (props.user?.avatar) return null;
        if (props.user) {
            return require("ASSETS/images/default-user-avatar.png")
        }
        return require("ASSETS/images/user-not-found-avatar.png")
    }

    function showSecondary() {
        return !!(props.carEngine && props.carTuning)
    }

    function gotoUser(e: React.MouseEvent) {
        if (!props.clickable) return null
        e.stopPropagation()
        return router.showRoute(FrontendRoute.USER(props.user.path))
    }

    function secondaryValue() {
        const engine = dict("drift.engine." + props.carEngine)
        const tuning = DriftUtils.getTuningWithoutEngine(dict("drift.tuning." + props.carTuning))
        return `${engine} | ${tuning}`
    }

    function useSubscription(): boolean {
        return SubscriptionUtils.useSubscriptionService(props.user)
    }

    function validDriverLicense(): DriverLicenseType {
        return DriverLicenseUtils.validLicenseType(props.user?.subscription)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="driver-info"
            data-error={!props.user}
            data-theme={props.theme}
            data-size={props.size ?? "normal"}
            data-interactive={props.clickable}
            onClick={gotoUser}>
            <div className="driver-info-picture-box">
                <Picture
                    file={props.user?.avatar}
                    alt={props.user?.nick}
                    className="driver-info-user-image"
                    defaultSource={defaultUserImage()}/>
                {(useSubscription() || UserUtils.doesRoleSkipSubscription(props.user?.role)) &&
                    <DriverLicenseBadge
                        licenseType={validDriverLicense()}
                        role={props.user.role}
                        size="tiny"/>}
            </div>
            <div className="driver-info-copy">
                <div className="driver-info-copy-primary">
                    {props.user?.nick ?? dict("user.not.found")}
                </div>
                {showSecondary() && <div className="driver-info-copy-secondary">
                    {secondaryValue()}
                </div>}
            </div>
            {props.carName && <CarImage
                className="driver-info-car-image"
                carName={props.carName}
                userID={props.user._id}/>}
        </div>
    );

}
