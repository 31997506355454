import * as React from "react";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelValueText} from "../../../../../ui/text/labelvalue/LabelValueText";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {CarImage} from "../../../../../ui/image/car/CarImage";
import {DriftUtils} from "../../../../../../../shared/utils/DriftUtils";

/******************************************************************
 * StintCarInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintCarInfo(props: {
    stint: IStintData,
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="stint-car-info">
            <CarImage
                carName={props.stint.enterData.car_name}
                displayCarMaxOut={true}
                aspectRatio={1}
                userID={props.stint.user._id}/>
            <ContentLayout
                gap="small"
                useFrameMargin={true}>
                <LabelValueText
                    size="small"
                    label={dict("stint.car.name.label")}
                    value={props.stint.enterData.car_name}/>
                <LabelValueText
                    size="small"
                    label={dict("drift.engine")}
                    value={dict("drift.engine." + props.stint.enterData.engine_type)}/>
                <LabelValueText
                    size="small"
                    label={dict("drift.tuning")}
                    value={DriftUtils.getTuningWithoutEngine(dict("drift.tuning." + props.stint.enterData.tuning_type))}/>
                <LabelValueText
                    size="small"
                    label={dict("drift.carsetup")}
                    value={dict("drift.carsetup." + props.stint.enterData.setup_mode?.toLowerCase())}/>
                <LabelValueText
                    size="small"
                    label={dict("drift.tires")}
                    value={dict("drift.tires." + props.stint.enterData.wheels)}/>
            </ContentLayout>
        </div>
    );

}
