import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Headline} from "../../../ui/text/headings/Headline";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {Table} from "../../../ui/table/Table";
import {IFavoriteData} from "../../../../../shared/models/submodels/IFavoriteData";
import {ITableColumnDefinition} from "../../../ui/table/ITableColumnDefinition";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {FavoriteButton} from "../../../ui/buttons/favorite/FavoriteButton";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";

/******************************************************************
 * FavoritesModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FavoritesModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api, router} = useServices();
    const {authUserID, authUserFavorites} = useAuthUser();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columnsDefinition(): ITableColumnDefinition<IFavoriteData>[] {
        return [{
            type: "image",
            dataKey: "group.avatar",
            size: "50px"
        }, {
            type: "text-bold",
            dataKey: "group.name",
        }, {
            type: "custom",
            size: "80px",
            customCell: (key, favorite) => {
                const isTop = favorite.group?._id === authUserFavorites[0]?.group?._id
                const isBottom = favorite.group?._id === authUserFavorites[authUserFavorites.length - 1]?.group?._id
                return <ContentLayout
                    key={key}
                    columns={2}
                    gap="none"
                    justifyItems="center">
                    <IconButton
                        type={"up"}
                        disabled={isTop}
                        size={"small"}
                        stopPropagation={true}
                        onClick={() => moveFavoriteUp(favorite)}/>
                    <IconButton
                        type={"down"}
                        disabled={isBottom}
                        size={"small"}
                        stopPropagation={true}
                        onClick={() => moveFavoriteDown(favorite)}/>
                </ContentLayout>
            }
        }, {
            type: "custom",
            size: authUserID ? "45px" : null,
            customCell: (key, favorite) => {
                return <ContentLayout
                    key={key}
                    justifyItems="center">
                    <FavoriteButton
                        favoriteID={favorite.group?._id}
                        type={"group"}/>
                </ContentLayout>
            }
        }];
    }

    async function moveFavoriteUp(favorite: IFavoriteData) {
        const favorites = [...authUserFavorites]
        const favoriteIndex = favorites?.findIndex(fav => fav.group?._id === favorite.group?._id)
        const favoriteAbove = favorites[favoriteIndex - 1]
        favorites[favoriteIndex - 1] = favorite
        favorites[favoriteIndex] = favoriteAbove
        await api.user.update({favorites})
    }

    async function moveFavoriteDown(favorite: IFavoriteData) {
        const favorites = [...authUserFavorites]
        const favoriteIndex = favorites?.findIndex(fav => fav.group?._id === favorite.group?._id)
        const favoriteBelow = favorites[favoriteIndex + 1]
        favorites[favoriteIndex + 1] = favorite
        favorites[favoriteIndex] = favoriteBelow
        await api.user.update({favorites})
    }

    function gotoFavorite(favorite: IFavoriteData) {
        state.showFavorites.setValue(false)
        if (favorite.group) {
            router.showRoute(FrontendRoute.GROUP(favorite.group?.path))
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <Modal
            className="favorites-modal"
            width="medium"
            closeAction={() => state.showFavorites.setValue(false)}>
            <Headline text={dict("favorites.modal.title")} style={"modal"}/>
            <ContentLayout>
                <Table<IFavoriteData>
                    rowsData={authUserFavorites}
                    showHeader={false}
                    showFilter={authUserFavorites?.length > 3}
                    visibleRows={10}
                    noDataInfo={dict("favorites.modal.noDataInfo")}
                    columnsDefinition={columnsDefinition()}
                    onRowClick={gotoFavorite}/>
                <ContentLayout
                    justifyItems="center"
                    columnTemplate="min-content min-content"
                    justifyContent="space-between">
                    <LabelButton
                        label={dict("favorites.modal.createGroup")}
                        style={"primary-small"}
                        onClick={() => state.showCreateGroup.setValue(true)}/>
                    <LabelButton
                        label={dict("favorites.modal.find.group")}
                        style={"primary-small"}
                        onClick={() => state.showGroupsFinder.setValue(true)}/>
                </ContentLayout>
            </ContentLayout>
        </Modal>
    </>
}
