import * as React from "react";
import {ReactElement} from "react";
import {BadgeColorType} from "./BadgeColorType";

/******************************************************************
 * Badge
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Badge(props: {
    label: string | ReactElement
    hideBackground?: boolean
    size?: "small" | "normal"
    showBorder?: boolean
    color?: BadgeColorType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="badge"
            data-hide-background={(props.hideBackground || props.showBorder) ?? false}
            data-size={props.size ?? "normal"}
            data-show-border={props.showBorder ?? false}
            data-color={props.color ?? "red"}>
            {props.label}
        </div>
    );

}
