import * as React from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {CreateGroupContent} from "./create/CreateGroupContent";
import {Headline} from "../../../ui/text/headings/Headline";
import {useEffect} from "react";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";

/******************************************************************
 * CreateGroupModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function CreateGroupModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict, api} = useServices()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        api.group.canCreateGroup().then(allowed => {
            if (!allowed) {
                state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.group")})
                state.showCreateGroup.setValue(null)
            }
        })
    }, [])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="large"
            position="global"
            className="create-group-modal"
            closeAction={() => state.showCreateGroup.setValue(false)}>
            <ContentLayout columnTemplate={"auto min-content"} justifyContent={"space-between"}>
                <Headline text={dict("group.create")} style="modal"/>
                <LabelButton
                    label={dict("help.label")}
                    style={"secondary-small"}
                    onClick={() => state.showArticle.setValue(dict("article.id.groups"))}/>
            </ContentLayout>
            <CreateGroupContent/>
        </Modal>
    );

}
