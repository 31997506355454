import * as React from "react";

/******************************************************************
 * ThreeSixtyDriftTargetIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ThreeSixtyDriftTargetIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="threesixty-drift-target-icon">
            <svg width={22 * (props.scale ?? 1)} height={19 * (props.scale ?? 1)} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="9.94971" r="6.5" stroke="white" strokeWidth="2"/>
                <mask id="mask0_1279_6819" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="6" width="22" height="7">
                    <rect y="8.94971" width="22" height="2" fill="white"/>
                    <rect y="11.9497" width="22" height="1" fill="white"/>
                    <rect y="6.94971" width="22" height="1" fill="white"/>
                </mask>
                <g mask="url(#mask0_1279_6819)">
                    <path d="M0 6.94971L2.48711 6.94971C2.48711 6.94971 1.92491 8.41722 1.92491 9.94971C1.92491 11.4822 2.48711 12.9497 2.48711 12.9497H0V6.94971Z" fill="white"/>
                    <path d="M22 12.9497L19.5129 12.9497C19.5129 12.9497 20.0751 11.4822 20.0751 9.94971C20.0751 8.41722 19.5129 6.94971 19.5129 6.94971L22 6.94971L22 12.9497Z" fill="white"/>
                    <path d="M8.34508 6.94971L13.5726 6.94971C13.5726 6.94971 14.9123 7.89184 14.9123 9.94971C14.9123 12.0076 13.5726 12.9497 13.5726 12.9497H8.34508C8.34508 12.9497 7.24179 11.7449 7.24179 9.94971C7.24179 8.15453 8.34508 6.94971 8.34508 6.94971Z" fill="white"/>
                </g>
            </svg>
        </div>
    );

}
