import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useServices} from "../../../../../hooks/useServices";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";
import {AuthUserPlanInfo} from "../../../../../ui/info/plan/AuthUserPlanInfo";

/******************************************************************
 * UserSubscriptionSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserSubscriptionSettings() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {authUserSubscription} = useAuthUser();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function hasPaidSubscription(): boolean {
        return authUserSubscription.driverLicense != "free"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={rootRef}
            className="user-subscription-settings">
            <AuthUserPlanInfo type={"settings"}/>
            <Spacer height="small"/>
            <InfoText
                text={dict(hasPaidSubscription()
                    ? "plan.paid.thankyou"
                    : "plan.free.thankyou")}
                align="center"
                style="abstract"/>
        </ContentLayout>
    )
}
