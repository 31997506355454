import {ILogEntryData} from "../types/ILogEntryData";
import {IKeyValuePair} from "../types/IKeyValuePair";

/******************************************************************
 * ConvertDataUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class ConvertDataUtils {

   static convertTextToLogEntries(text: any): ILogEntryData[] {
       if (text === "" || !text) {
           return [];
       }
       const lines = text.split('\n');
       return lines.reduce((result, line) => {
           try {
               result.push(JSON.parse(line));
           } catch (e) {}
           return result;
       }, [] as ILogEntryData[]);
   }

   static convertObjectToMarkdownJSON(obj: any): string {
       const jsonString = JSON.stringify(obj, null, 2);
       return "```json\n" + jsonString + "\n```";
   }

    static convertObjectToArray(obj: IKeyValuePair): Array<{ key: string; value: any }> {
        return Object.keys(obj).map(key => ({
            key,
            value: obj[key]
        }));
    }

}
