import {FrontendSpeaker} from "../../../core/services/FrontendSpeaker";
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue";
import {ICapiTargetData} from "../../../../shared/types/ICapiTargetData";
import {FrontendServices} from "../../../core/FrontendServices";
import {SpeakerUtils} from "../../../utils/SpeakerUtils";
import {FrontendConfig} from "../../../core/FrontendConfig";

/******************************************************************
 * StintAnnouncements
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class StintAnnouncements {

    /******************************************************************
     * Properties
     *****************************************************************/

    public invalid = new StateValue<boolean>()
    public ready = new StateValue<boolean>()
    public started = new StateValue<boolean>()
    public finished = new StateValue<boolean>()
    public falseStart = new StateValue<boolean>()
    public bestLap = new StateValue<boolean>()
    public lapTime = new StateValue<boolean>()
    public drivenTime = new StateValue<boolean>()
    public joker = new StateValue<boolean>()
    public targetScore = new StateValue<boolean>()
    public totalScore = new StateValue<boolean>()
    public targetName = new StateValue<boolean>()

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _services: FrontendServices) {
        this.initAnnouncements();
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public annouceLapTime(lapTime: number, isBestLap: boolean, isValidLap: boolean) {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!isValidLap || !lapTime) {
            this.speaker.speak(this.dict("speaker.text.invalidLapTime"))
            return
        }
        let formattedTime = SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: lapTime
        })
        if (!isBestLap || !this.bestLap.getValue()) {
            if (!this.lapTime.getValue()) return
            this.speaker.speak(formattedTime)
            return
        }
        if (!this.bestLap.getValue()) return
        let text = this.dict("speaker.text.bestLapTime")
        text = text.replace("{TIME}", formattedTime)
        this.speaker.speak(text)
    }

    public annouceJokerLap() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.joker.getValue()) return
        let text = this.dict("speaker.text.jokerLap")
        this.speaker.speak(text)
    }

    public annouceDrivenTime(drivenTime: number) {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.drivenTime.getValue()) return
        if (!drivenTime) return
        let formattedTime = SpeakerUtils.getSpeakableTime({
            services: this._services,
            time: drivenTime, speakSecondsLabel: true
        })
        let text = this.dict("speaker.text.drivenTime")
        text = text.replace("{TIME}", formattedTime)
        this.speaker.speak(text)
    }

    public annouceStarted() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.started.getValue()) return
        let text = this.dict("speaker.text.stint.started")
        this.speaker.speak(text)
    }

    public annouceInvalid() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.invalid.getValue()) return
        let text = this.dict("speaker.text.stint.invalid")
        this.speaker.speak(text, true)
    }

    public annouceFalseStart() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.falseStart.getValue()) return
        let text = this.dict("speaker.text.stint.falseStart")
        this.speaker.speak(text, true)
    }

    public annouceReady() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.ready.getValue()) return
        let text = this.dict("speaker.text.stint.ready")
        this.speaker.speak(text)
    }

    public annouceFinished() {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.finished.getValue()) return
        let text = this.dict("speaker.text.stint.finished")
        this.speaker.speak(text, true)
    }

    public annouceTotalScore(totalScore: number) {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!this.totalScore.getValue()) return
        if (!totalScore) return
        if (totalScore <= 0) return
        let text = this.dict("speaker.text.stint.totalScore")
        text = text.replace("{SCORE}", SpeakerUtils.getSpeakableScore(totalScore, this._services.state.language.getValue()))
        this.speaker.speak(text, true)
    }

    public annouceTarget(target: ICapiTargetData) {
        if (!FrontendConfig.ENABLE_STINT_SPEAKER) return
        if (!target) return
        const targetName = this.dict("speaker.text.targetCode_" + target.target_code)
        if (this.targetName.getValue()
            && this.targetScore.getValue()) {
            let text = this.dict("speaker.text.stint.target")
            text = text.replace("{NAME}", targetName)
            text = text.replace("{SCORE}", SpeakerUtils.getSpeakableScore(target.score, this._services.state.language.getValue()))
            this.speaker.speak(text)
            return
        }
        if (this.targetName.getValue()) {
            this.speaker.speak(targetName)
        }
        if (this.targetScore.getValue()) {
            let text = this.dict("speaker.text.stint.targetScore")
            text = text.replace("{SCORE}", SpeakerUtils.getSpeakableScore(target.score, this._services.state.language.getValue()))
            this.speaker.speak(text)
        }
    }

    /******************************************************************
     * Private Methodes
     *****************************************************************/

    private initAnnouncements() {
        SpeakerUtils.initAnnoucementSetting(this.ready, "stint.ready")
        SpeakerUtils.initAnnoucementSetting(this.started, "stint.started")
        SpeakerUtils.initAnnoucementSetting(this.bestLap, "stint.bestLap")
        SpeakerUtils.initAnnoucementSetting(this.lapTime, "stint.lapTime")
        SpeakerUtils.initAnnoucementSetting(this.drivenTime, "stint.drivenTime")
        SpeakerUtils.initAnnoucementSetting(this.finished, "stint.finished")
        SpeakerUtils.initAnnoucementSetting(this.falseStart, "stint.falseStart")
        SpeakerUtils.initAnnoucementSetting(this.joker, "stint.joker")
        SpeakerUtils.initAnnoucementSetting(this.invalid, "stint.invalid")
        SpeakerUtils.initAnnoucementSetting(this.targetScore, "stint.targetScore")
        SpeakerUtils.initAnnoucementSetting(this.targetName, "stint.targetName")
        SpeakerUtils.initAnnoucementSetting(this.totalScore, "stint.totalScore")
    }

    private get speaker(): FrontendSpeaker {
        return this._services.speaker
    }

    private dict(key: string): string {
        return this._services.dict.get(key)
    }


}
