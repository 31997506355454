import * as React from "react";

/******************************************************************
 * ProgressingAnimation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ProgressingAnimation(props: {
    size?: "normal" | "small"
}) {
    return (
        <div
            className="progressing-animation"
            data-size={props.size ?? "normal"}/>
    );
}
