/******************************************************************
 * CarChassisType
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export const CAR_CHASSIS = [
    "sturmkind_d1",
    "sturmkind_bmw",
    "sturmkind_mercedes",
    "sturmkind_porsche_992",
    "sturmkind_nissan_gtr",
    "subaru_impreza",
    "audi_quattro"
] as const



export type CarChassisType = typeof CAR_CHASSIS[number];

export function isCarChassis(chassi: string): boolean {
    return CAR_CHASSIS.includes(chassi as CarChassisType)
}
