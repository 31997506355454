import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ProgressingAnimation} from "../utils/progress/ProgressingAnimation";
import {InfoText} from "../text/infotext/InfoText";
import {useServices} from "../../hooks/useServices";
import {FrontendConfig} from "../../../core/FrontendConfig";
import {MapboxGeocoderControl} from "./geocoder/MapboxGeocoderControl";
import {ILocationData} from "../../../../shared/types/ILocationData";
import {MapTargetCrosshair} from "./assets/MapTargetCrosshair";
import {Badge} from "../info/badge/Badge";
import {IMapMarkerData} from "./marker/IMapMarkerData";

/******************************************************************
 * Map
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

declare const mapboxgl: any;

export type MapProjection = "globe" | "naturalEarth"
export type MapStyle = "default" | "dark"

export function Map(props: {
    height?: "normal" | "full",
    style?: MapStyle,
    initialZoomLevel?: number
    maxZoomLevel?: number
    projection?: MapProjection
    showSearch?: boolean
    badgeInfo?: string
    markers?: IMapMarkerData[]
    showCenterTargetCrosshair?: boolean
    initialLocation?: ILocationData
    onCenterChanged?: (location: ILocationData) => void
    onZoomChanged?: (zoom: number) => void
    onTouchStart?: () => void
    onTouchEnd?: () => void
    disabled?: boolean
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const scriptElementRef = useRef<HTMLScriptElement>(null)
    const linkElementRef = useRef<HTMLLinkElement>(null)
    const mapElementRef = useRef<HTMLDivElement>(null)
    const markersRef = useRef<any[]>([])

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, language} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isScriptLoaded, setIsScriptLoaded] = useState(false)
    const [map, setMap] = useState<any>(null)
    const [errorMessage, setErrorMessage] = useState<string>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadScript()
        appendStyle()
        return () => {
            removeMarkers()
            scriptElementRef.current.onload = null
            scriptElementRef.current?.remove()
            linkElementRef.current?.remove()
        }
    }, [])

    useEffect(() => {
        if (!isScriptLoaded || !mapElementRef.current) return
        initMap()
    }, [isScriptLoaded, mapElementRef.current])

    useEffect(() => {
        updateMarkers()
    }, [props.markers, map])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function loadScript() {
        scriptElementRef.current = document.createElement('script');
        scriptElementRef.current.src = 'https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.js';
        scriptElementRef.current.onload = () => setIsScriptLoaded(true)
        scriptElementRef.current.onerror = () => setErrorMessage(dict("map.not.available"))
        document.head.appendChild(scriptElementRef.current);
    }

    function appendStyle() {
        linkElementRef.current = document.createElement('link');
        linkElementRef.current.href = 'https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css';
        linkElementRef.current.rel = 'stylesheet';
        document.head.appendChild(linkElementRef.current);
    }

    function mapStyle() {
        switch (props.style) {
            case "dark":
                return "mapbox://styles/mapbox/dark-v11"
            default:
                return "mapbox://styles/mapbox/streets-v11"
        }
    }

    function initMap() {
        if (typeof mapboxgl === 'undefined') {
            setErrorMessage(dict("map.not.available"))
            return;
        }
        mapboxgl.accessToken = FrontendConfig.MAPBOX_ACCESS_TOKEN
        const map = new mapboxgl.Map({
            container: mapElementRef.current,
            style: mapStyle(),
            center: hasInitialLocation()
                ? [props.initialLocation.longitude, props.initialLocation.latitude]
                : props.projection == "globe" ? [8.43111, 49.32083] : [0, 0],
            zoom: hasInitialLocation()
                ? props.initialZoomLevel ?? 13
                : (props.initialZoomLevel ?? (props.projection == "globe" ? 1 : 0.5)),
            maxZoom: props.maxZoomLevel ?? 20,
            projection: props.projection ?? "globe"
        })
        map.addControl(new mapboxgl.NavigationControl({
            showCompass: false
        }), 'bottom-right');
        map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: false
        }), 'bottom-right');
        map.on('style.load', () => {
            map.setLayoutProperty('country-label', 'text-field', [
                'get',
                `name_${language}`
            ]);
        })
        map.on('moveend', () => {
            const center = map.getCenter()
            props.onCenterChanged?.({
                latitude: center.lat,
                longitude: center.lng
            })
        });
        map.on('zoom', () => {
            props.onZoomChanged?.(map.getZoom())
        })
        props.onZoomChanged?.(map.getZoom())
        setMap(map)
    }

    function hasInitialLocation() {
        return props.initialLocation?.latitude && props.initialLocation?.longitude
    }

    function updateMarkers() {
        if (!map) return
        removeMarkers()
        markersRef.current = props.markers?.map((markerData: IMapMarkerData) => {
            return new mapboxgl.Marker(markerData.element)
                .setLngLat([markerData.location.longitude, markerData.location.latitude])
                .addTo(map)
        })
    }

    function removeMarkers() {
        markersRef.current?.forEach(marker => marker?.remove())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="map"
            data-height={props.height ?? "normal"}
            data-show-crosshair={props.showCenterTargetCrosshair}
            data-disabled={props.disabled}
            onTouchStart={props.onTouchStart}
            onTouchEnd={props.onTouchEnd}>
            <div ref={mapElementRef} className="map-container"/>
            {map && props.showSearch &&
                <MapboxGeocoderControl
                    mapboxgl={mapboxgl}
                    map={map}/>}
            {map &&
                <MapTargetCrosshair scale={0.4}/>}
            {props.badgeInfo &&
                <Badge label={props.badgeInfo}/>}
            {!map && !errorMessage &&
                <ProgressingAnimation/>}
            {errorMessage &&
                <InfoText
                    text={errorMessage}
                    align={"center"}
                    size={"small"}
                    style="error"/>}
        </div>
    );

}
