import {Signal} from "@webfruits/core/dist/signal/Signal";
import {IMailData} from "../../../shared/models/IMailData";

/******************************************************************
 * FrontendSignal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendSignal {

    public readonly onServerAdminUpdate = new Signal()
    public readonly onServerAdminMailerUpdate = new Signal<IMailData>()
    public readonly onServerEventUpdate = new Signal()
    public readonly onServerTrackUpdate = new Signal()
    public readonly onServerGroupUpdate = new Signal()
    public readonly onServerGarageUpdate = new Signal()

    public readonly onAdminSimulatorDataChanged = new Signal()

    public readonly onLocalStorageChanged = new Signal<{key: string, value: string}>()

}
