import * as React from "react";
import {useEffect} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {VerifyForm} from "../../../ui/form/forms/VerifyForm";
import {useServices} from "../../../hooks/useServices";
import {useAuthUser} from "../../../hooks/useAuthUser";

/******************************************************************
 * VerifyConfirmModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function VerifyConfirmModal(props: {
    action: (code: string) => Promise<Response>
    forceProgressing?: boolean
    requestClose: () => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, dict} = useServices();
    const {authUserMail} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const abortController = new AbortController()
        api.user.requestVerificationCode({email: authUserMail}, abortController)
        return () => {
            abortController.abort()
        }
    }, [])

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className={"verify-confirm-modal"}
            width="small"
            closeAction={props.requestClose}>
            <Headline
                text={dict("verify.confirm.title")}
                style="modal"/>
            <VerifyForm
                info={dict("verify.confirm.info")}
                forceProcessing={props.forceProgressing}
                actionLabel={dict("verify.confirm.button.label")}
                action={props.action}/>
        </Modal>
    );

}
