import * as React from "react";

/******************************************************************
 * MegaphoneIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MegaphoneIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="megaphone-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M8.58665 7.09305L1.53163 14.2135C1.22987 14.5181 1.15583 14.9811 1.34757 15.3646L1.86935 16.4081C2.0707 16.8108 2.5163 17.0293 2.95794 16.9419L12.8396 14.986C13.4999 14.8553 13.8451 14.1228 13.5256 13.5304L10.1772 7.32217C9.85839 6.73116 9.05928 6.61604 8.58665 7.09305Z"
                        stroke="white" strokeWidth="1.5"/>
                    <path d="M16.0934 12.6987L18.7984 12.4956" stroke="white" strokeLinecap="round"/>
                    <path
                        d="M8.1609 16.8537L7.55955 15.4795L4.81118 16.6822L5.41253 18.0564L8.1609 16.8537ZM6.22363 19.9099C6.55575 20.6689 7.44022 21.0149 8.19916 20.6828C8.9581 20.3507 9.30411 19.4662 8.972 18.7072L6.22363 19.9099ZM5.41253 18.0564L6.22363 19.9099L8.972 18.7072L8.1609 16.8537L5.41253 18.0564Z"
                        fill="white"/>
                    <path d="M14.621 8.78711L17.0401 7.45801" stroke="white" strokeLinecap="round"/>
                    <path d="M12.374 5.46283L13.8956 3.43408" stroke="white" strokeLinecap="round"/>
            </svg>
        </div>
    );

}
