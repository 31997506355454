import * as React from "react";
import {Spacer} from "../../utils/spacer/Spacer";
import {Headline} from "./Headline";
import {ContentLayout} from "../../layout/content/ContentLayout";

/******************************************************************
 * BasicPageTitle
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function BasicPageTitle(props: {
    title: string,
    subTitle?: string
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout gap="small">
            <Spacer height="normal"/>
            <Headline
                text={props.title}
                align="center"
                style="h1"/>
            {props.subTitle &&
                <Headline
                    text={props.subTitle}
                    align="center"
                    style="h5"/>}
            <Spacer height="large"/>
        </ContentLayout>
    );

}
