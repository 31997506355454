import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {MongoObjectIDType} from "../../shared/types/MongoObjectIDType";
import {ISessionData, ISessionSetup} from "../../shared/models/ISessionData";
import {SessionStateType} from "../../shared/types/SessionStateType";

/******************************************************************
 * SessionAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async createSession(data: {
        name: string,
        eventID: MongoObjectIDType,
        setup: ISessionSetup,
        state?: SessionStateType,
        trackID?: MongoObjectIDType,
    }): Promise<Response> {
        return await this._frontend.api.request("POST", APIRoute.SESSION, data, true);
    }

    public async update(sessionID: MongoObjectIDType, data: ISessionData): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.SESSION, {sessionID: sessionID, ...data}, true);
    }

    public async delete(sessionID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("DELETE", APIRoute.SESSION, {sessionID: sessionID}, true)
    }

    public async reset(sessionID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("PATCH", APIRoute.SESSION_RESET, {sessionID: sessionID}, true)
    }

    public async getSessionRoute(sessionID: MongoObjectIDType): Promise<string> {
        const response = await this._frontend.api.request("GET", APIRoute.SESSION_ROUTE, {sessionID: sessionID}, true)
        if (response.status == 200) {
            const result = await response.json()
            return result?.route
        }
        return null
    }

    public async getSessionLog(sessionID: MongoObjectIDType): Promise<Response> {
        return await this._frontend.api.request("GET", APIRoute.SESSION_LOG, {sessionID: sessionID}, true)
    }

    public async createFollowSession(eventID: MongoObjectIDType, originalSessionID: MongoObjectIDType): Promise<{newSessionPath: string, originalSessionPath: string}> {
        const response = await this._frontend.api.request("POST", APIRoute.SESSION_FOLLOW_SESSION, {eventID, originalSessionID}, true)
        if (response.status == 200) {
            return await response.json()
        }
        return null
    }

    public async getSessionByID(sessionID: MongoObjectIDType): Promise<ISessionData> {
        const response = await this._frontend.api.request("GET", APIRoute.SESSION, {sessionID});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

    public async getSessionByRoute(sessionRoute: string): Promise<ISessionData> {
        const response = await this._frontend.api.request("GET", APIRoute.SESSION, {sessionRoute});
        if (response.status == 200) {
            return await response.json();
        }
        return null;
    }

}
