import {DriftClubFrontend} from "./core/DriftClubFrontend";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {FrontendConfig} from "./core/FrontendConfig";

require("./core/styles/index.scss");

/* ----------------------------------------------------------------
 * Version
 * --------------------------------------------------------------*/

console.log("DR!FTCLUB FRONTEND " + FrontendConfig.FRONTEND_VERSION + (process.env.PRODUCTION ? "" : " (dev-build)"));

/* ----------------------------------------------------------------
 * Init App
 * --------------------------------------------------------------*/

const app = new DriftClubFrontend();
PromisedDelay.waitUntilValid(() => !!app.view)
    .then(() => {
        document.body.appendChild(app.view);
    })

