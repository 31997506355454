import * as React from "react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {useElementSize} from "../../hooks/useElementSize";
import {useDeviceInfo} from "../../hooks/useDeviceInfo";

/******************************************************************
 * ActionBar
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ActionBar(props: {
    children?: ReactElement | ReactElement[]
    hide?: boolean
    backgroundColor?: "black" | "red"
    location:
        "main" |
        "main-top" |
        "sidebar" |
        "modal" |
        "mobile-menu" |
        "splitview-back" |
        "splitview-sidebar" |
        "article-block" |
        "picture" |
        "event-teaser"
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [width, height, scrollWidth] = useElementSize(ref)
    const {isMobile} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [scrollable, setScrollable] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECT
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setScrollable(width < scrollWidth)
    }, [width, height, scrollWidth])

    /* ----------------------------------------------------------------
 	 * RENDER
 	 * --------------------------------------------------------------*/

    return (
        <div
            ref={ref}
            className="action-bar"
            data-hide={props.hide}
            data-scrollable={scrollable}
            data-location={props.location}
            data-is-mobile={isMobile}
            data-background-color={props.backgroundColor ?? "black"}
            onTouchMoveCapture={e => e.stopPropagation()}
            onTouchEndCapture={e => e.stopPropagation()}
            onTouchStartCapture={e => e.stopPropagation()}>
            {props.children}
        </div>
    );
}
