import React = require("react");
import {EditableField} from "../../form/editable/EditableField";
import {ContentAlignType} from "../../../../utils/types/ContentAlignType";
import {ReactElement} from "react";

/******************************************************************
 * Headline
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

const styleToTagMap: { [key: string]: keyof JSX.IntrinsicElements } = {
    "h1": "h1",
    "h1-overlay": "h1",
    "h1-mono": "h1",
    "modal": "h2",
    "h2": "h2",
    "h3": "h3",
    "h3-inline": "h3",
    "h4": "h4",
    "h5": "h5",
    "h5-underlined": "h5",
    "h6": "h6"
};

export function Headline(props: {
    text: string | ReactElement
    editable?: boolean
    nowrap?: boolean
    color?: "default" | "red"
    disabled?: boolean
    align?: ContentAlignType
    action?: (value: string) => Promise<Response>
    onClick?: () => void
    hyphens?: boolean
    style:
        | "h1"
        | "h1-overlay"
        | "h1-mono"
        | "modal"
        | "h2"
        | "h3"
        | "h3-inline"
        | "h4"
        | "h5"
        | "h5-underlined"
        | "h6"
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    const Tag = styleToTagMap[props.style] || "h2";

    return (
        <Tag
            className="headline"
            onClick={props.onClick}
            data-hyphens={props.hyphens}
            data-color={props.color ?? "default"}
            data-clickable={!!props.onClick}
            data-style={props.style}
            data-disabled={props.disabled}
            data-align={props.align ?? "left"}
            data-nowrap={props.nowrap}>
            {!!props.action && props.editable
                ? <EditableField
                    action={props.action}
                    text={props.text as string}
                    ui="chromeless"
                    useEnterToSave={true}/>
                : <>{props.text}</>
            }
        </Tag>
    );

}
