import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {ICarData} from "../../../../../shared/models/ICarData";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {GarageCar} from "../../../systems/main/user/garage/car/GarageCar";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {useElementSize} from "../../../hooks/useElementSize";
import {Infobox} from "../Infobox";
import {ArrayUtils} from "../../../../utils/ArrayUtils";
import {PlanFeatureDefinitions} from "../../../../../shared/plan/PlanFeatureDefinitions";
import {PlanAccessMaxType} from "../../../../../shared/types/PlanAccessType";

/******************************************************************
 * GarageInfobox
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function GarageInfobox() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const carsRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, router, state} = useServices()
    const {
        routeOwnerID,
        routeOwnerPath,
        isRouteOwnerAuthUser,
        routeOwnerValidDriverLicense,
        routeOwnerUseSubscription} = useRouteOwner()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [loading, setLoading] = useState<boolean>(true)
    const [activeCars, setActiveCars] = useState<ICarData[]>()
    const [randomCars, setRandomCars] = useState<ICarData[]>([])
    const [carsWidth] = useElementSize(carsRef)
    const [hasInactiveCars, setHasInactiveCars] = useState<boolean>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        setLoading(true)
        loadCars()
    }, [routeOwnerID, routeOwnerUseSubscription, routeOwnerValidDriverLicense])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadCars() {
        const cars: ICarData[] = await api.car.getGarage(routeOwnerID)
        setLoading(false)
        const numVisibleCars = maxAllowedCars()
        let visibleCars: ICarData[]
        if (!numVisibleCars || numVisibleCars == 0) {
            visibleCars = cars
        } else {
            visibleCars = cars.slice(0, numVisibleCars)
        }
        if (!visibleCars || visibleCars.length == 0) {
            setRandomCars(null)
            return
        }
        setActiveCars(visibleCars)
        setRandomCars(ArrayUtils.shuffledArray(visibleCars).slice(0, 6))
        setHasInactiveCars(cars?.length > visibleCars?.length)
    }

    function maxAllowedCars() {
        return PlanFeatureDefinitions.getFeatureAccess<PlanAccessMaxType>("garage", routeOwnerValidDriverLicense)?.value
    }

    function computeColumns() {
        if (carsWidth < 600) return 2
        return 3
    }

    function title(): string {
        if (activeCars?.length == 1) {
            return dict("infobox.garage.single.title")
        }
        if (activeCars?.length > 0) {
            return activeCars.length + " " + dict("infobox.garage.title")
        }
        return dict("infobox.garage.title")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Infobox
            title={title()}
            hasData={activeCars?.length > 0}
            loading={loading}
            noDataInfo={dict(isRouteOwnerAuthUser
                ? "infobox.garage.empty.info.owner"
                : "infobox.garage.empty.info.guest")}
            createButtonLabel={dict("infobox.garage.create.button.label")}
            createButtonOnClick={() => state.showCar.setValue(true)}
            linkButtonLabel={dict("infobox.garage.showAll.button.label")}
            linkButtonOnClick={() => router.showRoute(FrontendRoute.USER_GARAGE(routeOwnerPath))}
            helpButtonLabel={dict("infobox.garage.academy.button.label")}
            helpButtonOnClick={() => state.showAcademy.setValue(dict("academy.id.garage"))}
            higherDriverLicenseRequiredOwnerInfo={hasInactiveCars && isRouteOwnerAuthUser
                ? dict("infobox.garage.full.owner.info")
                : null}>
            <ComponentInitializer key={routeOwnerID} isPropertyAvailable={!loading}>
                <ContentLayout
                    ref={carsRef}
                    columns={computeColumns()}>
                    {randomCars?.map((carData) => {
                        return <GarageCar
                            key={carData._id}
                            car={carData}/>
                    })}
                </ContentLayout>
            </ComponentInitializer>
        </Infobox>
    );

}
