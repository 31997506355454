import * as React from "react";
import {EditableField} from "../../../form/editable/EditableField";

/******************************************************************
 * TableEditableCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableEditableCell(props: {
    action: (value: string) => Promise<Response>
    text: string;
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-editable-cell">
            <EditableField
                action={props.action}
                text={props.text}
                ui="right"/>
        </div>
    );

}
