import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useServices} from "../../../../../hooks/useServices";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {SizeUtils} from "../../../../../../../shared/utils/SizeUtils";
import {ILabelValueData} from "../../../../../../../shared/types/ILabelValueData";
import {IDatabaseCollectionsData, IDatabaseInfoData} from "../../../../../../../shared/types/IDatabaseInfoData";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";

/******************************************************************
 * DatabaseInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DatabaseInfo() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>()

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices()
    const {authUserID} = useAuthUser()
    const [isMobileMode] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [stats, setStats] = useState<ILabelValueData[]>()
    const [collections, setCollections] = useState<IDatabaseCollectionsData[]>()
    const [isLoadingData, setIsLoadingData] = useState(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        if (!authUserID) return
        loadData()
    }, [authUserID]);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadData() {
        setIsLoadingData(true)
        const data = await api.admin.getDatabaseInfo()
        updateStats(data)
        setCollections(data.collections)
        setIsLoadingData(false)
    }

    function updateStats(data: IDatabaseInfoData) {
        setStats([{
            label: dict("admin.database.info.db"),
            value: data.stats.db
        }, {
            label: dict("admin.database.info.collections"),
            value: data.stats.collections
        }, {
            label: dict("admin.database.info.objects"),
            value: data.stats.objects
        }, {
            label: dict("admin.database.info.dataSize"),
            value: SizeUtils.bytesToSize(data.stats.dataSize)
        }, {
            label: dict("admin.database.info.indexSize"),
            value: SizeUtils.bytesToSize(data.stats.indexSize)
        }, {
            label: dict("admin.database.info.availableSystemSize"),
            value: SizeUtils.bytesToSize(data.stats.fsTotalSize - data.stats.fsUsedSize)
        }])
    }

    function statsColumnsDefinition(): ITableColumnDefinition<ILabelValueData>[] {
        return [{
            dataKey: "label",
            type: "text",
            columnName: dict("admin.database.stats.column.label"),
            size: "3fr"
        }, {
            dataKey: "value",
            type: "number",
            align: "right",
            size: "120px",
            columnName: dict("admin.database.stats.column.value")
        }];
    }

    function collectionsColumnsDefinition(): ITableColumnDefinition<IDatabaseCollectionsData>[] {
        return [{
            sortKey: "name",
            dataKey: "name",
            type: "text",
            size: "2fr",
            columnName: dict("admin.database.collections.column.name")
        }, {
            sortKey: "documents",
            dataKey: "documents",
            align: "right",
            type: "number",
            size: "120px",
            columnName: dict("admin.database.collections.column.documents")
        }, {
            sortKey: "size",
            dataKey: "size",
            align: "right",
            type: "number",
            size: "120px",
            columnName: dict("admin.database.collections.column.size"),
            value: data => SizeUtils.bytesToSize(data.size)
        }];
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="database-info"
            columns={isMobileMode ? 1 : 2}>
            <ContentLayout
                framed={true}
                gap="small">
                <Headline text={dict("admin.database.stats.title")} style="h5-underlined"/>
                <Table<ILabelValueData>
                    rowsData={stats}
                    isLoadingData={isLoadingData}
                    columnsDefinition={statsColumnsDefinition()}
                    style="admin-compact"
                    noDataInfo={dict("admin.database.stats.loading")}/>
            </ContentLayout>
            <ContentLayout
                framed={true}
                gap="small">
                <Headline text={dict("admin.database.collections.title")} style="h5-underlined"/>
                <Table<IDatabaseCollectionsData>
                    rowsData={collections}
                    columnsDefinition={collectionsColumnsDefinition()}
                    sortKey="size"
                    isLoadingData={isLoadingData}
                    sortDirection="desc"
                    style="admin-compact"
                    noDataInfo={dict("admin.database.stats.loading")}/>
            </ContentLayout>
        </ContentLayout>
    );

}
