import * as React from "react";
import {IStintData} from "../../../../../../../shared/models/IStintData";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {Table} from "../../../../../ui/table/Table";
import {ICapiTargetData} from "../../../../../../../shared/types/ICapiTargetData";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";

/******************************************************************
 * StintTargetsInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function StintTargetsInfo(props: {
    stint: IStintData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function columsDefinition(): ITableColumnDefinition<ICapiTargetData>[] {
        return [{
            sortKey: "rowID",
            columnName: dict("table.head.rowID"),
            type: "row-index",
            size: "50px"
        }, {
            sortKey: "target_code",
            columnName: dict("table.head.target"),
            type: "stint-target",
            size: "2fr"
        }, {
            columnName: dict("table.head.target.direction"),
            type: "text",
            size: "100px",
            value: targetData => {
                const sumAngles = targetData.orientations?.reduce((acc, curr) => acc + curr.angle, 0)
                if (!sumAngles || targetData.target_code == 0) {
                    return "–"
                }
                return sumAngles > 0 ? dict("stint.gymkhana.target.direction.right") : dict("stint.gymkhana.target.direction.left")
            }
        }, {
            dataKey: "score",
            sortKey: "score",
            align: "right",
            columnName: dict("table.head.score"),
            type: "number-bold",
            size: "100px"
        }, {
            columnName: dict("table.head.target.angle"),
            type: "number",
            align: "right",
            size: "100px",
            value: targetData => {
                if (!targetData.orientations || targetData.orientations?.length == 0) return "–"
                const sumAngles = targetData.orientations?.reduce((acc, curr) => acc + curr.angle, 0)
                if (!sumAngles) {
                    return "–"
                }
                const avgAngles = sumAngles ? sumAngles / targetData.orientations.length : 0
                return avgAngles.toFixed(2)
            }
        }, {
            columnName: dict("table.head.target.speed"),
            type: "number",
            align: "right",
            size: "100px",
            value: targetData => {
                if (!targetData.orientations || targetData.orientations?.length == 0) return "–"
                const sumSpeeds = targetData.orientations?.reduce((acc, curr) => acc + curr.speed, 0)
                if (!sumSpeeds) {
                    return "–"
                }
                const avgSpeed = sumSpeeds ? sumSpeeds / targetData.orientations.length : 0
                return avgSpeed.toFixed(2)
            }
        }]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (<>
        <ContentLayout
            className="stint-targets-info"
            gap="small"
            framed={true}>
            <Table<ICapiTargetData>
                style="standard"
                sortKey={"rowID"}
                sortDirection={"desc"}
                rowsData={props.stint?.targetsData}
                globalData={props.stint}
                columnsDefinition={columsDefinition()}/>
        </ContentLayout>
    </>);
}
