import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * FolderContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function FolderContextMenu(props: {
    folderID: MongoObjectIDType
    onRequestRename: () => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {eventID} = useEventState();

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function deleteFolder() {
        await api.event.deleteFolder(eventID, props.folderID)
    }

    async function renameFolder() {
        props.onRequestRename()
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconScale={0.9}
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContextMenuLabel
                label={dict("folder.rename.label")}
                icon="edit"
                onClick={renameFolder}/>
            <ContextMenuLabel
                label={dict("folder.delete.label")}
                icon="delete"
                warning={true}
                onClick={deleteFolder}/>
        </ContextMenu>
    );

}
