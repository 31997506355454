import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ISessionData} from "../../../../../shared/models/ISessionData";
import {ContextMenuDivider} from "../elements/ContextMenuDivider";
import {useGroupState} from "../../../hooks/useGroupState";
import {useSessionState} from "../../../hooks/useSessionState";
import {useEventState} from "../../../hooks/useEventState";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {ConfirmResetCustomElement} from "../../../systems/modals/session/reset/ConfirmResetCustomElement";

/******************************************************************
 * SessionContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionContextMenu(props: {
    sessionData: ISessionData
    enableShortCuts?: boolean
    onClicked: () => void
    onToggle?: (showMenu: boolean) => void
    onRequestEditPath: () => void
    onRequestEditName: () => void
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict, state} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState()
    const {sessionID} = useSessionState()
    const {
        eventOwnerType,
        eventIsFinished,
    } = useEventState()

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function editName() {
        props.onClicked()
        props.onRequestEditName()
    }

    function editPath() {
        props.onClicked()
        props.onRequestEditPath()
    }

    function deleteSession() {
        props.onClicked()
        state.showConfirm.setValue({
            type: "deleteSession",
            payload: {
                sessionName: props.sessionData.name,
                sessionID: props.sessionData._id
            }
        })
    }

    function resetSession() {
        props.onClicked()
        state.showConfirm.setValue({
            type: "resetSession",
            customElement: <ConfirmResetCustomElement sessionData={props.sessionData}/>,
            payload: {
                sessionName: props.sessionData.name,
                sessionID: props.sessionData._id
            }
        })
    }



    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="menuDots"
            iconScale={0.9}
            onToggle={props.onToggle}
            stopPropagation={true}
            enabled={isRouteOwnerAuthUser || isGroupAdmin}>
            <ContextMenuLabel
                label={dict("session.rename.label")}
                icon="title"
                onClick={editName}/>
            {!eventIsFinished &&
                <ContextMenuLabel
                    label={dict("session.path.label")}
                    icon="link"
                    keyShortcut={props.enableShortCuts ? FrontendConfig.SHORTCUT_SESSION_GAMEID : null}
                    onClick={editPath}/>}
            {props.sessionData._id == sessionID && <>
                <ContextMenuLabel
                    label={dict("session.settings.label")}
                    icon="settings"
                    keyShortcut={props.enableShortCuts ? FrontendConfig.SHORTCUT_SESSION_SETTINGS : null}
                    iconScale={0.8}
                    onClick={() => state.showSessionSettings.setValue(true)}/>
            </>}
            {!eventIsFinished && eventOwnerType == "group" && <>
                <ContextMenuDivider style="small"/>
                <ContextMenuLabel
                    label={dict("session.addFollowSession.label")}
                    icon="copy"
                    keyShortcut={props.enableShortCuts ? FrontendConfig.SHORTCUT_ADD_FOLLOW_SESSION : null}
                    onClick={() => state.showCreateFollowSession.setValue({originalSession: props.sessionData})}/>
            </>}
            <ContextMenuDivider style="small"/>
            {(!eventIsFinished && props.sessionData.state != "finished") &&
                <ContextMenuLabel
                    label={dict("session.reset.label")}
                    icon="sync"
                    warning={true}
                    keyShortcut={props.enableShortCuts ? FrontendConfig.SHORTCUT_SESSION_RESET : null}
                    onClick={resetSession}/>}
            <ContextMenuLabel
                label={dict("session.delete.label")}
                icon="delete"
                warning={true}
                keyShortcut={props.enableShortCuts ? FrontendConfig.SHORTCUT_SESSION_DELETE : null}
                onClick={deleteSession}/>
        </ContextMenu>
    );
}
