import * as React from "react";

/******************************************************************
 * TitleIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TitleIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="title-icon">
            <svg width={16 * (props.scale ?? 1)} height={17 * (props.scale ?? 1)} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.6381 6.26221C11.3497 6.26221 10.1984 7.01408 9.7053 8.17783L9.49999 8.65346L10.7603 9.19033L10.9653 8.71471C11.2434 8.05846 11.9 7.63502 12.6381 7.63502C13.7178 7.63502 14.4431 8.36221 14.4431 9.44471V9.51658C13.7519 9.53502 12.9228 9.58064 12.1847 9.66783C10.4809 9.86877 9.46405 10.8 9.46405 12.1591C9.46405 12.8828 9.73655 13.5341 10.2316 13.99C10.6962 14.4185 11.3297 14.6372 12.0159 14.6372C12.9803 14.6372 13.8128 14.4081 14.4431 13.9297V14.6372H15.8125V9.44471C15.8125 7.60064 14.4775 6.26221 12.6381 6.26221ZM12.0159 13.2956C11.4703 13.2956 10.8331 12.9881 10.8331 12.1591C10.8331 11.8263 10.9525 11.5931 11.22 11.4031C11.4806 11.2181 11.88 11.086 12.345 11.0313C13.0256 10.951 13.7922 10.9078 14.4387 10.89C14.3712 12.4991 13.5772 13.2956 12.0159 13.2956Z" fill="white"/>
                <path d="M2.91406 11.1331H6.83594L8.15438 14.6372H9.625L4.84375 2.44971L0.125 14.6372H1.59563L2.91406 11.1331ZM4.875 5.97189L6.32031 9.76221H3.42969L4.875 5.97189Z" fill="white"/>
            </svg>
        </div>
    );

}
