import * as React from "react";
import {ReactElement, useRef, useState} from "react";
import {IntersectingPixel} from "./IntersectingPixel";

/******************************************************************
 * PinComponent
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PinComponent(props: {
    className?: string
    children?: ReactElement | ReactElement[]
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const contentRef = useRef<HTMLDivElement>(null)
    const pinRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [pinned, setPinned] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className={"pin-component"}
            style={{height: contentRef.current?.clientHeight}}
            ref={pinRef}>
            <IntersectingPixel
                onIntersectionChange={isIntersecting => setPinned(!isIntersecting)}/>
            <div
                ref={contentRef}
                className={props.className}
                style={{
                    position: pinned ? "fixed" : null,
                    top: pinned ? 0 : null,
                    width: pinned ? pinRef.current?.clientWidth : null,
                    zIndex: 1
                }}>
                {props.children}
            </div>
        </div>
    );

}
