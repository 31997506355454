import * as React from "react";
import {useEffect, useState} from "react";
import {TrackLeaderboard} from "../../track/leaderboard/TrackLeaderboard";
import {ITrackData} from "../../../../../../shared/models/submodels/ITrackData";
import {useRouteStates} from "../../../../hooks/useRouteStates";
import {useServices} from "../../../../hooks/useServices";
import {FrontendRoute} from "../../../../../../shared/routes/FrontendRoute";
import {FrontendRouteUtils} from "../../../../../../shared/utils/FrontendRouteUtils";
import {ITrackFilterData} from "../../../../../../shared/models/submodels/ITrackFilterData";
import {TrackFilterPresets} from "../../../../../../shared/config/TrackFilterPresets";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {useEventState} from "../../../../hooks/useEventState";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {
    SelectInput,
    SelectInputOptionGroupType,
    SelectInputOptionType
} from "../../../../ui/form/elements/select/SelectInput";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {EmptyDataInfo} from "../../../../ui/info/empty/EmptyDataInfo";
import {TrackUtils} from "../../../../../utils/TrackUtils";

/******************************************************************
 * EventTrackPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventTrackPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, router} = useServices()
    const {routePath} = useRouteStates()
    const {eventID} = useEventState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [track, setTrack] = useState<ITrackData>()
    const [selectedTrackFilter, setSelectedTrackFilter] = useState<ITrackFilterData>(TrackFilterPresets.DEFAULT)
    const [loading, setLoading] = useState<boolean>(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadTrack()
    }, [routePath])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadTrack() {
        setLoading(true)
        const eventRoute = FrontendRouteUtils.parseEventRoute(routePath)
        const result = await api.track.getByRoute(FrontendRoute.TRACK(eventRoute.ownerType, eventRoute.ownerPath, eventRoute.trackPath))
        setSelectedTrackFilter(TrackFilterPresets.DEFAULT)
        setTrack(result)
        setLoading(false)
    }

    function defaultFilter(): ITrackFilterData {
        return TrackFilterPresets.PRESETS.find(preset => preset.presetType === "default")
    }

    function enginePresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "engine")
    }

    function tuningPresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "tuning")
    }

    function updateFilterOptions(): (SelectInputOptionType<string> | SelectInputOptionGroupType)[] {
        let options: (SelectInputOptionType<string> | SelectInputOptionGroupType)[] = []
        options.push({
            text: TrackUtils.trackFilterName(defaultFilter(), track?.type, dict),
            value: defaultFilter().path
        })
        options.push({
            groupLabel: dict("track.filter.preset.tunings.label"),
            options: tuningPresetFilters().map(filter => ({
                text: dict(filter.name),
                value: filter.path
            }))
        })
        options.push({
            groupLabel: dict("track.filter.preset.engines.label"),
            options: enginePresetFilters().map(filter => ({
                text: dict(filter.name),
                value: filter.path
            }))
        })
        if (track?.filters?.length > 0) {
            options.push({
                groupLabel: dict("track.filter.custom.label"),
                options: track.filters?.map(filter => ({
                    text: filter.name,
                    value: filter.path
                }))
            })
        }
        return options
    }

    function showFilteredLeaderboard(filterPath: string) {
        const availableFilters = [...TrackFilterPresets.PRESETS, ...track?.filters]
        const filter = availableFilters.find(filter => filter.path == filterPath)
        setSelectedTrackFilter(filter)
    }

    function gotoTrackPage() {
        if (!track) return
        router.showRoute(FrontendRoute.TRACK(track.ownerType, track.owner.path, track.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ComponentInitializer isPropertyAvailable={!loading} fullHeight={true}>
            {track
                ? <TrackLeaderboard
                    key={"event.track.page.leaderboard." + (track?._id ?? "") + "." + (selectedTrackFilter?.path ?? "")}
                    trackData={track}
                    trackFilter={selectedTrackFilter}
                    eventID={eventID}/>
                : <EmptyDataInfo text={dict("event.track.page.empty.label")}/>}
            <ActionBar location={"main"}>
                <ActionBarSpacer/>
                <SelectInput
                    key={"event.track.page.select.filter." + routePath}
                    size="small"
                    label={dict("event.track.select.filter.label")}
                    defaultValue={selectedTrackFilter?.path}
                    options={updateFilterOptions()}
                    onChange={showFilteredLeaderboard}/>
                <Divider style="action-bar"/>
                <LabelButton
                    label={dict("event.track.showTrackPage.label")}
                    style="action-main"
                    onClick={gotoTrackPage}/>
            </ActionBar>
        </ComponentInitializer>
    );

}
