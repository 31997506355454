import {FrontendServices} from "../core/FrontendServices";
import {APIRoute} from "../../shared/routes/APIRoute";
import {IPaddleDriverLicensePlanData} from "../../shared/models/IPaddleDriverLicensePlanData";
import {DriverLicenseType} from "../../shared/types/DriverLicenseType";
import {ISubscriptionChangePreviewData} from "../../shared/types/ISubscriptionChangePreviewData";
import {IPaddleCustomerAddressData} from "../../shared/types/IPaddleCustomerAddressData";

/******************************************************************
 * PaddleAPI
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class PaddleAPI {

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public async getDriverLicensePlans(abortController?: AbortController): Promise<IPaddleDriverLicensePlanData[]> {
        const response = await this._frontend.api.request("GET", APIRoute.PADDLE_DRIVERLICENSE_PLANS, null, true, abortController)
        const result = await response?.json()
        if (response && response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.error.createMessage(result)
            })
            return null
        }
        return result
    }

    public async updateSubscription(driverLicenseType: DriverLicenseType, preview: boolean, verificationCode: string = null): Promise<ISubscriptionChangePreviewData | boolean> {
        const route = preview ? APIRoute.PADDLE_PREVIEW_SUBSCRIPTION : APIRoute.PADDLE_UPDATE_SUBSCRIPTION
        const response = await this._frontend.api.request("PATCH", route, {driverLicenseType, verificationCode}, true)
        const result = await response.json()
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.error?.code ?? result.code)
            })
            return null
        }
        return preview ? result as ISubscriptionChangePreviewData : result as boolean
    }

    public async cancelSubscription(verificationCode: string): Promise<boolean> {
        const response = await this._frontend.api.request("POST", APIRoute.PADDLE_CANCEL_SUBSCRIPTION, {verificationCode}, true)
        if (response.status !== 200) {
            const result = await response.json()
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.error?.code ?? result.code)
            })
            return false
        }
        return true
    }

    public async getCustomerAddress(): Promise<IPaddleCustomerAddressData> {
        const response = await this._frontend.api.request("GET", APIRoute.PADDLE_CUSTOMER_ADDRESS, null, true)
        const result = await response.json()
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.error?.code ?? result.code)
            })
            return null
        }
        return result
    }

    public async updateCustomerAddress(addressData: IPaddleCustomerAddressData): Promise<boolean> {
        const response = await this._frontend.api.request("PATCH", APIRoute.PADDLE_CUSTOMER_ADDRESS, {addressData}, true)
        if (response.status !== 200) {
            const result = await response.json()
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.code)
            })
            return false
        }
        return true
    }

    public async getSubscriptionTransactionID(updatePaymentMethode: boolean): Promise<string> {
        const response = await this._frontend.api.request("GET", APIRoute.PADDLE_SUBSCRIPTION_TRANSACTION_ID, {updatePaymentMethode}, true)
        const result = await response.json()
        if (response.status !== 200) {
            this._frontend.state.showMessage.setValue({
                type: "error",
                message: this._frontend.dict.get(result.code)
            })
            return null
        }
        return result
    }

}
