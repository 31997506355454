import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {useServices} from "../../../hooks/useServices";
import {TrackHeader} from "./header/TrackHeader";
import {TrackConstructInfobox} from "../../../ui/infobox/tracks/TrackConstructInfobox";
import {useTrackState} from "../../../hooks/useTrackState";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {ITrackFilterPreview} from "../../../../../shared/types/ITrackFilterPreview";
import {TrackFilterPreviewInfobox} from "../../../ui/infobox/tracks/TrackFilterPreviewInfobox";
import {Headline} from "../../../ui/text/headings/Headline";
import {TrackFilterPresets} from "../../../../../shared/config/TrackFilterPresets";
import {EmptyDataInfo} from "../../../ui/info/empty/EmptyDataInfo";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {useGroupState} from "../../../hooks/useGroupState";
import {Divider} from "../../../ui/utils/divider/Divider";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {IEventData} from "../../../../../shared/models/IEventData";
import {Table} from "../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../ui/table/ITableColumnDefinition";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData";
import {TrackUtils} from "../../../../utils/TrackUtils";
import {Spacer} from "../../../ui/utils/spacer/Spacer";

/******************************************************************
 * TrackHomePage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackHomePage() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, api, dict, router} = useServices()
    const {
        trackID,
        trackFilters,
        trackInstructionInfo,
        trackInstructionImage,
        trackOwnerType,
        trackType
    } = useTrackState()
    const [isMobileMode] = useMobileStyle(rootRef, 700)
    const {isRouteOwnerAuthUser, routeOwnerValidDriverLicense} = useRouteOwner()
    const {authUserValidDriverLicense, authUserUseSubscription} = useAuthUser()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [presetFilterPreviews, setPresetFilterPreviews] = useState<ITrackFilterPreview[]>()
    const [customFilterPreviews, setCustomFilterPreviews] = useState<ITrackFilterPreview[]>()
    const [eventsUsingTrack, setEventsUsingTrack] = useState<IEventData[]>([])
    const [loading, setLoading] = useState(true)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadData()
    }, [trackFilters, authUserValidDriverLicense, routeOwnerValidDriverLicense])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadData() {
        setLoading(true)
        await loadEventsUsingTrack()
        await loadTrackFilterPreviewData()
        setLoading(false)
    }

    async function loadEventsUsingTrack() {
        const result = await api.track.getEventsUsingTrack(trackID)
        result?.sort((a, b) => {
            const result = b.state.localeCompare(a.state)
            if (result == 0) {
                return b._id.localeCompare(a._id)
            }
            return result
        })
        setEventsUsingTrack(result)
    }

    async function loadTrackFilterPreviewData() {
        const result = await api.track.getTrackFilterPreviews(trackID)
        setPresetFilterPreviews(result?.filter(data => data.filter.isPreset))
        setCustomFilterPreviews(result?.filter(data => !data.filter.isPreset))
    }

    function showConstructInfobox() {
        return trackInstructionInfo || trackInstructionImage
    }

    function eventsUsingTrackColumnsDefinition(): ITableColumnDefinition<IEventData>[] {
        return [{
            type: isMobileMode ? "state-compact" : "state",
            dataKey: "state",
            align: "center",
            size: isMobileMode ? "40px" : "100px"
        }, {
            dataKey: "name",
            sortKey: "name",
            type: "text-bold"
        }, {
            type: "image-circle",
            dataKey: "owner.avatar",
            size: "50px"
        }];
    }

    function gotoEvent(eventData: IEventData) {
        router.showRoute(FrontendRoute.EVENT(eventData.ownerType, eventData.owner.path, eventData.path))
    }

    function showTrackFilterDialog() {
        state.showTrackFilter.setValue(true)
    }

    function defaultFilter(): ITrackFilterData {
        return TrackFilterPresets.PRESETS.find(preset => preset.presetType === "default")
    }

    function enginePresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "engine")
    }

    function tuningPresetFilters(): ITrackFilterData[] {
        return TrackFilterPresets.PRESETS.filter(preset => preset.presetType === "tuning")
    }

    function isHigherDriverLicenseRequired(trackFilter: ITrackFilterData) {
        return TrackUtils.isHigherDriverLicenseRequired(
            authUserUseSubscription,
            authUserValidDriverLicense,
            trackFilter)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="track-home-page"
            ref={rootRef}
            size="content"
            alignSelf="start">
            <TrackHeader/>
            <Spacer height="normal"/>
            <ContentLayout gap="large">
                {showConstructInfobox() && <TrackConstructInfobox/>}
                <ContentLayout gap="none">
                    <Headline text={dict("track.used.events.label")} style="modal"/>
                    <ContentLayout
                        gap="small"
                        framed={true}>
                        {eventsUsingTrack?.length > 0
                            ? <Table<IEventData>
                                onRowClick={gotoEvent}
                                showHeader={false}
                                showFilter={eventsUsingTrack?.length > 5}
                                sortKey="state"
                                visibleRows={5}
                                columnsDefinition={eventsUsingTrackColumnsDefinition()}
                                rowsData={eventsUsingTrack}/>
                            : <EmptyDataInfo
                                size="small"
                                text={dict(trackOwnerType == "user"
                                    ? "track.used.trainings.empty"
                                    : "track.used.events.empty")}/>}
                    </ContentLayout>
                </ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={TrackUtils.trackFilterName(defaultFilter(), trackType, dict)} style="modal"/>
                    <TrackFilterPreviewInfobox
                        filter={defaultFilter()}
                        loading={loading}
                        showDetails={!isMobileMode}
                        entries={presetFilterPreviews?.find(data => data.filter.path == defaultFilter().path)?.entries}/>
                </ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={dict("track.filter.preset.tunings.label")} style="modal"/>
                    <ContentLayout columns={isMobileMode ? 1 : 2}>
                        <>{tuningPresetFilters().map(filter => {
                            return <TrackFilterPreviewInfobox
                                key={filter.path}
                                filter={filter}
                                loading={loading}
                                isHigherLicenseRequired={isHigherDriverLicenseRequired(filter)}
                                entries={presetFilterPreviews?.find(data => data.filter.path == filter.path)?.entries}/>
                        })}</>
                    </ContentLayout>
                </ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={dict("track.filter.preset.engines.label")} style="modal"/>
                    <ContentLayout columns={isMobileMode ? 1 : 2}>
                        <>{enginePresetFilters().map(filter => {
                            return <TrackFilterPreviewInfobox
                                key={filter.path}
                                filter={filter}
                                loading={loading}
                                isHigherLicenseRequired={isHigherDriverLicenseRequired(filter)}
                                entries={presetFilterPreviews?.find(data => data.filter.path == filter.path)?.entries}/>
                        })}</>
                    </ContentLayout>
                </ContentLayout>
                {(trackFilters?.length > 0 || isGroupAdmin || isRouteOwnerAuthUser) &&
                    <ContentLayout gap="none">
                        <Headline text={dict("track.filter.custom.label")} style="modal"/>
                        {trackFilters?.length > 0
                            ? <ContentLayout columns={isMobileMode ? 1 : 2}>
                                <>{trackFilters?.map(filter => {
                                    return <TrackFilterPreviewInfobox
                                        key={filter.path}
                                        filter={filter}
                                        loading={loading}
                                        isHigherLicenseRequired={isHigherDriverLicenseRequired(filter)}
                                        entries={customFilterPreviews?.find(data => data.filter.path == filter.path)?.entries}/>
                                })}</>
                            </ContentLayout>
                            : <EmptyDataInfo text={isRouteOwnerAuthUser || isGroupAdmin
                                ? dict("track.trackFilter.custom.empty")
                                : dict("track.trackFilter.custom.empty.guest")}/>}
                    </ContentLayout>}
            </ContentLayout>
            <ActionBar location="main">
                {(isRouteOwnerAuthUser || isGroupAdmin)
                    ? <>
                        <LabelButton
                            label={dict("track.filter.add.label")}
                            icon="plus"
                            onClick={showTrackFilterDialog}
                            style={"action-main"}/>
                        <Divider style={"action-bar"}/>
                    </>
                    : <ActionBarSpacer/>}
                <IconButton
                    type="settings"
                    size="small"
                    onClick={() => state.showTrackSettings.setValue(true)}/>
                <Divider style="action-bar"/>
                <IconButton
                    type="academy"
                    size="small"
                    onClick={() => state.showAcademy.setValue(dict("academy.id.tracks"))}/>
                <ActionBarSpacer/>
            </ActionBar>
        </ContentLayout>
    );
}
