import * as React from "react";
import {ITrackData} from "../../../../../shared/models/submodels/ITrackData";
import {Picture} from "../../image/Picture";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {IEventData} from "../../../../../shared/models/IEventData";

/******************************************************************
 * TrackInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackInfo(props: {
    trackData: ITrackData
    eventData?: IEventData
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router} = useServices()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function gotoTrack() {
        if (!props.eventData) {
            router.showRoute(FrontendRoute.TRACK(
                props.trackData.ownerType,
                props.trackData.owner.path,
                props.trackData.path))
            return
        }
        router.showRoute(FrontendRoute.EVENT_TRACK(
            props.eventData.ownerType,
            props.eventData.owner.path,
            props.eventData.path,
            props.trackData.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="track-info" onClick={gotoTrack}>
            <Picture
                file={props.trackData?.image}
                alt={props.trackData?.name}/>
            <div className="track-info-title">
                <h2>{props.trackData?.name}</h2>
                {props.trackData?.layout && <h3>{props.trackData?.layout}</h3>}
            </div>
        </div>
    );

}
