import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ArrowIcon} from "../../icons/ArrowIcon";
import {IFolderData} from "../../../../../shared/models/submodels/IFolderData";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {FoldingUtils} from "../../../../utils/FoldingUtils";
import {FolderContextMenu} from "../../context/folder/FolderContextMenu";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {EditableField} from "../../form/editable/EditableField";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {useServices} from "../../../hooks/useServices";
import {useEventState} from "../../../hooks/useEventState";
import {FormUtils} from "../../../../utils/FormUtils";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {useGroupState} from "../../../hooks/useGroupState";

/******************************************************************
 * Folder
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Folder(props: {
    data: IFolderData,
    isDragging?: boolean,
    hideArrow?: boolean,
    onChange?: (id: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api} = useServices();
    const {isRouteOwnerAuthUser} = useRouteOwner();
    const {isGroupAdmin} = useGroupState();
    const {eventID} = useEventState();
    const {hasTouch} = useDeviceInfo()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const inputRef = useRef<HTMLDivElement>();

    /* ----------------------------------------------------------------
 	 * STATE
 	 * --------------------------------------------------------------*/

    const [open, setOpen] = useState<boolean>();
    const [hover, setHover] = useState<boolean>(hasTouch);
    const [isRenaming, setIsRenaming] = useState<boolean>(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setOpen(FoldingUtils.isEventFolderOpen(props.data._id));
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function toggle() {
        if (isRenaming) return;
        FoldingUtils.setEventFolderState(props.data._id, !open);
        setOpen(open => !open);
        if (props.onChange) {
            props.onChange(props.data._id);
        }
    }

    async function rename() {
        setIsRenaming(true);
        await PromisedDelay.wait(0.1);
        FormUtils.focusAndPositionCaretToEnd(inputRef.current);
    }

    function onInputExit() {
        setIsRenaming(false)
    }

    function showContextMenu(): boolean {
        if (isRenaming) return false
        if (!hover) return false;
        return isRouteOwnerAuthUser || isGroupAdmin
    }

    async function updateName(value: string): Promise<Response> {
        return await api.event.renameFolder(eventID, props.data._id, value);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="folder"
            data-open={open}
            data-dragging={props.isDragging}
            data-renaming={isRenaming}
            data-hide-arrow={props.hideArrow}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(hasTouch)}>
            <div className="folder-name" onClick={toggle}>
                <ArrowIcon scale={0.8}/>
                <EditableField
                    text={props.data.name}
                    action={updateName}
                    disabled={!isRenaming}
                    useEnterToSave={true}
                    buttonSize="small"
                    ui="right"
                    onInputRef={(ref) => inputRef.current = ref.current}
                    onExit={onInputExit}/>
            </div>
            {showContextMenu() && (
                <FolderContextMenu
                    onRequestRename={rename}
                    folderID={props.data._id}
                />)}
        </div>
    );

}
