import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useServices} from "../../../../../hooks/useServices";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {NewsletterStateType} from "../../../../../../../shared/types/NewsletterStateType";
import {IMailData} from "../../../../../../../shared/models/IMailData";
import {Table} from "../../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {Divider} from "../../../../../ui/utils/divider/Divider";
import {MailUtils} from "../../../../../../../shared/utils/MailUtils";

/******************************************************************
 * UserNewsletterSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserNewsletterSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {authUserNewsletter, authUserNick} = useAuthUser();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [newsletterMails, setNewsletterMails] = useState<IMailData[]>()
    const [loadingMails, setLoadingMails] = useState<boolean>(true)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadNewsletterMails()
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function updateNewsletterState(newsletterState: NewsletterStateType) {
        await api.user.update({newsletter: newsletterState})
    }

    async function loadNewsletterMails() {
        const mails = await api.mail.getNewsletterMails()
        setNewsletterMails(mails)
        setLoadingMails(false)
    }

    function columnsDefinition(): ITableColumnDefinition<IMailData>[] {
        return [{
            type: "text-bold",
            columnName: dict("user.newsletter.settings.table.subject"),
            size: "2fr",
            sortKey: "subject",
            value: (mailData: IMailData) => MailUtils.replacePlaceholders({
                renderedMail: mailData.subject,
                recipientNick: authUserNick,
            })
        }, {
            type: "text",
            columnName: dict("user.newsletter.settings.table.date"),
            size: "1fr",
            sortKey: "sendDate",
            value: (mailData: IMailData) => TimeUtils.formatDate(mailData.sendDate)
        }]
    }

    function selectOptions(): SelectInputOptionType[] {
        return [{
            value: "subscribed",
            text: dict("user.newsletter.settings.subscribed")
        }, {
            value: "unsubscribed",
            text: dict("user.newsletter.settings.unsubscribed")
        }]
    }

    function showMail(mailData: IMailData) {
        state.showMailViewer.setValue(mailData._id)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="user-newsletter-settings">
            <ContentLayout gap="small">
                <InfoText text={dict("user.newsletter.info.text")}/>
                <SelectInput
                    defaultValue={authUserNewsletter ?? "unsubscribed" as NewsletterStateType}
                    onChange={updateNewsletterState}
                    options={selectOptions()}/>
            </ContentLayout>
            <Divider style="modal"/>
            <ContentLayout>
                <Headline
                    text={dict("user.newsletter.settings.table.headline")}
                    style={"h5-underlined"}/>
                <Table<IMailData>
                    rowsData={newsletterMails}
                    columnsDefinition={columnsDefinition()}
                    style="standard-compact"
                    sortKey="sendDate"
                    sortDirection="desc"
                    visibleRows={10}
                    isLoadingData={loadingMails}
                    noDataInfo={dict("user.newsletter.settings.table.noData")}
                    onRowClick={showMail}/>
            </ContentLayout>
        </ContentLayout>
    )

}
