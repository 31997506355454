import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {useServices} from "../../../../../hooks/useServices";
import {Article} from "../../../../../ui/article/Article";
import {Spacer} from "../../../../../ui/utils/spacer/Spacer";

/******************************************************************
 * UserOnBoardingInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserOnBoardingInfo() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateUserOnboardingToBeComplete() {
        setIsBusy(true)
        await api.user.update({onboardingCompleted: true})
        setIsBusy(false)
    }

    function showAcademy() {
        state.showAcademy.setValue(dict("academy.id.introduction"))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="user-onboarding-info"
            justifyItems="center"
            framed={false}
            ref={rootRef}>
            <Article articleID={dict("article.id.welcome")} hideTitle={true}/>
            <ContentLayout justifyItems="center">
                <LabelButton
                    icon="academy"
                    label={dict("user.onboarding.academy.label")}
                    style="primary"
                    onClick={showAcademy}/>
                <LabelButton
                    label={dict("user.onboarding.completed.button.label")}
                    style="secondary-small"
                    progressing={isBusy}
                    onClick={updateUserOnboardingToBeComplete}/>
            </ContentLayout>
            <Spacer height="large"/>
        </ContentLayout>
    )

}
