import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {ContentLayout} from "../../layout/content/ContentLayout";
import {Headline} from "../../text/headings/Headline";
import {Divider} from "../../utils/divider/Divider";
import {PlanHeadline} from "../../text/headings/PlanHeadline";
import {PlanAmount} from "../../text/amount/PlanAmount";
import {InfoText} from "../../text/infotext/InfoText";
import {LabelButton} from "../../buttons/label/LabelButton";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {useServices} from "../../../hooks/useServices";
import {ComponentInitializer} from "../../utils/init/ComponentInitializer";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {IPaddleDriverLicensePlanData} from "../../../../../shared/models/IPaddleDriverLicensePlanData";

/******************************************************************
 * PlanInfo
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AuthUserPlanInfo(props: {
    type: "settings" | "summary"
    onRequestClose?: () => void
}) {

    /* ----------------------------------------------------------------
     * REFS
     * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {authUserSubscription} = useAuthUser();
    const [isMobileStyle] = useMobileStyle(rootRef, 450)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [loadingPlans, setLoadingPlans] = useState<boolean>(true)
    const [refreshingSubscription, setRefreshingSubscription] = useState<boolean>(false)
    const [currentPaidPlan, setCurrentPaidPlan] = useState<IPaddleDriverLicensePlanData>()

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const abortController = new AbortController()
        loadPlans(abortController)
        return () => abortController.abort()
    }, [authUserSubscription])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function refreshSubscription(abortController: AbortController) {
        setRefreshingSubscription(true)
        await api.user.refreshAuthUserSubscription(abortController)
        await api.user.loadAuthUserData()
        setRefreshingSubscription(false)
    }

    async function loadPlans(abortController: AbortController) {
        setLoadingPlans(true)
        let paidPlans = await api.paddle.getDriverLicensePlans(abortController)
        setCurrentPaidPlan(paidPlans?.find(plan => plan.type == authUserSubscription.driverLicense))
        if (paidPlans) {
            setLoadingPlans(false)
        }
    }

    function planBillingInfo(): string {
        if (hasSubscription()) {
            if (isCanceled()) {
                return dict("plan.billing.canceled.info")
                    .replace("{DATE}", TimeUtils.formatDate(authUserSubscription.cancelAt, false, true))
            }
            return dict("plan.billing.renew.info")
                .replace("{DATE}", TimeUtils.formatDate(authUserSubscription.nextBilledAt, false, true))
        }
        return dict("plan.billing.free.info")

    }

    function hasSubscription(): boolean {
        if (!authUserSubscription) return false
        return !!authUserSubscription.subscriptionID
    }

    function isCanceled(): boolean {
        return !!authUserSubscription.cancelAt
    }

    function showCheckout() {
        state.showSubscriptionCheckout.setValue({
            type: "pay"
        })
    }

    function showInvoice() {
        const anchor = document.createElement("a")
        anchor.href = authUserSubscription.invoiceURL
        anchor.click()
    }

    function showPayNow(): boolean {
        if (!hasSubscription()) return false;
        return !authUserSubscription.isPaid;
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ComponentInitializer isPropertyAvailable={!loadingPlans}>
            <ContentLayout ref={rootRef}>
                <ContentLayout
                    framed={true}
                    gap="medium">
                    <ContentLayout columnTemplate={props.type == "settings" ? "1fr min-content" : null}>
                        <Headline
                            text={dict(hasSubscription()
                                ? "plan.user.paid.title"
                                : "plan.user.free.title")}
                            style="h5"/>
                        {props.type == "settings" &&
                            <LabelButton
                                label={dict("plan.user.subscription.refresh.button")}
                                progressing={refreshingSubscription}
                                style="secondary-small"
                                onClick={refreshSubscription}/>}
                    </ContentLayout>
                    {showPayNow() && <>
                        <Divider style="modal"/>
                        <InfoText
                            text={dict("plan.user.unpaid.info")}
                            size="small"
                            style="warning"/>
                        <ContentLayout columns={authUserSubscription.invoiceURL ? 2 : 1}>
                            {authUserSubscription.invoiceURL &&
                                <LabelButton
                                    label={dict("plan.user.invoice.button")}
                                    style="secondary"
                                    onClick={showInvoice}/>}
                            <LabelButton
                                label={dict("plan.user.unpaid.button")}
                                style="primary"
                                onClick={showCheckout}/>
                        </ContentLayout>
                    </>}
                    <Divider style="modal"/>
                    <ContentLayout gap="tiny">
                        <PlanHeadline
                            title={dict("plan.type." + authUserSubscription.driverLicense + ".title")}
                            size="large"/>
                        <PlanAmount
                            size="small"
                            amount={currentPaidPlan?.amount}
                            currency={currentPaidPlan?.currency}
                            interval={currentPaidPlan?.interval}/>
                    </ContentLayout>
                    <Divider style="modal"/>
                    <InfoText
                        text={planBillingInfo()}
                        style={authUserSubscription.cancelAt ? "warning" : "info"}
                        size="small"/>
                    {!hasSubscription() && !state.showSubscriptionInfo.getValue() &&
                        <LabelButton
                            label={dict("plan.show.all.label")}
                            style="primary"
                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>}
                </ContentLayout>
                {props.type == "summary" &&
                    <ContentLayout justifyItems="center">
                        <LabelButton
                            label={dict("plan.summary.close.button.label")}
                            style="primary"
                            onClick={props.onRequestClose}/>
                    </ContentLayout>}
                {hasSubscription() && props.type == "settings" &&
                    <ContentLayout columns={isMobileStyle ? 1 : 2} gap={isMobileStyle ? "small" : "medium"}>
                        <LabelButton
                            label={dict("plan.show.all.label")}
                            style={authUserSubscription.driverLicense == "premium"
                                ? isMobileStyle ? "secondary" : "secondary-small"
                                : isMobileStyle ? "primary" : "primary-small"}
                            onClick={() => state.showSubscriptionPlans.setValue(true)}/>
                        {!isCanceled() &&
                            <LabelButton
                                label={dict("plan.cancel.label")}
                                style={isMobileStyle ? "secondary" : "secondary-small"}
                                onClick={() => state.showConfirm.setValue({type: "cancelSubscription"})}/>}
                        {!isCanceled() &&
                            <LabelButton
                                label={dict("plan.update.paymentmethode.label")}
                                style={isMobileStyle ? "secondary" : "secondary-small"}
                                onClick={() => state.showSubscriptionUpdatePaymentMethod.setValue(true)}/>}
                        {!isCanceled() &&
                            <LabelButton
                                label={dict("plan.update.address.label")}
                                style={isMobileStyle ? "secondary" : "secondary-small"}
                                onClick={() => state.showSubscriptionUpdateAddress.setValue(true)}/>}
                    </ContentLayout>}
            </ContentLayout>
        </ComponentInitializer>
    </>

}
