import {useEffect, useState} from "react"
import {useServices} from "./useServices"
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {IResultData} from "../../../shared/models/IResultData";
import {ISessionData} from "../../../shared/models/ISessionData";
import {IScoreboardEntry} from "../../../shared/types/IScoreboardEntry";

/******************************************************************
 * useResultState Hook
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useResultState() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [resultData, setResultData] = useState<IResultData>(state.result.getValue())
    const [resultID, setResultID] = useState<MongoObjectIDType>(state.result.getValue()?._id)
    const [resultName, setResultName] = useState<string>(state.result.getValue()?.name)
    const [resultPath, setResultPath] = useState<string>(state.result.getValue()?.path)
    const [resultSessions, setResultSessions] = useState<ISessionData[]>(state.result.getValue()?.sessions)
    const [resultScoreboard, setResultScoreboard] = useState<IScoreboardEntry[]>(state.result.getValue()?.scoreboard)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        state.result.onChangeSignal.add(updateResultStates)
        return () => {
            state.result.onChangeSignal.remove(updateResultStates)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateResultStates() {
        const result: IResultData = state.result.getValue()
        setResultData(result)
        setResultID(result?._id)
        setResultName(result?.name)
        setResultPath(result?.path)
        setResultSessions(result?.sessions)
        setResultScoreboard(result?.scoreboard)
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        resultData,
        resultID,
        resultName,
        resultPath,
        resultSessions,
        resultScoreboard
    }
}
