import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {
    SelectInput,
    SelectInputOptionGroupType,
    SelectInputOptionType
} from "../../../../ui/form/elements/select/SelectInput";
import {useServices} from "../../../../hooks/useServices";
import {SessionSetupPresets} from "../../../../../../shared/config/SessionSetupPresets";
import {useEventState} from "../../../../hooks/useEventState";
import {useSessionState} from "../../../../hooks/useSessionState";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {ISessionPresetData} from "../../../../../../shared/models/ISessionPresetData";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {CreatePresetModal} from "./create/CreatePresetModal";
import {DeletePresetsModal} from "./delete/DeletePresetsModal";
import {ISessionSetup} from "../../../../../../shared/models/ISessionData";
import {useGroupState} from "../../../../hooks/useGroupState";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";

/******************************************************************
 * SessionSetupPresetSelectControl
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SessionSetupPresetSelectControl(props: {
    mode: "create" | "edit"
    onSetup?: (setup: ISessionSetup) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api, dict, state} = useServices();
    const {eventTeams, eventOwnerType} = useEventState()
    const {authUserID} = useAuthUser()
    const {groupID} = useGroupState()
    const {sessionSetup, sessionID} = useSessionState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [availablePresets, setAvailablePresets] = useState<ISessionPresetData[]>([])
    const [userPresets, setUserPresets] = useState<ISessionPresetData[]>([])
    const [presetsOptions, setPresetsOptions] = useState<(SelectInputOptionType<string> | SelectInputOptionGroupType)[]>()
    const [isBusy, setIsBusy] = useState<boolean>(false)
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
    const [showDeleteUserPresetsModal, setShowDeleteUserPresetsModal] = useState<boolean>(false)
    const [selectedPresetID, setSelectedPresetID] = useState<string>()
    const [isTraining] = useState<boolean>(eventOwnerType == "user")

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        loadAvailablePresets()
    }, [authUserID])

    useEffect(() => {
        setPresetsOptions(computePresetsOptions())
        updateSelectedPresetID()
    }, [sessionSetup, availablePresets])

    useEffect(() => {
        setPresetsOptions(computePresetsOptions())
    }, [selectedPresetID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadAvailablePresets() {
        const defaultPresetsSource = isTraining
            ? SessionSetupPresets.DEFAULT_TRAINING_SETUPS
            : SessionSetupPresets.DEFAULT_EVENT_SETUPS
        const defaultPresets: ISessionPresetData[] = defaultPresetsSource.map(defaultPreset => {
            return {
                _id: defaultPreset.key,
                name: dict("session.presets." + defaultPreset.key),
                isDefault: true,
                setup: defaultPreset.setup
            }
        })
        const userPresets = await api.presets.getSessionPresets(authUserID)
        const presets = [...defaultPresets, ...userPresets ?? []]
        presets.forEach(preset => {
            preset.requireTeams = preset.setup.classificationMode == "teams"
            preset.requireSolo = preset.setup.classificationMode == "solo"
        })
        setAvailablePresets(presets)
        setUserPresets(userPresets)
    }

    function computePresetsOptions(): (SelectInputOptionType<string> | SelectInputOptionGroupType)[] {
        const defaultOptions = availablePresets?.map(preset => {
            if (preset.requireTeams && eventTeams?.length < 1) return null
            if (preset.requireSolo && groupID) return null
            if (!preset.isDefault) return null
            return {value: preset._id, text: preset.name}
        })?.filter(item => item !== null)
        const userOptions = availablePresets?.map(preset => {
            if (preset.requireTeams && eventTeams?.length < 1) return null
            if (preset.requireSolo && groupID) return null
            if (preset.isDefault) return null
            return {
                value: preset._id,
                text: preset.name,
                requiredDriverLicense: "premium"
            } as SelectInputOptionType<MongoObjectIDType>
        })?.filter(item => item !== null)
        let options: (SelectInputOptionType<string> | SelectInputOptionGroupType)[] = []
        if (defaultOptions?.length > 0) {
            options.push({
                groupLabel: dict("session.presets.default"),
                options: defaultOptions
            })
        }
        if (userOptions?.length > 0) {
            options.push({
                groupLabel: dict("session.presets.user"),
                options: userOptions.sort((a, b) => {
                    return a.value.localeCompare(b.value)
                })
            })
        }
        if (props.mode == "edit" && selectedPresetID == "none") {
            options?.unshift({value: "none", text: dict("session.presets.none")})
        }
        return options
    }

    function updateSelectedPresetID() {
        if (props.mode == "create") {
            setSelectedPresetID(undefined)
            return
        }
        const equalPresetSetup = availablePresets?.find(presetSetup => {
            return SessionSetupPresets.isPresetEqualToSession(presetSetup, sessionSetup)
        })
        setSelectedPresetID(equalPresetSetup?._id ?? "none")
    }

    async function updateSessionSetup(presetID: string) {
        const preset = availablePresets?.find(presetSetup => presetSetup._id == presetID)
        delete (preset.setup.startOrderReversed)
        delete (preset.setup.startOrderFromSession)
        if (props.mode == "create") {
            props.onSetup(preset?.setup)
            return
        }
        setIsBusy(true)
        if (preset.setup) {
            const newSetup = {...preset.setup}
            if (sessionSetup.track) {
                newSetup.track = sessionSetup.track
            }
            await api.session.update(sessionID, {setup: newSetup})
            await api.session.update(sessionID, {setup: newSetup})
        }
        setIsBusy(false)
    }

    function showSaveSetupButton() {
        return props.mode == "edit" && selectedPresetID == "none"
    }

    function showEditUserPresetsButton() {
        return props.mode == "edit" && userPresets?.length > 0
    }

    function columns(): number {
        let columns = 1
        if (props.mode == "create") return columns
        if (showSaveSetupButton()) columns++
        if (showEditUserPresetsButton()) columns++
        return columns
    }

    function columnsTemplate(): string {
        let template = "1fr"
        if (props.mode == "create") return template
        if (showSaveSetupButton()) template += " min-content"
        if (showEditUserPresetsButton()) template += " min-content"
        return template
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            className="session-preset-select"
            columns={columns()}
            alignItems={props.mode == "create" ? "end" : "center"}
            gap="small"
            columnTemplate={columnsTemplate()}>
            <SelectInput
                size={props.mode == "create" ? "normal" : "small"}
                helpTopic={props.mode == "create" ? "session.presets" : null}
                label={dict("session.presets.label")}
                defaultValue={selectedPresetID}
                processing={isBusy}
                options={presetsOptions}
                readonly={isBusy}
                onChange={updateSessionSetup}
                onHigherDriverLicenseRequired={() => state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.sessionPresets")})}/>
            {showSaveSetupButton() && <IconButton
                size="small"
                type="add"
                onClick={() => setShowCreateModal(true)}/>}
            {showEditUserPresetsButton() && <IconButton
                type="edit"
                size={props.mode == "create" ? "normal" : "small"}
                onClick={() => setShowDeleteUserPresetsModal(true)}/>}
        </ContentLayout>
        {(showSaveSetupButton() || showEditUserPresetsButton()) &&
            <ActionBarSpacer/>}
        {showCreateModal && <CreatePresetModal
            onCreated={() => loadAvailablePresets()}
            onClose={() => setShowCreateModal(false)}/>}
        {showDeleteUserPresetsModal && <DeletePresetsModal
            userPresets={userPresets}
            onDeleted={() => loadAvailablePresets()}
            onClose={() => setShowDeleteUserPresetsModal(false)}/>}
    </>
}
