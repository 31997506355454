import {IUserData} from "../../shared/models/IUserData";
import {UserUtils} from "../../shared/utils/UserUtils";
import {FrontendConfig} from "../core/FrontendConfig";

/******************************************************************
 * SubscriptionUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SubscriptionUtils {

    static useSubscriptionService(user: IUserData): boolean {
        return UserUtils.useSubscriptionService(
            user,
            FrontendConfig.PADDLE_AVAILABLE,
            FrontendConfig.PADDLE_ENVIRONMENT,
            FrontendConfig.PADDLE_ENABLED_PER_DEFAULT
        )
    }

    static skipSubscriptionService(user: IUserData): boolean {
        return !SubscriptionUtils.useSubscriptionService(user)
    }

    static isSandBoxMode(): boolean {
        return FrontendConfig.PADDLE_ENVIRONMENT === "sandbox"
    }

}
