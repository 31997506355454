import * as React from "react";
import {ContextMenu} from "../ContextMenu";
import {useServices} from "../../../hooks/useServices";
import {ContextMenuLabel} from "../elements/ContextMenuLabel";
import {ArticleBlockType} from "../../../../../shared/models/IArticleData";

/******************************************************************
 * ArticleAddBlockContextMenu
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleAddBlockContextMenu(props: {
    onAddBlock?: (blockType: ArticleBlockType) => void
    onToggle?: (isOpen: boolean) => void
    availableBlocks?: ArticleBlockType[]
}) {

    /* ----------------------------------------------------------------
     * SERVICES
     * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function isBlockAvailable(blockType: ArticleBlockType) {
        return props.availableBlocks?.includes(blockType) ?? true
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            type="icon"
            iconType="add"
            iconSize="small"
            onToggle={props.onToggle}
            stopPropagation={true}>
            {isBlockAvailable("hero") &&
                <ContextMenuLabel
                    label={dict("article.block.add.hero")}
                    icon="megaphone"
                    iconScale={1}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("hero")}/>}
            {isBlockAvailable("text") &&
                <ContextMenuLabel
                    label={dict("article.block.add.text")}
                    icon="text"
                    iconScale={0.9}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("text")}/>}
            {isBlockAvailable("image") &&
                <ContextMenuLabel
                    label={dict("article.block.add.image")}
                    icon="image"
                    iconScale={0.9}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("image")}/>}
            {isBlockAvailable("youtube") &&
                <ContextMenuLabel
                    label={dict("article.block.add.youtube")}
                    icon="youtube"
                    iconScale={1.1}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("youtube")}/>}
            {isBlockAvailable("link") &&
                <ContextMenuLabel
                    label={dict("article.block.add.link")}
                    icon="link"
                    iconScale={1}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("link")}/>}
            {isBlockAvailable("feature") &&
                <ContextMenuLabel
                    label={dict("article.block.add.feature")}
                    icon="star"
                    iconScale={1}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("feature")}/>}
            {isBlockAvailable("testimonial") &&
                <ContextMenuLabel
                    label={dict("article.block.add.testimonial")}
                    icon="person"
                    iconScale={1}
                    stateIconType="add"
                    stateIconScale={0.4}
                    onClick={() => props.onAddBlock("testimonial")}/>}
        </ContextMenu>
    );

}
