import {FrontendServices} from "../FrontendServices";
import {IDictionaryEntryData} from "../../../shared/models/IDictionaryEntryData";
import {StringUtils} from "../../../shared/utils/StringUtils";
import {SharedConfig} from "../../../shared/config/SharedConfig";
import {LanguageFrontendUtils} from "../../utils/LanguageFrontendUtils";

/******************************************************************
 * FrontendDictionary
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class FrontendDictionary {

    /******************************************************************
     * Properties
     *****************************************************************/

    private _data: IDictionaryEntryData[] = [];

    /******************************************************************
     * Constructor
     *****************************************************************/

    constructor(private _frontend: FrontendServices) {
    }

    /******************************************************************
     * Public Methodes
     *****************************************************************/

    public get(key: string) {
        if (!this._data) {
            return undefined
        }
        const language = LanguageFrontendUtils.getLanguageFromHTML(this._frontend.state.authUser.getValue());
        const entry = this._data.filter(entry => entry.key && entry.key?.toLowerCase() == key?.toLowerCase())?.[0];
        if (entry && entry[language]) {
            return StringUtils.removeHTML(entry[language]);
        }
        if (entry && entry[SharedConfig.DEFAULT_LANGUAGE]) {
            return StringUtils.removeHTML(entry[SharedConfig.DEFAULT_LANGUAGE]);
        }
        const alternativeLanguage = SharedConfig.LANGUAGES.find(lang => !!entry?.[lang]);
        if (entry && alternativeLanguage && entry[alternativeLanguage]) {
            return StringUtils.removeHTML(entry[alternativeLanguage]);
        }
        if (this._frontend.state.authUser.hasValue()) {
            this._frontend.api.dict.addEntry({
                key: key,
                de: `{${key}}`,
                en: `{${key}}`
            })
        } else {
            console.log(`[${key}]`)
        }
        return `[${key}]`;
    }

    async loadDictionary(): Promise<void> {
       this._data = await this._frontend.api.dict.getEntries();
    }
}
