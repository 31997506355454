/******************************************************************
 * DeviceUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class DeviceUtils {

    static isTouchDevice(): boolean {
        return 'ontouchstart' in window || !!navigator.maxTouchPoints
    }

    static isRunningAsPWA(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches
    }

    static isRunningOnIPad(): boolean {
        return /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
    }

    static isRunningOnIPadOS(): boolean {
        return navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2
    }

    static isRunningOnIOS(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
    }

}
