import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IFileData} from "../../../../shared/models/IFileData";
import {useServices} from "../../hooks/useServices";
import {gsap} from "gsap";
import {Icon} from "../icons/Icon";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {useElementSize} from "../../hooks/useElementSize";

/******************************************************************
 * ImageComponent
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

interface IZoomLevel {
    level: number
    originX: number
    originY: number
}

export type PictureFramingType = "square" | "rounded-4" | "rounded-8" | "rounded-12" | "circle"
export type PictureShadowType = "small" | "large"

export function Picture(props: {
    file: IFileData
    fit?: "cover" | "contain"
    alt?: string
    zoomLevel?: number
    toggleZoomViaDoubleClick?: boolean
    width?: number | string
    height?: number | string
    framing?: PictureFramingType
    shadow?: PictureShadowType
    fullHeight?: boolean
    defaultSource?: string
    defaultAspectRatio?: number
    forceDefaultAspectRatio?: boolean
    allowMediaViewer?: boolean
    className?: string
    onZoomToggle?: (zoomLevel: number) => void
    stopPropagation?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {api, state} = useServices()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const imageRef = useRef<HTMLImageElement>()
    const rootRef = useRef<HTMLPictureElement>()
    const [imageElementWidth, imageElementHeight]= useElementSize(imageRef)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [src, setSrc] = useState<string>()
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [zoomLevel, setZoomLevel] = useState<IZoomLevel>({level: 1, originX: 0.5, originY: 0.5})

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        updateSource()
    }, [props.file, props.defaultSource, imageElementWidth, imageElementHeight])

    useEffect(() => {
        if (!props.zoomLevel) return
        if (props.zoomLevel === zoomLevel.level) return
        setZoomLevel({level: props.zoomLevel ?? 1, originX: 0.5, originY: 0.5})
    }, [props.zoomLevel])

    useEffect(() => {
        updateZoom()
    }, [zoomLevel])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateSource() {
        if (!props.file && !props.defaultSource) {
            setSrc(null)
            return
        }
        if (!props.file) {
            setSrc(props.defaultSource)
            return
        }
        if (!imageElementWidth) return
        if (imageElementWidth == 0) return
        setSrc(api.file.getImageURLByFile(props.file, imageElementWidth, imageElementHeight))
    }

    function updateZoom() {
        if (!rootRef.current || !imageRef.current) return
        let currentZoomLevel = (imageRef.current.clientWidth ?? rootRef.current.clientWidth) / rootRef.current.clientWidth
        if (!currentZoomLevel || currentZoomLevel < 1) {
            currentZoomLevel = 1
        }
        const zoom = {
            level: currentZoomLevel
        }
        gsap.to(zoom, {
            duration: 0.3,
            level: zoomLevel.level,
            ease: "power4.out",
            onUpdate: () => {
                if (!rootRef.current || !imageRef.current) return
                const root = rootRef.current
                const image = imageRef.current
                const rootWidth = root.clientWidth
                const rootHeight = root.clientHeight
                const imageWidth = rootWidth * zoom.level
                const imageHeight = rootHeight * zoom.level
                const offsetWidth = imageWidth - rootWidth
                const offsetHeight = imageHeight - rootHeight
                const originX = zoomLevel.originX ?? 0.5
                const originY = zoomLevel.originY ?? 0.5
                const scrollLeft = offsetWidth * originX
                const scrollTop = offsetHeight * originY
                image.style.width = zoom.level * 100 + "%"
                image.style.height = zoom.level * 100 + "%"
                root.scrollTo(scrollLeft, scrollTop)
            }
        })
    }

    function showInMediaViewer(e: React.MouseEvent<HTMLPictureElement, MouseEvent>) {
        if (!props.allowMediaViewer) return
        e.stopPropagation()
        state.showMediaViewer.setValue([{file: props.file}])
    }

    function toggleZoom(e: React.MouseEvent<HTMLPictureElement, MouseEvent>) {
        const originX = e.nativeEvent.offsetX / rootRef.current.clientWidth
        const originY = e.nativeEvent.offsetY / rootRef.current.clientHeight
        if (zoomLevel.level > 1) {
            setZoomLevel({level: 1, originX: originX, originY: originY})
            PromisedDelay.wait(0.3).then(() => props.onZoomToggle?.(1))
        } else {
            setZoomLevel({level: 2, originX: originX, originY: originY})
            PromisedDelay.wait(0.3).then(() => props.onZoomToggle?.(2))
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <picture
            ref={rootRef}
            className={"picture" + (props.className ? " " + props.className : "")}
            data-loading={!isLoaded && !!props.file}
            data-fit={props.fit ?? "cover"}
            data-full-height={props.fullHeight}
            data-hide={!props.file && !props.defaultSource}
            data-shadow={props.shadow}
            data-framing={props.framing ?? "square"}
            data-allow-media-viewer={props.allowMediaViewer}
            onClick={props.allowMediaViewer ? showInMediaViewer : null}
            onDoubleClick={props.toggleZoomViaDoubleClick ? toggleZoom : null}
            style={{
                width: props.width,
                height: props.height,
                overflow: props.zoomLevel > 1 ? "scroll" : "hidden",
                aspectRatio: props.forceDefaultAspectRatio
                    ? props.defaultAspectRatio
                    : (props.file?.metaData?.aspectRatio ?? props.defaultAspectRatio)
            }}>
            <img
                ref={imageRef}
                onLoad={() => setIsLoaded(true)}
                src={src}
                loading="lazy"
                alt={props.alt ?? props.file?.originalName ?? ""}
            />
            {props.allowMediaViewer && <div className="picture-zoom-icon">
                <Icon type="zoom"/>
            </div>}
        </picture>

    )

}
