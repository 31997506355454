import * as React from "react";
import {useEffect, useState} from "react";
import {Headline} from "../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Modal} from "../../Modal";
import {useServices} from "../../../../hooks/useServices";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {ComponentInitializer} from "../../../../ui/utils/init/ComponentInitializer";
import {IPaddleCustomerAddressData} from "../../../../../../shared/types/IPaddleCustomerAddressData";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {SellingCountries} from "../../../../../../shared/plan/SellingCountries";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {Spacer} from "../../../../ui/utils/spacer/Spacer";
import {Input} from "../../../../ui/form/elements/input/Input";

/******************************************************************
 * SubscriptionUpdateAddressModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SubscriptionUpdateAddressModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const {authUserSubscription} = useAuthUser()
    const [progressing, setProgressing] = useState<boolean>(false)
    const [initialAddress, setInitialAddress] = useState<IPaddleCustomerAddressData>()
    const [countryCode, setCountryCode] = useState<string>(null)
    const [postalCode, setPostalCode] = useState<string>(null)
    const [region, setRegion] = useState<string>(null)
    const [showPostalInput, setShowPostalInput] = useState<boolean>(false)
    const [showRegionInput, setShowRegionInput] = useState<boolean>(false)
    const [showCountryCodeInput, setShowCountryCodeInput] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestLatestAddressData()
    }, [authUserSubscription])

    useEffect(() => {
        setShowCountryCodeInput(SellingCountries.hasCountry(countryCode))
        setShowRegionInput(SellingCountries.isRegionRequired(countryCode))
        setShowPostalInput(SellingCountries.isPostalCodeRequired(countryCode))
    }, [countryCode])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function requestLatestAddressData() {
        const customerAddress = await api.paddle.getCustomerAddress()
        if (!customerAddress) {
            setErrorMessage(dict("plan.update.address.no.address.found.error"))
        }
        setCountryCode(customerAddress?.country_code)
        setPostalCode(customerAddress?.postal_code)
        setRegion(customerAddress?.region)
        setInitialAddress(customerAddress)
    }

    function countryOptions(): SelectInputOptionType[] {
        return SellingCountries.COUNTRIES.map(entry => ({value: entry.code, text: entry.name}))
    }

    async function updateAddress() {
        setProgressing(true)
        const success = await api.paddle.updateCustomerAddress({
            id: initialAddress.id,
            country_code: countryCode,
            postal_code: postalCode,
            region: region
        })
        if (success) {
            state.showSubscriptionUpdateAddress.setValue(false)
            return
        }
        setProgressing(false)
    }

    function hasNotChanged() {
        return initialAddress?.country_code === countryCode
            && initialAddress?.postal_code === postalCode
            && initialAddress?.region === region
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="subscription-change-preview-modal"
            width="medium"
            minHeight="small"
            closeAction={() => state.showSubscriptionUpdateAddress.setValue(false)}>
            <Headline
                text={dict("plan.update.address.title")}
                style="modal"/>
            <ComponentInitializer isPropertyAvailable={!!initialAddress || !!errorMessage}>
                {errorMessage &&
                    <ContentLayout justifyItems="center">
                        <InfoText
                            text={errorMessage}
                            align="center"
                            style="error"/>
                    </ContentLayout>}
                {!errorMessage && showCountryCodeInput &&
                    <ContentLayout>
                        <InfoText text={dict("plan.update.address.info")}/>
                        <SelectInput
                            label={dict("plan.update.address.country.label")}
                            options={countryOptions()}
                            defaultValue={countryCode}
                            readonly={progressing}
                            onChange={code => setCountryCode(code as string)}/>
                        {showPostalInput &&
                            <Input
                                type="text"
                                label={dict("plan.update.address.postal.label")}
                                readonly={progressing}
                                defaultValue={initialAddress.postal_code}
                                onChange={setPostalCode}/>}
                        {showRegionInput &&
                            <Input
                                type="text"
                                readonly={progressing}
                                label={dict("plan.update.address.region.label")}
                                defaultValue={initialAddress.region}
                                onChange={setRegion}/>}
                        <Spacer height="small"/>
                        <ContentLayout
                            columns={2}
                            justifyContent="space-between">
                            <LabelButton
                                label={dict("button.abort")}
                                style="secondary"
                                onClick={() => state.showSubscriptionUpdateAddress.setValue(false)}/>
                            <LabelButton
                                label={dict("plan.update.address.submit")}
                                style="primary"
                                disabled={hasNotChanged()}
                                progressing={progressing}
                                onClick={updateAddress}/>
                        </ContentLayout>
                    </ContentLayout>}
            </ComponentInitializer>
        </Modal>
    );

}
