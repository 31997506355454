import * as React from "react";
import {IGroupData} from "../../../../../../shared/models/IGroupData";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {GroupInfo} from "../../../../ui/info/group/GroupInfo";
import {UserInfo} from "../../../../ui/info/user/UserInfo";
import {Icon} from "../../../../ui/icons/Icon";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useAuthUser} from "../../../../hooks/useAuthUser";
import {ContactMessageType} from "../../../../../../shared/types/ContactMessageType";
import {IEventData} from "../../../../../../shared/models/IEventData";
import {useGroupState} from "../../../../hooks/useGroupState";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {ContactMessageToType} from "../../../../../../shared/types/ContactMessageToType";
import {InfoText} from "../../../../ui/text/infotext/InfoText";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * ContactHeader
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ContactHeader(props: {
    sendTo: ContactMessageToType
    messageType: ContactMessageType
}) {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = React.useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()
    const {authUserData} = useAuthUser()
    const {groupContact} = useGroupState()
    const [isMobile] = useMobileStyle(ref, 450)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function sendToUsers(): IUserData[] {
        return props.sendTo as IUserData[]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            ref={ref}
            className="contact-header"
            gap="small"
            columnTemplate="1fr min-content 1fr"
            alignItems="stretch">
            <ContentLayout
                framed={true}
                gap="small"
                alignItems="center">
                <UserInfo
                    user={authUserData}
                    clickToShowProfile={false}
                    style={isMobile ? "small" : "normal"}/>
            </ContentLayout>
            <ContentLayout alignSelf="center">
                <Icon type="next" scale={1.2}/>
            </ContentLayout>
            <ContentLayout
                framed={true}
                gap="small"
                alignItems="center">
                {props.messageType == "group" &&
                    <GroupInfo
                        group={props.sendTo as IGroupData}
                        style={isMobile ? "small" : "normal"}/>}
                {props.messageType == "event" &&
                    <UserInfo
                        user={(props.sendTo as IEventData).contact ?? groupContact}
                        clickToShowProfile={false}
                        style={isMobile ? "small" : "normal"}/>}
                {props.messageType == "user" &&
                    <UserInfo
                        user={props.sendTo as IUserData}
                        clickToShowProfile={false}
                        style={isMobile ? "small" : "normal"}/>}
                {props.messageType == "users" && sendToUsers()?.length > 1 &&
                    <InfoText
                        size="small"
                        text={dict("users.contact.header.users.info")
                            .replaceAll("{NUM_RECIPIENTS}", sendToUsers().length.toString())}/>}
                {props.messageType == "users" && sendToUsers()?.length == 1 &&
                    <UserInfo
                        user={sendToUsers()?.[0]}
                        clickToShowProfile={false}
                        style={isMobile ? "small" : "normal"}/>}
            </ContentLayout>
        </ContentLayout>
    );

}
