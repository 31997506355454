import * as React from "react";
import {Input} from "../../form/elements/input/Input";
import {useServices} from "../../../hooks/useServices";

/******************************************************************
 * TableFilter
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableFilter(props: {
    onChange: (value) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    function onChange(value) {
        if (!value) value = "";
        props.onChange(value);
    }

    return (
        <div className="table-filter">
            <Input
                type="text"
                icon="filter"
                size="small"
                placeholder={dict("table.filter.placeholder")}
                onChange={onChange}
            />
        </div>
    );

}
