import * as React from "react";
import {AdminDictionary} from "./dictionary/AdminDictionary";
import {AdminUsers} from "./users/AdminUsers";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {AdminSimulator} from "./simulator/AdminSimulator";
import {AdminMailFilter} from "./mailfilter/AdminMailFilter";
import {AdminDatabase} from "./database/AdminDatabase";
import {AdminLogs} from "./logs/AdminLogs";
import {AdminArticles} from "./articles/AdminArticles";
import {AdminCache} from "./cache/AdminCache";
import {AdminEnvVars} from "./env/AdminEnvVars";
import {AdminMailings} from "./mailings/AdminMailings";
import {AdminMailTemplates} from "./mailtemplates/AdminMailTemplates";

/******************************************************************
 * AdminDictionary
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AdminMain() {

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const {
        showAdminDictionary,
        showAdminArticles,
        showAdminUsers,
        showAdminSimulator,
        showAdminMailFilter,
        showAdminDatabase,
        showAdminLogs,
        showAdminCaches,
        showAdminEnvVars,
        showAdminMailings,
        showAdminMailTemplates
    } = useRouteStates();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <section className="admin-main">
            {showAdminDictionary && <AdminDictionary />}
            {showAdminArticles && <AdminArticles/>}
            {showAdminUsers && <AdminUsers />}
            {showAdminSimulator && <AdminSimulator />}
            {showAdminMailFilter && <AdminMailFilter />}
            {showAdminDatabase && <AdminDatabase />}
            {showAdminLogs && <AdminLogs />}
            {showAdminCaches && <AdminCache />}
            {showAdminEnvVars && <AdminEnvVars />}
            {showAdminMailings && <AdminMailings />}
            {showAdminMailTemplates && <AdminMailTemplates />}
        </section>
    );

}
