import * as React from "react";

/******************************************************************
 * DeleteIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DeleteIcon() {

    return (
        <div className="delete-icon">
            <svg width="20" height="20" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5965 24.3973C22.5965 25.7963 21.4633 26.9295 20.0643 26.9295H9.93569C8.53667 26.9295 7.40353 25.7963 7.40353 24.3973V9.20439H22.5965V24.3973ZM6.13745 5.40615H10.5687L11.8348 4.14008H18.1652L19.4313 5.40615H23.8625V7.93831H6.13745V5.40615Z" fill="white"/>
            </svg>
        </div>
    );

}
