import * as React from "react";
import {KeyController} from "../../../keyboard/KeyController";
import {IconButton, IconButtonSizeType} from "../../../buttons/icon/IconButton";
import {ProgressingAnimation} from "../../../utils/progress/ProgressingAnimation";

/******************************************************************
 * InlineVerifyAction
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function InlineVerifyAction(props: {
    isBusy: boolean;
    show: boolean;
    size?: IconButtonSizeType
    preventSave?: boolean;
    reverse?: boolean;
    useEnterForSave?: boolean;
    hideSaveButton?: boolean;
    hideResetButton?: boolean;
    onReset: () => void;
    onSave: () => void;
}) {
    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {props.show && <div
            className="inline-verify-action"
            data-reverse={props.reverse}
            data-busy={props.isBusy}
            data-prevent-save={props.preventSave}>
            {props.isBusy
                ? <ProgressingAnimation/>
                : <>
                    <KeyController enabled={true} code={"Escape"} onKey={props.onReset}/>
                    <KeyController enabled={props.useEnterForSave} code={"Enter"} onKey={props.onSave}/>
                    {!props.hideResetButton &&
                        <IconButton
                            type="cancel"
                            onClick={props.onReset}
                            stopPropagation={true}
                            size={props.size}/>}
                    {!props.hideSaveButton &&
                        <IconButton
                            type="save"
                            stopPropagation={true}
                            onClick={props.onSave}
                            size={props.size}/>}
                </>
            }
        </div>}
    </>

}
