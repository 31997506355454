import {ErrorCode} from "../keys/ErrorCode";
import {CarStateType} from "../types/CarStateType";

/******************************************************************
 * CarStateValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CarStateValidation {

    static STATES: CarStateType[] = [
        "original",
        "modified"
    ]

    static schemaValidator() {
        return {
            validate: {
                validator: value => CarStateValidation.isValid(value),
                message: ErrorCode.CAR_STATE_INVALID
            },
        }
    };

    static isValid(value: CarStateType): boolean {
        return CarStateValidation.STATES.some(state => state == value)
    }

}
