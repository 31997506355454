import * as React from "react";

/******************************************************************
 * DownloadIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function DownloadIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="download-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.2946 10.5344C13.9053 10.1451 13.2743 10.1448 12.8846 10.5336L10.7063 12.705C10.316 13.0945 9.68402 13.0945 9.29367 12.705L7.11539 10.5336C6.72569 10.1448 6.09466 10.1451 5.70539 10.5344C5.31581 10.9239 5.31581 11.5556 5.70539 11.9451L9.2929 15.5326C9.68342 15.9232 10.3166 15.9232 10.7071 15.5326L14.2946 11.9451C14.6842 11.5556 14.6842 10.9239 14.2946 10.5344Z" fill="white"/>
                <rect x="3.5" y="17.002" width="13" height="1.98389" rx="0.991943" fill="white"/>
                <rect x="9.00806" y="15.3848" width="10.2856" height="1.98389" rx="0.991943" transform="rotate(-90 9.00806 15.3848)" fill="white"/>
            </svg>
        </div>
    );

}
