import * as React from "react";
import {useServices} from "../../../../hooks/useServices";
import {ReplaceUtils} from "../../../../../utils/ReplaceUtils";

/******************************************************************
 * RegisterLegals
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function RegisterLegals() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function termsOfUseLink() {
        return (
            <a key={1} onClick={(e) => {
                e.preventDefault()
                state.showArticle.setValue(dict("article.id.termsofuse"))
            }}>
                {dict("register.legalinfo.termsofuse")}
            </a>
        )
    }

    function privacyLink() {
        return (
            <a key={2} onClick={(e) => {
                e.preventDefault()
                state.showArticle.setValue(dict("article.id.privacy"))
            }}>
                {dict("register.legalinfo.privacy")}
            </a>
        )
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="register-legals">
            <p>{ReplaceUtils.replaceWithComponent(dict("register.legalinfo"), {
                "TERMS_OF_USE_LINK": termsOfUseLink(),
                "PRIVACY_LINK": privacyLink(),
            })}</p>
        </div>
    );

}
