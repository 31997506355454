import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useTrackState} from "../../../hooks/useTrackState";
import {ITrackFilterData} from "../../../../../shared/models/submodels/ITrackFilterData";
import {useRouteStates} from "../../../hooks/useRouteStates";
import {FrontendRouteUtils} from "../../../../../shared/utils/FrontendRouteUtils";
import {TrackLeaderboard} from "./leaderboard/TrackLeaderboard";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {useServices} from "../../../hooks/useServices";
import {TrackFilterPresets} from "../../../../../shared/config/TrackFilterPresets";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";

/******************************************************************
 * TrackFilterPage
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackFilterPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state} = useServices()
    const {routePath} = useRouteStates()
    const {trackData, trackFilters} = useTrackState()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [filter, setFilter] = useState<ITrackFilterData>();

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        const trackRoute = FrontendRouteUtils.parseTrackRoute(routePath)
        const presetFilter = TrackFilterPresets.PRESETS.find(preset => preset.path === trackRoute?.filterPath)
        if (presetFilter) {
            setFilter(presetFilter)
            return
        }
        setFilter(trackFilters?.find(filter => filter.path === trackRoute?.filterPath))
    }, [routePath, trackFilters])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function showFilterDialog() {
        state.showTrackFilter.setValue(filter)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout alignItems="stretch">
            <TrackLeaderboard
                trackData={trackData}
                trackFilter={filter}/>
            <ActionBar location="main">
                <LabelButton
                    label={dict("track.filter.settings.label")}
                    style="action-main"
                    onClick={showFilterDialog}/>
            </ActionBar>
        </ContentLayout>
    );

}
