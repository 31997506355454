import {ErrorCode} from "../keys/ErrorCode";
import {CarPropertyType} from "../types/CarPropertyType";

/******************************************************************
 * CarPropertyTypeValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class CarPropertyTypeValidation {

    static PROPERTIES: CarPropertyType[] = [
        "body",
        "rims",
        "bodykit",
        "decals",
        "pointwork",
        "weight",
        "tuner",
        "photographer"
    ]

    static schemaValidator() {
        return {
            validate: {
                validator: value => CarPropertyTypeValidation.isValid(value),
                message: ErrorCode.CAR_PROPERTY_TYPE_INVALID
            },
        }
    };

    static isValid(value: CarPropertyType): boolean {
        return CarPropertyTypeValidation.PROPERTIES.some(prop => prop == value)
    }

}
