import * as React from "react";
import {SelectInput} from "../select/SelectInput";
import {ContentLayout} from "../../../layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {TargetPreProcessorMode} from "../../../../../../shared/types/TargetPreProcessorMode";

/******************************************************************
 * TrackTargetPreProcessorInput
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TrackTargetPreProcessorInput(props: {
    readonly: boolean
    mode: TargetPreProcessorMode
    onModeChanged: (value: TargetPreProcessorMode) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout columns={1}>
            <SelectInput
                label={dict("track.targetPreProcessorMode.label")}
                defaultValue={props.mode ?? "none" as TargetPreProcessorMode}
                readonly={props.readonly}
                helpTopic="track.targetPreProcessorMode"
                onChange={props.onModeChanged}
                options={[
                    {value: "none" as TargetPreProcessorMode, text: dict("track.targetPreProcessorMode.none")},
                    {value: "autoDisableTargetsNotAllowed" as TargetPreProcessorMode, text: dict("track.targetPreProcessorMode.autoDisableTargetsNotAllowed")},
                ]}/>
        </ContentLayout>
    );
}
