import * as React from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../../hooks/useServices";
import {SpeakerLanguageSetting} from "./speaker/SpeakerLanguageSetting";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {LanguageSelector} from "../../../../../ui/form/elements/language/LanguageSelector";

/******************************************************************
 * UserLanguageSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserLanguageSettings() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices();
    const {authUserLanguage} = useAuthUser();

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="user-language-settings">
            <ContentLayout framed={true}>
                <Headline
                    text={dict("user.language.settings.label")}
                    style="h5-underlined"/>
                <LanguageSelector/>
            </ContentLayout>
            <SpeakerLanguageSetting speakerLanguage={authUserLanguage}/>
        </ContentLayout>
    )
}
