import * as React from "react";
import {useRef, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useMobileStyle} from "../../../../hooks/useMobileStyle";
import {useServices} from "../../../../hooks/useServices";
import {useEventState} from "../../../../hooks/useEventState";
import {MongoObjectIDType} from "../../../../../../shared/types/MongoObjectIDType";
import {EventDriversList} from "./lists/EventDriversList";
import {TabbedContent} from "../../../../ui/layout/tabbed/TabbedContent";
import {RemoveDriverModal} from "./modals/RemoveDriverModal";
import {AddToNextModal} from "./modals/RequestAddToNextModal";
import {EventUtils} from "../../../../../../shared/utils/EventUtils";

/******************************************************************
 * EventRequestsKanban
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventRequestsKanban() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices()
    const {
        eventID,
        eventData,
        eventIsFinished,
        eventAccess,
        eventRequestingDrivers,
        eventPendingDrivers,
        eventRegisteredDrivers
    } = useEventState()
    const [isMobileWithThreeColumns] = useMobileStyle(ref, 1000)
    const [isMobileWithTwoColumns] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [requestRemoveDriver, setRequestRemoveDriver] = useState<MongoObjectIDType>(null)
    const [requestAddDriverToNext, setRequestAddDriverToNext] = useState<MongoObjectIDType>(null)
    const [progressing, setProgressing] = useState<boolean>(false)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function addDriverToNext(userID: MongoObjectIDType) {
        switch (eventAccess) {
            case "only-registered-drivers-after-approval":
                await registerDriver(userID)
                break
            case "only-registered-drivers-after-paid":
                const isRequesting = eventRequestingDrivers.find(driver => driver._id === userID)
                if (isRequesting) {
                    await moveDriverToPending(userID)
                } else {
                    await registerDriver(userID)
                }
                break
        }
        setRequestAddDriverToNext(null)
    }

    async function moveDriverToPending(userID: MongoObjectIDType) {
        if (EventUtils.isEntryFeeMessageNeededButNotDefined(eventData)) {
            state.showMessage.setValue({
                type: "normal",
                message: dict("event.request.missing.entryFeeMessage")
            })
            return
        }
        setProgressing(true)
        await api.event.changeDriverToPending(eventID, userID)
        setProgressing(false)
    }

    async function registerDriver(userID: MongoObjectIDType) {
        setProgressing(true)
        await api.event.registerDriver(eventID, userID)
        setProgressing(false)
    }

    async function removeDriver(userID: MongoObjectIDType) {
        setProgressing(true)
        const success = await api.event.removeDriver(eventID, userID)
        setProgressing(false)
        if (success) {
            setRequestRemoveDriver(null)
        }
    }

    function showPendingList(): boolean {
        if (eventIsFinished) {
            return false
        }
        switch (eventAccess) {
            case "only-registered-drivers-after-paid":
                return true
        }
        return false
    }

    function showRequestingList(): boolean {
        if (eventIsFinished) {
            return false
        }
        switch (eventAccess) {
            case "only-registered-drivers-after-approval":
            case "only-registered-drivers-after-paid":
                return true
        }
        return false
    }

    function numColumns(): number {
        if (eventIsFinished) {
            return 1
        }
        return showPendingList() ? 3 : 2
    }

    function showTabs(): boolean {
        if (eventIsFinished) {
            return false
        }
        if (numColumns() == 2) {
            return isMobileWithTwoColumns
        }
        return isMobileWithThreeColumns
    }

    function sendMailToUser(userID: MongoObjectIDType) {
        state.showContact.setValue({
            sendMessageTo: getUser(userID),
            messageType: "user"
        })
    }

    function getUser(userID: MongoObjectIDType) {
        return eventRequestingDrivers.find(user => user._id === userID)
            ?? eventPendingDrivers.find(user => user._id === userID)
            ?? eventRegisteredDrivers.find(user => user._id === userID)
    }

    function getNextListType(): "pending" | "registered" {
        switch (eventAccess) {
            case "only-registered-drivers-after-approval":
                return "registered"
            case "only-registered-drivers-after-paid":
                const isRequesting = eventRequestingDrivers.find(driver => driver._id === requestAddDriverToNext)
                return isRequesting ? "pending" : "registered"
        }
        return null
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            ref={ref}
            key={eventAccess}
            className="event-requests-kanban">
            {showTabs() &&
                <TabbedContent
                    style="small">
                    <EventDriversList
                        tabbedContent={{
                            name: dict("event.drivers.requesting.tab.label") + " (" + (eventRequestingDrivers?.length ?? 0) + ")",
                            disabled: !showRequestingList()
                        }}
                        type="requesting"
                        progressing={progressing}
                        onRemoveClicked={setRequestRemoveDriver}
                        onAddToNextClicked={setRequestAddDriverToNext}
                        onContactClicked={sendMailToUser}/>
                    <EventDriversList
                        tabbedContent={{
                            name: dict("event.drivers.pending.tab.label") + " (" + (eventPendingDrivers?.length ?? 0) + ")",
                            disabled: !showPendingList()
                        }}
                        progressing={progressing}
                        type={"pending"}
                        onRemoveClicked={setRequestRemoveDriver}
                        onAddToNextClicked={setRequestAddDriverToNext}
                        onContactClicked={sendMailToUser}/>
                    <EventDriversList
                        tabbedContent={{name: dict("event.drivers.registered.tab.label") + " (" + (eventRegisteredDrivers?.length ?? 0) + ")"}}
                        progressing={progressing}
                        type={"registered"}
                        onRemoveClicked={eventIsFinished ? null : setRequestRemoveDriver}
                        onContactClicked={sendMailToUser}/>
                </TabbedContent>}
            {!showTabs() &&
                <ContentLayout
                    columns={numColumns()}>
                    {showRequestingList() &&
                        <EventDriversList
                            type="requesting"
                            progressing={progressing}
                            onAddToNextClicked={setRequestAddDriverToNext}
                            onRemoveClicked={setRequestRemoveDriver}
                            onContactClicked={sendMailToUser}/>}
                    {showPendingList() &&
                        <EventDriversList
                            progressing={progressing}
                            type={"pending"}
                            onAddToNextClicked={setRequestAddDriverToNext}
                            onRemoveClicked={setRequestRemoveDriver}
                            onContactClicked={sendMailToUser}/>}
                    <EventDriversList
                        progressing={progressing}
                        type={"registered"}
                        onRemoveClicked={eventIsFinished ? null : setRequestRemoveDriver}
                        onContactClicked={sendMailToUser}/>
                </ContentLayout>}
        </ContentLayout>
        {requestRemoveDriver &&
            <RemoveDriverModal
                onRequestClose={() => setRequestRemoveDriver(null)}
                progressing={progressing}
                onRequestRemoveDriver={() => removeDriver(requestRemoveDriver)}/>}
        {requestAddDriverToNext &&
            <AddToNextModal
                onRequestClose={() => setRequestAddDriverToNext(null)}
                progressing={progressing}
                nextList={getNextListType()}
                user={getUser(requestAddDriverToNext)}
                onRequestAddToNext={() => addDriverToNext(requestAddDriverToNext)}/>}
    </>
}
