import * as React from "react";
import {useEffect, useState} from "react";
import {CSSUtils} from "../../../../../utils/CSSUtils";
import {FrontendConfig} from "../../../../../core/FrontendConfig";
import {InfoText} from "../../../text/infotext/InfoText";
import {useServices} from "../../../../hooks/useServices";
import {Tooltip} from "../../../tooltip/Tooltip";

/******************************************************************
 * AppLayoutResizer
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AppLayoutResizer() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const barElementRef = React.useRef<HTMLDivElement>(null)
    const pointerDownX = React.useRef<number>(0)
    const sidebarDownWidth = React.useRef<number>(0)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [interactionState, setInteractionState] = useState<"hover" | "inactive" | "dragging">("inactive")
    const [state, setState] = useState<"visible" | "collapsed">("visible")
    const [showCollapseInfo, setShowCollapseInfo] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        return () => {
            resetToDefault()
        }
    }, [])

    useEffect(() => {
        if (interactionState === "dragging") {
            document.body.style.cursor = "ew-resize"
            rootElement().style.pointerEvents = "none"
            window.addEventListener("mousemove", onMouseMove)
            window.addEventListener("mouseup", onMouseUp)
            window.addEventListener("touchmove", onMouseMove)
            window.addEventListener("touchend", onMouseUp)
        } else {
            document.body.style.cursor = null
            rootElement().style.pointerEvents = null
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
            window.removeEventListener("touchmove", onMouseMove)
            window.removeEventListener("touchend", onMouseUp)
            if (showCollapseInfo) {
                collapse()
            }
        }
        return () => {
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
            window.removeEventListener("touchmove", onMouseMove)
            window.removeEventListener("touchend", onMouseUp)
        }
    }, [interactionState])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function rootElement(): HTMLElement {
        return document.getElementsByTagName(FrontendConfig.ROOT_ELEMENT_NAME)[0] as HTMLElement
    }

    function onPointerEnter() {
        if (interactionState === "dragging") return
        setInteractionState("hover")
    }

    function onPointerLeave() {
        if (interactionState === "dragging") return
        setInteractionState("inactive")
    }

    function onMouseDown(e: React.PointerEvent<HTMLDivElement>) {
        setInteractionState("dragging")
        pointerDownX.current = e.screenX
        sidebarDownWidth.current = CSSUtils.getCSSVariableValueFromRoot("--sidebar-width")
    }

    function onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
        setInteractionState("dragging")
        pointerDownX.current = e.touches[0].screenX
        sidebarDownWidth.current = CSSUtils.getCSSVariableValueFromRoot("--sidebar-width")
    }

    function onMouseMove(e: MouseEvent | TouchEvent) {
        let screenX = 0
        if (e instanceof MouseEvent) {
            screenX = e.screenX
        } else if (e instanceof TouchEvent) {
            screenX = e.touches[0].screenX
        }
        const deltaX = screenX - pointerDownX.current
        const minSidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--min-sidebar-width")
        const defaultSidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--default-sidebar-width")
        let newSidebarWidth = sidebarDownWidth.current + deltaX
        setShowCollapseInfo(false)
        setState("visible")
        if (newSidebarWidth < minSidebarWidth) {
            newSidebarWidth = minSidebarWidth
            setShowCollapseInfo(true)
        }
        if (newSidebarWidth > 2 * defaultSidebarWidth) {
            newSidebarWidth = 2 * defaultSidebarWidth
        }
        CSSUtils.setCSSVariableValueToRoot("--show-metabar", `1`)
        CSSUtils.setCSSVariableValueToRoot("--sidebar-width", `${newSidebarWidth}px`)
    }

    function onMouseUp() {
        setInteractionState("inactive")
    }

    function resetToDefault() {
        const defaultSidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--default-sidebar-width")
        CSSUtils.setCSSVariableValueToRoot("--sidebar-width", `${defaultSidebarWidth}px`)
        CSSUtils.setCSSVariableValueToRoot("--show-metabar", `1`)
        setState("visible")
    }

    function collapse() {
        CSSUtils.setCSSVariableValueToRoot("--sidebar-width", `0px`)
        CSSUtils.setCSSVariableValueToRoot("--show-metabar", `0`)
        setShowCollapseInfo(false)
        setState("collapsed")
    }

    function toggle() {
        switch (state) {
            case "visible":
                if (isAtDefaultWidth()) {
                    collapse()
                } else {
                    resetToDefault()
                }
                break
            case "collapsed":
                resetToDefault()
                break
        }
    }

    function isAtDefaultWidth() {
        const defaultSidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--default-sidebar-width")
        const currentSidebarWidth = CSSUtils.getCSSVariableValueFromRoot("--sidebar-width")
        return currentSidebarWidth === defaultSidebarWidth
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <div
            className="app-layout-resizer"
            data-state={state}
            data-interaction-state={interactionState}
            data-show-collapse-info={showCollapseInfo}
            style={{pointerEvents: "all"}}>
            <div
                ref={barElementRef}
                className="app-layout-resizer-dragbar"
                onClick={() => state == "collapsed" ? toggle() : null}
                onDoubleClick={toggle}
                onTouchStart={onTouchStart}
                onMouseEnter={onPointerEnter}
                onMouseLeave={onPointerLeave}
                onMouseDown={onMouseDown}/>
            {interactionState == "dragging" &&
                <div className="app-layout-resizer-collapse-info">
                    <InfoText
                        className={"app-layout-resizer-collapse-info-text"}
                        size={"small"}
                        text={dict("app.layout.resizer.collapseInfo")}/>
                </div>}
        </div>
        {interactionState != "dragging" &&
            <Tooltip
                label={state == "collapsed"
                    ? dict("app.layout.resizer.tooltip.expand")
                    : isAtDefaultWidth()
                        ? dict("app.layout.resizer.tooltip.collapse")
                        : dict("app.layout.resizer.tooltip.reset")}
                hoverElement={barElementRef?.current}/>}
    </>

}
