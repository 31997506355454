import * as React from "react";
import {ISessionLeaderboardEntry} from "../../../../../../shared/types/ISessionLeaderboardEntry";
import {ISessionSetup} from "../../../../../../shared/models/ISessionData";

/******************************************************************
 * TableLeaderboardPositionCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TableLeaderboardPositionCell(props: {
    entry: ISessionLeaderboardEntry;
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function theme(): "dark" | "light" {
        switch (props.entry.state) {
            case "ready":
            case "driving":
                return "light"
            case "invalid":
            case "finished":
                return "dark"
        }
    }

    function setup(): ISessionSetup {
        return props.entry.session.setup
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="table-leaderboard-position-cell"
            data-all-rejected={props.entry.hasOnlyRejectedStints && setup().useStintApproval}
            data-theme={theme()}>
            {setup().showOnlyOwnStints ? "?" : (props.entry.position + ".")}
        </div>
    );

}
