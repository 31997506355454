import * as React from "react";
import {PrivacyType} from "../../../../../../shared/types/PrivacyType";
import {PrivateIcon} from "../../../icons/PrivateIcon";

/******************************************************************
 * TablePrivacyCell
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TablePrivacyCell(props: {
    state: PrivacyType
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="table-privacy-cell">
            {props.state == "public" ? null : <PrivateIcon scale={0.8}/>}
        </div>
    );

}
