import * as React from "react";

/******************************************************************
 * PrivateIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function PrivateIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="private-icon">
            <svg width={14 * (props.scale ?? 1)} height={16 * (props.scale ?? 1)} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.20298 7.37034H9.79695V5.27259C9.79695 4.50051 9.52381 3.84133 8.97752 3.29504C8.43123 2.74875 7.77205 2.47561 6.99997 2.47561C6.22788 2.47561 5.5687 2.74875 5.02241 3.29504C4.47613 3.84133 4.20298 4.50051 4.20298 5.27259L4.20298 7.37034ZM13.2932 8.4192V14.7124C13.2932 15.0038 13.1912 15.2514 12.9873 15.4554C12.7833 15.6593 12.5357 15.7613 12.2443 15.7613H1.75565C1.4643 15.7613 1.21665 15.6593 1.01271 15.4554C0.808761 15.2514 0.706787 15.0038 0.706787 14.7124V8.4192C0.706787 8.12785 0.808761 7.8802 1.01271 7.67626C1.21665 7.47231 1.4643 7.37034 1.75565 7.37034H2.10528V5.27259C2.10528 3.93237 2.58601 2.78153 3.54747 1.82006C4.50894 0.8586 5.65978 0.377869 7 0.377869C8.34023 0.377869 9.49107 0.8586 10.4525 1.82006C11.414 2.78153 11.8947 3.93237 11.8947 5.27259V7.37034H12.2444C12.5357 7.37034 12.7834 7.47231 12.9873 7.67626C13.1912 7.8802 13.2932 8.12785 13.2932 8.4192H13.2932Z" fill="white"/>
            </svg>
        </div>
    );

}
