import * as React from "react";
import {useEffect, useState} from "react";
import {Picture} from "../../../../ui/image/Picture";
import {IMediaViewerItem} from "./IMediaViewerItem";
import {IFileData} from "../../../../../../shared/models/IFileData";
import {useServices} from "../../../../hooks/useServices";

/******************************************************************
 * MediaViewerItem
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MediaViewerItem(props: {
    data: IMediaViewerItem
    zoomLevel: number
    maxZoomLevel: number
    onZoomToggle: (zoomLevel: number) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {api} = useServices()

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [currentFile, setCurrentFile] = useState<IFileData>(null)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/

    useEffect(() => {
        switch (typeof props.data.file) {
            case "string":
                loadImage(props.data.file)
                break
            case "object":
                setCurrentFile(props.data.file)
                break
        }
    }, [props.data])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadImage(fileID: string) {
        const file = await api.file.getFile(fileID)
        setCurrentFile(file)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Picture
            file={currentFile}
            width="100%"
            height="100%"
            toggleZoomViaDoubleClick={true}
            zoomLevel={props.zoomLevel}
            onZoomToggle={props.onZoomToggle}
            fit="contain"/>
    );

}
