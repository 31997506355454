import * as React from "react";

/******************************************************************
 * SimulatorSessionProperty
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function SimulatorSessionProperty(props: {
    label: string
    value: string | string[]
}) {

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function value(): string | React.ReactFragment {
        switch (typeof props.value) {
            case "undefined":
                return "–"
            case "object":
                return props.value?.map((text, i) => <React.Fragment key={i}>{text}<br/></React.Fragment>) ?? "–"
            case "string":
                return props.value
        }

    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="simulator-session-property">
            <h4>{props.label}</h4>
            <p>{value()}</p>
        </div>
    );

}
