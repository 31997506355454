import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {Article} from "../../../ui/article/Article";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {EditModeActionBar} from "../academy/bars/EditModeActionBar";
import {Spacer} from "../../../ui/utils/spacer/Spacer";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {CopyArticleContentModal} from "./CopyArticleContentModal";

/******************************************************************
 * ArticleModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function ArticleModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const dialogRef = React.useRef<HTMLDialogElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices();
    const {authUserIsAdmin} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [articleID, setArticleID] = useState<string>(null);
    const [editMode, setEditMode] = useState<boolean>(false)
    const [showCopyArticleModal, setShowCopyArticleModal] = useState<boolean>(false)
    const [version, setVersion] = useState<number>(0)
    const [articleHistory, setArticleHistory] = useState<{
        articleID: MongoObjectIDType
        scrollTop: number
    }[]>([])

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setArticleID(state.showArticle.getValue())
        state.showArticle.onChangeSignal.add(onShowArticleChanged)
        return () => state.showArticle.onChangeSignal.remove(onShowArticleChanged)
    }, [])

    useEffect(() => {
        if (articleID) {
            if (articleHistory[articleHistory.length - 2]?.articleID == articleID) {
                scrollToTop(articleHistory[articleHistory.length - 1]?.scrollTop)
                setArticleHistory(articleHistory.slice(0, articleHistory.length - 1))
                return
            }
            setArticleHistory([...articleHistory, {
                articleID: articleID,
                scrollTop: scrollTop()
            }])
            scrollToTop()
        }
    }, [articleID])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function onShowArticleChanged() {
        setArticleID(state.showArticle.getValue())
    }

    function close() {
        state.showArticle.setValue(null);
    }

    function showPrevArticle() {
        const prevArticleID = articleHistory[articleHistory.length - 2]
        if (prevArticleID) {
            state.showArticle.setValue(prevArticleID.articleID)
        }
    }

    function scrollToTop(value: number = 0) {
        const modalFrame = dialogRef.current?.getElementsByClassName("modal-frame")?.[0]
        if (modalFrame) {
            modalFrame.scrollTop = value
        }
    }

    function scrollTop(): number {
        const modalFrame = dialogRef.current?.getElementsByClassName("modal-frame")?.[0]
        if (modalFrame) {
            return modalFrame.scrollTop
        }
        return 0
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            closeAction={close}
            className="article-modal"
            type="article"
            onDialogRef={ref => dialogRef.current = ref.current}
            width="large">
            <Article
                key={version}
                edit={editMode}
                articleID={articleID}
                onRequestEdit={() => setEditMode(true)}/>
            <Spacer height="modal-actionbar"/>
            {articleHistory.length > 1 &&
                <ActionBar location="splitview-back">
                    <IconButton
                        type="prev"
                        size="small"
                        onClick={showPrevArticle}/>
                </ActionBar>}
            {authUserIsAdmin &&
                <EditModeActionBar
                    editMode={editMode}
                    onRequestCopyArticleContent={() => setShowCopyArticleModal(true)}
                    onEditModeChange={setEditMode}/>}
            {showCopyArticleModal &&
                <CopyArticleContentModal
                    onCloseRequested={() => setShowCopyArticleModal(false)}
                    onSuccessfulCopy={() => {
                        setShowCopyArticleModal(false)
                        setVersion(prevState => prevState + 1)
                    }}
                    targetArticleID={articleID}/>}
        </Modal>
    );

}
