import * as React from "react";
import {useState} from "react";
import {CapiSettingsDisplay} from "../../../../../ui/utils/capi/CapiSettingsDisplay";
import {LabelButton} from "../../../../../ui/buttons/label/LabelButton";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {useAuthUser} from "../../../../../hooks/useAuthUser";
import {useServices} from "../../../../../hooks/useServices";

/******************************************************************
 * UserCapiSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function UserCapiSettings() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {authUserNick, authUserCapiPassword} = useAuthUser();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [isCreatingNewPassword, setIsCreatingNewPassword] = useState<boolean>()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function createCapiPassword() {
        setIsCreatingNewPassword(true);
        await api.user.createNewCapiPassword()
        setIsCreatingNewPassword(false);
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="user-capi-settings">
            <InfoText text={dict("user.capi.info")}/>
            <CapiSettingsDisplay
                capiPassword={authUserCapiPassword}
                nick={authUserNick}/>
            <InfoText text={dict("user.capi.code.info")}/>
            <div>
                <LabelButton
                    label={dict("user.capi.createPassword")}
                    style="primary"
                    progressing={isCreatingNewPassword}
                    onClick={createCapiPassword}
                />
            </div>
        </ContentLayout>
    );

}
