import {ISessionSetup} from "../models/ISessionData";
import {TrackPreset} from "./TrackPreset";
import {ISessionPresetData} from "../models/ISessionPresetData";
import {ITrackData} from "../models/submodels/ITrackData";
import {FrontendConfig} from "../../frontend/core/FrontendConfig";
import {SharedConfig} from "./SharedConfig";

/******************************************************************
 * SessionSetupPresets
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type SessionDefaultSetupPreset = {
    key: string,
    setup: ISessionSetup
}

export class SessionSetupPresets {

    static isTrackEqualToSession(trackData: ITrackData, currentSessionSetup: ISessionSetup): boolean {
        if (!trackData) return false;
        if (!currentSessionSetup.track) return false;
        const propsToCompare = [
            "minLapTime",
            "numSectors",
            "sectorTargets",
            "targetPreProcessor",
            "lapCountMode",
            "trackBundle",
            "jokerLapTarget"
        ];
        for (let i = 0; i < propsToCompare.length; i++) {
            const prop = propsToCompare[i];
            let trackProp = JSON.stringify(trackData[prop]);
            let sessionProp = JSON.stringify(currentSessionSetup[prop]);
            if (trackProp == '"none"') trackProp = undefined;
            if (sessionProp == '"none"') sessionProp = undefined;
            if (trackProp !== sessionProp) return false;
        }
        return true;
    }

    static isPresetEqualToSession(setupPreset: ISessionPresetData, currentSessionSetup: ISessionSetup): boolean {
        if (!setupPreset) return false;
        if (!currentSessionSetup) return false;
        const propsToCompare = [
            "mode",
            "classificationMode",
            "leaderboardGrouping",
            "finishType",
            "stintValidationMode",
            "teamScoringMode",
            "simultaneousTeamDrivers",
            "laps",
            "syncedLapRaceFulfillMode",
            "duration",
            "timing",
            "stints",
            "activeFrom",
            "activeUntil",
            "carSetup",
            "trackConditions",
            "tires",
            "useStartDelay",
            "points",
            // "startOrderReversed",
            "fastestLapPoints",
            "numJokerLaps",
            "jokerLapPenalty",
            "falseStartPenalty",
            "engines",
            "tunings",
            "announceLeaderboardEveryLap",
            "announceLapsRemainingEveryLap",
            "announceTimeRemainingEverySeconds",
            "deleteStintsForbidden",
            "useStintApproval",
            "showOnlyOwnStints",
            "editTargetsForbidden"
            // "additionalSyncLaps"
        ];
        if (!currentSessionSetup.track) {
            propsToCompare.push(
                "minLapTime",
                "numSectors",
                "targetPreProcessor",
                "sectorTargets",
                "lapCountMode",
                "trackBundle",
                "jokerLapTarget"
            );
        }
        for (let i = 0; i < propsToCompare.length; i++) {
            const prop = propsToCompare[i];
            let presetProp = JSON.stringify(setupPreset.setup[prop]);
            let sessionProp = JSON.stringify(currentSessionSetup[prop]);
            if (presetProp == '"none"') presetProp = undefined;
            if (sessionProp == '"none"') sessionProp = undefined;
            if (presetProp !== sessionProp) {
                return false;
            }
        }
        return true;
    }

    static readonly GENERAL: ISessionSetup = {
        stintValidationMode: "valid-when-at-least-one-lap",
        duration: "00:10:00",
        laps: 0,
        syncedLapRaceFulfillMode: "leader",
        stints: 0,
        activeFrom: null,
        activeUntil: null,
        trackConditions: ["drift_asphalt"],
        carSetup: ["RACE", "DRIFT"],
        tires: ["normal", "gravel_tires", "spikes"],
        startTime: null,
        startDelay: 0,
        useStartDelay: false,
        points: null,
        fastestLapPoints: 0,
        track: null,
        lapCountMode: TrackPreset.DEFAULT_TRACK_LAP_COUNT_MODE,
        trackBundle: TrackPreset.DEFAULT_TRACK_TRACKBUNDLE,
        numSectors: TrackPreset.DEFAULT_TRACK_NUM_SECTORS,
        sectorTargets: TrackPreset.DEFAULT_TRACK_SECTOR_TARGETS,
        targetPreProcessor: TrackPreset.DEFAULT_TARGET_PREPROCESSOR,
        jokerLapTarget: TrackPreset.DEFAULT_TRACK_JOKER_TARGET,
        jokerLapPenalty: 10,
        numJokerLaps: TrackPreset.DEFAULT_TRACK_NUM_JOKER_LAPS,
        minLapTime: TrackPreset.DEFAULT_TRACK_MINLAPTIME,
        startOrderFromSession: null,
        startOrderReversed: false,
        falseStartPenalty: 10,
        simultaneousTeamDrivers: 0,
        engines: ["any"],
        tunings: ["any"],
        announceLeaderboardEveryLap: FrontendConfig.DEFAULT_ANNOUNCE_LEADERBOARD_EVERY_N_LAPS,
        announceLapsRemainingEveryLap: FrontendConfig.DEFAULT_ANNOUNCE_LAPS_REMAINING_EVERY_N_LAPS,
        announceTimeRemainingEverySeconds: FrontendConfig.DEFAULT_ANNOUNCE_TIME_REMAINING_EVERY_N_SECONDS,
        deleteStintsForbidden: false,
        additionalSyncLaps: SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS,
        useStintApproval: false,
        showOnlyOwnStints: false
    }

    static readonly DEFAULT_SOLO_BESTLAP_SETUP: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "lap",
        classificationMode: "solo",
        finishType: "laps",
        leaderboardGrouping: "engine-and-tuning",
        laps: 0,
        timing: "async"
    }

    static readonly DEFAULT_SOLO_RACE: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "race",
        classificationMode: "solo",
        finishType: "laps",
        carSetup: ["RACE"],
        laps: 10,
        leaderboardGrouping: "engine-and-tuning",
        stintValidationMode: "valid-when-started",
        timing: "async"
    }

    static readonly DEFAULT_SOLO_GYMKHANA: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "gymkhana",
        classificationMode: "solo",
        finishType: "duration",
        carSetup: ["DRIFT"],
        duration: "00:01:00",
        leaderboardGrouping: "engine-and-tuning",
        stintValidationMode: "valid-when-started",
        timing: "async"
    }

    static readonly DEFAULT_DRIVER_BESTLAP_SETUP: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "lap",
        classificationMode: "drivers",
        finishType: "laps",
        leaderboardGrouping: "driver",
        laps: 0,
        timing: "async",
    }

    static readonly DEFAULT_DRIVER_GYMKHANA: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "gymkhana",
        classificationMode: "drivers",
        finishType: "duration",
        carSetup: ["DRIFT"],
        duration: "00:01:00",
        leaderboardGrouping: "driver",
        stintValidationMode: "valid-when-started",
        timing: "async",
    }

    static readonly DEFAULT_DRIVER_SYNC_RACE_SETUP: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "race",
        classificationMode: "drivers",
        finishType: "laps",
        useStartDelay: true,
        points: [25, 18, 15, 12, 10, 8, 6, 4, 2, 1],
        fastestLapPoints: 1,
        laps: 20,
        leaderboardGrouping: "driver",
        stintValidationMode: "valid-when-started",
        timing: "sync",
    }

    static readonly DEFAULT_TEAM_SYNC_RACE_SETUP: ISessionSetup = {
        ...SessionSetupPresets.GENERAL,
        mode: "race",
        classificationMode: "teams",
        finishType: "laps",
        laps: 40,
        useStartDelay: true,
        points: [25, 18, 15, 12, 10, 8, 6, 4, 2, 1],
        fastestLapPoints: 1,
        leaderboardGrouping: "team",
        simultaneousTeamDrivers: 0,
        stintValidationMode: "valid-when-started",
        timing: "sync",
    }

    static readonly DEFAULT_TRAINING_SETUPS: SessionDefaultSetupPreset[] = [
        {
            key: "defaultSoloBestLapSetup",
            setup: SessionSetupPresets.DEFAULT_SOLO_BESTLAP_SETUP
        },
        {
            key: "defaultSoloRaceSetup",
            setup: SessionSetupPresets.DEFAULT_SOLO_RACE
        },
        {
            key: "defaultSoloGymkhana",
            setup: SessionSetupPresets.DEFAULT_SOLO_GYMKHANA
        }
    ]

    static readonly DEFAULT_EVENT_SETUPS: SessionDefaultSetupPreset[] = [
        {
            key: "defaultDriverBestLapSetup",
            setup: SessionSetupPresets.DEFAULT_DRIVER_BESTLAP_SETUP
        },
        {
            key: "defaultDriverSyncRaceSetup",
            setup: SessionSetupPresets.DEFAULT_DRIVER_SYNC_RACE_SETUP
        },
        {
            key: "defaultTeamSyncRaceSetup",
            setup: SessionSetupPresets.DEFAULT_TEAM_SYNC_RACE_SETUP
        },
        {
            key: "defaultDriverGymkhana",
            setup: SessionSetupPresets.DEFAULT_DRIVER_GYMKHANA
        }

    ]
}
