import * as React from "react";

/******************************************************************
 * TextIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function TextIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="text-icon">
            <svg width={24 * (props.scale ?? 1)} height={25 * (props.scale ?? 1)} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0625 11.0348H13.5C12.9033 11.0348 12.331 10.7977 11.909 10.3758C11.4871 9.95382 11.25 9.38153 11.25 8.78479V2.22229C11.25 2.17256 11.2302 2.12487 11.1951 2.08971C11.1599 2.05454 11.1122 2.03479 11.0625 2.03479H6.75C5.95435 2.03479 5.19129 2.35086 4.62868 2.91347C4.06607 3.47608 3.75 4.23914 3.75 5.03479V20.0348C3.75 20.8304 4.06607 21.5935 4.62868 22.1561C5.19129 22.7187 5.95435 23.0348 6.75 23.0348H17.25C18.0456 23.0348 18.8087 22.7187 19.3713 22.1561C19.9339 21.5935 20.25 20.8304 20.25 20.0348V11.2223C20.25 11.1726 20.2302 11.1249 20.1951 11.0897C20.1599 11.0545 20.1122 11.0348 20.0625 11.0348ZM15.75 18.5348H8.25C8.05109 18.5348 7.86032 18.4558 7.71967 18.3151C7.57902 18.1745 7.5 17.9837 7.5 17.7848C7.5 17.5859 7.57902 17.3951 7.71967 17.2545C7.86032 17.1138 8.05109 17.0348 8.25 17.0348H15.75C15.9489 17.0348 16.1397 17.1138 16.2803 17.2545C16.421 17.3951 16.5 17.5859 16.5 17.7848C16.5 17.9837 16.421 18.1745 16.2803 18.3151C16.1397 18.4558 15.9489 18.5348 15.75 18.5348ZM15.75 14.7848H8.25C8.05109 14.7848 7.86032 14.7058 7.71967 14.5651C7.57902 14.4245 7.5 14.2337 7.5 14.0348C7.5 13.8359 7.57902 13.6451 7.71967 13.5045C7.86032 13.3638 8.05109 13.2848 8.25 13.2848H15.75C15.9489 13.2848 16.1397 13.3638 16.2803 13.5045C16.421 13.6451 16.5 13.8359 16.5 14.0348C16.5 14.2337 16.421 14.4245 16.2803 14.5651C16.1397 14.7058 15.9489 14.7848 15.75 14.7848Z" fill="white"/>
                <path d="M19.6509 9.37492L12.9098 2.63383C12.8967 2.6208 12.8801 2.61193 12.8619 2.60835C12.8438 2.60476 12.825 2.60662 12.8079 2.61368C12.7908 2.62074 12.7762 2.6327 12.7659 2.64805C12.7556 2.66339 12.7501 2.68144 12.75 2.69992V8.78477C12.75 8.98368 12.829 9.17445 12.9697 9.3151C13.1103 9.45575 13.3011 9.53477 13.5 9.53477H19.5848C19.6033 9.53469 19.6214 9.52915 19.6367 9.51884C19.6521 9.50854 19.664 9.49392 19.6711 9.47684C19.6781 9.45976 19.68 9.44097 19.6764 9.42284C19.6728 9.4047 19.664 9.38803 19.6509 9.37492Z" fill="white"/>
            </svg>
        </div>
    );

}
