import * as React from "react";
import {useRef} from "react";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {useServices} from "../../../../../hooks/useServices";
import {useEventState} from "../../../../../hooks/useEventState";
import {useMobileStyle} from "../../../../../hooks/useMobileStyle";
import {SelectInput, SelectInputOptionType} from "../../../../../ui/form/elements/select/SelectInput";
import {InfoText} from "../../../../../ui/text/infotext/InfoText";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {EventUtils} from "../../../../../../../shared/utils/EventUtils";

/******************************************************************
 * EventDateSettings
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EventDateSettings() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api} = useServices();
    const {eventID, eventDate, eventDateUntil, eventIsPermanent, eventData} = useEventState();
    const [isMobileMode] = useMobileStyle(ref, 500)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function updateDate(date: string): Promise<Response> {
        return await api.event.update(eventID, {date: date ?? null})
    }

    async function updateDateUntil(dateUntil: string): Promise<Response> {
        return await api.event.update(eventID, {dateUntil: dateUntil ?? null})
    }

    async function updatePermanentState(selectValue: string): Promise<Response> {
        return await api.event.update(eventID, {isPermanent: selectValue === "permanent"})
    }

    function formatDate(date: string): string {
        if (!date) return null
        let d = new Date(date);
        return d.toISOString().substring(0, 10)
    }

    function permanentOptions(): SelectInputOptionType[] {
        return [
            {value: "non-permanent", text: dict("event.not.permanent.label")},
            {value: "permanent", text: dict("event.permanent.label")}
        ]
    }

    function dateInlineHelp(): string {
        if (!TimeUtils.isValidDate(eventDate)) {
            return null
        }
        const startDate = EventUtils.computeStartDate(eventData)
        return dict("event.date.settings.date.inlineHelp")
            .replaceAll("{STARTDATE}", TimeUtils.formatDate(startDate.toISOString()))
    }

    function dateUntilInlineHelp(): string {
        if (!TimeUtils.isValidDate(eventDate)) {
            return null
        }
        const endDate = EventUtils.computeEndDate(eventData)
        return dict("event.date.settings.dateUntil.inlineHelp")
            .replaceAll("{ENDDATE}", TimeUtils.formatDate(endDate.toISOString()))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-date-settings" ref={ref}>
            <InfoText
                text={dict("event.date.info.text")}
                size="small"/>
            <SelectInput
                options={permanentOptions()}
                defaultValue={eventIsPermanent ? "permanent" : "non-permanent"}
                onChange={updatePermanentState}/>
            {!eventIsPermanent &&
                <ContentLayout
                    key={eventDate + eventDateUntil}
                    columns={isMobileMode ? 1 : 2}>
                    <Input
                        type="date"
                        label={dict("event.date.input.label")}
                        placeholder={dict("event.date.placeholder")}
                        customLinkLabel={eventDate && dict("date.delete.label")}
                        onCustomLinkClick={() => updateDate(null)}
                        defaultValue={formatDate(eventDate)}
                        action={updateDate}
                        inlineHelp={dateInlineHelp()}/>
                    <Input
                        type="date"
                        label={dict("event.date.until.input.label")}
                        placeholder={dict("event.date.until.placeholder")}
                        customLinkLabel={eventDateUntil && dict("date.delete.label")}
                        onCustomLinkClick={() => updateDateUntil(null)}
                        defaultValue={formatDate(eventDateUntil)}
                        action={updateDateUntil}
                        inlineHelp={dateUntilInlineHelp()}/>
                </ContentLayout>}
        </ContentLayout>
    );

}
