import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {UserInfo} from "../../../ui/info/user/UserInfo";
import {TabbedContent} from "../../../ui/layout/tabbed/TabbedContent";
import {StintDetail} from "./stint/StintDetail";
import {StintsOverview} from "./stints/StintsOverview";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {MongoObjectIDType} from "../../../../../shared/types/MongoObjectIDType";
import {IStintData} from "../../../../../shared/models/IStintData";
import {useSessionState} from "../../../hooks/useSessionState";
import {ComponentInitializer} from "../../../ui/utils/init/ComponentInitializer";
import {ISessionLeaderboardEntry} from "../../../../../shared/types/ISessionLeaderboardEntry";
import {LeaderboardUtils} from "../../../../../shared/utils/LeaderboardUtils";
import {ContentInfo} from "./info/ContentInfo";
import {TimeUtils} from "../../../../../shared/utils/TimeUtils";
import {TeamInfo} from "../../../ui/info/team/TeamInfo";
import {useMobileStyle} from "../../../hooks/useMobileStyle";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {RaceDirectorUtils} from "../../../../../shared/utils/RaceDirectorUtils";

/******************************************************************
 * LeaderboardEntryModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LeaderboardEntryModal() {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const ref = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices();
    const {sessionData, sessionLeaderboard, sessionMode, sessionStartOrderFromSession, sessionID} = useSessionState()
    const {authUserSubscription} = useAuthUser()
    const [isMobileMode] = useMobileStyle(ref, 600)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [loadingStintsData, setLoadingStintsData] = useState<boolean>(false)
    const [stints, setStints] = useState<IStintData[]>()
    const [stintDetailTabName, setStintDetailTabName] = useState<string>()
    const [currentTabID, setCurrentTabID] = useState<number>(0)
    const [contentInfoValue, setContentInfoValue] = useState<string>()
    const [contentInfoLabel, setContentInfoLabel] = useState<string>()
    const [currentStintData, setCurrentStintData] = useState<IStintData>()
    const [detailStintID, setDetailStintID] = useState<MongoObjectIDType>(
        state.showLeaderboardEntry.getValue().latestStint._id
    );
    const [latestEntry, setLatestEntry] = useState<ISessionLeaderboardEntry>(
        state.showLeaderboardEntry.getValue()
    )

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        const originalEntry: ISessionLeaderboardEntry = state.showLeaderboardEntry.getValue()
        if (!originalEntry) {
            setLatestEntry(undefined)
            return
        }
        const latestEntry = LeaderboardUtils.findMostMatchingEntry(sessionLeaderboard, originalEntry)
        if (sessionStartOrderFromSession
            && !LeaderboardUtils.isAnyDriverDrivingOrFinished(sessionLeaderboard)
            && !latestEntry
            && originalEntry) {
            return
        }
        setLatestEntry(latestEntry)
    }, [sessionLeaderboard])

    useEffect(() => {
        if (!latestEntry) {
            state.showLeaderboardEntry.setValue(null)
            return
        }
        if (loadingStintsData) {
            return
        }
        loadStintsData()
    }, [latestEntry, authUserSubscription])

    useEffect(() => {
        updateStintDetailName()
        updateContentInfoValue()
    }, [stints, detailStintID, currentStintData, currentTabID])

    /* ----------------------------------------------------------------
 	 * METHODS
 	 * --------------------------------------------------------------*/

    async function loadStintsData() {
        setLoadingStintsData(true)
        let newStintsData: IStintData[]
        try {
            newStintsData = await api.stint.getStints(latestEntry?.stintIDs)
        } catch {
            const userIDs = latestEntry?.team
                ? latestEntry?.team?.members?.map(member => member._id)
                : [latestEntry?.latestStint?.user?._id]
            newStintsData = await api.stint.getStintsBySessionAndUsers(sessionID, userIDs)
        }
        if (!detailStintID
            || !newStintsData?.find(stintData => stintData._id === detailStintID)
            || stints?.length < newStintsData?.length) {
            setDetailStintID(newStintsData?.[newStintsData.length - 1]._id)
        }
        setStints(newStintsData);
        setLoadingStintsData(false)
    }

    function showStintDetail(stintData: IStintData) {
        setDetailStintID(stintData._id);
        setCurrentTabID(1);
    }

    function updateStintDetailName() {
        let latestStintText = ""
        if (latestEntry && latestEntry?.latestStint._id === detailStintID) {
            latestStintText = dict("driver.stint.detail.title.latest")
        }
        setStintDetailTabName(
            dict("driver.stint.detail.title")
                .replace("{STINT_NUMBER}", currentStintNumber()?.toString() ?? "")
                .replace("{LATEST_STINT}", latestStintText))
    }

    function updateContentInfoValue() {
        if (!stints && !currentStintData) {
            setContentInfoValue("–")
            setContentInfoLabel("–")
            return
        }
        switch (currentTabID) {
            case 0:
                if (hasOverview()) {
                    setContentInfoLabel(dict("stints.overview.label"))
                    setContentInfoValue(dict("stints.overview.info." + (stints?.length > 1
                        ? "multiple"
                        : "single"))
                        .replace("{STINTS_LENGTH}", latestEntry?.stintIDs?.length?.toString() ?? ""))
                    break
                }
            default:
                switch (sessionMode) {
                    case "lap":
                        setContentInfoLabel(dict("table.head.bestLap"))
                        setContentInfoValue(TimeUtils.formatDrivenTime(currentStintData?.bestLapTime) ?? "–")
                        break
                    case "race":
                        setContentInfoLabel(dict("table.head.time"))
                        setContentInfoValue(RaceDirectorUtils.drivenTime(currentStintData, sessionData, true))
                        break
                    case "gymkhana":
                        setContentInfoLabel(dict("table.head.score"))
                        setContentInfoValue(currentStintData?.score?.toString() ?? "–")
                        break
                }
        }
    }

    function stintOverviewTabName() {
        return dict("driver.stints.overview.title")
    }

    function currentStintNumber() {
        const stintIndex = latestEntry?.stintIDs?.indexOf(detailStintID)
        if (stintIndex === -1 || stintIndex === undefined) {
            return undefined
        }
        return stintIndex + 1
    }

    function hasOverview() {
        return stints?.length > 1
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            width="super-large"
            height="large"
            className="leaderboard-entry-modal"
            closeAction={() => state.showLeaderboardEntry.setValue(null)}>
            <ComponentInitializer isPropertyAvailable={!!stints}>
                <ContentLayout ref={ref} alignSelf="start">
                    <header>
                        <ContentLayout
                            useFrameMargin={false}
                            columnTemplate={isMobileMode ? "1fr" : "auto auto"}
                            justifyItems="stretch"
                            justifyContent="space-between">
                            {latestEntry?.team && currentTabID === 0
                                ? <TeamInfo
                                    team={latestEntry.team}
                                    style="leaderboard-entry-modal"/>
                                : <UserInfo
                                    user={currentStintData?.user ?? latestEntry?.latestStint?.user}
                                    clickToShowProfile={true}
                                    style="leaderboard-entry-modal"/>}
                            {!isMobileMode &&
                                <ContentInfo
                                    value={contentInfoValue}
                                    label={contentInfoLabel}/>}
                        </ContentLayout>
                    </header>
                    <main>
                        {hasOverview()
                            ? <TabbedContent showContentByID={currentTabID}>
                                <StintsOverview
                                    stints={stints}
                                    availableStintsIDs={latestEntry?.stintIDs}
                                    onStintClick={showStintDetail}
                                    tabbedContent={{
                                        onClick: () => setCurrentTabID(0),
                                        name: stintOverviewTabName()
                                    }}/>
                                <StintDetail
                                    stintID={detailStintID}
                                    onStintUpdated={setCurrentStintData}
                                    tabbedContent={{
                                        onClick: () => setCurrentTabID(1),
                                        name: stintDetailTabName,
                                        disabled: !stints || stints.length === 0
                                    }}/>
                            </TabbedContent>
                            : <StintDetail
                                stintID={detailStintID}
                                onStintUpdated={setCurrentStintData}/>}
                    </main>
                </ContentLayout>
            </ComponentInitializer>
        </Modal>
    );

}
