import * as React from "react";

/******************************************************************
 * HamburgerIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function HamburgerIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="hamburger-icon">
            <svg width={18 * (props.scale ?? 1)} height={13 * (props.scale ?? 1)} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.22485 2.03467H16.7752" stroke="white" strokeWidth="2.22147" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M1.22485 6.84787H16.7752" stroke="white" strokeWidth="2.22147" strokeMiterlimit="10" strokeLinecap="round"/>
                <path d="M1.22485 11.661H16.7752" stroke="white" strokeWidth="2.22147" strokeMiterlimit="10" strokeLinecap="round"/>
            </svg>
        </div>
    );

}
