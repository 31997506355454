/******************************************************************
 * ColorUtils
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/
import {FrontendConfig} from "../../../../core/FrontendConfig";

export class ColorUtils {

    static getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static getHexStringFromCSSVariable(variableName: string): string {
        const root = document.querySelector(FrontendConfig.ROOT_ELEMENT_NAME) as HTMLElement;
        return getComputedStyle(root)
            ?.getPropertyValue(variableName)
            ?.trim()
    }

}
