import * as React from "react";

/******************************************************************
 * LocationIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function LocationIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="location-icon">
            <svg width={20 * (props.scale ?? 1)} height={29 * (props.scale ?? 1)} viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0.53479C4.47937 0.53479 0 4.56667 0 9.53479C0 17.5348 10 28.5348 10 28.5348C10 28.5348 20 17.5348 20 9.53479C20 4.56667 15.5206 0.53479 10 0.53479ZM10 14.5348C9.20887 14.5348 8.43552 14.3002 7.77772 13.8607C7.11992 13.4211 6.60723 12.7964 6.30448 12.0655C6.00173 11.3346 5.92252 10.5304 6.07686 9.75443C6.2312 8.97851 6.61216 8.26577 7.17157 7.70636C7.73098 7.14695 8.44372 6.76599 9.21964 6.61165C9.99556 6.45731 10.7998 6.53652 11.5307 6.83927C12.2616 7.14202 12.8864 7.65471 13.3259 8.31251C13.7654 8.97031 14 9.74366 14 10.5348C13.9988 11.5953 13.577 12.612 12.8271 13.3619C12.0773 14.1118 11.0605 14.5336 10 14.5348Z" fill="white"/>
            </svg>
        </div>
    );

}
