import {useEffect, useState} from "react"
import {useServices} from "./useServices"
import {SessionStateType} from "../../../shared/types/SessionStateType"
import {ISessionData, ISessionSetup} from "../../../shared/models/ISessionData"
import {SessionGameModeType} from "../../../shared/types/SessionGameModeType"
import {SessionFinishType} from "../../../shared/types/SessionFinishType"
import {SessionTimingType} from "../../../shared/types/SessionTimingType";
import {DriftCarSetupType} from "../../../shared/types/DriftCarSetupType";
import {DriftTrackConditionType} from "../../../shared/types/DriftTrackConditionType";
import {DriftTiresType} from "../../../shared/types/DriftTiresType";
import {DriftTrackBundleType} from "../../../shared/types/DriftTrackBundleType";
import {SessionSetupSectionType} from "../../../shared/types/SessionSetupSectionType";
import {DriftTargetCodeType} from "../../../shared/types/DriftTargetCodeType";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {DriftEngineType} from "../../../shared/types/DriftEngineType";
import {DriftTuningType} from "../../../shared/types/DriftTuningType";
import {SessionClassificationModeType} from "../../../shared/types/SessionClassificationModeType";
import {SessionStintValidationModeType} from "../../../shared/types/SessionStintValidationModeType";
import {TrackLapCountModeType} from "../../../shared/types/TrackLapCountModeType";
import {ISessionLeaderboardEntry} from "../../../shared/types/ISessionLeaderboardEntry";
import {SessionLeaderboardGroupingType} from "../../../shared/types/SessionLeaderboardGroupingType";
import {SessionTeamScoringMode} from "../../../shared/types/SessionTeamScoringMode";
import {SessionSyncedLapRaceFulfillMode} from "../../../shared/types/SessionSyncedLapRaceFulfillMode";
import {ILogEntryData} from "../../../shared/types/ILogEntryData";
import {TargetPreProcessorMode} from "../../../shared/types/TargetPreProcessorMode";
import {FrontendConfig} from "../../core/FrontendConfig";
import {SharedConfig} from "../../../shared/config/SharedConfig";
import {useAuthUser} from "./useAuthUser";

/******************************************************************
 * useSessionState Hook
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function useSessionState() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [sessionID, setSessionID] = useState<MongoObjectIDType>(state.session.getValue()?._id)
    const [sessionData, setSessionData] = useState<ISessionData>(state.session.getValue())
    const [sessionSetup, setSessionSetup] = useState<ISessionSetup>(state.session.getValue()?.setup)
    const [sessionName, setSessionName] = useState<string>(state.session.getValue()?.name)
    const [sessionPath, setSessionPath] = useState<string>(state.session.getValue()?.path)
    const [sessionState, setSessionState] = useState<SessionStateType>(state.session.getValue()?.state)
    const [sessionMode, setSessionMode] = useState<SessionGameModeType>(state.session.getValue()?.setup?.mode)
    const [sessionClassificationMode, setSessionClassificationMode] = useState<SessionClassificationModeType>(state.session.getValue()?.setup?.classificationMode)
    const [sessionLeaderboardGrouping, setSessionLeaderboardGrouping] = useState<SessionLeaderboardGroupingType>(state.session.getValue()?.setup?.leaderboardGrouping)
    const [sessionTeamScoringMode, setSessionTeamScoringMode] = useState<SessionTeamScoringMode>(state.session.getValue()?.setup?.teamScoringMode)
    const [sessionSimluataneousTeamDrivers, setSessionSimluataneousTeamDrivers] = useState<number>(state.session.getValue()?.setup?.simultaneousTeamDrivers)
    const [sessionFinishType, setSessionFinishType] = useState<SessionFinishType>(state.session.getValue()?.setup?.finishType)
    const [sessionLaps, setSessionLaps] = useState<number>(state.session.getValue()?.setup?.laps)
    const [sessionLapCountMode, setSessionLapCountMode] = useState<TrackLapCountModeType>(state.session.getValue()?.setup?.lapCountMode)
    const [sessionSyncedLapRaceFulfillMode, setSessionSyncedLapRaceFulfillMode] = useState<SessionSyncedLapRaceFulfillMode>(state.session.getValue()?.setup?.syncedLapRaceFulfillMode)
    const [sessionDuration, setSessionDuration] = useState<string>(state.session.getValue()?.setup?.duration)
    const [sessionTiming, setSessionTiming] = useState<SessionTimingType>(state.session.getValue()?.setup?.timing)
    const [sessionAvailableStints, setSessionAvailableStints] = useState<number>(state.session.getValue()?.setup?.stints)
    const [sessionStintValidationType, setSessionStintValidationType] = useState<SessionStintValidationModeType>(state.session.getValue()?.setup?.stintValidationMode)
    const [sessionActiveFrom, setSessionActiveFrom] = useState<string>(state.session.getValue()?.setup?.activeFrom)
    const [sessionActiveUntil, setSessionActiveUntil] = useState<string>(state.session.getValue()?.setup?.activeUntil)
    const [sessionCarSetup, setSessionCarSetup] = useState<DriftCarSetupType[]>(state.session.getValue()?.setup?.carSetup)
    const [sessionTrackConditions, setSessionTrackConditions] = useState<DriftTrackConditionType[]>(state.session.getValue()?.setup?.trackConditions)
    const [sessionTires, setSessionTires] = useState<DriftTiresType[]>(state.session.getValue()?.setup?.tires)
    const [sessionTrackBundle, setSessionTrackBundle] = useState<DriftTrackBundleType>(state.session.getValue()?.setup?.trackBundle)
    const [sessionStartTime, setSessionStartTime] = useState<string>(state.session.getValue()?.setup?.startTime)
    const [sessionUseStartDelay, setSessionUseStartDelay] = useState<boolean>(state.session.getValue()?.setup?.useStartDelay)
    const [sessionVisibleSections, setSessionVisibleSections] = useState<SessionSetupSectionType[]>(state.session.getValue()?.setup?.visibleSections)
    const [sessionSectorTargets, setSessionSectorTargets] = useState<DriftTargetCodeType[]>(state.session.getValue()?.setup?.sectorTargets)
    const [sessionNumSectors, setSessionNumSectors] = useState<number>(state.session.getValue()?.setup?.numSectors)
    const [sessionTargetPreProcessor, setSessionTargetPreProcessor] = useState<TargetPreProcessorMode>(state.session.getValue()?.setup?.targetPreProcessor)
    const [sessionPoints, setSessionPoints] = useState<number[]>(state.session.getValue()?.setup?.points)
    const [sessionFastestLapPoints, setSessionFastestLapPoints] = useState<number>(state.session.getValue()?.setup?.fastestLapPoints)
    const [sessionJokerLapTarget, setSessionJokerLapTarget] = useState<DriftTargetCodeType | "none">(state.session.getValue()?.setup?.jokerLapTarget)
    const [sessionJokerLapPenalty, setSessionJokerLapPenalty] = useState<number>(state.session.getValue()?.setup?.jokerLapPenalty)
    const [sessionNumJokerLaps, setSessionNumJokerLaps] = useState<number>(state.session.getValue()?.setup?.numJokerLaps)
    const [sessionStartOrderFromSession, setSessionStartOrderFromSession] = useState<MongoObjectIDType>(state.session.getValue()?.setup?.startOrderFromSession)
    const [sessionStartOrderReversed, setSessionStartOrderReversed] = useState<boolean>(state.session.getValue()?.setup?.startOrderReversed)
    const [sessionFalseStartPenality, setSessionFalseStartPenality] = useState<number>(state.session.getValue()?.setup?.falseStartPenalty)
    const [sessionMinLapTime, setSessionMinLapTime] = useState<number>(state.session.getValue()?.setup?.minLapTime)
    const [sessionEngines, setSessionEngines] = useState<DriftEngineType[]>(state.session.getValue()?.setup?.engines)
    const [sessionTunings, setSessionTunings] = useState<DriftTuningType[]>(state.session.getValue()?.setup?.tunings)
    const [sessionForbiddenDrivers, setSessionForbiddenDrivers] = useState<MongoObjectIDType[]>(state.session.getValue()?.forbiddenDrivers);
    const [sessionForbiddenTeams, setSessionForbiddenTeams] = useState<MongoObjectIDType[]>(state.session.getValue()?.forbiddenTeams);
    const [sessionLeaderboard, setSessionLeaderboard] = useState<ISessionLeaderboardEntry[]>(state.sessionLeaderboard.getValue())
    const [sessionLogData, setSessionLogData] = useState<ILogEntryData[]>(state.sessionLogData.getValue())
    const [sessionTrackID, setSessionTrackID] = useState<MongoObjectIDType>(state.session.getValue()?.setup?.track)
    const [sessionAnnounceLeaderboardEveryLap, setSessionAnnounceLeaderboardEveryLap] = useState<number>(state.session.getValue()?.setup?.announceLeaderboardEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_LEADERBOARD_EVERY_N_LAPS)
    const [sessionAnnounceLapsRemainingEveryLap, setSessionAnnounceLapsRemainingEveryLap] = useState<number>(state.session.getValue()?.setup?.announceLapsRemainingEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_LAPS_REMAINING_EVERY_N_LAPS)
    const [sessionAnnounceTimeRemainingEverySeconds, setSessionAnnounceTimeRemainingEverySeconds] = useState<number>(state.session.getValue()?.setup?.announceTimeRemainingEverySeconds ?? FrontendConfig.DEFAULT_ANNOUNCE_TIME_REMAINING_EVERY_N_SECONDS)
    const [sessionAnnounceDrivenTimeEveryLap, setSessionAnnounceDrivenTimeEveryLap] = useState<number>(state.session.getValue()?.setup?.announceDrivenTimeEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_DRIVEN_TIME_EVERY_N_LAPS)
    const [sessionDeleteStintsForbidden, setSessionDeleteStintsForbidden] = useState<boolean>(state.session.getValue()?.setup?.deleteStintsForbidden ?? false)
    const [sessionAdditionalLapsToSync, setSessionAdditionalLapsToSync] = useState<number>(state.session.getValue()?.setup?.additionalSyncLaps ?? SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS)
    const [sessionLatestStintActivityTimestamp, setSessionLatestStintActivityTimestamp] = useState<string>(state.session.getValue()?.latestStintActivityTimestamp)
    const [sessionIsFinished, setSessionIsFinished] = useState<boolean>(state.session.getValue()?.state === "finished")
    const [sessionUseStintApproval, setSessionUseStintApproval] = useState<boolean>(state.session.getValue()?.setup?.useStintApproval)
    const [sessionShowOnlyOwnStints, setSessionShowOnlyOwnStints] = useState<boolean>(state.session.getValue()?.setup?.showOnlyOwnStints)
    const [sessionEditTargetsForbidden, setSessionEditTargetsForbidden] = useState<boolean>(state.session.getValue()?.setup?.editTargetsForbidden)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        state.session.onChangeSignal.add(updateSessionStates)
        state.sessionLeaderboard.onChangeSignal.add(updateSessionLeaderboard)
        state.sessionLogData.onChangeSignal.add(updateSessionLogs)
        return () => {
            state.session.onChangeSignal.remove(updateSessionStates)
            state.sessionLeaderboard.onChangeSignal.remove(updateSessionLeaderboard)
            state.sessionLogData.onChangeSignal.remove(updateSessionLogs)
        }
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateSessionLogs() {
        setSessionLogData(state.sessionLogData.getValue())
    }

    function updateSessionLeaderboard() {
        setSessionLeaderboard(state.sessionLeaderboard.getValue())
    }

    function updateSessionStates() {
        const session: ISessionData = state.session.getValue()
        setSessionData(session)
        setSessionSetup(session?.setup)
        setSessionID(session?._id)
        setSessionName(session?.name)
        setSessionPath(session?.path)
        setSessionState(session?.state)
        setSessionMode(session?.setup?.mode)
        setSessionClassificationMode(session?.setup?.classificationMode)
        setSessionLeaderboardGrouping(session?.setup?.leaderboardGrouping)
        setSessionTeamScoringMode(session?.setup?.teamScoringMode)
        setSessionSimluataneousTeamDrivers(session?.setup?.simultaneousTeamDrivers)
        setSessionFinishType(session?.setup?.finishType)
        setSessionLaps(session?.setup?.laps)
        setSessionLapCountMode(session?.setup?.lapCountMode)
        setSessionSyncedLapRaceFulfillMode(session?.setup?.syncedLapRaceFulfillMode)
        setSessionDuration(session?.setup?.duration)
        setSessionTiming(session?.setup?.timing)
        setSessionStintValidationType(session?.setup?.stintValidationMode)
        setSessionAvailableStints(session?.setup?.stints)
        setSessionActiveFrom(session?.setup?.activeFrom)
        setSessionActiveUntil(session?.setup?.activeUntil)
        setSessionCarSetup(session?.setup?.carSetup)
        setSessionTrackConditions(session?.setup?.trackConditions)
        setSessionTires(session?.setup?.tires)
        setSessionTrackBundle(session?.setup?.trackBundle)
        setSessionStartTime(session?.setup?.startTime)
        setSessionUseStartDelay(session?.setup?.useStartDelay)
        setSessionVisibleSections(session?.setup?.visibleSections)
        setSessionSectorTargets(session?.setup?.sectorTargets)
        setSessionNumSectors(session?.setup?.numSectors)
        setSessionTargetPreProcessor(session?.setup?.targetPreProcessor)
        setSessionPoints(session?.setup?.points)
        setSessionFastestLapPoints(session?.setup?.fastestLapPoints)
        setSessionJokerLapTarget(session?.setup?.jokerLapTarget)
        setSessionJokerLapPenalty(session?.setup?.jokerLapPenalty)
        setSessionNumJokerLaps(session?.setup?.numJokerLaps)
        setSessionStartOrderFromSession(session?.setup?.startOrderFromSession)
        setSessionStartOrderReversed(session?.setup?.startOrderReversed)
        setSessionFalseStartPenality(session?.setup?.falseStartPenalty)
        setSessionMinLapTime(session?.setup?.minLapTime)
        setSessionEngines(session?.setup?.engines)
        setSessionTunings(session?.setup?.tunings)
        setSessionForbiddenDrivers(session?.forbiddenDrivers)
        setSessionForbiddenTeams(session?.forbiddenTeams)
        setSessionTrackID(session?.setup?.track)
        setSessionAnnounceLeaderboardEveryLap(session?.setup?.announceLeaderboardEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_LEADERBOARD_EVERY_N_LAPS)
        setSessionAnnounceLapsRemainingEveryLap(session?.setup?.announceLapsRemainingEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_LAPS_REMAINING_EVERY_N_LAPS)
        setSessionAnnounceTimeRemainingEverySeconds(session?.setup?.announceTimeRemainingEverySeconds ?? FrontendConfig.DEFAULT_ANNOUNCE_TIME_REMAINING_EVERY_N_SECONDS)
        setSessionAnnounceDrivenTimeEveryLap(session?.setup?.announceDrivenTimeEveryLap ?? FrontendConfig.DEFAULT_ANNOUNCE_DRIVEN_TIME_EVERY_N_LAPS)
        setSessionDeleteStintsForbidden(session?.setup?.deleteStintsForbidden ?? false)
        setSessionAdditionalLapsToSync(session?.setup?.additionalSyncLaps ?? SharedConfig.DEFAULT_SYNC_LAPS_ADDITIONAL_LAPS)
        setSessionLatestStintActivityTimestamp(session?.latestStintActivityTimestamp)
        setSessionIsFinished(session?.state === "finished")
        setSessionUseStintApproval(session?.setup?.useStintApproval)
        setSessionShowOnlyOwnStints(session?.setup?.showOnlyOwnStints)
        setSessionEditTargetsForbidden(session?.setup?.editTargetsForbidden)
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        sessionData,
        sessionSetup,
        sessionID,
        sessionName,
        sessionPath,
        sessionState,
        sessionMode,
        sessionFinishType,
        sessionLaps,
        sessionLapCountMode,
        sessionDuration,
        sessionTiming,
        sessionAvailableStints,
        sessionStintValidationType,
        sessionActiveFrom,
        sessionActiveUntil,
        sessionCarSetup,
        sessionTrackConditions,
        sessionTires,
        sessionTrackBundle,
        sessionStartTime,
        sessionUseStartDelay,
        sessionVisibleSections,
        sessionSectorTargets,
        sessionNumSectors,
        sessionTargetPreProcessor,
        sessionPoints,
        sessionFastestLapPoints,
        sessionJokerLapTarget,
        sessionNumJokerLaps,
        sessionStartOrderFromSession,
        sessionStartOrderReversed,
        sessionFalseStartPenality,
        sessionMinLapTime,
        sessionEngines,
        sessionTunings,
        sessionForbiddenDrivers,
        sessionForbiddenTeams,
        sessionClassificationMode,
        sessionLeaderboardGrouping,
        sessionTeamScoringMode,
        sessionLeaderboard,
        sessionLogData,
        sessionSimluataneousTeamDrivers,
        sessionSyncedLapRaceFulfillMode,
        sessionJokerLapPenalty,
        sessionTrackID,
        sessionAnnounceLeaderboardEveryLap,
        sessionAnnounceLapsRemainingEveryLap,
        sessionAnnounceTimeRemainingEverySeconds,
        sessionAnnounceDrivenTimeEveryLap,
        sessionDeleteStintsForbidden,
        sessionAdditionalLapsToSync,
        sessionLatestStintActivityTimestamp,
        sessionIsFinished,
        sessionUseStintApproval,
        sessionShowOnlyOwnStints,
        sessionEditTargetsForbidden
    }
}
