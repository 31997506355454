import * as React from "react";
import {useEffect, useState} from "react";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {useServices} from "../../../../hooks/useServices";
import {IArticleData} from "../../../../../../shared/models/IArticleData";
import {Table} from "../../../../ui/table/Table";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../../shared/utils/TimeUtils";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {useEnviroment} from "../../../../hooks/useEnviroment";
import {Headline} from "../../../../ui/text/headings/Headline";
import {useNotificationState} from "../../../../hooks/useNotificationState";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {SelectInput, SelectInputOptionType} from "../../../../ui/form/elements/select/SelectInput";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {Badge} from "../../../../ui/info/badge/Badge";
import {ArticleType} from "../../../../../../shared/types/ArticleType";

/******************************************************************
 * AdminArticles
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

type ModifiedStateType = "localNewer" | "masterNewer" | "upToDate"
type FilterType = "all" | "localNewer" | "masterNewer"

export function AdminArticles() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {isDevEnviroment} = useEnviroment();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [filteredArticles, setFilteredArticles] = useState<IArticleData[]>([])
    const [articles, setArticles] = useState<IArticleData[]>([])
    const [newMasterArticles, setNewMasterArticles] = useState<IArticleData[]>([])
    const [masterArticles, setMasterArticles] = useState<IArticleData[]>([])
    const {setNotification} = useNotificationState()
    const [isCreatingArticle, setIsCreatingArticle] = useState(false)
    const [currentFilter, setCurrentFilter] = useState<FilterType>("all")
    const [isLoadingData, setIsLoadingData] = useState(true)

    /* ----------------------------------------------------------------
     * EFFECTS
     * --------------------------------------------------------------*/


    useEffect(() => {
        loadArticles()
        state.showArticle.onChangeSignal.add(loadArticles)
        return () => state.showArticle.onChangeSignal.remove(loadArticles)
    }, [])

    useEffect(() => {
        updateFilter()
    }, [currentFilter, articles])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadArticles() {
        setIsLoadingData(true)
        const articles = await api.article.getArticles()
        if (isDevEnviroment) {
            const masterArticles = await api.admin.getMasterArticles()
            const newMasterArticles = masterArticles?.filter(masterArticle => {
                return !articles.find(article => article._id === masterArticle._id)
            })
            setMasterArticles(masterArticles)
            setNewMasterArticles(newMasterArticles)
        }
        setArticles(articles)
        setIsLoadingData(false)
    }

    async function deleteArticle(article: IArticleData): Promise<Response> {
        await api.article.deleteArticle(article._id)
        await loadArticles()
        return null;
    }

    async function downloadArticleFromLiveServer(articleData: IArticleData) {
        await api.admin.upsertMasterArticleToLocal(articleData._id)
        await loadArticles()
    }

    async function uploadArticleToLiveServer(articleData: IArticleData) {
        await api.admin.upsertLocalArticleToMaster(articleData._id)
        await loadArticles()
    }

    function filterOptions(): SelectInputOptionType<FilterType>[] {
        return [
            {
                value: "all",
                text: dict("admin.articles.filters.all.label"),
                badge: <Badge label={countArticles("all")} color="white"/>
            },
            {
                value: "localNewer",
                text: dict("admin.articles.filters.localNewer.label"),
                badge: <Badge label={countArticles("localNewer")} color="white"/>
            },
            {
                value: "masterNewer",
                text: dict("admin.articles.filters.masterNewer.label"),
                badge: <Badge label={countArticles("masterNewer")} color="white"/>
            }
        ]
    }

    function updateFilter() {
        if (!articles) return
        if (!isDevEnviroment) return
        setFilteredArticles(articles?.filter(article => {
            const stateType = getArticleFilterType(article, false)
            return currentFilter === "all" || stateType === currentFilter
        }))
    }

    function countArticles(filter: FilterType): string {
        return articles.filter(article => {
            const stateType = getArticleFilterType(article, false)
            return filter === "all" || stateType === filter
        })?.length.toString() ?? "0"
    }

    function columnsDefinition(isMaster: boolean = false): ITableColumnDefinition<IArticleData>[] {
        return [
            {
                type: "text",
                size: "40px",
                dataKey: "language",
                sortKey: "language",
            },
            {
                columnName: dict("articles.table.column.title"),
                dataKey: "title",
                type: "text-bold",
                sortKey: "title",
                size: "5fr",
                action: article => {
                    if (article.title.startsWith("help.")) {
                        state.showHelp.setValue(article.title.split("help.")[1])
                        return null;
                    }
                    state.showArticle.setValue(article._id)
                    return null;
                }
            }, {
                columnName: dict("articles.table.column.type"),
                dataKey: "type",
                type: "text",
                sortKey: "type",
                size: "1.2fr",
                value: article => {
                    return dict("article.type." + (article.type ?? "default" as ArticleType))
                }
            }, {
                columnName: dict("articles.table.column.lastModified"),
                dataKey: "lastModified",
                type: "text",
                sortKey: "lastModified",
                size: "2fr",
                value: article => {
                    return TimeUtils.formatDate(article.lastModified)
                }
            },
            {
                columnName: dict("articles.table.column.lastEditor"),
                type: "text",
                size: "2fr",
                value: data => {
                    return data.editors?.length ? data.editors[data.editors.length - 1].nick : null
                }
            }, {
                type: "custom",
                size: isDevEnviroment ? "40px" : null,
                customCell: (key, article) => {
                    return <ContentLayout key={key} justifyItems="center">
                        <IconButton
                            type={"clipboardCheck"}
                            scale={0.8}
                            onClick={() => {
                                try {
                                    navigator.clipboard.writeText(article._id)
                                        .then(async () => {
                                            setNotification({
                                                type: "info",
                                                message: dict("article.id.copiedToClipboard")
                                            })
                                        })
                                } catch (e) {
                                    setNotification({type: "info", message: dict("article.id.copyToClipboardFailed")})
                                }
                            }}/>
                    </ContentLayout>
                }
            }, {
                type: "custom",
                size: isDevEnviroment ? "40px" : null,
                customCell: (key, article) => {
                    const stateType = getArticleFilterType(article, isMaster)
                    return <ContentLayout key={key}>
                        {!isMaster && stateType == "upToDate" && <IconButton
                            type="check"
                            iconColor="green"
                            disabled={true}/>}
                        {stateType == "masterNewer" && <IconButton
                            type="download"
                            disabled={isMaster ? false : (stateType != "masterNewer")}
                            onClick={() => downloadArticleFromLiveServer(article)}/>}
                        {stateType == "localNewer" && <IconButton
                            type="upload"
                            disabled={isMaster ? true : (stateType != "localNewer")}
                            onClick={() => uploadArticleToLiveServer(article)}/>}
                    </ContentLayout>
                }
            },
            {
                type: "delete",
                size: isMaster ? null : "40px",
                action: deleteArticle
            }]
    }

    async function createArticle() {
        setIsCreatingArticle(true)
        const article = await api.article.createArticle(dict("article.default.title"), state.language.getValue())
        state.showArticle.setValue(article._id)
        setIsCreatingArticle(false)
    }

    function getArticleFilterType(article: IArticleData, isMaster: boolean): ModifiedStateType {
        const masterArticle = masterArticles.find(masterArticle => masterArticle._id === article._id)
        let isMasterNewer = isMaster
        let isLocalNewer = !isMaster
        if (!isMaster && masterArticle) {
            isMasterNewer = new Date(masterArticle.lastModified) > new Date((article.lastModified))
            isLocalNewer = new Date(masterArticle.lastModified) < new Date(article.lastModified)
        }
        if (isMasterNewer) {
            return "masterNewer"
        }
        if (isLocalNewer) {
            return "localNewer"
        }
        return "upToDate"
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-articles" gap="large">
            {newMasterArticles?.length > 0 && <ContentLayout gap="none">
                <Headline text={dict("admin.articles.newMasterArticles.title")} style="modal"/>
                <Table<IArticleData>
                    sortKey="lastModified"
                    showFilter={true}
                    rowsData={newMasterArticles}
                    visibleRows={25}
                    columnsDefinition={columnsDefinition(true)}/>
            </ContentLayout>}
            <ContentLayout gap="none">
                <ContentLayout columnTemplate="auto min-content" justifyContent="space-between">
                    <Headline
                        text={dict("admin.articles.table.title")}
                        style="modal"/>
                    <LabelButton
                        label={dict("admin.articles.create.label")}
                        style="primary-small"
                        progressing={isCreatingArticle}
                        onClick={createArticle}/>
                </ContentLayout>
                <Table<IArticleData>
                    sortKey="lastModified"
                    style="admin"
                    showFilter={true}
                    isLoadingData={isLoadingData}
                    rowsData={isDevEnviroment ? filteredArticles : articles}
                    visibleRows={25}
                    columnsDefinition={columnsDefinition()}/>
            </ContentLayout>
            {isDevEnviroment &&
                <ActionBar location="main">
                    <SelectInput
                        size={"small"}
                        style={"important"}
                        defaultValue={currentFilter}
                        options={filterOptions()}
                        onChange={value => setCurrentFilter(value as FilterType)}/>
                </ActionBar>}
        </ContentLayout>
    );

}
