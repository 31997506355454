import * as React from "react";
import {useEffect, useRef, useState} from "react";

/******************************************************************
 * Tooltip
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function Tooltip(props: {
    label: string
    hoverElement: HTMLElement
    positionMode?: "size" | "global"
}) {

    /* ----------------------------------------------------------------
 	 * REF
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [show, setShow] = useState(false)
    const [isTouch, setIsTouch] = useState(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        addListeners()
        updatePosition()
        return () => {
            removeListeners()
        }
    }, [props.hoverElement])

    useEffect(() => {
        updatePosition()
    }, [show])

    function entered() {
        setShow(true)
    }

    function pointerLeft() {
        setShow(false)
    }

    function touchstarted() {
        setIsTouch(true)
    }

    function addListeners() {
        window.addEventListener("wheel", pointerLeft)
        props.hoverElement?.addEventListener("click", pointerLeft)
        props.hoverElement?.addEventListener("touchstart", touchstarted)
        props.hoverElement?.addEventListener("mouseenter", entered)
        props.hoverElement?.addEventListener("mouseleave", pointerLeft)
    }

    function removeListeners() {
        props.hoverElement?.removeEventListener("click", pointerLeft)
        props.hoverElement?.removeEventListener("touchstart", touchstarted)
        props.hoverElement?.removeEventListener("mouseenter", entered)
        props.hoverElement?.removeEventListener("mouseleave", pointerLeft)
        window.removeEventListener("wheel", pointerLeft)
    }

    function updatePosition() {
        if (!props.hoverElement) return
        const hoverElementRect = props.hoverElement.getBoundingClientRect()
        if (!hoverElementRect) return
        switch (props.positionMode) {
            case "size":
                rootRef.current.style.left = `${hoverElementRect.width}px`
                rootRef.current.style.top = `${hoverElementRect.height / 2}px`
                break
            case "global":
            default:
                rootRef.current.style.left = `${hoverElementRect.right}px`
                rootRef.current.style.top = `${hoverElementRect.top + hoverElementRect.height / 2}px`
                break
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="tooltip"
            ref={rootRef}
            data-show={show && !isTouch}>
            <div className="tooltip-label">
                {props.label}
            </div>
        </div>
    );

}
