import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {Modal} from "../Modal";
import {useServices} from "../../../hooks/useServices";
import {SplitView} from "../../../ui/splitview/SplitView";
import {CreateAcademyChapterModal} from "./modals/CreateAcademyChapterModal";
import {IAcademyChapterData} from "../../../../../shared/models/submodels/IAcademyChapterData";
import {SplitViewContent} from "../../../ui/splitview/content/SplitViewContent";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {DeleteAcademyChapterModal} from "./modals/DeleteAcademyChapterModal";
import {ChapterActionBar} from "./bars/ChapterActionBar";
import {EditAcademyChapterModal} from "./modals/EditAcademyChapterModal";
import {Article} from "../../../ui/article/Article";
import {EditModeActionBar} from "./bars/EditModeActionBar";
import {PromisedDelay} from "@webfruits/toolbox/dist/timer/PromisedDelay";
import {Divider} from "../../../ui/utils/divider/Divider";
import {useUtils} from "../../../hooks/useUtils";

/******************************************************************
 * AcademyModal
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function AcademyModal() {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const dialogRef = React.useRef<HTMLDialogElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state, dict, api} = useServices();
    const {multilangString, multilangObjectID} = useUtils();
    const {authUserIsAdmin, authUserHasPermission} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showCreateChapterModal, setShowCreateChapterModal] = useState<boolean>(false)
    const [showEditChapterModal, setShowEditChapterModal] = useState<boolean>(false)
    const [showDeleteChapterModal, setShowDeleteChapterModal] = useState<boolean>(false)
    const [currentContentLabel, setCurrentContentLabel] = useState<string>(null)
    const [currentChapterData, setCurrentChapterData] = useState<IAcademyChapterData>(null)
    const [chapters, setChapters] = useState<IAcademyChapterData[]>([])
    const [splitViewMobileMode, setSplitViewMobileMode] = useState<boolean>(false)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [initAnchorChapterID, setInitAnchorChapterID] = useState<string>(state.showAcademy.getValue()?.toString())
    const [skipTransitions, setSkipTransitions] = useState<boolean>(false)
    const [changingChapter, setChangingChapter] = useState<boolean>(false)
    const [version, setVersion] = useState<number>(0)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadChapters()
    }, [])

    useEffect(() => {
        if (changingChapter) return
        if (initAnchorChapterID && chapters?.length > 0) {
            setChangingChapter(true)
            setCurrentChapterData(chapters?.find(chapterData => chapterData._id === initAnchorChapterID))
            setInitAnchorChapterID(null)
            return
        }
        setCurrentChapterData(chapters?.find(chapterData => multilangString(chapterData.title) === currentContentLabel))
        PromisedDelay.wait(1).then(() => setChangingChapter(false))
        scrollToTop()
    }, [currentContentLabel, chapters])

    useEffect(() => {
        setSkipTransitions(true)
        PromisedDelay.wait(1).then(() => {
            setSkipTransitions(false)
        })
    }, [editMode])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function close() {
        state.showAcademy.setValue(null);
    }

    async function loadChapters() {
        const data = await api.academy.getChaptersWithArticles()
        setChapters(data)
    }

    function Entries(): ReactElement[] {
        const entries: ReactElement[] = []
        chapters.forEach((chapter) => {
            if (chapter.addSpacerAbove) {
                entries.push(<Divider key={chapter._id + "spacer"} style="splitview-menu-full"/>)
            }
            const articleID = multilangObjectID(chapter.article)
            entries.push(<SplitViewContent
                key={chapter._id}
                label={multilangString(chapter.title)}
                content={<Article
                    key={articleID}
                    hideTypeSelect={true}
                    edit={editMode}
                    articleID={articleID}/>}
            />)
        })
        return entries
    }

    function scrollToTop() {
        const modalFrame = dialogRef.current?.getElementsByClassName("modal-frame")?.[0]
        if (modalFrame) {
            modalFrame.scrollTop = 0
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <Modal
            closeAction={close}
            width="extra-large"
            height={splitViewMobileMode ? "auto" : "large"}
            type="article"
            padding="none"
            onDialogRef={ref => dialogRef.current = ref.current}
            className="academy-modal">
            <SplitView
                key={editMode.toString() + version.toString()}
                padding="modal"
                skipTransitions={skipTransitions}
                title={dict("academy.title")}
                showContentByLabel={multilangString(currentChapterData?.title)}
                onMobileMode={setSplitViewMobileMode}
                onCurrentContentLabelChanged={setCurrentContentLabel}
                sidebarActionBar={authUserIsAdmin && editMode && !splitViewMobileMode
                    ? <ChapterActionBar
                        chaptersData={chapters}
                        currentChapterData={currentChapterData}
                        onAddChapter={() => setShowCreateChapterModal(true)}
                        onEditChapter={() => setShowEditChapterModal(true)}
                        onDeleteChapter={() => setShowDeleteChapterModal(true)}
                        onUpdatedPosition={loadChapters}/>
                    : null}>
                {Entries()}
            </SplitView>
            {authUserHasPermission("article:update") &&
                <EditModeActionBar
                    editMode={editMode}
                    showLanugageSelector={true}
                    onEditModeChange={setEditMode}/>}
        </Modal>
        {showCreateChapterModal &&
            <CreateAcademyChapterModal
                onSuccess={loadChapters}
                onClose={() => setShowCreateChapterModal(false)}/>}
        {showEditChapterModal && currentChapterData &&
            <EditAcademyChapterModal
                chapterData={currentChapterData}
                onSuccess={loadChapters}
                onUpdateAcademyRequested={() => {
                    loadChapters()
                    setVersion(prevState => prevState + 1)
                }}
                onClose={() => setShowEditChapterModal(false)}/>}
        {showDeleteChapterModal && currentChapterData &&
            <DeleteAcademyChapterModal
                chapterData={currentChapterData}
                onSuccess={loadChapters}
                onClose={() => setShowDeleteChapterModal(false)}/>}

    </>

}
