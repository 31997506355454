import * as React from "react";
import {useState} from "react";
import {useEventState} from "../../../../../hooks/useEventState";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {DriverList} from "../../../../../ui/list/driver/DriverList";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {Headline} from "../../../../../ui/text/headings/Headline";
import {Input} from "../../../../../ui/form/elements/input/Input";
import {ITabbedContentChildProps} from "../../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {useGroupState} from "../../../../../hooks/useGroupState";
import {MongoObjectIDType} from "../../../../../../../shared/types/MongoObjectIDType";
import {ConfirmationMailModal} from "../modals/ConfirmationMailModal";
import {EventUtils} from "../../../../../../../shared/utils/EventUtils";
import {IconButton} from "../../../../../ui/buttons/icon/IconButton";
import {ContextMenu} from "../../../../../ui/context/ContextMenu";
import {ContextMenuSelectable} from "../../../../../ui/context/elements/ContextMenuSelectable";
import {ContextMenuDivider} from "../../../../../ui/context/elements/ContextMenuDivider";
import {ContextMenuLabel} from "../../../../../ui/context/elements/ContextMenuLabel";

/******************************************************************
 * EventDriversList
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export type EventDriverListType = "requesting" | "pending" | "registered"

export function EventDriversList(props: {
    tabbedContent?: ITabbedContentChildProps
    type: EventDriverListType
    progressing?: boolean
    onRemoveClicked?: (userID: MongoObjectIDType) => void
    onAddToNextClicked?: (userID: MongoObjectIDType) => void
    onContactClicked?: (userID: MongoObjectIDType) => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {
        eventID,
        eventData,
        eventAccess,
        eventEntryFee,
        eventMaxDrivers,
        eventIsFinished,
        eventRequestingDrivers,
        eventRegisteredDrivers,
        eventPendingDrivers,
        eventEntryFeeMessage,
        eventRegisteredMessage,
        eventRequestsForbidden,
        eventAutoAcceptRequests
    } = useEventState()
    const {isGroupAdmin} = useGroupState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showConfirmationMail, setShowConfirmationMail] = useState<boolean>(false)
    const [confirmationMessage, setConfirmationMessage] = useState<string>(message)
    const [savingEntryFee, setSavingEntryFee] = useState<boolean>(false)
    const [savingMaxDrivers, setSavingMaxDrivers] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function label(): string {
        switch (props.type) {
            case "requesting":
                return dict("event.drivers.requesting.label") + " (" + (eventRequestingDrivers?.length ?? 0) + ")"
            case "pending":
                return dict("event.drivers.pending.label") + " (" + (eventPendingDrivers?.length ?? 0) + ")"
            case "registered":
                return dict("event.drivers.registered.label") + " (" + (eventRegisteredDrivers?.length ?? 0) + ")"
        }
    }

    function emtpyInfo(): string {
        switch (props.type) {
            case "requesting":
                return dict("event.drivers.requesting.empty.info")
            case "pending":
                return dict("event.drivers.pending.empty.info")
            case "registered":
                return dict("event.drivers.registered.empty.info")
        }
    }

    function listUsers(): IUserData[] {
        switch (props.type) {
            case "requesting":
                return eventRequestingDrivers ?? []
            case "pending":
                return eventPendingDrivers ?? []
            case "registered":
                return eventRegisteredDrivers ?? []
        }
    }

    function hasMessage(): boolean {
        switch (props.type) {
            case "pending":
                return eventEntryFeeMessage?.length > 10
            case "registered":
                return eventRegisteredMessage?.length > 10
        }
        return false
    }

    function defaultMessage(): string {
        switch (props.type) {
            case "pending":
                return dict("event.driver.pending.mail.text")
            case "registered":
                return dict("event.driver.registered.mail.text")
        }
        return ""
    }

    function message(): string {
        switch (props.type) {
            case "pending":
                return eventEntryFeeMessage ?? defaultMessage()
            case "registered":
                return eventRegisteredMessage ?? defaultMessage()
        }
        return ""
    }

    async function saveEntryFee(value: string): Promise<Response> {
        setSavingEntryFee(true)
        await api.event.update(eventID, {drivers: {entryFee: value}})
        setSavingEntryFee(false)
        return null
    }

    async function toggleRequestsForbidden() {
        await api.event.update(eventID, {drivers: {requestsForbidden: !eventRequestsForbidden}})
    }

    async function toggleAutoAcceptRequests() {
        const newValue = !eventAutoAcceptRequests
        if (newValue
            && EventUtils.isEntryFeeMessageNeededButNotDefined(eventData)) {
            state.showMessage.setValue({
                type: "normal",
                message: dict("event.request.missing.entryFeeMessage")
            })
            return
        }
        await api.event.update(eventID, {drivers: {autoAcceptRequests: newValue}})
    }

    async function saveMaxDrivers(value: string): Promise<Response> {
        setSavingMaxDrivers(true)
        await api.event.update(eventID, {drivers: {maxDrivers: parseInt(value)}})
        setSavingMaxDrivers(false)
        return null
    }

    function showContactModalToMessageAll() {
        state.showContact.setValue({
            sendMessageTo: listUsers(),
            messageType: "users"
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        <ContentLayout
            framed={true}
            className="event-drivers-list"
            alignSelf="start"
            alignContent="start"
            rowTemplate={eventIsFinished ? null : "min-content min-content min-content"}
            minHeight={360}
            gap="small">
            <ContentLayout
                className="event-driver-list-header"
                columnTemplate={eventIsFinished ? null : "1fr min-content"}
                gap="small"
                justifyContent="space-between">
                <Headline
                    text={label()}
                    style="h5-underlined"/>
                {isGroupAdmin && !eventIsFinished &&
                    <ContentLayout
                        gap="small"
                        columnTemplate={props.type == "registered" ? "min-content min-content" : null}>
                        {props.type == "registered" &&
                            <IconButton
                                type="add"
                                size="tiny"
                                onClick={() => state.showSelectDrivers.setValue(true)}/>}
                        <ContextMenu
                            type="icon"
                            iconScale={1.1}
                            iconSize="tiny"
                            iconColor={props.type == "pending" && !hasMessage() ? "red" : null}
                            iconType="settings"
                            iconOpacityMode={props.type == "pending" && !hasMessage() ? "none" : null}
                            stateIconType={props.type != "requesting" && !hasMessage() ? "warning" : null}
                            stateIconColor="yellow"
                            stateIconScale={0.9}
                            stopPropagation={true}>
                            {props.type == "requesting" && <>
                                <ContextMenuSelectable
                                    label={dict("event.drivers.requestForbidden.false")}
                                    onClick={toggleRequestsForbidden}
                                    selected={!eventRequestsForbidden}/>
                                <ContextMenuSelectable
                                    label={dict("event.drivers.requestForbidden.true")}
                                    onClick={toggleRequestsForbidden}
                                    selected={eventRequestsForbidden}/>
                                <ContextMenuDivider/>
                                <ContextMenuSelectable
                                    label={dict("event.drivers.autoAcceptRequests.false")}
                                    onClick={toggleAutoAcceptRequests}
                                    selected={!eventAutoAcceptRequests}/>
                                <ContextMenuSelectable
                                    label={dict("event.drivers.autoAcceptRequests.true")}
                                    onClick={toggleAutoAcceptRequests}
                                    selected={eventAutoAcceptRequests}/>
                                <ContextMenuDivider/>
                            </>}
                            {props.type != "requesting" && <>
                                <ContextMenuLabel
                                    icon="mail"
                                    iconColor={props.type == "pending" && !hasMessage() ? "red" : null}
                                    iconScale={0.8}
                                    stateIconType={!hasMessage() ? "warning" : null}
                                    stateIconColor={!hasMessage() ? "yellow" : null}
                                    stateIconScale={0.9}
                                    label={dict("event.drivers.list.message.edit.label")}
                                    onClick={() => setShowConfirmationMail(true)}/>
                                <ContextMenuDivider/>
                            </>}
                            {listUsers()?.length > 0 && <>
                                <ContextMenuLabel
                                    icon="group"
                                    iconScale={0.8}
                                    stateIconType="mail"
                                    stateIconColor="yellow"
                                    stateIconScale={0.8}
                                    label={dict("event.drivers.list.message.toAll.label")}
                                    onClick={showContactModalToMessageAll}/>
                                <ContextMenuDivider/>
                            </>}
                            <ContextMenuLabel
                                icon="help"
                                iconScale={0.8}
                                label={dict("help.label")}
                                onClick={() => state.showHelp.setValue(
                                    "event.participation." + props.type + (props.type == "registered" && eventAccess == "only-registered-drivers-after-paid" ? ".paid" : "")
                                )}/>
                        </ContextMenu>
                    </ContentLayout>}
            </ContentLayout>
            {isGroupAdmin && !eventIsFinished &&
                <ContentLayout
                    className="event-driver-list-settings"
                    alignItems="center"
                    justifyItems="start"
                    minHeight={40}
                    gap="small"
                    framed={false}>
                    {props.type == "requesting" && <>
                        <Input
                            size="small"
                            inline={true}
                            type="number"
                            minNumber={0}
                            maxNumber={1000}
                            processing={savingMaxDrivers}
                            placeholder={dict("event.maxDrivers.placeholder")}
                            defaultValue={eventMaxDrivers?.toString() ?? null}
                            label={dict("event.maxDrivers.label")}
                            action={saveMaxDrivers}/>
                    </>}
                    {props.type == "pending" && <>
                        <Input
                            size="small"
                            inline={true}
                            type="text"
                            processing={savingEntryFee}
                            defaultValue={eventEntryFee}
                            label={dict("event.entryfee.label")}
                            action={saveEntryFee}/>
                    </>}
                </ContentLayout>}
            <DriverList
                data={listUsers()}
                primaryIconType="mail"
                secondaryIconType="cancel"
                tertiaryIconType="add"
                size="small"
                isBusy={props.progressing}
                userClickable={true}
                emptyDataInfo={emtpyInfo()}
                visibleRows={25}
                onPrimaryIconClick={isGroupAdmin ? props.onContactClicked : null}
                onSecondaryIconClick={isGroupAdmin ? props.onRemoveClicked : null}
                onTertiaryIconClick={isGroupAdmin ? props.onAddToNextClicked : null}/>
        </ContentLayout>
        {showConfirmationMail &&
            <ConfirmationMailModal
                type={props.type}
                message={confirmationMessage}
                defaultMessage={message()}
                onMessageChange={setConfirmationMessage}
                onRequestClose={() => setShowConfirmationMail(false)}/>}
    </>


}
