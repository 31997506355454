import * as React from "react";
import {MailBodyBlockActionBar} from "../actionbar/MailBodyBlockActionBar";
import {IMailData} from "../../../../../../../shared/models/IMailData";
import {useServices} from "../../../../../hooks/useServices";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {EmptyDataInfo} from "../../../../../ui/info/empty/EmptyDataInfo";

/******************************************************************
 * MailEmptyBodyBlock
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function MailEmptyBodyBlock(props: {
    mailData: IMailData
    onChange: () => void
}) {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict} = useServices()

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="mail-empty-body-block"
            framed={true}>
            <EmptyDataInfo text={dict("mail.body.block.empty.info")}/>
            <MailBodyBlockActionBar
                mailData={props.mailData}
                block={null}
                onChange={props.onChange}/>
        </ContentLayout>
    );

}
