import {ErrorCode} from "../keys/ErrorCode";

/******************************************************************
 * SessionDurationValidation
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export class SessionDurationValidation {

    static get REGEXP() {
        return /^\d+:\d{2}:\d{2}$/;
    }

    static schemaValidator() {
        return {
            validate: {
                validator: value => SessionDurationValidation.isValid(value),
                message: ErrorCode.SESSION_DURATION_INVALID
            },
        }
    };

    static isValid(mail: string): boolean {
        return SessionDurationValidation.REGEXP.test(mail.toLowerCase());
    }
}
