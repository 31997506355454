import * as React from "react";

/******************************************************************
 * EditIcon
 *
 * @author matthias.schulz@driftclub.com
 *****************************************************************/

export function EditIcon(props: {
    scale?: number
}) {
    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="edit-icon">
            <svg width={14 * (props.scale ?? 1)} height={14 * (props.scale ?? 1)} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.474121 10.6793V13.3545H3.1493L11.0429 5.4609L8.36768 2.78572L0.474121 10.6793ZM13.1045 3.39922C13.3828 3.12101 13.3828 2.66801 13.1045 2.38979L11.4388 0.724045C11.1606 0.445826 10.7076 0.445826 10.4294 0.724045L9.12387 2.02953L11.799 4.70471L13.1045 3.39922Z" fill="white"/>
            </svg>
        </div>
    );

}
